<!-- <div class="workspace-permissions">
	<div class="d-flex justify-content-between permission-header">
		<button type="button" class="btn add-user" (click)="showUserModal()">
			<i class="fa fa-plus-circle" aria-hidden="true"></i>
			{{'workspace_edit_addNewUser'|translate}}
		</button>
		<button (click)="showPermissionDetailsInfo()" class="btn add-user">
			<i class="fa fa-users fa-fw"></i>
			{{'permissionDetails' | translate}}
		</button>
	</div>
	<div class="permission-list table-responsive p-3">
		<div *ngIf="rowFiltered" class="d-flex justify-content-end">
			<label class="p-2">Total: {{filteredUsers.length}} records</label>
		</div>
		<ag-grid-angular #userPermissionTable [rowData]="filteredUsers" [debounceVerticalScrollbar]="true"
			[headerHeight]="72" [rowHeight]="80" [defaultColDef]="defaultColDef" class="ag-theme-alpine"
			[columnDefs]="userPermissionColumnsDef" [context]="tableContext" [components]="components"
			[domLayout]="'autoHeight'" [enableCellTextSelection]="true" [ensureDomOrder]="true">
		</ag-grid-angular>
	</div>
	<div class="d-flex justify-content-between permission-footer">
		<button type="button" class="btn add-user" (click)="showUserModal()">
			<i class="fa fa-plus-circle" aria-hidden="true"></i>
			{{'workspace_edit_addNewUser'|translate}}
		</button>
	</div>
</div> -->
<div class="row">
	<div class="col-lg-auto col-md-12 col-sm-12 d-flex justify-content-center">
		<div class="workspace-img-div " (click)="$event.stopPropagation();editAvatar()">
			<img *ngIf="_workspace?.avatarUrl || _workspace?.coverUrl" class="workspace-img-div" [src]="_workspace?.avatarUrl || _workspace?.coverUrl" alt="">
			<span *ngIf="!(_workspace?.avatarUrl || _workspace?.coverUrl)" class="c-full-white profile-text text-uppercase h-100 w-100 d-flex"
				[style.backgroundColor]="timelineService.getColorFromName(_workspace?.name)">
				{{_workspace?.name | slice:0:2}}
			</span>
			<span class="badge badge-edit-button" (click)="$event.stopPropagation();editCover()">
				<i class="fa fa-cog fa-lg"></i>
			</span>
		</div>
	</div>
	<div class="col-lg-11 col-md-12 col-sm-12 d-flex flex-wrap" [style.gap]="'5px'">
		<div class="w-100">
			<span class="f-12 _700">{{'workspace_workspaceName' | translate}}</span>
		</div>
		<div class="w-100">
			<form [formGroup]="headerForm" class="workspace-form">
				<input type="text" (click)="updateWorkspaceName()" class="input-section f-12 _400 w-100 pl-2"
					placeholder="{{'workspace_workspaceName' | translate}}" formControlName="name">
			</form>
		</div>

	</div>
</div>
<div class="card mt-4 mb-4">
	<div class="d-flex flex-wrap w-100 mt-3 p-3">
		<button class="add-user-btn align-items-center btn d-flex justify-content-center text-nowrap text-uppercase"
			(click)="showUserModal()">
			<div class="circle d-flex justify-content-center align-items-center">
				<i class="fa fa-plus" aria-hidden="true"></i>
			</div>
			<span class="ml-2">{{'workspace_edit_addNewUser' | translate}}</span>
		</button>
	</div>
	<div class="d-flex flex-wrap w-100">
		<!-- <div class="col-lg-3 col-md-6 col-sm-12 d-flex flex-lg-nowrap flex-md-nowrap flex-sm-wrap w-100 flex-wrap my-3"
			[style.gap]="'10px'">
			<div
				class="d-flex justify-content-center justify-content-lg-start justify-content-md-end justify-content-sm-center w-100">
				<select class="user-and-teams f-11 _400" name="" id="">
					<option class="f-10 _400" value="">Users view</option>
					<option class="f-10 _400" value=""></option>
				</select>
			</div>
			<div
				class="d-flex justify-content-center justify-content-lg-start justify-content-md-end justify-content-sm-center w-100">
				<select class="user-and-teams f-11 _400" name="" id="">
					<option class="f-11 _400" value="">Teams</option>
					<option class="f-11 _400" value=""></option>
				</select>
			</div>
		</div> -->
		<div class="col-12 d-flex flex-lg-nowrap flex-md-wrap flex-sm-wrap w-100 my-3">
			<div
				class="d-flex justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center w-100">
				<button (click)="showPermissionDetailsInfo()" class="permission-detail-btn btn f-10 _400"> PERMISSION
					DETAILS </button>
			</div>
		</div>
	</div>
	<div class="mt-4">
		<!-- Table Header -->
		<div class="d-lg-flex d-md-flex d-none w-100">
			<div class="col-md-3 table-header-text">User</div>
			<div class="col-md-4 table-header-text">Email</div>
			<div class="col-md-3 table-header-text">Role</div>
			<div class="col-md-2 table-header-text">Actions</div>
		</div>
		<!-- <hr class="d-none d-md-block"> -->

		<!-- User Row (Repeat this block for each user) -->
		<div class="d-flex flex-wrap w-100 py-3 user-detail" *ngFor="let user of filteredUsers">
			<div class="align-items-center  col-12 col-md-3 d-flex justify-content-start flex-wrap p-0"
				[style.gap]="'20px'">
				<span class="d-md-none fw-bold table-header-text ml-1">Name </span>
				<mtm-user-profile-picture *ngIf="!user.user?.avatarUrl" [user]="user" [username]="user.username"
					width="35" height="35" />
				<img *ngIf="user.user?.avatarUrl" [src]="user.user?.avatarUrl" class="rounded-circle" alt="" width="35"
					height="35">
				<span class="f-12 _400">{{user.fullName}}</span>
				<!-- <span class="f-12 _400  ms-2 d-none d-md-inline">{{user.fullName}}</span> -->
			</div>
			<div class="align-items-center  col-12 col-md-4 d-flex justify-content-start flex-wrap p-0"
				[style.gap]="'20px'">
				<span class="d-md-none fw-bold table-header-text ml-1">Email</span>
				<span class="f-12 _400 _1-line-text-ellipsis">{{user.user.email}}</span>
			</div>
			<div class="align-items-center  col-12 col-md-3 d-flex justify-content-start flex-wrap p-0"
				[style.gap]="'25px'">
				<span class="d-md-none fw-bold table-header-text ml-1">Role</span>
				<select class="form-select role-dropdown f-12 _400" (change)="updateRole($event.target.value,user)">
					<option selected class="f-12 _400" [value]="''">{{ ('role' + user.user.globalRole) | translate }}</option>
					<option *ngFor="let userRole of availableRoles" [value]="userRole.code" class="f-12 _400">
						{{ ('role' + userRole.code) | translate }}
					</option>
				</select>
			</div>
			<div class="align-items-center col-12 col-md-2  d-flex justify-content-start flex-wrap p-0"
				[style.gap]="'10px'">
				<span class="d-md-none fw-bold table-header-text ml-1">Action</span>
				<button (click)="removeUser(user.user)" class="btn btn-link p-0"><i class="fa fa-trash"
						[style.color]="'#E45933'"></i>
					<span class="c-mtm-full-black f-12 _400 ml-1">Remove</span></button>
			</div>
		</div>
		<!-- Repeat end -->
	</div>
</div>
<mtm-upload-file [preDefinedFilter]="['IMAGE_FILTER']" [hidden]="true" #avatarUploader
(files)="fileChangeListener($event)"  [style.display]="'none'">
</mtm-upload-file>

<mtm-upload-file [preDefinedFilter]="['IMAGE_FILTER']" [hidden]="true" #coverUploader
(files)="fileChangeListener($event, 'cover')"  [style.display]="'none'">
</mtm-upload-file>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TimelineService } from 'app/shared/services/timeline.service';
import { Subject } from 'rxjs';
import { CreateCampaignComponent } from '../create-campaign/create-campaign.component';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { AuthService } from 'app/shared/services/auth.service';
import { environment } from 'environments/environment';
import { ThemeService } from "app/shared/services/theme.service";
import { ActiveWorkspaceUpdatedEvent, Brand, DriveCampaign, HeaderInfo, WorkspaceDTO } from "app/shared/interfaces";
import { takeUntil } from "rxjs/operators";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { EmitterService } from "app/shared/services/emitter.service";

export enum createBtnOptions {
  PROJECT = 'Project',
  CAMPAIGN = 'Campaign',
  DRIVE_CAMPAIGN = 'Drive Campaign',
  WORKSPACE = 'Workspace'
}

export enum createBtnCpdOptions {
  PROJECT = 'Project',
  DRIVE_CAMPAIGN = 'Campaign',
  WORKSPACE = 'Workspace'
}

@Component({
  selector: 'mtm-analytics-drivecampaign-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {

  images = {
    analytics: '../../../assets/img/project-v2/analytics.png',
    campaignDriveDashboard: '../../../assets/img/project-v2/new-project.png',
    dashboard: '../../../assets/img/project-v2/workspace-img.png',
    timeline: '../../../assets/img/project-v2/timeline-header.png',
    ysl: '../../../assets/img/project-v2/ysl-dashboard.png',
    taskproject: '../../../assets/img/project-v2/task-project-header.png',
    scenario2: '../../../assets/img/project-v2/scenario2Dashboard.png',
    scenario4ysl: '../../../assets/img/project-v2/scenario4yslheader.png'

  }
  // headerImage: string = '../../../assets/img/project-v2/black.png';
  headerImage: string
  isDashboard: boolean = true;
  title: string = '';
  subTitle: string = ''
  timelineId: string;
  ngUnsubscribe = new Subject();
  workspaceId: string;
  upperCase: boolean = false;
  isAnalytics: boolean = false;
  authUser: any;
  isHr: boolean = false;
  isYsl: boolean = false;
  isNotificationView: boolean = false;
  isTaskProject: boolean = false;
  isRecap: boolean = false
  isGeneral: boolean = false;
  cacheBuster: string = new Date().getTime().toString();
  isWorkspaceDashboard: boolean = false;
  searchString: string;
  headerTitle: string = '';
  headerLabel: string = '';
  isArchived: boolean = false;
  isFavorites: boolean = false;
  isAll: boolean = true;
  isDriveCampaignComponet: boolean = false;
  isCampaignComponent: boolean = false;
  isProjectComponent: boolean = false;
  isSharedComponent: boolean = false;
  showSideNav: boolean = false;
  isWorkspaceActive: boolean = false;
  isCreateActive: boolean = false;
  createBtnRouts: any;
  modal: NgbModalRef;
  canCreateProject: boolean = false;
  showCampaign: boolean;
  showDriveCampaign: boolean;
  isCpd: boolean = false;
  canCreateWorkspace: boolean = false;
  workspace: WorkspaceDTO | null = null;
  campaign: DriveCampaign | null = null;
  currentBrand: Brand | null;

  constructor(
    private timelineService: TimelineService,
    public router: Router,
    private modalService: NgbModal,
    private projectV2Service: ProjectV2ServiceService,
    private permissionService: PermissionService,
    private authService: AuthService,
    private themeService: ThemeService,
    private workspaceService: WorkspaceService,
  ) {
  }


  ngOnInit() {
    // this.isWorkspaceDashboard = false;
    // // this.showSideNav = false;
    this.workspace = this.workspaceService.getActiveWorkspace();
    this.currentBrand = this.authService.getMyBrand();
    const isPaidSubscriber = this.authService.hasPaidSubscription();
    this.canCreateWorkspace = this.permissionService.hasAuthority(Authority.Z, null) || (isPaidSubscriber && this.permissionService.hasAuthority(Authority.S, null));
    this.canCreateProject = ((this.permissionService.hasAuthority(Authority.Z, this.permissionService.GLOBAL_SCOPE_PROJECT_ID) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription()) && this.permissionService.hasAuthority(Authority.P, this.permissionService.GLOBAL_SCOPE_PROJECT_ID));
    const authUser = this.authService.getAuthUser();
    this.showCampaign = authUser.campaign;
    this.isCpd = authUser.companyId == environment.ltg.cpdCompanyId;


    let buttonOptions = Object.values(!this.isCpd ? createBtnOptions : createBtnCpdOptions);

    if (!this.canCreateWorkspace) {
      buttonOptions = buttonOptions.filter((item) => item !== 'Workspace');
    }
    this.createBtnRouts = buttonOptions;

    this.showDriveCampaign = authUser.drivecampaign;
    this, this.timelineService.resetTabsEmmiter.subscribe(data => {
      this.allItems();
    })
    let image = this.timelineService.getComponentDetail();
    this.timelineService.isDriveNav.subscribe(data => {
      this.showSideNav = data;
    });
    this.timelineService.workspaceDashboardEmitter.subscribe(data => {
      this.isWorkspaceDashboard = data;
      this.isWorkspaceActive = true;
      this.isSharedComponent = false;
      this.isProjectComponent = false;
      this.isCampaignComponent = false;
      this.isDriveCampaignComponet = false;
    });


    // remove header title if  change view
    this.headerTitle = '';
    this.timelineService.imageEventEmitter.subscribe((data: any) => {
      this.headerImage = data.url + `?v=${this.cacheBuster}`; //here we are add ?v=${this.cacheBuster}  asquery params for latest image cacheBuster is current time
      this.title = data.userName;
      this.subTitle = data.title;
      this.isTaskProject = data.isTaskProject;
      this.isDashboard = data.isDashboard;
      this.isGeneral = data.isGeneral;
      this.isRecap = data.isRecap;
      this.headerTitle = data.headerTitle;
      if (this.headerTitle) {
        switch (this.headerTitle) {
          case 'Drive Campaigns':
            this.isDriveCampaignComponet = true;
            this.isCampaignComponent = false;
            this.isSharedComponent = false;
            this.isProjectComponent = false;
            this.isWorkspaceActive = false;
            this.isWorkspaceDashboard = false;
            this.headerLabel = 'loreal_driveCampaigns';
            break;
          case 'Campaigns':
            this.isCampaignComponent = true;
            this.isSharedComponent = false;
            this.isProjectComponent = false;
            this.isWorkspaceActive = false;
            this.isDriveCampaignComponet = false;
            this.isWorkspaceDashboard = false;
            this.headerLabel = 'loreal_campaigns';
            break;
          case 'Projects':
            this.isProjectComponent = true;
            this.isWorkspaceActive = false;
            this.isSharedComponent = false;
            this.isCampaignComponent = false;
            this.isDriveCampaignComponet = false;
            this.isWorkspaceDashboard = false;
            this.headerLabel = 'projects';
            break;
          case 'Shared':
            this.isSharedComponent = true;
            this.isProjectComponent = false;
            this.isWorkspaceActive = false;
            this.isCampaignComponent = false;
            this.isDriveCampaignComponet = false;
            this.isWorkspaceDashboard = false;
            this.headerLabel = 'loreal_shared';
            break;
          case 'Workspaces':
            this.isWorkspaceDashboard = true;
            this.isWorkspaceActive = true;
            this.isSharedComponent = false;
            this.isProjectComponent = false;
            this.isCampaignComponent = false;
            this.isDriveCampaignComponet = false;
            this.headerLabel = 'workspaces';
            break;
          default:
            break;
        }
      }
    })

    this.syncHeaderInfo();

    this.router.events.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (event) => {
          if (!(event instanceof NavigationEnd)) {
            return;
          }
          this.syncHeaderInfo();
        }
      });

    this.timelineService.activeCampaign$.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((campaign) => {
        this.campaign = campaign;
        this.syncHeaderInfo();
      }
      );

    EmitterService.get(ActiveWorkspaceUpdatedEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (updates) => {
        this.workspace = updates.workspace;
        this.syncHeaderInfo();
      }
    });

    EmitterService.get('BRAND_UPDATED').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (brand) => {
        this.currentBrand = brand;
        this.syncHeaderInfo();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.workspace && this.workspace.name)
      this.timelineService.workspaceName = this.workspace.name;

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.themeService.clearHeaderInfo();
    this.themeService.updateContent({
      fullWidthContent: false,
    });
  }

  private syncHeaderInfo() {
    let headerInfo: HeaderInfo = {
      title: '',
      subtitle: '',
      avatarName: '',
      avatarImage: '',
      subtitleParams: {}
    };
    if (this.campaign) {
      headerInfo.subtitle = this.isCpd ? 'menu_campaignSubtitle' : 'menu_driveCampaignSubtitle';
      headerInfo.subtitleParams = { name: this.campaign.name };
    }

    if (this.workspace) {
      headerInfo.avatarImage = this.workspace.avatarUrl || this.workspace.coverUrl;
      headerInfo.avatarName = this.workspace.name;
      headerInfo.title = this.workspace.name;
    } else {
      headerInfo.avatarImage = this.currentBrand?.logo || '';
      headerInfo.avatarName = this.currentBrand?.name || '';
    }

    let url = location.pathname;

    if (url.endsWith('/')) {
      url = url.substring(0, url.length - 1);
    }

    if (url === '/workspaces/campaign-drive') {
      if (this.workspace) {
        headerInfo.subtitle = 'menu_campaignsProjects';
      } else {
        headerInfo.title = this.isCpd ? 'menu_campaignsProjects' : 'menu_driveCampaignsProjects';
      }
    } else if (url === '/workspaces') {
      headerInfo.title = 'workspaces';
    } else if (url === '/workspaces/projects') {
      headerInfo.title = 'projects';
    } else if (url.includes('/workspaces/analytics')) {
      headerInfo.title = 'leftBarReporting';
    }

    this.themeService.updateHeaderInfo(headerInfo);

    this.themeService.updateContent({
      fullWidthContent: true,
    });
  }

  searchItem() {
    this.timelineService.searchEventEmitter.emit(this.searchString);
  }

  allItems() {
    this.isArchived = false;
    this.isFavorites = false;
    this.isAll = true;
    this.timelineService.emitIsArchived(false);
    this.timelineService.emitIsFavorites(false);
    this.timelineService.emitIsAll(true);
  }

  favoriteItems() {
    this.isArchived = false;
    this.isFavorites = true;
    this.isAll = false;
    this.timelineService.emitIsAll(false);
    this.timelineService.emitIsArchived(false);
    this.timelineService.emitIsFavorites(true);
  }

  getArchiveProjects() {
    this.isArchived = true;
    this.isFavorites = false;
    this.isAll = false;
    this.timelineService.emitIsAll(false);
    this.timelineService.emitIsFavorites(false);
    this.timelineService.emitIsArchived(true);
  }

  setHeaderTitle(title: string, componentName: string) {
    this.timelineService.workspaceName = '';
    this.headerTitle = title;
    this.timelineService.componentName.next({
      componentName: componentName,
      bannerUrl: ''
    });
  }

  isActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  createNew() {
    this.isCreateActive = !this.isCreateActive;
  }

  createNewItem(event: string) {
    this.isCreateActive = !this.isCreateActive;
    switch (event) {
      case createBtnOptions.PROJECT:
        this.router.navigate(['/projects/new/company'])
        break;
      case createBtnOptions.CAMPAIGN:
        if (this.isCpd) {
          this.projectV2Service.driveCoordinatorMilestone = undefined;
          this.projectV2Service.timelineId = null;
          this.projectV2Service.updateFormData({}, {})
          this.router.navigate([`workspaces/campaigndrive/${this.workspaceId}/general`]);
          this.timelineService.isDriveNav.emit(false);
        } else {
          this.modal = this.modalService.open(CreateCampaignComponent, { size: 'lg' });
          this.modal.result.then(value => { })
        }
        break;
      case createBtnOptions.DRIVE_CAMPAIGN:
        this.projectV2Service.driveCoordinatorMilestone = undefined;
        this.projectV2Service.timelineId = null;
        this.projectV2Service.updateFormData({}, {})
        this.router.navigate([`workspaces/campaigndrive/${this.workspaceId}/general`]);
        this.timelineService.isDriveNav.emit(false);
        break;
      case createBtnOptions.WORKSPACE:
        this.router.navigate(['/workspace/new']);
        break;
      default:
        break;
    }
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { ActiveWorkspaceUpdatedEvent, CachedWorkspaceProjectsUpdatedEvent, CachedWorkspacesUpdatedEvent, WorkspaceDTO } from "../interfaces/workspace.interface";
import { ApiService } from "./api.service";
import { EmitterService } from "./emitter.service";

@Injectable()
export class WorkspaceService {
	private activeWorkspace: WorkspaceDTO = null;
	private inMarketplace: boolean = false; //placeholder workspace for production company
	private availableWorkspaces: any = []; //cached workspaces
	private availableProjects: any = []; //cached projects
	private activeWorkspaceCover: string = '';
	private activeWorkspaceAvatar: string = '';

	private messageSource = new BehaviorSubject(false);
	currentStatus = this.messageSource.asObservable();
	public formValue:any;
	//for including workspaceId in url
	private currentId = new BehaviorSubject({});
	workspaceId = this.currentId.asObservable();

	constructor(private apiService: ApiService) {
	}

	changeWorkspaceId(id: string, name?: string) {
		this.currentId.next({ id, name });
	}

	changeStatus(isClicked: boolean) {
		this.messageSource.next(isClicked)
	}

	get ActiveWorkspaceCover() {
		return this.activeWorkspaceCover;
	}

	get ActiveWorkspaceAvatar() {
		return this.activeWorkspaceAvatar;
	}

	getWorkspaceById(companyId: string, workspaceId: string, includeBrand: boolean = false): Observable<any> {
		const url = `/api/workspace/${companyId}/${workspaceId}?includeBrandId=${includeBrand}`;
		return this.apiService.httpGet(url);
	}

	getWorkspaces(companyId: string): Observable<any> {
		const url = `/api/workspace/${companyId}`;
		return this.apiService.httpGet(url);
	}

	getProjectsInWorkspaces(companyId: string, workspaceId: string): Observable<any> {
		const url = `/api/workspace/${companyId}/workspace/${workspaceId}`;
		return this.apiService.httpGet(url);
	}

	createWorkspace(data: WorkspaceDTO, brandId: string) {
		let url = `/api/workspace/${data.companyId}`;
		const urlParams = [];
		if (brandId) {
			urlParams.push('brandId=' + encodeURIComponent(brandId));
		}
		const queryString = urlParams.join('&');
		if (queryString) {
			url += '?' + queryString;
		}
		return this.apiService.httpPost(url, data);
	}

	editWorkspace(workspaceId: string, data: WorkspaceDTO) {
		const url = `/api/workspace/${data.companyId}/${workspaceId}`;
		return this.apiService.httpPut(url, data);
	}

	//note: companyId here refers to current user's companyId
	//the ids of workspaces can be from different company
	getWorkspacesByIds(companyId: string, ids: string[]): Observable<any> {
		const idsFormat = ids.join(',');
		const url = `/api/workspace/${companyId}/byIds?workspaceIds=${idsFormat}`;
		return this.apiService.httpGet(url);
	}

	addUserToWorkspace(companyId: string, workspaceId: string, userName: string, roleType: string, defaultProjectRoles: string[]) {
		const url = `/api/workspace/${companyId}/${workspaceId}/addUser/${userName}/${roleType}?defaultProjectRoles=${defaultProjectRoles.join(',')}`;
		return this.apiService.httpPost(url, {});
	}

	removeUserFromWorkspace(companyId: string, workspaceId: string, userName: string) {
		const url = `/api/workspace/${companyId}/${workspaceId}/removeUser/${userName}`;
		return this.apiService.httpPost(url, {});
	}

	deleteWorkspace(companyId: string, workspaceId: string) {
		const url = `/api/workspace/${companyId}/workspace/${workspaceId}`;
		return this.apiService.httpDelete(url);
	}

	deleteWorkspaceCover(companyId: string, workspaceId: string): Observable<any> {
		return this.apiService.httpDelete(`/api/workspace/${companyId}/${workspaceId}/cover`);
	}

	setActiveWorkspace(workspace: WorkspaceDTO) {
		this.activeWorkspace = { ...workspace };
		this.inMarketplace = false;
		EmitterService.get(ActiveWorkspaceUpdatedEvent).emit({
			workspace,
			inMarketplace: false
		});
	}

	unsetActiveWorkspace() {
		this.changeStatus(false)
		this.activeWorkspace = null;
		this.inMarketplace = false;
		this.activeWorkspaceAvatar = '';
		this.activeWorkspaceCover = '';
		EmitterService.get(ActiveWorkspaceUpdatedEvent).emit({
			workspace: null,
			inMarketplace: this.inMarketplace
		});
		this.currentId.next({ id: '', name: '' });
	}

	getActiveWorkspace(): WorkspaceDTO | null {
		if (!this.activeWorkspace)
			return null;
		this.changeWorkspaceId(this.activeWorkspace.id, this.activeWorkspace.name)
		return { ...this.activeWorkspace };
	}

	setInMarketplace(isIn: boolean) {
		this.inMarketplace = isIn;
		if (isIn) {
			this.activeWorkspace = null;
			this.activeWorkspaceAvatar = '';
			this.activeWorkspaceCover = '';
		}
		EmitterService.get(ActiveWorkspaceUpdatedEvent).emit({
			workspace: this.activeWorkspace,
			inMarketplace: this.inMarketplace
		});
	}

	getInMarketplace(): boolean {
		return this.inMarketplace;
	}

	setCachedWorkspaces(workspaces: any[]) {
		this.availableWorkspaces = workspaces;
		EmitterService.get(CachedWorkspacesUpdatedEvent).emit([...workspaces]);
	}

	getCachedWorkspaces() {
		return [...this.availableWorkspaces];
	}

	setCachedProjects(projects: any[]) {
		this.availableProjects = projects;
		EmitterService.get(CachedWorkspaceProjectsUpdatedEvent).emit([...projects]);
	}

	getCachedProjects() {
		return [...this.availableProjects];
	}

	clearWorkspaceCache() {
		this.availableWorkspaces = [];
		this.availableProjects = [];
	}

	//use for caching cover & avatar
	setActiveWorkspaceCoverAndAvatar(options) {
		if (!this.activeWorkspace) {
			return;
		}
		if (options.cover) {
			this.activeWorkspaceCover = options.cover;
		}
		if (options.avatar) {
			this.activeWorkspaceAvatar = options.avatar;
		}
	}

	removeActiveWorkspaceCover() {
		this.activeWorkspaceCover = '';
	}

}

<div class="card-container">
    <div class="col loader-div" *ngIf="showLoader">
        <div class="d-flex justify-content-center align-items-center h-100">
            <div class="spinner-grow bg-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only"></span>
            </div>
            <div style="position: absolute; padding-top: 100px;">
                <h5 class="text-dark text-uppercase font-weight-bold">{{'processing' | translate}}</h5>
            </div>
        </div>
    </div>
    <div class="card col pb-5 mb-2" id="page1">
        <div class="row">
            <div class="content-top-banner-popup col p-0 d-flex justify-content-center align-items-center">
                <img *ngIf="!isCpd" [src]="timelineData?.banner ? timelineData.banner : headerImage" class="w-100" />
                <div class="col logo-div">
                    <div class="col">
                        <div class="ml-3 position-absolute">
                            <div class="company-logo-img-div d-flex justify-content-center align-items-center">                               
                                    <img class="company-logo" *ngIf="timelineData?.logo" [src]="timelineData?.logo" alt="">
                                    <span *ngIf="!timelineData?.logo" class="d-flex justify-content-center align-items-center c-full-white profile-text _700 text-uppercase company-logo"
                                        [style.backgroundColor]="timelineService.getColorFromName(timelineData?.name)">
                                        {{timelineData?.name | slice:0:2}}
                                    </span>
                            </div>
                            <div class="header-title-div"><span class="company-title">{{headerTitle}}</span>
                            </div>
                            <div class="title-div"> <span class="company-header-title">{{headerSubtitle}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 text-right p-4" style="position: absolute; top: -15px; cursor: pointer;">
                    <i class="fa fa-clone fa-lg text-white mt-3" title="Copy page" (click)="generateImage('page1')"></i>
                </div>
            </div>
        </div>
        <div class="col mt-3">
            <!-- timeline summary block -->
            <div class="col">
                <mtm-summary-block [isDownloadComponentActive]="true" [timelineData]="timelineData"></mtm-summary-block>
            </div>
            <!-- full view timeline -->
            <div class="mt-3" *ngIf="isFullTimeline">
                <div class="mb-2">
                    <span class="f-10 _700">{{'timelineFullView' | translate}}</span>
                </div>
                <div class="ml-3" style="margin-right: -15px;">
                    <mtm-timeline-full-view (isVisible)="onVisibilityChanged($event)" [isDownload]="true"
                        [timeLineData]="timelineData"></mtm-timeline-full-view>
                </div>
            </div>
        </div>
    </div>
    <div class="card col pb-5 mb-2" id="page2">
        <div class="row page-header">
            <div class="col page-header-logo-div">
                <div class="ml-3 position-absolute">
                    <div class="page-header-logo-img-div d-flex justify-content-center align-items-center">
                        <!-- <img width="37px" height="37px" [src]="timelineService.brandLogo()" /> -->
                        <img class="page-header-logo-img-div" *ngIf="timelineData?.logo" [src]="timelineData?.logo" alt="">
                        <span *ngIf="!timelineData?.logo" class="d-flex justify-content-center align-items-center page-header-logo-img-div c-full-white profile-text _700 text-uppercase"
                            [style.backgroundColor]="timelineService.getColorFromName(timelineData?.name)">
                            {{timelineData?.name | slice:0:2}}
                        </span>
                    </div>
                    <div class="page-header-title-div"><span class="f-10 _700 c-full-white">{{headerTitle}}</span>
                    </div>
                    <div class="page-header-sec-title-div"> <span class="f-18 _700 c-full-white">{{headerSubtitle}}</span>
                    </div>
                </div>
                <div class="w-100 text-right p-4" style="position: absolute; top: -15px; cursor: pointer;">
                    <i class="fa fa-clone fa-lg text-white mr-3" title="Copy page" (click)="generateImage('page2')"></i>
                </div>
            </div>
        </div>
        <div class="col mt-2" *ngIf="visualizations.length">
            <div class="mt-3" *ngFor="let v of visualizations; let i = index;">
                <span class="f-22 _500 c-mtm-full-black">{{v.name}}
                    <i class="ml-3 fa fa-clone text-dark" style="font-size: 15px; cursor: pointer;"
                        title="Copy visualization" (click)="generateImage('visualization'+ i+1)"></i>
                </span>
                <div class="d-flex flex-wrap bg-white" [id]="'visualization'+ i+1" *ngIf="!v.isBothSelected">
                    <div class="col-sm-1 my-2 p-0" [style.max-width]="'73px'" *ngFor="let item of v.milestone">
                        <div class="visualization-card-mad-div" [style.background-color]="item.blockColor">
                            <div class="">
                                <span class="milestone-card-div-text">{{item.mad}}</span>
                            </div>
                            <div class="milestone-mad-date">
                                <span class="milestone-card-div-text">{{item.driveDate | date:
                                    'dd.MM.YYYY'}}</span>
                            </div>
                        </div>
                        <div class="visualization-name-div" [style.height]="'60px'">
                            <div class="p-1">
                                <span class="milestone-name-text _700">{{item.blockName}}</span>
                                <span class="milestone-name-text">{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div [id]="'visualization'+ i+1">
                    <div *ngIf="v.isBothSelected" class="row mt-1"><span class=" pl-3 f-14 _600">Colored ordered
                            sorted</span></div>
                    <div class="d-flex flex-wrap bg-white" *ngIf="v.isBothSelected">
                        <div class="col-sm-1 my-2 p-0" [style.max-width]="'73px'"
                            *ngFor="let item of v.colorSortedMilestones">
                            <div class="visualization-card-mad-div" [style.background-color]="item.blockColor">
                                <div class="">
                                    <span class="milestone-card-div-text">{{item.mad}}</span>
                                </div>
                                <div class="milestone-mad-date">
                                    <span class="milestone-card-div-text">{{item.driveDate | date:
                                        'dd.MM.YYYY'}}</span>
                                </div>
                            </div>
                            <div class="visualization-name-div" [style.height]="'60px'">
                                <div class="p-1">
                                    <span class="milestone-name-text _700">{{item.blockName}}</span>
                                    <span class="milestone-name-text">{{item.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="v.isBothSelected" class="row mt-1"><span class=" pl-3 f-14 _600">Chronological order
                            sorted</span></div>
                    <div class="d-flex flex-wrap bg-white" *ngIf="v.isBothSelected">
                        <div class="col-sm-1 my-2 p-0" [style.max-width]="'73px'"
                            *ngFor="let item of v.chronologicalSortedMilestones">
                            <div class="visualization-card-mad-div" [style.background-color]="item.blockColor">
                                <div class="">
                                    <span class="milestone-card-div-text">{{item.mad}}</span>
                                </div>
                                <div class="milestone-mad-date">
                                    <span class="milestone-card-div-text">{{item.driveDate | date:
                                        'dd.MM.YYYY'}}</span>
                                </div>
                            </div>
                            <div class="visualization-name-div" [style.height]="'60px'">
                                <div class="p-1">
                                    <span class="milestone-name-text _700">{{item.blockName}}</span>
                                    <span class="milestone-name-text">{{item.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class=" download-btn-div mt-5">
        <button class="file-download-btn" (click)="downloadImages()"><i
                class="fa fa-file fa-lg mr-3 file-download-icon"></i>
            <span class="file-download-btn-title">{{'downloadAsPpt' | translate}}</span>
        </button>
    </div>
</div>
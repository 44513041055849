import { AfterViewInit, Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayService } from 'app/shared/services/overlayService';
import { Observable, Subject, Subscription, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { hallmark, product } from "../../../shared/interfaces";
import { ProjectV2ServiceService } from "../../../shared/services/project-v2-service.service";
import { DateHelperService } from 'app/shared/services/date-helper.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { AuthService } from 'app/shared/services/auth.service';
import { ApiService } from 'app/shared/services/api.service';
import { UploadService } from 'app/shared/services/upload.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UploadModalComponent } from 'app/on-boarding/profile-avatar/upload-modal/upload-modal.component';
import { DOCUMENT } from '@angular/common';
import { HelperService } from 'app/shared/services/helper.service';
import { DataTransferBetweenComponentsService } from 'app/shared/services/data-transfer-between-components.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { UserService } from 'app/shared/services/user.service';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { environment } from 'environments/environment';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { MtmBreadcrumbLink, MtmBreadcrumbService } from "app/shared/services/mtm-breadcrumb.service";
import { EmitterService } from 'app/shared/services/emitter.service';
import { CampaignCellComponent } from 'app/admin/users/table/renderer/campaign-cell/campaign-cell.component';

@Component({
  selector: 'mtm-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent implements OnInit, OnDestroy {

  generalInfoForm: UntypedFormGroup;
  criteriaForm: UntypedFormGroup;
  ngUnsubscribe = new Subject();
  workspaces: any[] = []
  expDate: any;
  isSelected = false;
  isOpen: boolean = false;
  isDropdownOpen: boolean = false;
  priorityLevel: string = 'Please select...';
  selectedProductCategory: string;
  selectedComplexityCategory: string;;
  hallmarkSelectedComplexityCategory: string;
  selectedLCampaignTypeCategory: string;
  selectedCoffretType: string;
  isproductDropdownOpen: boolean = false;
  isComplexityDropdownOpen: boolean = false;
  isHallmarkComplexityDropdownOpen: boolean = false;
  isCampaignTypeDropdownOpen: boolean = false;
  isProjectSelected: boolean = false;
  productCampaign = 'Product Campaign';
  hallmarks = 'Hallmarks';
  cpdBrandCampaign = 'Brand Campaign Tentpole / Event';
  cpdProductCampaign = 'Product Campaign';
  isProductCampaign: boolean = false;
  isCpdProductCampaign: boolean = false;
  isCampaignType: boolean = true;
  isChinaSpecific: boolean = false;
  isPOSMselected: boolean = true;
  isCoffretSelected: boolean = true;
  isCollectorSelected: boolean = true;
  isDmiProduced: boolean = true;
  isCoffretDmiProduced: boolean = true;
  isCampaignChanged: boolean = true;
  isXmas: boolean = false;
  isCoffretTypeDropdownOpen: boolean = false;
  isProductLaunch: boolean = false;
  categoryComplexity: any[] = [];
  grayStar = '../../../assets/img/project-v2/gray-star.svg';
  blackStar = '../../../assets/img/project-v2/filled-star.svg';
  criticalRightArrow = '';
  midiumRighArrow = '';
  lowRightArrow = '';
  highRightArrow = '';
  highblackStar = this.blackStar;
  lowblackStar = this.blackStar;
  midblackStar = this.blackStar;
  tempCampiagnType: any[] = [];
  selectedProjectType: string;
  timelineResult: any;
  expectedMadDate: any;
  isOtherPriorityLevel: boolean = false;
  isMediumPriorityLevel: boolean = false;
  criteriaFormData: any;
  generalFormData: any;
  timelineId: string;
  removeHallmarkData = ['Set Type', 'POSM Type', 'Include for China', 'Hallmarks Type', 'Collector', 'Set']
  removeProductCampaignData = ['Product Launch Type', 'Campaign Type', 'DMI Produce Type']
  submitted: boolean = false;
  driveCampaignImage: any;
  timeline: any;
  selectWorkspace: 'Select Workspace';
  workspaceDisabled: boolean = false;
  isCriticalPriorityLevel: boolean = false; levelsOfPriority = [
    { id: 'Low' },
    { id: 'Medium' },
    { id: 'High' }
  ]
  activeProduct: string;
  productCategory = [
    {
      name: 'Product Launch China Normal Cosmetics',
      complexity: [
        { name: '1T/2T' },
        { name: '3T/4T' }]
    },
    {
      name: 'Product Launch China Special Cosmetics',
      complexity: [
        { name: '1T/2T' },
        { name: '3T' },
        { name: '4T' }]
    },
    {
      name: 'Product Launch China Special Cosmetics with Nano',
      complexity: [
        { name: '1T/2T' },
        { name: '3T' },
        { name: '4T' }]
    },
    {
      name: 'Product Launch not in China',
      complexity: [
        { name: '1T/2T' },
        { name: '3T/4T' }]
    },
    {
      name: 'No Product Launch Only Campaign',
      complexity: []
    },
  ];

  cpdProductSubCategory = [
    {
      key: "Makeup",
      label: 'Make Up'
    },
    {
      key: "Skincare",
      label: 'Skincare'
    },
    {
      key: "Hair",
      label: 'Hair'
    },
    {
      key: "Coloration",
      label: 'Coloration'
    },
    {
      key: "Men",
      label: 'Men'
    }
  ]

  kindOfLaunches = [
    {
      key: "Pillar",
      label: 'Pillar'
    },
    {
      key: "Reno",
      label: 'Reno'
    }
  ];

  cpdProductCategory = [
    {
      key: "Makeup",
      label: 'Make Up',
      subcategory: [
        {
          key: "Eyes",
          label: 'Eyes'
        },
        {
          key: "Face",
          label: 'Face'
        },
        {
          key: "Lips",
          label: 'Lips'
        },
        {
          key: "Nails",
          label: 'Nails'
        },
      ]
    },
    {
      key: "Skincare",
      label: 'Skincare',
      subcategory: [
        {
          key: "Face",
          label: 'Face'
        },
        {
          key: "Body",
          label: 'Body'
        },
        {
          key: "Sun",
          label: 'Sun'
        },
      ]
    },
    {
      key: "Hair",
      label: 'Hair',
      subcategory: [
        {
          key: "Haircare",
          label: 'Haircare'
        },
        {
          key: "Styling",
          label: 'Styling'
        }
      ]
    },
    {
      key: "Coloration",
      label: 'Coloration'
    },
    {
      key: "Men",
      label: 'Men',
      subcategory: [
        {
          key: "Face",
          label: 'Face'
        },
        {
          key: "Hygiene",
          label: 'Hygiene'
        }
      ]
    }
  ]

  dmiOwnerships: any[] = [];

  complexityCategory = [
    { name: '1T/2T' },
    { name: '3T/4T' },
    { name: '3T' },
    { name: '4T' }
  ];

  hallmarksComplexicity = [
    { name: '1T/2T' },
    { name: '3T' },
  ];

  campaignTypeCategory = [
    { name: 'Standard' },
    { name: 'New Territory' },
    { name: 'No Dedicated Campaign' }
  ]

  campaignOnlyTypeCategory = [
    { name: 'Standard' },
    { name: 'New Territory' },
    { name: 'No Dedicated Campaign' },
    { name: 'Reddit' }
  ]

  campaignTypeCategory360 = [
    { name: 'launchReference' },
    { name: 'launchNewTerritory' }
  ]

  ProductLaunchcampaignTypeCategory = [
    { name: 'Standard' },
    { name: 'New Territory' },
    { name: 'No Dedicated Campaign' },
    { name: 'Reddit' }
  ]

  typeOfCoffret = [
    { name: 'Standard' },
    { name: 'Premium' },
    { name: 'Both' }
  ];
  workspaceId: string;
  authUser: any;
  selectedWorkspace: any;
  selectedWorkspaceName: string;
  CurrentWorkspace: string;
  workspaceFormControl: UntypedFormControl;
  modal: NgbModalRef;
  breadcrumbs: any;
  canEditOwner: boolean = false;
  banner: any;
  status: string;
  cacheBuster: string = new Date().getTime().toString();
  brandData: any = [];
  participants: any[] = [];
  brandOwners: any[] = [];
  // fieldErrors: any = {};
  backupParticipants: any[] = [];
  existingLeaders: any[] = [];
  existingManagers: any[] = [];
  editModeData: any;
  isParticipantsLoaded: boolean = false;
  isCpd = false;
  isLoading: boolean = false;
  driveCampaignId: string;

  timelineLoaded: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public projectV2ServiceService: ProjectV2ServiceService,
    private overlayService: OverlayService,
    private timelineService: TimelineService,
    private workspaceService: WorkspaceService,
    private authService: AuthService,
    private api: ApiService,
    private permissionService: PermissionService,
    public uploadService: UploadService,
    private dataTransferService: DataTransferBetweenComponentsService,
    private modalService: NgbModal,
    private cryptoService: CryptoService,
    private userService: UserService,
    private analyticService: AnalyticsService,
    private breadcrumbService: BreadcrumbService,
    private mtmBreadcrumbService: MtmBreadcrumbService
  ) {
    this.timelineService.isDriveNav.emit(false);
    EmitterService.get('DRIVE_CAMPAIGN:FORM_UPDATED').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: () => {
        this.initialization();
      }
    });
    EmitterService.get('DRIVE_CAMPAIGN:ACTIVE_PAGE').emit('general');
  }

  ngOnInit(): void {

    if (!this.projectV2ServiceService.timelineId) {
      this.route.queryParams.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe({
        next: (query) => {
          if (query?.wid && query.dcId) {
            this.workspaceId = this.cryptoService.decryptUsingAES256(query.wid);
            this.driveCampaignId = this.cryptoService.decryptUsingAES256(query.dcId);
            this.projectV2ServiceService.updateDriveCampaignOnly(this.workspaceId, this.driveCampaignId);
            this.setBreadcrumb();
          } else {
            this.initialization();
            this.setBreadcrumb();
          }
        }
      });
      return;
    }
    this.initialization();
    this.setBreadcrumb();
  }


  // get form controls
  get gif() { return this.generalInfoForm.controls; }
  get cf() { return this.criteriaForm.controls; }

  ngOnDestroy(): void {
    this.breadcrumbs = this.document.body.getElementsByTagName('breadcrumb');
    if (this.breadcrumbs.length) {
      this.breadcrumbs[0].classList.remove('campaign-drive-general');
    }
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
    this.mtmBreadcrumbService.clearLinks();
  }

  private hideRoute() {
    this.dataTransferService.ngOnChangesBreadcrumbComponent();
    this.breadcrumbService.hideRoute('/workspaces/campaigndrive/general');
  }

  setBreadcrumb() {

    const queryParams = this.route.snapshot.queryParams;

    const brand = this.authService.getMyBrand()

    if (!Object.keys(queryParams).length) {
      let links = [];
      const hasLtgPermission = this.authService.checkLtgPermission();
      if (hasLtgPermission) {

        if (brand) {

          links.push({
            label: brand.name
          })
        }

        if (this.CurrentWorkspace) {
          links.push({
            label: 'Workspaces',
            url: ['/workspaces']
          });
          
          links.push({
            label: this.CurrentWorkspace,
            url: ['/workspaces', 'campaign-drive'],
            queryParams: {
              wid: this.cryptoService.encryptString(this.workspaceId)
            }
          });
        } else {
          links.push({
            label: this.isCpd ? "Campaign & Projects" : 'Drive Campaign & Projects',
            url: ['/workspaces', 'campaign-drive'],
          });
        }

        links.push({
          label: this.isCpd ? 'New Campaign' : 'New Drive Campaign'
        });


        this.mtmBreadcrumbService.setLinks(links);
      }
    } else {

      const pageLinks: MtmBreadcrumbLink[] = [
        {
          label: brand.name
        }, {
          label: 'Workspaces',
          url: ['/workspaces']
        }, {
          label: this.timelineService.workspaceName || "workspace",
          url: ['/workspaces', 'campaign-drive'],
          queryParams: {
            wid: this.cryptoService.encryptString(this.timelineService.workspaceId)
          }
        }, {
          label: this.timelineService.driveCampaignData.name || "Campaign",
          url: ['/workspaces', 'campaigndrive', 'dashboard'],
          queryParams: {
            wid: this.cryptoService.encryptString(this.timelineService.workspaceId),
            dc: this.cryptoService.encryptString(this.timelineService.driveCampaignId)
          }
        },
        {
          label: 'Settings'
        }, {
          label: 'General'
        }
      ]
      this.mtmBreadcrumbService.setLinks(pageLinks);
    }
  }


  initialization() {
    this.timelineLoaded = true;
    this.authUser = this.authService.getAuthUser();
    this.isCpd = this.authUser.companyId == environment.ltg.cpdCompanyId;
    this.canEditOwner = (this.permissionService.hasAuthority(Authority.Z, null) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.U));
    this.breadcrumbs = this.document.body.getElementsByTagName('breadcrumb');
    if (this.breadcrumbs.length) {
      this.breadcrumbs[0].classList.add('campaign-drive-general');
    }
    this.hideRoute();
    this.route.params.subscribe(() => {
      this.hideRoute();
    });
    // for dashboard image
    // this.timelineService.componentName.next('generalComponent');
    this.timelineService.componentName.next({
      componentName: 'generalComponent',
      bannerUrl: ''
    })
    if (this.route.snapshot.params['workspaceId']) {
      this.workspaceDisabled = true;
      this.workspaceId = this.route.snapshot.params['workspaceId'];
    } else {
      if (this.projectV2ServiceService.timelineId) {
        const formData: any = this.projectV2ServiceService.getFormData();
        this.workspaceId = formData.general.workspaceId;
      }
    }
    if (this.workspaceId) {
      this.timelineService.setworksapceId.next(this.workspaceId);
      HelperService.changeBreadcrumbUrl(this.dataTransferService.breadcrumbComponent, 1, `/workspaces/campaign-drive?wid=${this.cryptoService.encryptString(this.workspaceId)}`);
      HelperService.changeBreadcrumbUrl(this.dataTransferService.breadcrumbComponent, 2, `/workspaces/campaigndrive/${this.workspaceId}/general`);
      this.workspaceFormControl = new UntypedFormControl('');
    }
    this.getWorkspaces();
    this.categoryComplexity = [
      { name: '1T/2T' },
      { name: '3T/4T' },
      { name: '3T' },
      { name: '4T' }
    ];
    this.tempCampiagnType = this.campaignTypeCategory;

    // General Info Form
    !this.isCpd ? this.generateGeneralInforForm() : this.generateCpdGeneralInforForm();

    // Criteria Form
    !this.isCpd ? this.generateCritariaForm() : this.generateCpdCriteriaForm();
    this.getProjectData();
    if (this.projectV2ServiceService.timelineId) {
      const formData: any = this.projectV2ServiceService.getFormData();
      this.activeProduct = formData.general.activeProduct;
      this.editModeData = formData;
      if (this.isCpd) {
        this.setDMIOwnershipBased(formData.criteria['Campaign Leading']);
      }
      this.generalInfoForm.patchValue(formData.general);
      this.criteriaForm.patchValue(formData.criteria);
      this.expectedMadDate = this.formatDate(formData.general.expectedMad);
      !this.isCpd ? this.selectProjectType(formData.criteria['Project Type']) : this.selectCpdProjectType(formData.criteria['Project Type']);
      if (formData.criteria['Product Launch Type']) {
        this.updateCategoryComplexity(formData.criteria['Product Launch Type']);
      }
      if (formData.general['timelines']) {
        this.timeline = formData.general['timelines'];
      }
      if (formData.general['logo']) {
        this.driveCampaignImage = formData.general['logo'];
      }
      if (formData.general['status']) {
        this.status = formData.general['status'];
      }
      if (formData.general['banner']) {
        this.banner = formData.general['banner'];
      }
      if (formData.general['priority'] == 'OTHER') {
        this.getOtherPriority();
      } else if (formData.general['priority'] == 'MEDIUM') {
        this.getMediumPriority();
      } else {
        this.getCriticalPriority();
      }
      if (!this.isCpd) {
        if (formData.criteria['Project Type'] == this.productCampaign) {
          this.removeObjectFromCriteriaForm(this.removeHallmarkData)
        } else {
          this.removeObjectFromCriteriaForm(this.removeProductCampaignData);
        }
      } else {
        if (formData.criteria['Project Type'] == this.cpdBrandCampaign) {
          this.removeObjectFromCriteriaForm(['Campaign Leading', 'Campaign Type', 'Repush Type', 'Product Category', 'Product Subcategory', 'DMI Based', 'priority'])
        } else {
          this.removeObjectFromCriteriaForm(['priority'])
          const subCategory = this.cpdProductCategory.find(c => c.key == formData.criteria['Product Category'])
          if (subCategory) {
            this.getCpdProductCategory(subCategory);
            this.criteriaForm.patchValue({
              'Product Subcategory': formData.criteria['Product Subcategory']
            });
          }
        }
      }
      if (this.isProductCampaign) {
        if (formData.criteria['POSM'] == 'No') {
          this.removeObjectFromCriteriaForm(['DMI Produce Type']);
        } else {
          this.addObjectInCriteriaForm(['DMI Produce Type']);
          if (formData.criteria['Locally Produced'] == 'Yes') {
            this.criteriaForm.patchValue({ 'DMI Produce Type': 'No' });
          } else {
            this.criteriaForm.patchValue({ 'DMI Produce Type': 'Yes' });
          }
        }
      } else {
        if (!this.isCpd) {
          if (formData.criteria['Set'] == 'No') {
            this.removeObjectFromCriteriaForm(['Set Type']);
          } else {
            this.addObjectInCriteriaForm(['Set Type']);
            if (formData.criteria['Set DMI Produced']) {
              if (formData.criteria['Set DMI Produced'] == 'Yes') {
                this.criteriaForm.patchValue({ 'Set Type': 'Yes' });
              } else {
                this.criteriaForm.patchValue({ 'Set Type': 'No' });
              }
            } else if (formData.criteria['Set Locally Produced']) {
              if (formData.criteria['Set Locally Produced'] == 'Yes') {
                this.criteriaForm.patchValue({ 'Set Type': 'No' });
              } else {
                this.criteriaForm.patchValue({ 'Set Type': 'Yes' });
              }
            }
          }
          if (formData.criteria['POSM'] == 'No') {
            this.removeObjectFromCriteriaForm(['POSM Type']);
          } else {
            this.addObjectInCriteriaForm(['POSM Type']);
            if (formData.criteria['POSM DMI Produced']) {
              if (formData.criteria['POSM DMI Produced'] == 'Yes') {
                this.criteriaForm.patchValue({ 'POSM Type': 'Yes' });
              } else {
                this.criteriaForm.patchValue({ 'POSM Type': 'No' });
              }
            } else if (formData.criteria['POSM Locally Produced']) {
              if (formData.criteria['POSM Locally Produced'] == 'Yes') {
                this.criteriaForm.patchValue({ 'POSM Type': 'No' });
              } else {
                this.criteriaForm.patchValue({ 'POSM Type': 'Yes' });
              }
            }
          }
        }
      }
      this.getTimeline();
    } else {
      //this.updateCampaignCategory(true);
    }
    this.getParticipants();
    this.setBreadcrumb();
  }

  // update category complexity in edit mode
  updateCategoryComplexity(productCategoryName: string): void {
    const selectedProduct = this.productCategory.find(product => {
      switch (productCategoryName) {
        case 'Normal Cosmetic':
          return product.name === 'normalcosmetic';
        case 'specialCosmeticWithNanoparticles':
          return product.name === 'specialCosmeticWithNanoparticles';
        case 'specialCosmetic':
          return product.name === 'specialCosmetic';
        default:
          return false;
      }
    });
    if (selectedProduct) {
      try {
        this.categoryComplexity = selectedProduct.complexity;
      } catch (error) {
        console.error('error', error);
      }
    } else {
      this.categoryComplexity = [];
    }
  }

  timelineData: any;
  async getTimeline() {
    try {
      const driveCampaign = await this.timelineService.getTimeLine(this.workspaceId, this.projectV2ServiceService.timelineId);
      if (driveCampaign) {
        this.timelineData = driveCampaign;
        this.timelineService.activeCampaign$.next(driveCampaign);
      }
    } catch (e) {
      console.log(e);
    }
  }

  generateCpdGeneralInforForm() {
    this.generalInfoForm = this.fb.group({
      "id": new UntypedFormControl('', []),
      "name": new UntypedFormControl('', [Validators.required]),
      'productName': new UntypedFormControl('', []),
      "description": new UntypedFormControl('', []),
      "logo": new UntypedFormControl(null, []),
      "workspaceId": new UntypedFormControl(null, []),
      "projectLeaders": new UntypedFormControl(null, [Validators.required]),
      "expectedMad": new UntypedFormControl('', []),
      "priority": new UntypedFormControl('', []),
      "workspace": new UntypedFormControl('', [Validators.required]),
      "timelines": new UntypedFormControl('', []),
      "banner": new UntypedFormControl(null, []),
      'activeProduct': new UntypedFormControl('Yes', [Validators.required])
    });
  }

  generateGeneralInforForm() {
    this.generalInfoForm = this.fb.group({
      "id": new UntypedFormControl('', []),
      "name": new UntypedFormControl('', [Validators.required]),
      'productName': new UntypedFormControl('', [Validators.required]),
      'cncManager': new UntypedFormControl('', []),
      "description": new UntypedFormControl('', []),
      "logo": new UntypedFormControl(null, []),
      "workspaceId": new UntypedFormControl(null, []),
      "projectLeaders": new UntypedFormControl(null, []),
      "expectedMad": new UntypedFormControl('', []),
      "priority": new UntypedFormControl('', []),
      "workspace": new UntypedFormControl('', [Validators.required]),
      "timelines": new UntypedFormControl('', []),
      "banner": new UntypedFormControl(null, []),
      'activeProduct': new UntypedFormControl('Yes', [Validators.required])
    });
  }

  generateCritariaForm() {
    this.criteriaForm = this.fb.group({
      // Common Fields
      'Project Type': new UntypedFormControl('', [Validators.required]),
      'expectedDate': new UntypedFormControl('', [Validators.required]),
      'Is Three Star': new UntypedFormControl('', [Validators.required]),
      'Complexity': new UntypedFormControl(null, [Validators.required]),
      'POSM': new UntypedFormControl(null, [Validators.required]),

      // Product Fields
      'Product Launch Type': new UntypedFormControl(null, [Validators.required]),
      'Campaign Type': new UntypedFormControl(null, [Validators.required]),
      'DMI Produce Type': new UntypedFormControl(null, [Validators.required]),

      // Hallmark Fields
      'Hallmarks Type': new UntypedFormControl(null, [Validators.required]),
      'Collector': new UntypedFormControl(null, [Validators.required]),
      'Include for China': new UntypedFormControl(null, [Validators.required]),
      'Set': new UntypedFormControl(null, [Validators.required]),
      'Set Type': new UntypedFormControl(null, [Validators.required]),
      'POSM Type': new UntypedFormControl(null, [Validators.required])
    });
  }

  generateCpdCriteriaForm() {
    this.criteriaForm = this.fb.group({
      // Common Fields
      'Project Type': new UntypedFormControl('', [Validators.required]),
      'expectedDate': new UntypedFormControl('', [Validators.required]),
      //'priority': new UntypedFormControl('', [Validators.required]),

      // CPD Product Fields
      'Campaign Leading': new UntypedFormControl('', [Validators.required]),
      'Campaign Type': new UntypedFormControl('', [Validators.required]),
      'Repush Type': new UntypedFormControl('', [Validators.required]),
      'Product Category': new UntypedFormControl('', [Validators.required]),
      'Product Subcategory': new UntypedFormControl('', [Validators.required]),
      'Asset Bundle': new UntypedFormControl('', [Validators.required]),
      'DMI Based': new UntypedFormControl('', [Validators.required]),
    });
  }

  // validator function for criteria form fields
  isValid(field: string): boolean {
    const formField = this.criteriaForm.get(field);
    return formField ? formField.invalid && this.submitted : false;
  }

  // validator function for general form fields
  isValidGeneralForm(field: string): boolean {
    const formField = this.generalInfoForm.get(field);
    return formField ? formField.invalid && this.submitted : false;
  }

  isworkspaceValid(field): boolean {
    if (!this.submitted) {
      return true;
    }
    let workspaceValue = this.generalInfoForm.get(field).value;
    if (((workspaceValue === 'Select Workspace') || !workspaceValue) && this.submitted) {
      return false;
    } else {
      return true;
    }
  }

  dropDown(value) {
    this.isOpen = value;
    this.isOpen = !this.isOpen
  }

  onHoverSelector() {
    this.isSelected = !this.isSelected
  }

  filterDateChanged() {
    if (this.expectedMadDate) {
      this.expDate = new Date(this.expectedMadDate.year,this.expectedMadDate.month,this.expectedMadDate.day,0,0,0,0).toISOString();
    }
  }

  getPriorityLevel(closeDropDown, value) {
    this.isOpen = closeDropDown;
    this.isOpen = !this.isOpen;
    this.priorityLevel = value;
  }

  productCatDropDown(value) {
    this.isproductDropdownOpen = value;
    this.isproductDropdownOpen = !this.isproductDropdownOpen;
  }

  complexityCatDropDown(value) {
    this.isComplexityDropdownOpen = !value;
  }
  hallmarkComplexityCatDropDown(value) {
    this.isHallmarkComplexityDropdownOpen = !value;
  }
  campaignTypeTypeCatDropDown(value) {
    this.isCampaignTypeDropdownOpen = !value;
  }

  async getProductCategory(category: any) {
    if (category.name) {
      switch (category.name) {
        case 'normalcosmetic':
        case 'Product Launch China Normal Cosmetics':
          category.name = 'Product Launch China Normal Cosmetics';
          break;
        case 'specialCosmeticWithNanoparticles':
        case 'Product Launch China Special Cosmetics':
          category.name = 'Product Launch China Special Cosmetics';
          break;
        case 'specialCosmetic':
        case 'Product Launch China Special Cosmetics with Nano':
          category.name = 'Product Launch China Special Cosmetics with Nano';
          break;
        case 'Product Launch not in China':
        case 'productLaunchNotInChina':
          category.name = 'Product Launch not in China';
          break;
        case 'No Product Launch Only Campaign':
        case 'noProductLaunchOnlyCampaign':
          category.name = 'No Product Launch Only Campaign';
          break;
        default:
          return false;
      }
    }

    console.log('category', category)
    // reset complexity when change category
    this.selectedComplexityCategory = '';
    if (this.cf['Complexity']) {
      this.cf['Complexity'].setValue('');
    }
    this.selectedProductCategory = category.name;
    const productLaunchCategoryControl = this.criteriaForm.get('Product Launch Type');
    if (productLaunchCategoryControl) {
      productLaunchCategoryControl.setValue(category.name);
    } else {
      console.error('Product Launch Type control is null.');
    }
    const selectedProduct = this.productCategory.find(product => product.name === this.cf['Product Launch Type'].value);
    if (selectedProduct) {
      try {
        this.categoryComplexity = [];
        this.categoryComplexity = selectedProduct.complexity;
        if (!this.categoryComplexity.length) {
          this.removeObjectFromCriteriaForm(['Complexity']);
        } else {
          const productLaunchCategoryControl = this.criteriaForm.get('Complexity');
          if (!productLaunchCategoryControl) {
            this.addObjectInCriteriaForm(['Complexity']);
          }
        }
      } catch (error) {
        console.error('error', error)
      }
    }

  }

  getComplexity(category) {
    this.selectedComplexityCategory = category.name;
    const productLaunchCategoryControl = this.criteriaForm.get('Complexity');
    if (productLaunchCategoryControl) {
      productLaunchCategoryControl.setValue(category.name);
    } else {
      console.error('Complexity control is null.');
    }
  }

  getHallmarkComplexity(category) {
    this.hallmarkSelectedComplexityCategory = category.name;
    const productLaunchCategoryControl = this.criteriaForm.get('Complexity');
    if (productLaunchCategoryControl) {
      productLaunchCategoryControl.setValue(category.name);
    } else {
      console.error('Complexity control is null.');
    }
    if (this.criteriaForm.value['Hallmarks Type'] == 'Holiday') {
      const posmControl = this.criteriaForm.get('POSM');
      if (posmControl) {
        posmControl.setValue('Yes');
      }
      const typeOfPOSM = this.criteriaForm.get('Type Of POSM');
      if (typeOfPOSM) {
        typeOfPOSM.setValue('DMI Produced');
      }
    }
    // this.isHallmarkComplexityDropdownOpen = !isDropdownOpen;
  }

  setToNo() {
    // const typeOfProductionControl = this.criteriaForm.get('Type Of Set');
    // if (typeOfProductionControl) {
    //   typeOfProductionControl.setValue(null);
    // }
  }

  posmToNo() {
    // const typeOfPOSMControl = this.criteriaForm.get('Type Of POSM');
    //   if (typeOfPOSMControl) {
    //     typeOfPOSMControl.setValue(null);
    //   }
  }

  getCampaignType(category) {
    console.log('category', category)
    if ((category?.name == 'No Dedicated Campaign') || (category?.name == 'Reddit')) {
      this.removeObjectFromCriteriaForm(['DMI Produce Type']);
    } else {
      this.addObjectInCriteriaForm(['DMI Produce Type']);
    }
    // if (this.cf['Campaign Type'].value == 'Fast Track Reddit') {
    //   this.removeObjectFromCriteriaForm(['POSM DMI Produced', 'POSM']);
    // } else {
    //   this.addObjectInCriteriaForm(['POSM DMI Produced', 'POSM']);
    // }
    const productLaunchCategoryControl = this.criteriaForm.get('Campaign Type');
    if (productLaunchCategoryControl) {
      productLaunchCategoryControl.setValue(category.name);
    } else {
      console.error('Campaign Type Category control is null.');
    }
    // this.isCampaignTypeDropdownOpen = !isDropdownOpen;
  }

  // updateCampaignCategory(isCampaignTypeChanged?) {
  //   this.campaignTypeCategory = [];
  //   if (isCampaignTypeChanged) {
  //     this.campaignTypeCategory = this.campaignTypeCategory360;
  //   } else {
  //     this.campaignTypeCategory = this.ProductLaunchcampaignTypeCategory;
  //   }
  // }

  changeProjectType() {
    this.isProjectSelected = false;
  }

  selectProjectType(type) {
    this.selectedProjectType = type;
    this.isOtherPriorityLevel = false;
    this.isMediumPriorityLevel = false;
    this.isCriticalPriorityLevel = false;
    this.criteriaForm.patchValue({
      "Project Type": this.selectedProjectType
    });
    if (type == this.productCampaign) {
      this.isProductCampaign = true;
    } else if (type == this.hallmarks) {
      this.isProductCampaign = false;
    }
    this.isProjectSelected = true;
  }

  selectCpdProjectType(type) {
    this.selectedProjectType = type;
    this.isOtherPriorityLevel = false;
    this.isMediumPriorityLevel = false;
    this.isCriticalPriorityLevel = false;
    this.criteriaForm.patchValue({
      "Project Type": this.selectedProjectType
    });
    if (type == this.cpdProductCampaign) {
      this.isCpdProductCampaign = true;
    } else if (type == this.cpdBrandCampaign) {
      this.isCpdProductCampaign = false;
    }
    this.isProjectSelected = true;
  }

  coffretType(value) {
    this.isCoffretTypeDropdownOpen = !value;
  }

  onContinue() {
    this.submitted = true;
    this.isLoading = true;
    if (this.isCpd) {
      if (this.criteriaForm.value['Campaign Type'] == 'Product Launch') {
        this.removeObjectFromCriteriaForm(['Repush Type']);
      }
    } else {
      if (this.criteriaForm.value['Project Type'] == 'Product Campaign') {
        if (this.criteriaForm.value['Product Launch Type'] == 'No Product Launch Only Campaign') {
          this.removeObjectFromCriteriaForm(['Complexity']);
        }
      } else {
        if (this.criteriaForm.value['Hallmarks Type'] == 'China Specific Hallmarks') {
          this.removeObjectFromCriteriaForm(['POSM Type', 'Include for China']);
        }
      }
      if (this.criteriaForm.value['Campaign Type'] == 'No Dedicated Campaign' || this.criteriaForm.value['Campaign Type'] == 'Reddit') {
        const productLaunchCategoryControl = this.criteriaForm.get('POSM');
        if (productLaunchCategoryControl) {
          productLaunchCategoryControl.setValue('No');
          this.removeObjectFromCriteriaForm(this.removeHallmarkData);
        }
      }
      if (this.criteriaForm.value['POSM'] == 'No') {
        this.removeObjectFromCriteriaForm(['POSM Type']);
      }
      if (this.criteriaForm.value['Set'] == 'No') {
        this.removeObjectFromCriteriaForm(['Set Type']);
      }
    }

    this.generalInfoForm.patchValue({ projectLeaders: this.existingLeaders });
    this.generalInfoForm.patchValue({ cncManager: this.existingManagers });
    if (this.generalInfoForm?.valid && this.criteriaForm?.valid) {
      this.onSubmit();
    } else {
      this.isLoading = false;
    }
  }

  getProjectData() {
    this.projectV2ServiceService.getMasterData().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(result => {
    });
  }

  getOtherPriority() {
    this.isOtherPriorityLevel = true;
    this.isMediumPriorityLevel = false;
    this.isCriticalPriorityLevel = false;
    this.criteriaForm.patchValue({
      priority: 'OTHER'
    });
  }

  getMediumPriority() {
    this.isOtherPriorityLevel = false;
    this.isMediumPriorityLevel = true;
    this.isCriticalPriorityLevel = false;
    this.criteriaForm.patchValue({
      priority: 'MEDIUM'
    });
  }

  getCriticalPriority() {
    this.isOtherPriorityLevel = false;
    this.isMediumPriorityLevel = false;
    this.isCriticalPriorityLevel = true;
    this.criteriaForm.patchValue({
      priority: 'CRITICAL'
    });
  }

  onSubmit() {
    this.isLoading = true;
    const generalFormData = this.generalInfoForm.value;
    console.log('generalFormData', generalFormData)
    const criteriaFormData = this.removeBlankFields(this.criteriaForm.value);
    console.log('criteriaFormData', criteriaFormData)
    let madDate: any;
    let payload = generalFormData;
    if (this.workspaceId) {
      generalFormData.workspaceId = this.workspaceId;
    }

    if (typeof payload.projectLeaders == 'string') {
      payload.projectLeaders = payload.projectLeaders.split(',');
    }

    if (this.existingLeaders) {
      payload.projectLeaders = this.existingLeaders.map(owner => owner.username);
    }

    if (typeof payload.existingManagers == 'string') {
      payload.cncManager = payload.existingManagers.split(',');
    }

    if (this.existingManagers) {
      payload.cncManager = this.existingManagers.map(owner => owner.username);
    }

    if (criteriaFormData.expectedDate) {
      // date to send, for product timeline component
      madDate = `${criteriaFormData.expectedDate.day}.${criteriaFormData.expectedDate.month}.${criteriaFormData.expectedDate.year}`;

      // add expected date and priority
      payload.expectedMad = this.getExpectedMadDate();
      payload.status = this.status ? this.status : 'IN_PROGRESS';
      // delete extra keys
      delete criteriaFormData.expectedDate;

      // add criteria object
      criteriaFormData['Company ID'] = this.authUser.companyId;
      if (!this.isCpd) {
        if (this.isProductCampaign) {
          if (criteriaFormData['POSM'] == 'Yes') {
            if (criteriaFormData['DMI Produce Type'] == 'Yes') {
              criteriaFormData['DMI Produced'] = 'Yes';
            } else if (criteriaFormData['DMI Produce Type'] == 'No') {
              criteriaFormData['Locally Produced'] = 'Yes';
            }
            delete criteriaFormData['DMI Produce Type'];
          }
          delete criteriaFormData['DMI Produce Type'];
        } else {
          if (criteriaFormData['POSM'] == 'Yes') {
            if (criteriaFormData['POSM Type'] == 'Yes') {
              criteriaFormData['POSM DMI Produced'] = 'Yes';
            } else if (criteriaFormData['POSM Type'] == 'No') {
              criteriaFormData['POSM Locally Produced'] = 'Yes';
            }
            if (criteriaFormData['Hallmarks Type'] == 'China Specific Hallmarks') {
              criteriaFormData['POSM Locally Produced'] = 'Yes';
            }
          }
          if (criteriaFormData['Set'] == 'Yes') {
            if (criteriaFormData['Set Type'] == 'Yes') {
              criteriaFormData['Set DMI Produced'] = 'Yes';
            } else if (criteriaFormData['Set Type'] == 'No') {
              criteriaFormData['Set Locally Produced'] = 'Yes';
            }
          }
          delete criteriaFormData['POSM Type'];
          delete criteriaFormData['Set Type'];
        }
      }

      payload['criteria'] = JSON.stringify(criteriaFormData);

      //update timeline
      if (payload.id || this.projectV2ServiceService.timelineId) {
        // payload.id = this.projectV2ServiceService.timelineId;
        payload.timelines = this.projectV2ServiceService.timelineData;
        //remove object from criteria based on project type
        if (this.cf['Project Type'].value == this.productCampaign) {
          const object = JSON.parse(payload.criteria);
          const keysToRemove = this.removeHallmarkData;
          keysToRemove.forEach(key => delete object[key]);
          payload['criteria'] = JSON.stringify(object)
        } else {
          const object = JSON.parse(payload.criteria);
          const keysToRemove = this.removeProductCampaignData;
          keysToRemove.forEach(key => delete object[key]);
          payload['criteria'] = JSON.stringify(object)
        }
        if (this.timeline) {
          payload['timelines'] = this.timeline;
        }
        payload.id = this.projectV2ServiceService.timelineId;
        payload['assetList'] = this.timelineData.assetList;
        payload.activeProduct = this.activeProduct || 'Yes';
        this.projectV2ServiceService.createTimeline(payload).pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe({
          next: (result: any) => {
            this.uploadAvatarImage(
              this.driveCampaignImage,
              `/api/loreal-timeline/drive-campaigns/${this.workspaceId}/${result.id}/logo`
            ).pipe(
              takeUntil(this.ngUnsubscribe)
            ).subscribe({
              next: (resAvatarURL: any) => {
                generalFormData.banner = resAvatarURL.logo;
                this.projectV2ServiceService.updateFormData(generalFormData, criteriaFormData);
              },
              error(err) {
                console.error('error', err)
              },
              complete() {
                this.refreshImageVersion();
              },
            });
            // for banner
            this.uploadAvatarImage(
              this.banner,
              `/api/loreal-timeline/drive-campaigns/${this.workspaceId}/${result.id}/banner`
            ).pipe(
              takeUntil(this.ngUnsubscribe)
            ).subscribe({
              next: (resbannerURL: any) => {
                generalFormData.banner = resbannerURL.banner;
              },
              error(err) {
                console.error('error', err)
              },
              complete() {
                this.refreshImageVersion();
              },

            });
            this.timelineResult = result;
            console.log('edittimelineResult', this.timelineResult)
            this.projectV2ServiceService.updateFormData(generalFormData, criteriaFormData);
            this.projectV2ServiceService.timelineId = result.id;
            this.projectV2ServiceService.timelineData = result.timelines;
            this.projectV2ServiceService.sendData(result, madDate)
            this.router.navigate([
              `workspaces/campaigndrive/${this.workspaceId}/recap`], {
              queryParams: {
                wid: this.cryptoService.encryptString(this.workspaceId),
                dcId: this.cryptoService.encryptString(result.id),
              }
            });
          },
          error(err) {
            this.overlayService.showError('Unable to update timeline data.', 'Error');
            this.expectedMadDate = null;
            this.isLoading = false;
          },
          complete() {
            this.isLoading = false;
          },
        },)
      } else {
        //create new timeline
        payload.activeProduct = 'Yes';
        this.projectV2ServiceService.createTimeline(payload).pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe({
          next: (result: any) => {
            // for logo
            this.uploadAvatarImage(
              this.driveCampaignImage,
              `/api/loreal-timeline/drive-campaigns/${this.workspaceId}/${result.id}/logo`
            ).pipe(
              takeUntil(this.ngUnsubscribe)
            ).subscribe({
              next: (resAvatarURL: any) => {
                generalFormData.banner = resAvatarURL.logo;
              },
              error(err) {
                console.error('error', err)
              },
              complete() {
                this.refreshImageVersion();
              },
            });
            // for banner
            this.uploadAvatarImage(
              this.banner,
              `/api/loreal-timeline/drive-campaigns/${this.workspaceId}/${result.id}/banner`
            ).pipe(
              takeUntil(this.ngUnsubscribe)
            ).subscribe({
              next: (resbannerURL: any) => {
                generalFormData.banner = resbannerURL.banner;
              },
              error(err) {
                console.error('error', err)
              },
              complete() {
                this.refreshImageVersion();
              },

            });
            this.timelineResult = result;
            console.log('new this.timelineResult', this.timelineResult);
            this.generalInfoForm.patchValue({ id: result.id });
            this.projectV2ServiceService.updateFormData(generalFormData, this.criteriaForm.value);
            this.projectV2ServiceService.timelineId = result.id;
            this.projectV2ServiceService.timelineData = result.timelines;
            this.projectV2ServiceService.sendData(result, madDate)
            this.router.navigate([
              `workspaces/campaigndrive/${this.workspaceId}/recap`],
              {
                queryParams: {
                  wid: this.cryptoService.encryptString(this.workspaceId),
                  dcId: this.cryptoService.encryptString(result.id),
                }
              });

          },
          error: (err) => {
            console.log('err', err)
            this.overlayService.showError(`Server Error: ${err.message}`, 'Error');
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
          },
        });
      }
    } else {
      this.overlayService.showError('Please select expected date.', 'Error');
      this.isLoading = false;
    }

  }

  addObjectInCriteriaForm(items: any) {
    items.forEach(elem => {
      this.criteriaForm.addControl(elem, new UntypedFormControl(null, []));
    });
  }

  getExpectedMadDate() {
    const year = this.expectedMadDate.year;
    const month = this.expectedMadDate.month < 10 ? '0' + this.expectedMadDate.month : this.expectedMadDate.month;
    const day = this.expectedMadDate.day < 10 ? '0' + this.expectedMadDate.day : this.expectedMadDate.day;
    return `${year}-${month}-${day}T00:00:00.000Z`
  }

  removeObjectFromCriteriaForm(items: any) {
    items.forEach(elem => {
      this.criteriaForm.removeControl(elem);
    });
  }

  resetCriteriaForm() {
    !this.isCpd ? this.generateCritariaForm() : this.generateCpdCriteriaForm();
  }

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = month;
    }
    if (day.length < 2) {
      day = day;
    }
    const dt = { 'year': year, 'month': parseInt(month), 'day': parseInt(day) };
    return dt;
  }

  //remove Blank Fields from forms
  removeBlankFields(obj: any): any {
    const result: any = {};
    for (const key in obj) {
      const value = obj[key];
      if (value !== '' || value == null) {
        result[key] = value;
      }
    }
    return result;
  }

  getWorkspaces() {
    this.analyticService.getBrands().pipe(takeUntil(this.ngUnsubscribe)).
      subscribe({
        next: (brands: any) => {
          let allBrands = brands;
          allBrands.forEach(brand => {
            // set header banner  
            if (brand.workspaces) {
              this.workspaces.push(...brand.workspaces);;

              this.workspaces.forEach(workspace => {
                // Remove name space from start
                workspace.name = workspace.name.trim();
              });

              // Sort the workspaces based on their names
              this.workspaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
              if (this.workspaceId) {
                // Set the selectedWorkspace based on your condition (e.g., workspace ID match)
                this.selectedWorkspace = this.workspaces.find(workspace => workspace.id === this.workspaceId);
                this.selectedWorkspaceName = this.selectedWorkspace.name;
                console.log("this is wid ", this.selectedWorkspaceName);
                if (!this.CurrentWorkspace && this.selectedWorkspaceName) {
                  this.CurrentWorkspace = this.selectedWorkspaceName;
                  this.setBreadcrumb();
                }
                this.projectV2ServiceService.workspaceName = this.selectedWorkspace.name;
                // Set the initial value of the FormControl
                this.generalInfoForm.patchValue({ workspace: this.selectedWorkspace ? this.selectedWorkspace.name : '' });
              }
            }
          });
          this.getParticipants();
        },
        error: (err) => {
          console.error('err', err)
        },
        complete: () => {
          console.log('workspace loaded')
        }
      });
  }

  /**
   * Used to send image to second cropper
   * @param $event
   */
  fileChangeListener($event): void {
    let files = [];
    if (!$event.event) {
      files = $event.files[0];
      if (files) {
        this.openModal();
        this.modal.componentInstance.imageFile = files;
        this.setProfileImage(files);
      }
    }
    else {
      files = $event.event.target.files;
      if (files.length) {
        this.openModal();
        this.modal.componentInstance.imageChangedEvent = $event.event;
        this.setProfileImage(files[0]);
      }
    }
  }

  brandFileChangeListener($event): void {
    let files = [];
    if (!$event.event) {
      files = $event.files[0];
      if (files) {
        this.openModal();
        this.modal.componentInstance.imageFile = files;
        this.setBrandProfileImage(files);
      }
    }
    else {
      files = $event.event.target.files;
      if (files.length) {
        this.openModal();
        this.modal.componentInstance.imageChangedEvent = $event.event;
        this.setBrandProfileImage(files[0]);
      }
    }
  }

  private setBrandProfileImage(file) {
    let fileReader = new FileReader();
    // this.openModal();
    fileReader.onload = () => {
      this.banner = fileReader.result;
    };
    fileReader.readAsDataURL(file);
  }

  private setProfileImage(file) {
    let fileReader = new FileReader();
    // this.openModal();
    fileReader.onload = () => {
      this.driveCampaignImage = fileReader.result;
    };

    fileReader.readAsDataURL(file);
  }
  /**
   * Open crop modal
   */
  private openModal() {
    this.modal = this.modalService.open(UploadModalComponent, { size: 'lg' });
    this.modal.componentInstance.setCrop(false);
  }

  changeWorkspace() {
    const selectedWorkspaceName = this.generalInfoForm.get('workspace').value;
    this.selectedWorkspace = this.workspaces.find(workspace => workspace.name === selectedWorkspaceName);
    if (!this.selectedWorkspace) {
      return;
    }
    this.workspaceId = this.selectedWorkspace.id;
    this.selectedWorkspaceName = this.selectedWorkspace.name;
    this.projectV2ServiceService.workspaceName = this.selectedWorkspace.name;
    if (this.workspaceId) {
      this.leaderParticipantsBySelectedWorkspace = this.participants.filter(p => this.selectedWorkspace.usernames.indexOf(p.username) > -1);
      this.managerParticipantsBySelectedWorkspace = this.participants.filter(p => this.selectedWorkspace.usernames.indexOf(p.username) > -1);
    }
    this.backupLeaderParticipantsBySelectedWorkspace = this.leaderParticipantsBySelectedWorkspace;
    this.backupManagerParticipantsBySelectedWorkspace = this.managerParticipantsBySelectedWorkspace;
  }

  private uploadAvatarImage(image, partialURL): Observable<any> {
    return new Observable((observer) => {
      this.uploadService
        .uploadImage(image, this.api.baseUrl + partialURL).pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(
          (resAvatar) => {
            if (!resAvatar.logo) return;

            observer.next(resAvatar.logo);
          },
          (err) => {
            observer.error();
          }
        );
    });
  }

  returnToProject() {
    const path = `workspaces/campaigndrive/dashboard`;
    this.router.navigate([path], {
      queryParams: {
        wid: this.cryptoService.encryptString(this.workspaceId),
        dc: this.cryptoService.encryptString(this.projectV2ServiceService.timelineId),
      }
    });
  }

  refreshImageVersion() {
    this.cacheBuster = new Date().getTime().toString();
  }

  leaderParticipantsByBrand: any = [];
  backupLeaderParticipantsByBrand: any = [];
  leaderParticipantsBySelectedWorkspace: any = [];
  backupLeaderParticipantsBySelectedWorkspace: any = [];

  managerParticipantsByBrand: any = [];
  backupManagerParticipantsByBrand: any = [];
  managerParticipantsBySelectedWorkspace: any = [];
  backupManagerParticipantsBySelectedWorkspace: any = [];


  async getParticipants() {
    try {
      const brand = JSON.parse(localStorage.getItem('brand'));
      this.isParticipantsLoaded = false;
      let result = await firstValueFrom(
        this.userService.getUsersByCompanyId(this.authUser.companyId).pipe(takeUntil(this.ngUnsubscribe))
      );
      result = result.filter(user => user.fullName && user.fullName.trim() !== '');
      this.participants = result;
      this.leaderParticipantsByBrand = this.participants.filter(u => brand.usernamesRoles.find(b => u.username == b.username));
      this.managerParticipantsByBrand = this.participants.filter(u => brand.usernamesRoles.find(b => u.username == b.username));
      if (this.workspaceId) {
        this.leaderParticipantsBySelectedWorkspace = this.participants.filter(p => this.selectedWorkspace?.usernames.indexOf(p.username) > -1);
        this.managerParticipantsBySelectedWorkspace = this.participants.filter(p => this.selectedWorkspace?.usernames.indexOf(p.username) > -1);
      }
      this.backupLeaderParticipantsByBrand = this.leaderParticipantsByBrand;
      this.backupLeaderParticipantsBySelectedWorkspace = this.leaderParticipantsBySelectedWorkspace;
      this.backupManagerParticipantsByBrand = this.managerParticipantsByBrand;
      this.backupManagerParticipantsBySelectedWorkspace = this.managerParticipantsBySelectedWorkspace;
      this.backupParticipants = result;
      this.isParticipantsLoaded = true;

      // Check if participants are available before processing
      if (this.participants && this.participants.length > 0) {
        if (this.brandData.owners) {
          const ownerUsernames = this.brandData.owners.map(owner => owner.toLowerCase());

          // Filter existing leaders
          this.existingLeaders = ((!!this.selectedWorkspace ? this.leaderParticipantsBySelectedWorkspace : this.leaderParticipantsByBrand)).filter(participant =>
            ownerUsernames.includes(participant.username.toLowerCase())
          );

          // Mark participants as selected based on ownership
          this.participants.filter(participant => {
            const usernameLower = participant.username.toLowerCase();
            participant.selected = ownerUsernames.includes(usernameLower);
            return participant;
          });

        }

        // Handle existing leaders based on projectLeaders in formData
        if (this.editModeData && this.editModeData.general && this.editModeData.general['projectLeaders']) {
          const projectLeaders = this.editModeData.general['projectLeaders'];

          const matchedLeaders = this.participants
            .filter(leader => projectLeaders.includes(leader.username))
            .map(participant => ({ ...participant, selected: true }));

          // Mark participants as selected based on ownership
          ((!!this.selectedWorkspace ? this.leaderParticipantsBySelectedWorkspace : this.leaderParticipantsByBrand)).filter(participant => {
            const usernameLower = participant.username.toLowerCase();
            participant.selected = projectLeaders.includes(usernameLower);
            return participant;
          });

          // Push the matched objects into the existingLeaders and brandOwners arrays
          this.existingLeaders = matchedLeaders;
        }

        // Handle existing leaders based on projectLeaders in formData
        if (this.editModeData && this.editModeData.general && this.editModeData.general['cncManager']) {
          const managers = this.editModeData.general['cncManager'];

          const matchedLeaders = this.participants
            .filter(leader => managers.includes(leader.username))
            .map(participant => ({ ...participant, selectedAsManager: true }));

          // Mark participants as selected based on ownership
          ((!!this.selectedWorkspace ? this.managerParticipantsBySelectedWorkspace : this.managerParticipantsByBrand)).filter(participant => {
            const usernameLower = participant.username.toLowerCase();
            participant.selectedAsManager = managers.includes(usernameLower);
            return participant;
          });

          // Push the matched objects into the existingLeaders and brandOwners arrays
          this.existingManagers = matchedLeaders;
        }

        // sort leaders
        this.participants.sort((p1, p2) => p1.fullName.localeCompare(p2.fullName));
        this.backupParticipants.sort((p1, p2) => p1.fullName.localeCompare(p2.fullName));
        this.existingLeaders.sort((p1, p2) => p1.fullName.localeCompare(p2.fullName));
      } else {
      }
    } catch (error) {
      console.error('Error loading participants', error);
    }
  }

  searchParticipants(e) {
    if (this.selectedWorkspace) {
      const participants = this.backupLeaderParticipantsBySelectedWorkspace.filter(p => !!p.fullName.trim());
      if (e.target.value) {
        this.leaderParticipantsBySelectedWorkspace = participants.filter((p) => p.email.toLowerCase().includes(e.target.value.toLowerCase()) || p.fullName.toLowerCase().includes(e.target.value.toLowerCase()));
      } else {
        this.leaderParticipantsBySelectedWorkspace = participants.filter(p => !!p.fullName.trim() && !(p.roles?.length == 1 && p?.roles[0] == 'COMPANY_OCCASIONAL'));
      }
    } else {
      const participants = this.backupLeaderParticipantsByBrand.filter(p => !!p.fullName.trim());
      if (e.target.value) {
        this.leaderParticipantsByBrand = participants.filter((p) => p.email.toLowerCase().includes(e.target.value.toLowerCase()) || p.fullName.toLowerCase().includes(e.target.value.toLowerCase()));
      } else {
        this.leaderParticipantsByBrand = participants.filter(p => !!p.fullName.trim() && !(p.roles?.length == 1 && p.roles[0] == 'COMPANY_OCCASIONAL'));
      }
    }
  }

  searchManagerParticipants(e) {
    if (this.selectedWorkspace) {
      const participants = this.backupManagerParticipantsBySelectedWorkspace.filter(p => !!p.fullName.trim());
      if (e.target.value) {
        this.managerParticipantsBySelectedWorkspace = participants.filter((p) => p.email.toLowerCase().includes(e.target.value.toLowerCase()) || p.fullName.toLowerCase().includes(e.target.value.toLowerCase()));
      } else {
        this.managerParticipantsBySelectedWorkspace = participants.filter(p => !!p.fullName.trim() && !(p.roles?.length == 1 && p.roles[0] == 'COMPANY_OCCASIONAL'));
      }
    } else {
      const participants = this.backupManagerParticipantsBySelectedWorkspace.filter(p => !!p.fullName.trim());
      if (e.target.value) {
        this.managerParticipantsByBrand = participants.filter((p) => p.email.toLowerCase().includes(e.target.value.toLowerCase()) || p.fullName.toLowerCase().includes(e.target.value.toLowerCase()));
      } else {
        this.managerParticipantsByBrand = participants.filter(p => !!p.fullName.trim() && !(p.roles?.length == 1 && p.roles[0] == 'COMPANY_OCCASIONAL'));
      }
    }
  }

  ownerSelected(e, participant) {
    const { username } = participant;

    const indexOfUsername = this.existingLeaders.findIndex(owner => owner.username === username);

    if (indexOfUsername === -1) {
      // The participant is not in the list, so add them.
      this.existingLeaders.push(participant);
      participant.selected = true;
    } else {
      // The participant is already in the list, so remove them.
      this.existingLeaders.splice(indexOfUsername, 1); // Remove the participant by index.
      participant.selected = false;
    }
  }

  managerSelected(e, participant) {
    const { username } = participant;

    const indexOfUsername = this.existingManagers.findIndex(owner => owner.username === username);

    if (indexOfUsername === -1) {
      this.existingManagers.push(participant);
      participant.selectedAsManager = true;
    } else {
      this.existingManagers.splice(indexOfUsername, 1);
      participant.selectedAsManager = false;
    }
  }

  getKindOfLaunch(kindOfLaunch) {
    const kindOfLaunchControl = this.criteriaForm.get('Repush Type');
    if (kindOfLaunchControl) {
      kindOfLaunchControl.setValue(kindOfLaunch.key);
    }
  }

  productSubCategories: any;
  getCpdProductCategory(category) {
    this.productSubCategories = category.subcategory;
    const productCategory = this.criteriaForm.get('Product Category');
    if (this.criteriaForm.get('Product Subcategory')) {
      this.criteriaForm.get('Product Subcategory').setValue('');
    }
    if (productCategory) {
      productCategory.setValue(category.key);
    }
    if (!this.productSubCategories?.length) {
      this.removeObjectFromCriteriaForm(['Product Subcategory']);
    }
  }

  getCpdProductSubCategory(category) {
    const productCategory = this.criteriaForm.get('Product Subcategory');
    if (productCategory) {
      productCategory.setValue(category.key);
    }
  }

  setAssetBundle(val) {
    const productSubCategory = this.criteriaForm.get('Asset Bundle');
    if (productSubCategory) {
      productSubCategory.setValue(val);
    }
  }

  getDmiOwnership(dmiOwnership) {
    const dmiOwnershipControl = this.criteriaForm.get('DMI Based');
    if (dmiOwnershipControl) {
      dmiOwnershipControl.setValue(dmiOwnership.key);
    }
  }

  hallmarkHolidaySelected() {
    const collectorControl = this.criteriaForm.get('Collector');
    if (collectorControl) {
      collectorControl.setValue('Yes');
    }
    // const setControl = this.criteriaForm.get('Set');
    // if (setControl) {
    //   setControl.setValue('Yes');
    // }
    // const typeOfProductionControl = this.criteriaForm.get('Type Of Set');
    // if (typeOfProductionControl) {
    //   typeOfProductionControl.setValue('DMI Produced');
    // }
  }

  setDMIOwnershipBased(leading) {
    if (leading == this.criteriaForm.get('Campaign Leading')?.value) {
      return;
    }
    // 'Campaign Leading': new UntypedFormControl('', [Validators.required]),
    // 'Campaign Type': new UntypedFormControl('', [Validators.required]),
    // 'Repush Type': new UntypedFormControl('', [Validators.required]),
    // 'Product Category': new UntypedFormControl('', [Validators.required]),
    // 'Product Subcategory': new UntypedFormControl('', [Validators.required]),
    // 'Asset Bundle': new UntypedFormControl('', [Validators.required]),
    // 'DMI Based': new UntypedFormControl('', [Validators.required]),
    if (this.criteriaForm.get('DMI Based')) {
      this.criteriaForm.get('DMI Based').setValue('');
    }
    if (this.criteriaForm.get('Product Category')) {
      this.criteriaForm.get('Product Category').setValue('');
    }
    if (this.criteriaForm.get('Product Subcategory')) {
      this.criteriaForm.get('Product Subcategory').setValue('');
    }
    if (this.criteriaForm.get('Asset Bundle')) {
      this.criteriaForm.get('Asset Bundle').setValue('');
    }
    if (this.criteriaForm.get('Repush Type')) {
      this.criteriaForm.get('Repush Type').setValue('');
    }
    if (this.criteriaForm.get('Campaign Type')) {
      this.criteriaForm.get('Campaign Type').setValue('');
    }
    if (leading == 'Master') {
      this.dmiOwnerships = [
        {
          key: 'Paris',
          label: 'Paris'
        },
        {
          key: 'USA',
          label: 'USA'
        }
      ];
    } else {
      this.dmiOwnerships = [
        {
          key: 'Paris',
          label: 'Paris'
        },
        {
          key: 'Mexico',
          label: 'Mexico'
        },
        {
          key: 'Brazil',
          label: 'Brazil'
        },
        {
          key: 'Singapore',
          label: 'Singapore'
        }
      ];
    }
  }
}

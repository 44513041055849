import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/shared/services/auth.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { UserService } from 'app/shared/services/user.service';
import { AddUserComponent } from './add-user/add-user.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { Subject, takeUntil, tap } from 'rxjs';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { OverlayService } from 'app/shared/services/overlayService';
import { HelperService } from 'app/shared/services/helper.service';
import { TeamEditorComponent, TeamEditorTypes } from 'app/shared/components/team-editor/team-editor.component';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { Team } from 'app/shared/interfaces';
import { TeamService } from 'app/shared/services/team.service';
import { workspaceTeamTableColDefs } from 'app/account/brands/tables/workspace-team/workspace-team-table.coldefs';
import { NotificationService } from 'app/shared/services/notification.service';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { WorkspaceTeamTableHeaderComponent } from 'app/account/brands/tables/workspace-team/header/workspace-team-table-header.component';

@Component({
  selector: 'mtm-user-and-teams',
  templateUrl: './user-and-teams.component.html',
  styleUrls: ['./user-and-teams.component.scss']
})
export class UserAndTeamsComponent implements OnInit {

  @Input() workspace: any;
  @Input() companyUsers: any[] = [];
  @Input() setBreadcrumb: () => void;
  // @Input() roles: any[] = [];
  companyId: string;
  authUser: any;
  users: any = [];
  modal: NgbModalRef;
  teams: any = []
  backupUsers: any = [];
  ngUnsubscribe = new Subject();
  selectedTeam: any;
  UserRole: any = [];
  private rolesToExclude: Set<string> = new Set();
  allRoles: any;
  availableRoles: any = [];
  isChecked: boolean = false;
  isActive: boolean = false;
  isUserView: boolean = false;
  isOverideRole: boolean = false;
  viewMode: string = 'teamView';

  workspaceBrandId: string;
  defaultColDef: ColDef = {
    sortable: false,
    resizable: true,
    filter: false
  };
  teamColDefs: ColDef[] = workspaceTeamTableColDefs;
  teamComponents: { [p: string]: any } = { agColumnHeader: WorkspaceTeamTableHeaderComponent };
  teamTableContext: any;

  set roles(value: any[]) {
    this.allRoles = value;
    this.initRoleSet();
    let rolesToShow = value.filter(r => (r.code.startsWith('COMPANY') && !this.rolesToExclude.has(r.code)) || r.code === 'ADMIN');
    rolesToShow.sort((a: any, b: any) => (this.getRoleOrder(a.code) < this.getRoleOrder(b.code) ? -1 : 1));
    this.availableRoles = rolesToShow;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    public timelineService: TimelineService,
    private modalService: NgbModal,
    private teamService: TeamService,
    private translatePipe: TranslatePipe,
    private notificationService: NotificationService,
    private permissionService: PermissionService,
    private overlayService: OverlayService
  ) { }

  ngOnInit(): void {
    this.workspaceBrandId = this.workspace?.brand?.id || HelperService.getEmptyUUID();
    this.teamTableContext = {
      componentParent: this
    };
    this.authUser = this.authService.getAuthUser();
    this.getTeams();
    this.initRoleSet();

    if (this.setBreadcrumb) {
      this.setBreadcrumb();
    }
  }

  getTeams() {
    this.userService.getWorkspaceTeams(this.workspace.id).subscribe({
      next: (value: any) => {
        if (value.length) {
          value.sort((a: any, b: any) => a.name.localeCompare(b.name));
        }
        this.teams = value.filter(w => w.projectId == HelperService.getEmptyUUID());
        this.teams.forEach(team => {
          team['isOverride'] = false;
          team['isActivate'] = false;
          team['dicissionMaker'] = false;
          team['lastUpdate'] = Math.floor(new Date().getTime() / 1000);
        });
        this.getUsers();
      },
      error: (err) => {
        console.error('err', err)

      },
      complete: () => {
      },
    })
  }

  getUsers() {
    this.userService.getUsersByCompanyId(this.authUser.companyId)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe({
        next: (user) => {
          this.users = user;
          this.backupUsers = user;
          this.teams.forEach(team => {
            team.members.forEach(teamMemberUsername => {
              const userToUpdate = this.users.find(user => user.username === teamMemberUsername);
              if (userToUpdate.activationStatus === "ACTIVE_COMPLETED") {
                userToUpdate['isActivated'] = true;
              } else {
                userToUpdate['isActivated'] = false;
              }
              if (userToUpdate) {
                userToUpdate['teamName'] = team.name;
              }
            });
          });
          this.backupUsers = this.users;
        },
        error: (err) => {
          console.error('err', err);
        },
        complete: () => {

        },
      })
  }

  addNewUser() {
    this.modal = this.modalService.open(AddUserComponent, { size: 'lg' });
    this.modal.componentInstance.workspaceId = this.workspace.id;
    this.modal.result.then((result: any) => {
      this.getTeams();
    });
  }

  createGroup() {
    this.modal = this.modalService.open(CreateGroupComponent, { size: 'lg' });
  }

  onTeamSelect(event: any) {
    if (event.target.value === 'Users view') {
      return this.isUserView = true;
    }
    const selectedValue = event.target.value;
    this.selectedTeam = this.teams.find(team => team.id === selectedValue);
    if (this.selectedTeam && this.selectedTeam.members.length > 0) {
      this.users = this.users.filter(user => {
        if (this.selectedTeam.members.includes(user.username)) {
          return user;
        }
      })
    }
  }

  changeRole(event: any) {
    const selectedRole = event.target.value;
  }

  private initRoleSet() {
    if (this.rolesToExclude.size > 0)
      return;

    if (this.rolesToExclude.size == 0) {
      if (!this.permissionService.hasAuthority(Authority.S, null))
        this.rolesToExclude.add('COMPANY_PRINCIPAL');

      this.rolesToExclude.add('COMPANY_OCCASIONAL');
    }
    const authUser = this.authService.getAuthUser();
    this.rolesToExclude.add(authUser.company.companyType == 'ADVERTISER' ? 'COMPANY_PRODUCTION_OWNER' : 'COMPANY_PROJECT_OWNER');
  }

  private getRoleOrder(code: string): number {
    switch (code) {
      case 'COMPANY_PRINCIPAL':
        return 1;
      case 'COMPANY_REGULAR':
        return 3;
      default:
        return 2;
    }
  }

  toggleCheckbox() {
    this.isChecked = !this.isChecked;
  }

  toggleActivation(user: any) {
    user.isActivated = !user.isActivated;
  }

  removeUser(user) {

    // if (this.teams && this.teams.length > 0) {
    //   this.teams = this.teams.map(team => {
    //     if (team.name === user.teamName) {
    //       team.members = (team.members || []).filter(member => member !== user.username);
    //     }
    //     return team;
    //   });
    // }
    let filteredTeams: any[] = [];
    if (this.teams && this.teams.length > 0) {
      filteredTeams = this.teams.filter(team => team.name === user.teamName);

      // Check if filteredTeams is not empty before accessing its members property
      if (filteredTeams.length > 0 && filteredTeams[0].members) {
        filteredTeams[0].members = (filteredTeams[0].members || []).filter(member => member !== user.username);
      }
    }
    // Assuming removeTeamMember takes the updated team as an argument
    this.userService.removeTeamMember(filteredTeams)
      .subscribe({
        next: (result) => {
          this.getTeams();
          this.overlayService.showWarning('User removed.');
        },
        error: (err) => {
          console.error(err)
        },
        complete: () => {

        }
      }
      );
  }

  addTeamMember() {

  }

  selectView(event: any) {
    const selectedView = event.target.value;
    if (selectedView === 'Users view') {
      this.isUserView = true;
    } else {
      this.isUserView = false;
    }
  }

  toggleOverrideRoles(team) {
    team.isOverride = !team.isOverride;
    this.isOverideRole = !this.isOverideRole;
  }

  overrideRoles(event: any) {
    event.target.value
  }

  deleteTeam(team: Team) {
    this.notificationService.open({
      title: this.translatePipe.transform('team_deleteTeam'),
      description: this.translatePipe.transform('team_confirmDelete', { name: team.name }),
      confirmBtn: this.translatePipe.transform('yes'),
      cancelBtn: this.translatePipe.transform('no')
    }).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((confirm: any) => {
      if (!confirm)
        return;

      this.teamService.deleteTeam({
        projectId: team.projectId,
        workspaceId: team.workspaceId,
        companyId: team.companyId,
        brandId: team.brandId,
        id: team.id
      }).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.getTeams();
          },
          error: () => {
            this.overlayService.showError(this.translatePipe.transform('deleteFailed'));
          }
        });

    });
  }

  toggleTeamActivation(team) {
    team.isActivate = !team.isActivate;
  }

  teamDicissionMaker(team) {
    team.dicissionMaker = !team.dicissionMaker;
  }

  getBrandTeams(companyId, brandId) {
    this.teamService.getTeamsByWorkspaceId(companyId, brandId, HelperService.getEmptyUUID())
      .subscribe((res: any) => {
        res.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.teams = res;
      });
  }

  createTeam() {
    const modalRef = this.modalService.open(TeamEditorComponent, { size: 'lg', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.brandId = this.workspaceBrandId;
    modalRef.componentInstance.workspaceId = this.workspace.id;
    modalRef.componentInstance.projectId = HelperService.getEmptyUUID();
    modalRef.componentInstance.level = TeamEditorTypes.Workspace;
    modalRef.componentInstance.availableUsernames = this.workspace.usernames;
    modalRef.result.then((result: any) => {
      this.getTeams();
    });
  }

  editTeam(event: RowClickedEvent<Team>) {
    const modalRef = this.modalService.open(TeamEditorComponent, { size: 'lg', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.brandId = event.data.brandId || this.workspaceBrandId;
    modalRef.componentInstance.workspaceId = this.workspace.id;
    modalRef.componentInstance.projectId = HelperService.getEmptyUUID();
    modalRef.componentInstance.teamId = event.data.id;
    modalRef.componentInstance.level = TeamEditorTypes.Workspace;
    modalRef.componentInstance.availableUsernames = this.workspace.usernames;
    modalRef.result.then((result: any) => {
      this.getTeams();
    });
  }
}

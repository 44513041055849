<div class="audio-annotation-component h-60">
    <div class="toolbar w-100 mb-2 d-flex align-items-center" [hidden]="checkInvalidUser() || hideAnnotationToolbar">
        <div class="annotation-nav-comment d-flex flex-fill align-items-center">
            <!-- <mtm-user-profile-picture [user]="authUser" [username]="authUser.username" [height]="32" [width]="32"
                [showBorder]="false" *ngIf="pluginReady">
            </mtm-user-profile-picture> -->
            <div class="pl-2 annotation-mode-toggle d-flex align-items-center h-100" title>
                <ui-switch switchColor="#43CB9A" defaultBgColor="#FFFFFF" size="small" class="annotation-mode-switch"
                    [(ngModel)]="annotationToolbarActive"
                    (ngModelChange)="toggleAnnotationToolbarDisplay(annotationToolbarActive)">
                </ui-switch>
            </div>

            <div class="time-range-container px-2 h-100 d-flex align-items-center">
                <div class="time-range d-flex align-items-center"
                    [ngClass]="{'disabled-toolbar': !annotationToolbarActive}">
                    <div class="start-time d-flex flex-column align-items-center justify-content-center"
                        [ngClass]="{'format-open': isTimeFormatOpen, 'selected': activeTimeMode==='start' && annotatingMode}"
                        (click)="changeActiveTimeMode('start');startAnnotationMode()" title="Annotation Start Time">
                        <i id="startTimeAdd" class="fa fa-caret-up" aria-hidden="true"
                            (click)="onStartTimeRangeChange('add')"></i>
                        <span [innerHTML]="getStartTime()"></span>
                        <i id="startTimeMin" class="fa fa-caret-down" aria-hidden="true"
                            (click)="onStartTimeRangeChange('min')"></i>
                    </div>
                    <i class="fa fa-play mx-2" style="font-size: xx-small;color: #898C98"></i>
                    <div class="end-time d-flex flex-column align-items-center justify-content-center"
                        [ngClass]="{'selected': activeTimeMode==='end' && annotatingMode}"
                        (click)="changeActiveTimeMode('end');startAnnotationMode()" title="Annotation End Time">
                        <i id="endTimeAdd" class="fa fa-caret-up" aria-hidden="true"
                            (click)="onEndTimeRangeChange('add')"></i>
                        <span [innerHTML]="getEndTime()"></span>
                        <i id="endTimeMin" class="fa fa-caret-down" aria-hidden="true"
                            (click)="onEndTimeRangeChange('min')"></i>
                    </div>
                    <!-- <div id="changeFormat" class="change-time-format text-center d-inline-flex"
                        (click)="isTimeFormatOpen = !isTimeFormatOpen" [ngClass]="{'format-open': isTimeFormatOpen}"
                        title="Time Format">
                        <div class="format-type-short d-flex align-items-center justify-content-center px-1">
                            <span [hidden]="activeTimeFormat === 'milliseconds'">S</span>
                            <span [hidden]="activeTimeFormat === 'seconds'">MS</span>
                        </div>
                        <div class="caret px-1" [ngClass]="{ 't-open': isTimeFormatOpen, 't-close': !isTimeFormatOpen }">
                            <i class="fa fa-play" aria-hidden="true"></i>
                        </div>
                        <div class="format-types" [hidden]=!isTimeFormatOpen>
                            <div class="seconds-format pt-1" [ngClass]="{'active': activeTimeFormat === 'seconds'}"
                                (click)="changeTimeFormat('seconds', 'top')">
                                <span>Seconds</span>
                            </div>
                            <div class="milliseconds-format pt-1"
                                [ngClass]="{'active': activeTimeFormat === 'milliseconds'}"
                                (click)="changeTimeFormat('milliseconds', 'top')">
                                <span>Milliseconds</span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="annotation-nav-comment pl-1 d-flex flex-fill align-items-center">
                <div class="annotation-text-area d-flex flex-fill align-items-center" [style.position]="'relative'"
                    [style.top]="'-22px'">
                    <div class="editor-container d-flex flex-fill" [style.width]="'100%'">
                        <mtm-text-editor [elementId]="'annotCommentArea'" (keydown.enter)="$event.preventDefault()"
                            [(value)]="annotationComment" class="flex-fill" [dropUp]="false" [maxHeight]="'44px'"
                            (keyup)="$event.stopPropagation()" (enterKeyPress)="addNewAnnotation()"
                            (deleteFileCallback)="onFileDeleted($event)" [backgroundColor]="'#FFFFFF'"
                            [placeholder]="getPlaceholderText()" [files]="files" [videoRecordFiles]="videoRecordFiles"
                            [audioRecordFiles]="audioRecordFiles"
                            (click)="$event.stopPropagation();startAnnotationMode()" [quillActive]="true"
                            [isAnnotationEditor]="true" [userAvatar]="authUser.username"
                            (uploadFileCallback)="uploadFileCallback($event)" [showBottomToolbar]="true"
                            [supportPrivateComment]="true" [(privateCondition)]="privateCondition"
                            (privateConditionChange)="handlePrivateConditionChange($event)">
                        </mtm-text-editor>
                    </div>
                </div>
            </div>
        </div>
        <div class="validation-buttons">
            <div class="d-inline-flex align-items-center annot-validation">
                <!-- <div class="separator"></div> -->
                <div id="clearModeIcon" [ngClass]="{'disabledButton': annotatingMode === false}"
                    class="toolbarOptions cancel-button pl-1 pr-1" (click)="cleanUpFiles();canceladdNewAnnotation()">
                    <span class="tooltiptext" directiveCommentMinButtons>{{ 'cancelAnnotation' | translate
                        }}</span>
                    <img src="../../../../../assets/img/annotations/{{ annotatingMode ? 'cancel_on' : 'cancel_off' }}.svg"
                        alt="" height="24">
                </div>
                <div [ngClass]="{'disabledButton': isInValidInput() === true}"
                    (click)="$event.preventDefault();addNewAnnotation()"
                    class="toolbarOptions save-button pt-2 pb-2 pl-1 pr-1">
                    <span class="tooltiptext" directiveCommentMinButtons>{{ 'saveAnnotation' | translate
                        }}</span>
                    <img src="../../../../../assets/img/annotations/annot_send.svg" alt="" height="24">
                </div>
            </div>
        </div>
    </div>
    <div class="media-top-menu d-flex align-items-center">
        <div class="mr-auto d-flex align-items-center p-2">
            <i class="mtm-icon mtm-icon-topic-info mr-2 pointer"></i>
            <i class="mtm-icon mtm-icon-topic-download mr-2 pointer"></i>
            <i class="mtm-icon mtm-icon-topic-list mr-2 pointer" (click)="toggleAssetList()"></i>
            <label class="m-0">{{subSectionState?.activeOption?.title}}</label>
        </div>
        <div class="version-dropdown" ngbDropdown container="body">
            <button ngbDropdownToggle [disabled]="isBusy" (click)="$event.stopPropagation()"
                class="btn d-flex align-item-center justify-content-between py-2 px-3 dropdown-toggle">
                <span class="version mr-2">{{ 'V' + subSectionState?.activeVersion?.versionNumber }}</span>
                <span>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </span>
            </button>
            <div ngbDropdownMenu class="p-1 dropdown-menu">
                <button *ngFor="let version of subSectionState?.activeOption?.simpleVersions"
                    class="d-flex align-items-center btn dropdown-item version-selector-dropdown" [disabled]="isBusy"
                    (click)="switchVersion(version.versionNumber)">
                    <span class="version">{{ 'V' + version.versionNumber }}</span>
                </button>
                <button class="d-flex align-items-center justify-content-center btn dropdown-item
              version-selector-dropdown btn-manage-versions" [disabled]="isBusy" (click)="showManageVersions()">
                    <span class="text-uppercase">{{'versioning_edit_manageVersions' | translate}}</span>
                </button>
                <button class="d-flex align-items-center justify-content-center btn  dropdown-item
               version-selector-dropdown btn-add-version" [disabled]="isBusy" (click)="createNewVersion()">
                    <span class="d-flex align-items-center justify-content-center icon-wrapper mr-2">
                        <i class="fa fa-plus" aria-hidden="true"></i></span>
                    <span class="text-uppercase">{{'versioning_edit_createNewVersion' | translate}}</span>
                </button>
            </div>
        </div>
        <div class="ml-auto d-flex align-items-center pointer p-2" (click)="openReviewLinkSettings()">
            <i class="mtm-icon mtm-icon-topic-share mr-2 pointer"></i>
            <label class="m-0 _bold text-uppercase pointer">Share</label>
        </div>
    </div>
    <div class="colour-spacer-top"></div>
    <div class="waveform-container" #waveformContainer>
        <!-- <div class="amplitude-wave-form" id="waveformContainer">
        </div> -->
        <div class="wave-forme" id="waveform"></div>
        <div class="wave-form-pre" [ngClass]="{'active': waveFormsDisplay}" id="waveform2"></div>
        <div class="wave-form-post" [ngClass]="{'active': waveFormsDisplay}" id="waveform3" #postWaveform></div>
        <div class="waveform-mask">
            <div class="elapsed-mask" #elapsedMask [style.width]="elapsedWidth + '%'"></div>
            <div class="selectable-range" #selectableRange></div>
            <div class="remaining-mask" #remainingMask></div>
        </div>
        <div [hidden]="!isAnnotationDisplayEnabled"
            [ngClass]="{'disabled': !isAnnotationDisplayEnabled || annotatingMode === true, 'justify-content-end': annotation.left > 50,
            'active': (activeAnnotation && activeAnnotation.annotation && activeAnnotation.annotation.id===annotation.id),
            'inactive': (activeAnnotation && activeAnnotation.type==='click' && activeAnnotation.annotation && activeAnnotation.annotation.id!==annotation.id)}"
            class="annotation-marker px-1 d-flex align-items-end" *ngFor="let annotation of annotations; let i=index;"
            [ngStyle]="{'left': annotation.left + '%', 'width': annotation.width + '%', 'z-index': 400}" #marker>
            <div class="annotation-comment-modal" #commentBox
                [ngClass]="{'annotation-toggled': activeAnnotation?.type === 'click'}"
                *ngIf="(activeAnnotation && activeAnnotation.annotation && activeAnnotation.annotation.id===annotation.id)"
                [ngStyle]="{'left': updateMarkerBoxLeft(annotation, commentBox)}">
                <div class="comment-box-container">
                    <mtm-comment #commentsComponent [projectId]="projectId" [sectionId]="sectionId"
                        [subsectionId]="subsectionId" [typeId]="typeId" [item]="item"
                        [projectParticipants]="projectParticipants" [previewAnnotation]="activeAnnotation.annotation"
                        [annotationPlugin]="annotationPlugin" [isPlayerReady]="true" [isAnnotationModeActive]="true">
                    </mtm-comment>
                </div>
            </div>
        </div>
        <div class="no-pointer-left" *ngFor="let annotation of annotations; let i=index;"
            [ngClass]="{'active': (activeAnnotation && activeAnnotation.type==='click' && activeAnnotation.annotation && activeAnnotation.annotation.id===annotation.id)}"
            [ngStyle]="{'left': '0%', 'width': annotation.left + '%'}">
        </div>
        <div class="no-pointer-right" *ngFor="let annotation of annotations; let i=index;"
            [ngClass]="{'active': (activeAnnotation && activeAnnotation.type==='click' && activeAnnotation.annotation && activeAnnotation.annotation.id===annotation.id)}"
            [ngStyle]="{'left': annotation.left+annotation.width + '%', 'width': 100-(annotation.left+annotation.width) + '%'}">
        </div>
        <!-- <div class="avatar-container" *ngFor="let annotation of annotations; let i=index;"
            [ngStyle]="{'left': getAvatarLeft(annotation) + '%', 'z-index': 9999}"
            [ngClass]="{'disabled': !isAnnotationDisplayEnabled || annotatingMode === true,
            'active': (activeAnnotation && activeAnnotation.annotation && activeAnnotation.annotation.id===annotation.id),
            'inactive': (activeAnnotation && activeAnnotation.type==='click' && activeAnnotation.annotation && activeAnnotation.annotation.id!==annotation.id)}">
            <mtm-user-profile-picture [username]="annotation.comments[0].meta.user_id" [height]="markerAvatarSize" [width]="markerAvatarSize" [showBorder]="false"
                class="marker-avatar">
            </mtm-user-profile-picture>
        </div> -->
        <input type="range" class="amplitude-song-slider" id="global-large-song-slider" onkeydown="return false;">
    </div>
    <div class="colour-spacer-bottom"></div>
    <div class="player-container d-flex flex-column">
        <div class="player-controls d-flex">
            <div class="d-flex dynamic-flex">
                <div class="amplitude-play-pause d-flex align-items-center toolbar-item">
                    <div class="caret ml-2" title="Play" *ngIf="playerState === 'paused' || playerState === 'stopped'">
                        <i class="fa fa-play" aria-hidden="true"></i>
                    </div>
                    <div class="caret ml-2" title="Pause" *ngIf="playerState === 'playing'">
                        <i class="fa fa-pause" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="volume-container d-flex align-items-center toolbar-item" title="Mute">
                    <div class="volume-button px-2" (click)="toggleMute()">
                        <div class="volume-mute" *ngIf="volumeMute || volume == 0">
                            <img src="../../../../../assets/img/annotations/sound_off.svg" alt="" height="20">
                        </div>
                        <div class="volume-low" *ngIf="volume >= 1 && volume < 25 && !volumeMute">
                            <img src="../../../../../assets/img/annotations/sound_s.svg" alt="" height="20">
                        </div>
                        <div class="volume-mid" *ngIf="volume >= 25 && volume < 50 && !volumeMute">
                            <img src="../../../../../assets/img/annotations/sound_m.svg" alt="" height="20">
                        </div>
                        <div class="volume-high" *ngIf="volume >=50 && !volumeMute">
                            <img src="../../../../../assets/img/annotations/sound_l.svg" alt="" height="20">
                        </div>
                    </div>
                    <div class="volume-progress-container d-flex align-items-center">
                        <input class="volume-slider" type="range" (input)="updateVolume($event.target.value)"
                            value="100" min="0" max="100" step="1" value="{{volume}}">
                        <progress value="{{volume}}" min="0" max="100" value="100" class="volume-progress"></progress>
                    </div>
                </div>
            </div>
            <!-- <div class="custom-spacer"></div> -->
            <!-- <div class="cancel-playback-container d-flex align-items-center">
                <div class="cancel-box d-flex align-items-center" *ngIf="activeAnnotation?.type === 'click'"
                    (click)="closeActiveAnnotation()">
                    <span>Cancel Playback Range</span>
                    <img src="../../../../../assets/img/annotations/esc.svg" alt="" height="15">
                </div>
            </div> -->
            <div class="bottom-time-control d-flex align-items-center" *ngIf="pluginReady"
                (click)="isBottomTimeFormatOpen = !isBottomTimeFormatOpen">
                <div class="time-container d-flex align-items-center" title="Time Format"
                    [ngClass]="{'active': isBottomTimeFormatOpen}">
                    <span class="current-time px-2">{{getCurrentTime()}}</span>
                    <span class="divider"> / </span>
                    <span class="duration px-2">{{getAudioDuration()}}</span>
                    <div class="caret pr-2"
                        [ngClass]="{ 't-open': isBottomTimeFormatOpen, 't-close': !isBottomTimeFormatOpen }">
                        <i class="fa fa-play" aria-hidden="true"></i>
                    </div>
                </div>
                <div class="format-types d-flex flex-column align-items-center justify-content-center"
                    *ngIf="isBottomTimeFormatOpen">
                    <div class="seconds-format pt-1 d-flex justify-content-center"
                        [ngClass]="{'active': activeTimeFormat === 'seconds'}"
                        (click)="changeTimeFormat('seconds', 'bottom')">
                        <span>Seconds</span>
                    </div>
                    <div class="milliseconds-format pt-1 d-flex justify-content-center"
                        [ngClass]="{'active': activeTimeFormat === 'milliseconds'}"
                        (click)="changeTimeFormat('milliseconds', 'bottom')">
                        <span>Milliseconds</span>
                    </div>
                </div>
            </div>
            <!-- <div class="custom-spacer"></div> -->
            <div class="d-flex justify-content-end dynamic-flex">
                <div class="download-button-display d-flex align-items-center justify-content-center p-2 toolbar-item">
                    <mtm-file-download-control [file]="item" [displayType]="'icon'" [iconSize]="24" [lightIcon]="true"
                        [iconColor]="'white'"
                        [uniqueControlId]="item.projectId + '-' + item.sectionId + '-' + item.subSectionId + '-' + item.id"
                        [iconUrl]="'../../../../../assets/img/annotations/download.png'">
                    </mtm-file-download-control>
                </div>
                <div class="toggle-annotation-display d-flex align-items-center justify-content-center toolbar-item"
                    (click)="toggleAnnotationDisplay()">
                    <img src="../../../../../assets/img/annotations/{{ isAnnotationDisplayEnabled ? 'toggleDisplayIconEnabled' : 'toggleDisplayIconDisabled' }}.png"
                        alt="" height="36" title="Toggle Annotations Display">
                </div>
            </div>
        </div>
        <!-- <div class="song-navigation">
            <input type="range" min="1" max="100" step="0.01" class="amplitude-song-slider w-100"/>
        </div>
        <div class="progress-container">
            <progress class="amplitude-song-played-progress w-100"></progress>
        </div> -->
        <div *ngIf="amplitudeReady" class="progress-bar">
            <!-- <div class="playable-range" *ngFor="let annotation of annotations; let i=index;"
                [ngClass]="{'active': (activeAnnotation && activeAnnotation.type==='click' && activeAnnotation.annotation && activeAnnotation.annotation.id===annotation.id)}"
                [ngStyle]="{'left': annotation.left + '%', 'width': annotation.width + '%', 'z-index': '14'}">
            </div> -->
            <div class="no-pointer-left" *ngFor="let annotation of annotations; let i=index;"
                [ngClass]="{'active': (activeAnnotation && activeAnnotation.type==='click' && activeAnnotation.annotation && activeAnnotation.annotation.id===annotation.id)}"
                [ngStyle]="{'left': '0%', 'width': annotation.left + '%'}">
            </div>
            <div class="no-pointer-right" *ngFor="let annotation of annotations; let i=index;"
                [ngClass]="{'active': (activeAnnotation && activeAnnotation.type==='click' && activeAnnotation.annotation && activeAnnotation.annotation.id===annotation.id)}"
                [ngStyle]="{'left': annotation.left+annotation.width + '%', 'width': 100-(annotation.left+annotation.width) + '%'}">
            </div>
            <input type="range" (input)="updateAudioTime($event.target.value)" (click)="inputClickChange()" value="0"
                min="0" max="{{audioDuration}}" step="{{audioStep}}" class="w-100 current-time-input"
                id="progress-navigation" [ngClass]="{'invalid-user': checkInvalidUser()}">
            <progress [ngClass]="{'bufferComplete': (bufferProgress.value == 1), 'invalid-user': checkInvalidUser()}"
                id="song-played-progress" class="amplitude-song-played-progress" value="0" step="{{audioStep}}" min="0"
                max="{{audioDuration}}"></progress>
            <progress #bufferProgress id="song-buffered-progress" class="amplitude-buffered-progress" value="0"
                [ngClass]="{'invalid-user': checkInvalidUser()}"></progress>
            <progress class="empty-buffer-progress" value="0"
                [ngClass]="{'invalid-user': checkInvalidUser()}"></progress>
        </div>
        <div class="marker-container" [ngClass]="{'disabled': !isAnnotationDisplayEnabled}"
            *ngIf="preWaveFormReady && postWaveFormReady">
            <div class="annotation-bar-container-first active hidden-row"
                *ngFor="let annotation of progressBarMarkers; let i=index;" [ngClass]="{'active': (activeAnnotation && activeAnnotation.annotation && activeAnnotation.annotation.id === annotation.id),
                'disabled-marker': (isPlaying || annotatingMode || (activeAnnotation && activeAnnotation.annotation && activeAnnotation.annotation.id !== annotation.id)),
                'invalid-user': checkInvalidUser()}"
                [ngStyle]="{'left': annotation.left + '%', 'width': annotation.width + '%', 'z-index': '16'}"
                (mouseenter)="hoverInAnnotation(annotation)" (mouseleave)="hoverOutAnnotation(annotation)"
                (click)="annotationBarClick(annotation, $event.target)">
            </div>
            <div class="d-block annotation-bar-container pt-5">
                <mtm-videojs-annotation-bar-list [annotations]="annotations" [projectId]="projectId"
                    [sectionId]="sectionId" [subsectionId]="subsectionId" [projectParticipants]="projectParticipants"
                    [typeId]="typeId" [item]="item" [annotationPlugin]="annotationPlugin" [isPlayerReady]="true"
                    [isAudio]="true" [isPlaying]="isPlaying" [activePluginAnnotation]="activeAnnotation">
                </mtm-videojs-annotation-bar-list>
            </div>
        </div>
    </div>
</div>
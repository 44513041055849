import * as _ from 'lodash';
import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModalComponent } from './../components/notification-modal/notification-modal.component';
import { ApiService } from 'app/shared/services/api.service';
import { AuthService } from "app/shared/services/auth.service";
import { HelperService } from "./helper.service";
import { TranslatePipe } from "../pipes/translate.pipe";
import { MtmDatePipe } from "../pipes/mtm-date.pipe";
import { UserService } from "./user.service";
import * as moment from 'moment';
import { ConversationService } from './conversation.service';

export const NOTIFICATION_STATUS = {
	LOADING: 'LOADING',
	LOADED: 'LOADED',
	ERROR: 'ERROR'
}
export const listenerNotificationStatus: EventEmitter<string> = new EventEmitter<string>();
export const contentLanguageUpdated: EventEmitter<string> = new EventEmitter<string>();

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	private messageSource = new BehaviorSubject('');
	currentMessage = this.messageSource.asObservable();

	notificationRedirect$ = new BehaviorSubject<any>(null);

	changeMessage(message: string) {
		this.messageSource.next(message)
	}
	/* ----------- NOTIFICATIONS ----------- */
	public notificationStatus: string;
	public allNotifications: any[] = [];
	public todayNotifications: any[] = [];
	public pastNotifications: any[] = [];
	public notificationUnseenCount: number = 0;
	public displayLabelOfUnseenNotificaiton: string = '0';
	private userProfileSettings: any;
	private newRole: string;
	private invitedRoleName: string;
	private oldRole: string;

	/* ----------- ///////////// ----------- */

	constructor(private modalService: NgbModal,
		private apiService: ApiService,
		private mtmDate: MtmDatePipe,
		private authService: AuthService,
		public userService: UserService,
		private translatePipe: TranslatePipe,
		private conversation: ConversationService) {
	}

	modalRef: NgbModalRef;
	//NOTIFICATION_MODAL//
	open(data: any, avoidDuplicate?: any): Observable<any> {
		// TODO This should be moved to another service since it is not related
		// to Notification controller from API

		let subject = new Subject<any>();

		if (avoidDuplicate && this.modalRef) {
			this.modalRef.close();
		}

		if (!data.size)
			data.size = 'sm';

		this.modalRef = this.modalService.open(NotificationModalComponent, { size: data.size, backdrop: data.backdrop, windowClass: data.windowClass });
		this.modalRef.componentInstance.setParams(data);
		this.modalRef.result.then((result) => {
			subject.next(result);
		}, (reason) => {
			subject.next(false);
		});

		return subject.asObservable();
	}


	/* ----------- USER_NOTIFICATIONS ----------- */
	prepareNotification(item) {
		item.object = item.objectJson ? JSON.parse(item.objectJson) : null;
		if (item.object && item.object.notifiedBy && !item.object.notifiedTargetUsername) {
			item.object.notifiedTargetUsername = item.username;
		}
		item.date = item.time;
		item.isToday = ((new Date().getTime() - item.date) / 86400000) < 0.9;
		item.notificationDisplay = this.getMessage(item.code, item.object || {}) || {};
		return item;
	}

	addNewNotification(item, isInit = false) {
		this.prepareNotification(item);
		if (isInit)
			this.allNotifications.push(item);
		else
			this.allNotifications.unshift(item);

		this.allNotifications = this.allNotifications.filter(item => item.notificationDisplay && item.notificationDisplay.label);
		this.todayNotifications = this.allNotifications.filter(k => k.isToday);
		this.pastNotifications = this.allNotifications.filter(k => !k.isToday);

		if (!isInit) {
			this.setNotificationStatus(NOTIFICATION_STATUS.LOADED);
			this.notificationUnseenCount++;
		}

		this.notificationUnseenCount < 9 ? this.displayLabelOfUnseenNotificaiton = this.notificationUnseenCount.toString() : this.displayLabelOfUnseenNotificaiton = '9+'
	}

	private setNotificationStatus(status) {
		this.notificationStatus = status;
		listenerNotificationStatus.emit(status);
	}

	private clearNotificationVariables() {
		this.allNotifications = [];
		this.todayNotifications = [];
		this.pastNotifications = [];
		this.notificationUnseenCount = 0;

		this.setNotificationStatus(NOTIFICATION_STATUS.LOADING);
	}

	getAllUnseenNotifications() {
		this.clearNotificationVariables();

		this.userService.getUserProfileSettings().subscribe(result => {
			this.userProfileSettings = result

			this.getAllUnseenNotificationsFromAPI().subscribe(data => {
				if (!_.get(data, 'notifications') || !Array.isArray(data.notifications)) {
					this.setNotificationStatus(NOTIFICATION_STATUS.ERROR);
					return;
				}

				this.clearNotificationVariables();
				this.notificationUnseenCount = data.unseenCount;
				data.notifications.forEach(item => this.addNewNotification(item, true));
				this.setNotificationStatus(NOTIFICATION_STATUS.LOADED);
			}, err => this.setNotificationStatus(NOTIFICATION_STATUS.ERROR));
		});
	}

	getAllUnseenNotificationsFromAPI(): Observable<any> {
		return this.apiService.httpGet(`/api/notifications/unseen`);
	}

	getUnseenProjectNotificationsCount(projectId: string): Observable<any> {
		if (Array.isArray(projectId) && projectId.length > 1) {
			return this.apiService.httpGet(`/api/notifications/project/unseen?projectIds=${[projectId]}`);
		} else if (projectId && projectId.length) {
			return this.apiService.httpGet(`/api/notifications/project/${projectId}/unseen`);
		} else {
			return ;
		}
	}

	getUnseenWorkspaceNotificationsCount(workspaceId: string): Observable<any> {
		return this.apiService.httpGet(`/api/notifications/workspace/${workspaceId}/unseen`);
	}

	getUnseenNotificationsCount(projectIds: string[]): Observable<any> {
		return this.apiService.httpPost('/api/notifications/count', projectIds);
	}

	getUnseenProjectNotificationsCountInWorkspace(workspaceId: string): Observable<any> {
		return this.apiService.httpGet(`/api/notifications/${workspaceId}/count`);
	}

	/**
	 * Mark notifications as seen
	 */
	markSeenNotifications() {
		this.apiService.httpPut(`/api/notifications`, {}).subscribe(
			(data: any) => {
				data.message === 'OK' ? this.notificationUnseenCount = 0 : this.getAllUnseenNotifications()
			},
			(err: any) => {
				this.setNotificationStatus(NOTIFICATION_STATUS.ERROR);
			});
	}

	/*
	Mark all notifications as seen. If project id is supplied then only notifications in that project are affected
	 */
	markNotificationsAsSeen(projectId : string | undefined ):  Observable<any> {
		const endpoint = (projectId === undefined || projectId === null) ? `/api/notifications/${projectId}/unseen` : `/api/notifications`;
		return this.apiService.httpPut(endpoint, {});
	}

	getNotifications(projectId?: string, category?: string, codes?: string[], notifyByUsers?: any, sections?: any, subSections?: any, startDate?: number, endDate?: number, pagingState?: string, limit: number = 25): Observable<any> {
		let subject = new Subject<any>();
		this.userService.getUserProfileSettings().subscribe(result => {
			this.userProfileSettings = result
			let url = `/api/notifications`;
			if (pagingState && pagingState !== '')
				url = url + `&pagingState=${pagingState}`;
			if (projectId && projectId !== '')
				url = url + `&projectIds=${projectId}`;
			if (notifyByUsers)
				url = url + `&notifyByUsers=${notifyByUsers}`;
			if (sections)
				url = url + `&sections=${sections}`;
			if (subSections)
				url = url + `&subSections=${subSections}`;
			if (startDate)
				url = url + `&startDate=${startDate}`;
			if (endDate)
				url = url + `&endDate=${endDate}`;
			if (category && category !== '')
				url = url + `&category=${category}`;
			if (codes)
				url = url + `&codes=${codes.join(",")}`;
			if (limit)
				url += `&limit=${limit}`;

			if (url.indexOf('?') == -1) {
				let queryIndex = url.indexOf('&');
				if (queryIndex != -1)
					url = url.substr(0, queryIndex) + '?' + url.substr(queryIndex + 1, url.length - 1);
			}

			this.apiService.httpGet(url).subscribe(
				(result: any) => {
					result.data.forEach((item: any) => {
						this.prepareNotification(item);
					});
					subject.next(result);
				},
				(err: any) => {
					subject.error(err);
				}
			);
		});
		return subject.asObservable();
	}

	getStages(): Observable<any> {
		return this.apiService.httpGet('/api/notifications/stages');
	}

	/**
	 * Get message for display notification
	 * @param
	 * @param code
	 * @param object
	 */
	getMessage(code: string, object: any = null) {
		if (code != 'CONVERSATION_MESSAGE_SENT') {
			this.conversation.notificationMessage(true)
		}

		if (object.newRole) {
			this.newRole = this.translatePipe.translateRole(object.newRole);
		}

		if (object.oldRole) {
			this.oldRole = this.translatePipe.translateRole(object.oldRole);
		}

		if (object.roleName) {
			this.invitedRoleName = this.translatePipe.translateRole(object.roleName);
		}


		let notificationCode: any = "notification" + code;

		let translatedNotification = this.translatePipe.transform(notificationCode);

		let message = {
			label: translatedNotification,
			action: {}
		}

		let originalCode = notificationCode;
		let authUserName = this.authService.getAuthUserName();

		if (object) {
			if (code == 'ANNOTATION_REPLIED') {
				if (object.notifiedTargetUsername == authUserName) {
					notificationCode += "_YOUR"
				} else if (object.notifiedByUsername == object.notifiedTargetUsername) {
					notificationCode += "_SELF"
				}
			} else if (object.notifiedByUsername == authUserName) {
				notificationCode += "_SELF"
			} else if (object.notifiedTargetUsername == authUserName) {
				notificationCode += "_YOUR"
			}
			try {
				switch (code) {
					// Project
					case 'PROJECT_CREATED':
					case 'PROJECT_UPDATED':
					case 'PROJECT_ARCHIVED':
					case 'PROJECT_DELETED':
						let projectNotificationCode = notificationCode.replace("_YOUR", "")
						message.label = this.translatePipe.transform(projectNotificationCode, {
							company_name: object.companyName,
							notifiedBy: object.notifiedBy,
							project_name: object.projectName
						});
						break;
					case 'PROJECT_INVITATION':
					case 'PROJECT_USER_INVITED':
					case 'PROJECT_USER_REMOVED':
						message.label = this.translatePipe.transform(notificationCode, {
							company_name: object.companyName,
							notifiedTarget: object.notifiedTarget,
							notifiedBy: object.notifiedBy,
							project_name: object.projectName
						});
						break;
					case 'PROJECT_USER_ROLES_UPDATED':
						const roles = object.notifiedTargetRolesCodes.map(role => this.translatePipe.translateRole(role)).join(", ");
						message.label = this.translatePipe.transform(notificationCode, {
							notifiedTargetRoles: roles,
							notifiedTarget: object.notifiedTarget,
							notifiedBy: object.notifiedBy,
							project_name: object.projectName
						});
						//this.authService.loginWithToken(this.authService.getAuthToken(), this.authService.getAuthUser().username);
						break;
					// Sub-Section
					case 'PROJECT_SUBSECTION_CREATED':
					case 'PROJECT_SUBSECTION_DATE_CHANGED':
						message.label = this.translatePipe.transform(originalCode, {
							notifiedBy: object.notifiedBy,
							sectionName: object.sectionName,
							subSectionName: this.findSubsectionName(object.subSectionName),
							project_name: object.projectName
						});
						break;
					case 'PROJECT_SUBSECTION_DELETED':
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%PROJECTNAME%', object.projectName);
						message.label = message.label.replace('%SECTIONNAME%', object.sectionName);
						message.label = message.label.replace('%SUBSECTIONNAME%', this.findSubsectionName(object.subSectionName));
						break;
					// Sub-Section Item
					case 'PROJECT_SUBSECTION_ITEM_CREATED':
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%SUBSECTIONNAME%', this.findSubsectionName(object.subSectionName));
						message.label = message.label.replace('%ITEM%', object.item);
						break;
					case 'PROJECT_SUBSECTION_ITEM_APPROVED':
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%SUBSECTIONNAME%', this.findSubsectionName(object.subSectionName));
						message.label = message.label.replace('%ITEMNAME%', object.itemName);
						message.label = message.label.replace('%EDITING_TYPE%', object.editingType);
						break;
					// Comment
					case 'COMMENT_CREATED':
					case 'COMMENT_DELETED':
					case 'COMMENT_EDITED':
					case 'ANNOTATION_CREATED':
					case 'ANNOTATION_DELETED':
					case 'ANNOTATION_EDITED':
						message.label = this.translatePipe.transform(originalCode, {
							notifiedBy: object.notifiedBy,
							subSectionName: this.findSubsectionName(object.subSectionName),
							itemName: object.itemName,
							version: object.versionNumber
						});
						break;
					case 'ANNOTATION_TAGGED':
						message.label = this.translatePipe.transform(notificationCode, {
							notifiedBy: object.notifiedBy,
							notifiedTarget: object.notifiedTarget,
							subSectionName: this.findSubsectionName(object.subSectionName),
							itemName: object.itemName
						});
						break;
					case 'VOTE_YES':
					case 'VOTE_NO':
					case 'VOTE_MAYBE':
						let isBatchUpload = false;
						let labelContent = '';
						if (object.fileVotes) {
							isBatchUpload = true;
							const fileVotes = JSON.parse(object.fileVotes);
							const fileNames = Object.values(fileVotes).map((vote: any) => vote.name).join(', ');
							labelContent = this.translatePipe.transform('notification_batchVoteDescription', { file: fileNames, option: object.itemName });
						} else if (object.itemVotes) {
							const itemVotes = JSON.parse(object.itemVotes);
							labelContent = Object.values(itemVotes).map((vote: any) => this.translatePipe.transform('notification_normalVoteDescription',
								{ version: vote.versionNumber || object.versionNumber, option: vote.name })).join(', ');
						}

						message.label = this.translatePipe.transform(originalCode, {
							notifiedBy: object.notifiedBy,
							subSectionName: this.findSubsectionName(object.subSectionName),
							items: labelContent,
						});
						break;
					case 'VOTE_CANCELED':
						let previousStatus = '';
						switch (object.previousVote) {
							case 'YES':
								previousStatus = 'edit_vote_approval';
								break;
							case 'NEUTRAL':
								previousStatus = 'edit_vote_neutralVote';
								break;
							case 'NO':
								previousStatus = 'edit_vote_rejection';
								break;
						}
						message.label = this.translatePipe.transform(originalCode, {
							notifiedBy: object.notifiedBy,
							subSectionName: this.findSubsectionName(object.subSectionName),
							itemName: object.itemName,
							status: this.translatePipe.transform(previousStatus),
							version: object.versionNumber
						});
						break;
					// Comment replies
					case 'COMMENT_REPLIED':
					case 'ANNOTATION_REPLIED':
						message.label = this.translatePipe.transform(notificationCode, {
							notifiedBy: object.notifiedBy,
							notifiedTarget: object.notifiedTarget,
							subSectionName: this.findSubsectionName(object.subSectionName),
							itemName: object.itemName
						});
						break;
					// Uploads
					case 'FILE_UPLOADED':
						let file = {
							contentType: object.fileContentType,
							name: object.fileName,
							type: null,
							publicLink: null
						};
						let fileUploadNotificationCode;
						if (!object.sectionId && !object.conversationId) {
							fileUploadNotificationCode = notificationCode.replace("_YOUR", "") + "_KEY_FILES";
						} else if (object.sectionName) {
							fileUploadNotificationCode = notificationCode.replace("_YOUR", "") + "_SECTION";
						} else if (object.subsectionPartName && object.subsectionPartItemName) {
							fileUploadNotificationCode = notificationCode.replace("_YOUR", "") + "_ITEM";
						} else if (object.conversationTitle) {
							if (object.conversationType == "ONE_TO_ONE") {
								fileUploadNotificationCode = notificationCode + "_CONVERSATION_121";
							} else {
								fileUploadNotificationCode = notificationCode.replace("_YOUR", "") + "_CONVERSATION";
							}
						}
						message.label = this.translatePipe.transform(fileUploadNotificationCode, {
							notifiedBy: object.notifiedBy,
							notifiedTarget: object.notifiedTarget,
							fileType: this.findFileType(file),
							fileName: this.findFileName(object),
							subSectionPartItemName: object.subsectionPartItemName,
							subsectionPartName: object.subsectionPartName,
							sectionName: object.sectionName,
							subSectionName: this.findSubsectionName(object.subSectionName),
							conversationTitle: object.conversationTitle,
							itemName: object.itemName ? object.itemName : object.sectionName
						});
						break;
					case 'FILE_BATCH_UPLOADED':
						const batchCode = notificationCode.replace("_YOUR", "").replace("_SELF", "");
						const totalUploads = parseInt(object.totalSuccess, 10) + parseInt(object.totalFailed, 10);
						if(totalUploads == 1) {
							message.label = this.translatePipe.transform(
								'notificationFILE_UPLOADED', {
									notifiedBy: object.notifiedBy,
									fileName: this.findFileName(object),
								}
							);
						} else {
							message.label = this.translatePipe.transform(batchCode, {
								notifiedBy: object.notifiedBy,
								totalSuccessful: object.totalSuccess,
								totalFailures: object.totalFailed
							});
						}

						break;
					case 'FILE_DELETED':
						let fileDeleteNotificationCode = notificationCode.replace("_YOUR", "");
						if (!object.sectionId) {
							fileDeleteNotificationCode = fileDeleteNotificationCode + "_KEY_FILES";
						}
						message.label = this.translatePipe.transform(fileDeleteNotificationCode, {
							notifiedBy: object.notifiedBy,
							sectionName: object.sectionName,
							fileName: this.findFileName(object),
							itemName: object.itemName ? object.itemName : object.sectionName
						});
						break;
					case 'FILE_DOWNLOADED':
						let fileDownloadedNotificationCode = notificationCode.replace("_YOUR", "");
						message.label = this.translatePipe.transform(fileDownloadedNotificationCode, {
							notifiedBy: object.notifiedBy,
							notifiedTarget: object.notifiedTarget,
							fileName: this.findFileName(object),
							sectionName: object.sectionName,
							subSectionName: this.findSubsectionName(object.subSectionName),
							assetname: object.assetname ? object.assetname : object.sectionName
						});
						break;
					case 'CONVERSATION_CALL_MISSED':
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						break;
					/*
					case 'CONVERSATION_CALL_REJECTED':
						message.label = message.label.replace('%NOTIFIEDBY%', object.rejectedUser);
						break;
					*/
					case 'PROPOSAL_ACCEPTED':
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%COMPANY_NAME%', object.notifiedByCompanyName);

						break;

					case 'INVITATION_ACCEPTED':
						message.label = message.label.replace('%COMPANY_NAME%', object.companyName);
						break;
					case 'INVITATION_FIRST_ACCEPTED':
						message.label = message.label.replace('%COMPANY_NAME%', object.companyName);
						break;
					case 'PROPOSAL_UPLOADED':
						let proposalUploadedNotificationCode = notificationCode.replace("_YOUR", "");
						message.label = this.translatePipe.transform(proposalUploadedNotificationCode, {
							notifiedBy: object.notifiedBy,
							notifiedTarget: object.notifiedTarget,
							company_name: object.companyName
						});
						break;
					case 'PROPOSAL_DELETED':
					case 'PROPOSAL_QA_COMMENT':
					case 'PROPOSAL_DISCUSSION_COMMENT':
						message.label = this.translatePipe.transform(notificationCode, {
							notifiedBy: object.notifiedBy,
							notifiedTarget: object.notifiedTarget,
							company_name: object.companyName
						});
						break;
					case 'BRIEF_UPLOAD':
						return this.getMessage("FILE_UPLOADED", object);
					case 'PROJECT_DATE':
						message.label = message.label.replace('%DATE_TYPE%', object.changedDate);
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%OLD_DATE%', object.oldDate);
						message.label = message.label.replace('%NEW_DATE%', object.newDate);
						break;
					case 'DOWNPAYMENT_REMINDER':
					case 'FINALPAYMENT_REMINDER':
						let paymentReminderNotificationCode = notificationCode.replace("_YOUR", "");
						if (!object.remainingDays || object.remainingDays <= 0) {
							paymentReminderNotificationCode += "_TODAY"
						}
						let paymentDeadline = object.downpaymentDeadline ? object.downpaymentDeadline : object.finalpaymentDeadline
						message.label = this.translatePipe.transform(paymentReminderNotificationCode, {
							remainingDays: object.remainingDays,
							paymentDeadline: this.mtmDate.transform(paymentDeadline)
						});
						message.action = {
							event: "PAYMENT",
							message: this.translatePipe.transform("notificationActionPayNow")
						}
						break;
					case 'INVITATION_DECLINED':
						message.label = this.translatePipe.transform(notificationCode, {
							COMPANY_NAME: object.companyName
						});
						break;
					case 'TRANSFER_STATUS':
						let transferStatusNotificationCode = notificationCode.replace("_YOUR", "");
						let transferStatus = object.transferStatus.toUpperCase();
						message.label = this.translatePipe.transform(transferStatusNotificationCode + "_" + transferStatus + "_" + object.paymentType);
						break;
					case 'PAYMENT_STATUS':
						let paymentStatusNotificationCode = notificationCode.replace("_YOUR", "");
						let paymentStatus = object.paymentStatus.toUpperCase();
						message.label = this.translatePipe.transform(paymentStatusNotificationCode + "_" + paymentStatus, {
							paymentType: this.translatePipe.transform("saleType" + object.paymentType),
							paymentMethodType: this.translatePipe.transform(object.paymentMethodType == "CARD" ? "paymentKindCreditCard" : "paymentKindSepa").toLowerCase(),
						});
						if (paymentStatus == 'FAILED') {
							message.action = {
								event: "PAYMENT",
								message: this.translatePipe.transform("notificationActionPayNow")
							}
						}
						break;
					case 'VERIFICATION_STATUS':
						message.label = this.translatePipe.transform(notificationCode, {
							verification_info: object.verificationInfo,
							verification_status: object.verificationStatus
						})
						break;
					case 'CONVERSATION_CREATED':
					case 'CONVERSATION_UPDATED':
					case 'CONVERSATION_ARCHIVED':
					case 'CONVERSATION_DELETED':
						message.label = this.translatePipe.transform(notificationCode, {
							title: object.conversationTitle,
							notifiedBy: object.notifiedBy,
						})
						break;
					case 'CONVERSATION_USER_ADDED':
					case 'CONVERSATION_USER_REMOVED':
						message.label = this.translatePipe.transform(notificationCode, {
							notifiedBy: object.notifiedBy,
							notifiedTarget: object.notifiedTarget,
							title: object.conversationTitle
						});
						break;
					case 'EVENT_CREATED':
					case 'EVENT_UPDATED':
					case 'EVENT_ARCHIVED':
					case 'EVENT_DELETED':
						message.label = this.translatePipe.transform(notificationCode, {
							eventtitle: object.eventTitle,
							eventdate: moment(object.eventStart, "x").format(this.userProfileSettings.datePattern),
							eventstart: moment(object.eventStart, "x").format(this.userProfileSettings.timePattern),
							eventend: moment(object.eventEnd, "x").format(this.userProfileSettings.timePattern),
							notifiedBy: object.notifiedBy,
							notifiedTarget: object.notifiedTarget
						})
						break;
					case 'EVENT_USER_ADDED':
					case 'EVENT_USER_REMOVED':
						message.label = this.translatePipe.transform(notificationCode, {
							notifiedBy: object.notifiedBy,
							notifiedTarget: object.notifiedTarget,
							eventtitle: object.eventTitle,
							eventdate: moment(object.eventStart, "x").format(this.userProfileSettings.datePattern),
							eventstart: moment(object.eventStart, "x").format(this.userProfileSettings.timePattern),
							eventend: moment(object.eventEnd, "x").format(this.userProfileSettings.timePattern)
						});
						break;
					//workSpace
					case 'WORKSPACE_ADDED_USER':
					case 'WORKSPACE_DELETED_USER':
					case 'WORKSPACE_EDITED':
					case 'WORKSPACE_CREATED':
					case 'WORKSPACE_DELETED':
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%WORKSPACENAME%', object.workspaceName);
						message.label = message.label.replace('%ROLENAME%', this.invitedRoleName);
						message.label = message.label.replace('%OLDWORKSPACE%', object.oldWorkspaceName);
						break;

					case 'WORKSPACE_MOVED_PROJECT':
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%PROJECTNAME%', object.projectName);
						message.label = message.label.replace('%NEWWORKSPACENAME%', object.workspaceName);
						message.label = message.label.replace('%OLDWORKSPACE%', object.oldWorkspaceName);
						break;

					case 'WORKSPACE_EDITED_USER':
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%COMPANY_NAME%', object.companyName);
						message.label = message.label.replace('%OLDROLE%', this.oldRole);
						message.label = message.label.replace('%NEWROLE%', this.newRole);
						break;
					case 'CHANGED_COMPANY_ROLE':
						message.label = this.translatePipe.transform(notificationCode, {
							COMPANY_NAME: object.notifiedByCompanyName,
							USERNAME: object.notifiedByUsername,
							OLD_ROLE: this.oldRole,
							NEW_ROLE: this.newRole
						});
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'INVITED_TO_COMPANY':
						message.label = this.translatePipe.transform(notificationCode, {
							ROLE_NAME: object.roleName,
							INVITER_NAME: object.notifiedByUsername
						});
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'COMPANY_OWNERSHIP_TRANSFERRED':
						message.label = this.translatePipe.transform(notificationCode, {
							OLD_USERNAME: object.oldOwner,
							NEW_USERNAME: object.newOwner
						});
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'PAYMENT_METHOD_ADDED':
						message.label = this.translatePipe.transform(notificationCode, {
							USERNAME: object.notifiedBy
						});
						break;
					case 'PAYMENT_METHOD_REMOVED':
						message.label = this.translatePipe.transform(notificationCode, {
							USERNAME: object.notifiedBy
						});
						break;
					case 'DELETED_FROM_COMPANY':
						message.label = this.translatePipe.transform(notificationCode, {
							USERNAME: object.notifiedBy
						});
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'DEACTIVATED_FROM_COMPANY':
						message.label = this.translatePipe.transform(notificationCode, {
							USERNAME: object.notifiedBy
						});
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'DELETED_FILED_FROM_ACTIVE_STORAGE':
						message.label = this.translatePipe.transform(notificationCode, {
							USERNAME: object.notifiedBy
						});
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'DELETED_FILED_FROM_ARCHIVAL_STORAGE':
						message.label = this.translatePipe.transform(notificationCode, {
							USERNAME: object.notifiedBy
						});
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'DOWNGRADED_SCHEDULED_COMPANY_SUBSCRIPTION':
						message.label = this.translatePipe.transform(notificationCode, {
							USERNAME: object.notifiedBy,
							OLD_PLAN_NAME: object.oldPlanName,
							NEW_PLAN_NAME: object.newProductName,
						});
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'SUBSCRIPTION_PLAN_DOWNGRADE_IN_ONE_WEEK':
						message.label = this.translatePipe.transform(notificationCode);
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'SUBSCRIPTION_PLAN_DOWNGRADE_IN_THREE_DAYS':
						message.label = this.translatePipe.transform(notificationCode);
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'SUBSCRIPTION_PLAN_DOWNGRADE_TOMORROW':
						message.label = this.translatePipe.transform(notificationCode);
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'DOWNGRADE_SUCCESSFUL':
						message.label = this.translatePipe.transform(notificationCode, {
							OLD_PLAN_NAME: object.oldPlanName,
							NEW_PLAN_NAME: object.newProductName,
							COMPANY_NAME: object.notifiedByCompanyName
						});
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'DOWNGRADE_FAILED_USAGE_EXCEEDED':
						message.label = this.translatePipe.transform(notificationCode);
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'DOWNGRADE_FAILED_PAYMENT_FAILURE':
						message.label = this.translatePipe.transform(notificationCode);
						break;
					case 'SUBSCRIPTION_UPGRADE_SUCCESFULL':
						message.label = this.translatePipe.transform(notificationCode, {
							OLD_PLAN_NAME: object.oldPlanName,
							NEW_PLAN_NAME: object.newProductName,
							COMPANY_NAME: object.notifiedByCompanyName
						});
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'REMINDER_SUBSCRIPTION_PLAN_RENEWAL_ONE_WEEK_BEFORE':
						message.label = this.translatePipe.transform(notificationCode);
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'REMINDER_SUBSCRIPTION_PLAN_RENEWAL_THREE_DAYS_BEFORE':
						message.label = this.translatePipe.transform(notificationCode);
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'REMINDER_SUBSCRIPTION_PLAN_RENEWAL_TWENTYFOUR_HOURS_BEFORE':
						message.label = this.translatePipe.transform(notificationCode);
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'PLAN_RENEWAL_SUCCESFULL':
						message.label = this.translatePipe.transform(notificationCode);
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'PLAN_RENEWAL_FAILED':
						message.label = this.translatePipe.transform(notificationCode);
						message.action = {
							showCompany: true,
							companyName: object.notifiedByCompanyName
						}
						break;
					case 'CONVERSATION_MESSAGE_SENT':
						const messageLabel = notificationCode + '_' + object.conversationType;
						message.label = this.translatePipe.transform(messageLabel);
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedByUsername == authUserName ? object.notifiedTarget : object.notifiedBy);
						message.label = message.label.replace('%GROUP%', object.conversationTitle);
						break;
					case 'TASK_CREATED':
					case 'TASK_USER_ADDED':
						message.label = this.translatePipe.transform(notificationCode.replace('_YOUR', '').replace('_SELF', '') + '_' + object.userRoleInTask + (object.sectionName ? '' : '_project'));
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%TASKTITLE%', object.taskTitle);
						message.label = message.label.replace('%SUBSECTIONNAME%', this.findSubsectionName(object.subSectionName));
						message.label = message.label.replace('%PROJECTNAME%', object.projectName);
						break;
					case 'TASK_DELETED':
					case 'TASK_USER_REMOVED':
					case 'TASK_STATUS_UPDATED':
					case 'TASK_UPDATED':
					case 'TASK_STATUS_OVERDUE':
					case 'TASK_STATUS_BEWARE':
					case 'TASK_STATUS_UPCOMING_EXPIRY':
						message.label = this.translatePipe.transform(notificationCode.replace('_YOUR', '').replace('_SELF', '') + (object.sectionName ? '' : '_project'));
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%TASKTITLE%', object.taskTitle);
						message.label = message.label.replace('%SUBSECTIONNAME%', this.findSubsectionName(object.subSectionName));
						message.label = message.label.replace('%PROJECTNAME%', object.projectName);
						break;
					case 'TRANSCRIBING_STARTED':
					case 'TRANSCRIBING_FINISHED':
					case 'TRANSLATION_STARTED':
					case 'TRANSLATION_DELETED':
					case 'TRANSLATION_FINISHED':
					case 'BURN_SUBTITLE_STARTED':
					case 'BURN_SUBTITLE_FINISHED':
					case 'SUBTITLE_EDITED':
					case 'BURN_SUBTITLE_DELETED':
						message.label = message.label.replace('%NOTIFIEDBY%', object.notifiedBy);
						message.label = message.label.replace('%SUBTITLENAME%', object.fileName);
						message.label = message.label.replace('%LANGUAGE%', object.language);
						break;
				}
			} catch (err) {
				console.log(err);
				console.log(code);
				console.log(object);
			}
		}
		if (message.label == notificationCode) {
			return null;
		}
		return message;
	}

	private findFileName(object: any) {
		return object.fileName ? object.fileName.split(", ").map(f => {
			let filename = f.substring(f.lastIndexOf("/") + 1);
			if (filename.lastIndexOf(".") - filename.lastIndexOf("_") == 14) {
				return filename.substring(0, filename.lastIndexOf("_")) + filename.substring(filename.lastIndexOf("."));
			}
			return filename;
		}).join(", ") : '';
	}

	private findFileType(file) {
		if (HelperService.isVideoFile(file))
			return this.translatePipe.transform("fileTypeVideo");
		else if (HelperService.isImageFile(file))
			return this.translatePipe.transform("fileTypePhoto");
		else
			return this.translatePipe.transform("fileTypeFile");
	}

	private findSubsectionName(subSectionName) {
		if (!subSectionName) {
			return;
		}
		let translated = this.translatePipe.transform(subSectionName);
		let found = translated;
		if (subSectionName == translated) {
			subSectionName = subSectionName.replace("_", " ");
			found = subSectionName;
		}
		return found.toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
	}

}

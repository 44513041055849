<!-- <ng-template #headerContent>
	<mtm-workspace-edit-header [workspace]="workspace"></mtm-workspace-edit-header>
</ng-template>
 -->
<div class="campaign-overview-component flex-column py-4 px-2">
	<div class="d-flex flex-column mx-auto">
		<div class="d-block">
			<div class="d-none d-md-block d-lg-flex">
				<div class="align-items-center d-flex flex-md-wrap page-tab-control w-100">
					<div class="align-items-center d-flex flex-lg-nowrap flex-md-wrap justify-content-lg-start justify-content-md-center project-nav-tabs w-100 h-100" [style.gap]="'10px'">
						<div class="d-flex justify-content-lg-start justify-content-md-center w-100 h-100 ">

						<div class="tab-item pointer h-100 text-center" (click)="toggleTab(0)"
							[ngClass]="{ 'active': isworkspace }">
							<span>{{ 'workspace_edit_workspacesPermissions' | translate }}</span>
						</div>
						<div class="tab-item pointer h-100 text-center" (click)="toggleTab(1)"
							[ngClass]="{ 'active': isproject }">
							<span>{{ 'workspace_edit_projectsPermissions' | translate }}</span>
						</div>
						<div class="tab-item pointer h-100 text-center" (click)="toggleTab(2)"
							[ngClass]="{ 'active': isuser }">
							<span>{{ 'workspace_edit_usersAndTeams' | translate }}</span>
						</div>
					</div>

					<div class="align-items-center d-flex justify-content-end justify-content-lg-end justify-content-md-center w-100" [style.gap]="'10px'">
						<div class="tab-item p-0" [style.border-bottom]="'none'">
							<button class="btn btn-orange text-uppercase position-relative btn-remove-workspace text-nowrap py-1 px-4" (click)="deleteWorkspace()">
								<span class="f-10 _700">{{'removeworkspace' | translate}}</span>
							</button>
						</div>
						<div class="tab-item p-0" [style.border-bottom]="'none'">
							<button class="btn btn-orange text-uppercase position-relative btn-save-changes text-nowrap py-1 px-4" (click)="save()">
								<span class="f-10 _700">{{'saveChanges' | translate}}</span>
							</button>
						</div>
					</div>
					</div>
				</div>
			</div>
			<div class="d-block d-md-none">
				<div class="d-flex flex-column page-tab-control">
					<div class="d-flex flex-wrap mb-3 project-nav-tabs w-100">
						<div class="tab-item pointer w-100 text-center" (click)="toggleTab(0)"
							[ngClass]="{ 'active': isworkspace }">
							<span>{{ 'workspace_edit_workspacesPermissions' | translate }}</span>
						</div>
						<div class="tab-item pointer w-100 text-center" (click)="toggleTab(1)"
							[ngClass]="{ 'active': isproject }">
							<span>{{ 'workspace_edit_projectsPermissions' | translate }}</span>
						</div>
						<div class="tab-item pointer w-100 text-center" (click)="toggleTab(2)"
							[ngClass]="{ 'active': isuser }">
							<span>{{ 'workspace_edit_usersAndTeams' | translate }}</span>
						</div>
					</div>
					<div class="align-items-center d-flex flex-wrap justify-content-center w-100" [style.gap]="'10px'">
						<div class="tab-item p-0 w-100">
							<button
								class="btn btn-orange text-uppercase position-relative btn-remove-workspace text-nowrap py-1 px-4"
								(click)="deleteWorkspace()">
								<span class="f-10 _700">{{'removeworkspace' | translate}}</span>
							</button>
						</div>
						<div class="tab-item p-0 w-100">
							<button
								class="btn btn-orange text-uppercase position-relative btn-save-changes text-nowrap py-1 px-4"
								(click)="save()">
								<span class="f-10 _700">{{'saveChanges' | translate}}</span>
							</button>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
<div class="px-2">
	<!-- <div class="card tab-container">
		<div class="nav-tabs">
			<div class="nav-item" *ngFor="let tab of  tabList;let i=index">
				<a (click)="toggleTab(i)" [class.active]="i==tabIndex" class="nav-link">{{tab | translate}}</a>
			</div>
		</div>
	</div> -->

	<mtm-edit-workspace-permissions *ngIf="tabIndex==0" [workspaceId]="workspace.id"
		[setBreadcrumb]="setBreadcrumb.bind(this)" [roles]="roles"></mtm-edit-workspace-permissions>

	<mtm-workspace-projects *ngIf="tabIndex == 1" [workspace]="workspace" [companyUsers]="companyUsers"
		[setBreadcrumb]="setBreadcrumb.bind(this)" [roles]="roles"></mtm-workspace-projects>
	<mtm-user-and-teams *ngIf="tabIndex == 2" [workspace]="workspace" [companyUsers]="companyUsers"
		[setBreadcrumb]="setBreadcrumb.bind(this)" [roles]="roles"></mtm-user-and-teams>
</div>
import * as _ from 'lodash';
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject, of, BehaviorSubject } from "rxjs";
import { ApiService } from "./api.service";
import { HelperService } from './helper.service';
import { map, tap, share, finalize } from 'rxjs/operators';
import { AnnotationPrivateCondition } from "../interfaces";

export let onAddingAnnotation: EventEmitter<any> = new EventEmitter<any>();
export let onEditingAnnotation: EventEmitter<any> = new EventEmitter<any>();
export let onEditingCancelAnnotation: EventEmitter<any> = new EventEmitter<any>();
export let onSaveEditedAnnotation: EventEmitter<any> = new EventEmitter<any>();
export let onAddNewAnnotation: EventEmitter<any> = new EventEmitter<any>();
export let onCancelAddingAnnotation: EventEmitter<any> = new EventEmitter<any>();
export let onDeleteAnnotation: EventEmitter<any> = new EventEmitter<any>();

export let onAnnotationsLoaded: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationsStateChanged: EventEmitter<any> = new EventEmitter<any>();
export let onOpenAnnotation: EventEmitter<any> = new EventEmitter<any>();
export let onCloseActiveAnnotation: EventEmitter<any> = new EventEmitter<any>();
export let onReplyCommentAnnotation: EventEmitter<any> = new EventEmitter<any>();
export let onDeleteCommentAnnotation: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationFocused: EventEmitter<any> = new EventEmitter<any>();
export let onCommentInputFocused: EventEmitter<any> = new EventEmitter<any>();

export let onPlayerReady: EventEmitter<any> = new EventEmitter<any>();
export let onVideoPlayerInitializing: EventEmitter<any> = new EventEmitter<any>();
export let onVideoPlayerInitialized: EventEmitter<any> = new EventEmitter<any>();
export let onVideoPlayerErrorLoaded: EventEmitter<any> = new EventEmitter<any>();
export let onVideoPlayerDisposed: EventEmitter<any> = new EventEmitter<any>();
export let onAddingAnnotationDataChanged: EventEmitter<any> = new EventEmitter<any>();
export let onVideoPaused: EventEmitter<any> = new EventEmitter<any>();
export let onVideoPlay: EventEmitter<any> = new EventEmitter<any>();

export let onImageInitReady: EventEmitter<any> = new EventEmitter<any>();
export let onImageZoomChanged: EventEmitter<any> = new EventEmitter<any>();
export let onImagePluginReady: EventEmitter<any> = new EventEmitter<any>();

export let onAudioPluginReady: EventEmitter<any> = new EventEmitter<any>();
export let onFirstRowMarkersReady: EventEmitter<any> = new EventEmitter<any>();

export let onCommentPluginReady: EventEmitter<any> = new EventEmitter<any>();

export let onAnnotationHoverOpened: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationHoverClosed: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationClickOpened: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationClickClosed: EventEmitter<any> = new EventEmitter<any>();

export let onAnnotationMarkerSelectedActiveHover: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationMarkerSelectedInActiveHover: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationMarkerSelectedActiveClick: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationMarkerSelectedInActiveClick: EventEmitter<any> = new EventEmitter<any>();

//Audio Hover/Click Events
export let onAnnotationHoveredIn: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationHoveredOut: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationClickedIn: EventEmitter<any> = new EventEmitter<any>();
export let onAnnotationClickedOut: EventEmitter<any> = new EventEmitter<any>();

export let onUserColourCreated: EventEmitter<any> = new EventEmitter<any>();

export let onShowAnnotationCommentReplies: EventEmitter<any> = new EventEmitter<any>();

export let onTimeFormatChange: EventEmitter<any> = new EventEmitter<any>();
export let onTimeFormatChangePlugin: EventEmitter<any> = new EventEmitter<any>();

export let wsAnnotationChange: EventEmitter<any> = new EventEmitter<any>();

export class AnnotationComment {
  id: string;
  body: string;
  meta: {
    datetime: any,
    updatetime: any,
    user_id: string,
    user_name: string
  };
  parentId: string;
  files: any[];
}

export class AnnotationCommentDTO {
  id: string;
  body: string;
  files: any;
  taggedUsers: any;
  meta: {
    datetime: any,
    updatetime: any,
    username: string
  };
  parentId: string
}

export class Annotation {
  id: string;
  shape: string;
  range?: { start: number, end: number, isGeneralComment?: boolean };
  resolution: { height: number, width: number };
  comments: Array<AnnotationComment>

  //optional properties
  color?: string;
  colorOpacity?: string;
  isCommentInTimeRange?: boolean;
  rangeInTimeString?: { start: number, end: number };
  secondRangeString?: { start: number, end: number };
}

export class AnnotationDTO {
  id?: string;
  shape: string;
  resolution: string;
  range?: string;
  privateConditionKey?: AnnotationPrivateCondition;
  comments: Array<AnnotationCommentDTO>
}

export type MediaAnnotationQueryParams = {
  projectId: string;
  sectionId: string;
  subsectionId: string;
  typeId: string;
  itemId: string;
  participants: any[];
  privateConditionRequest?: AnnotationPrivateCondition;
  nativeObject?: boolean;
  cacheRequest?: boolean;
  lightweight?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AnnotationService {

  private cache: any = {};
  private cachedObservable: any = {};

  privateConditionChange$: BehaviorSubject<AnnotationPrivateCondition> = new BehaviorSubject(null);

  constructor(private apiService: ApiService) {
  }

  getCommentViewModel(comment: any, participants: any[]) {
    let annotationComment = new AnnotationComment();
    annotationComment.id = comment.id;
    annotationComment.body = comment.body;
    annotationComment.parentId = comment.parentId;
    annotationComment.files = comment.files;
    annotationComment.meta = {
      datetime: comment.meta.datetime,
      updatetime: comment.meta.updatetime,
      user_id: comment.meta.username,
      user_name: (_.find(participants, { username: comment.meta.username }) || {}).fullName
    }
    annotationComment.parentId = comment.parentId;
    return annotationComment
  }

  getAnnotationViewModel(annotation: any, participants: any[]) {
    let range = JSON.parse(annotation.range);
    let resolution = JSON.parse(annotation.resolution) || {};
    let entity = new Annotation();
    entity.id = annotation.id;
    if (range) {
      entity.range = {
        start: parseFloat(range.start || 0),
        end: parseFloat(range.end || 0),
        isGeneralComment: range.isGeneralComment || false
      }
    }
    else {
      entity.range = range;
    }
    entity.resolution = {
      height: parseFloat(resolution.height),
      width: parseFloat(resolution.width)
    }
    entity.shape = annotation.shape;
    entity.comments = new Array<AnnotationComment>();
    _.map(annotation.comments, (comment: any) => {
      let annotationComment = new AnnotationComment();
      annotationComment.id = comment.id;
      annotationComment.body = comment.body;
      annotationComment.parentId = comment.parentId;
      annotationComment.files = comment.files;
      annotationComment.meta = {
        datetime: comment.meta.datetime,
        updatetime: comment.meta.updatetime,
        user_id: comment.meta.username,
        user_name: (_.find(participants, { username: comment.meta.username }) || {}).fullName
      }
      annotationComment.parentId = comment.parentId;
      entity.comments.push(annotationComment);
    });
    return entity;
  }

  getMediaAnnotations(params: MediaAnnotationQueryParams): Observable<any> {
    const {
      projectId,
      sectionId,
      subsectionId,
      typeId,
      itemId,
      participants,
      nativeObject = false,
      lightweight = false,
      privateConditionRequest = false
    } = params;
    const url = `/api/mediaAnnotations/${projectId}/${sectionId}/${subsectionId}/${typeId}/${itemId}`;
    const queryParams: string[] = [];
    if (lightweight) {
      queryParams.push(`lightweight=true`);
    }
    if (privateConditionRequest) {
      queryParams.push(`privateConditionRequest=${privateConditionRequest}`);
    }
    const queryParamsString: string = !!queryParams.length ? '?' + queryParams.join('&') : '';
    return this.apiService.httpGet(url + queryParamsString)
      .pipe(share(),
        map((data: any) => {
          let result: any = null;
          if (nativeObject) {
            result = data;
          } else {
            let annotations = new Array<Annotation>();
            data.map((annotation: any) => {
              const parents = annotation.comments.filter((comment: any) => HelperService.isObjectEmpty(comment.parentId));
              const replies = annotation.comments.filter((comment: any) => !HelperService.isObjectEmpty(comment.parentId));
              replies.sort((commentA: any, commentB: any) => {
                return _.get(commentA, 'meta.datetime') - _.get(commentB, 'meta.datetime');
              });
              annotation.comments = [...parents, ...replies];
              let entity = this.getAnnotationViewModel(annotation, participants);
              annotations.push(entity);
            })
            annotations.sort((annotationA: any, annotationB: any) => {
              return _.get(annotationB.comments, '[0].meta.datetime') - _.get(annotationA.comments, '[0].meta.datetime');
            });
            result = annotations;
          }
          return result;
        })
      );
  }

  getAnnotations(projectId: string, sectionId: string, subSectionId: string, typeId: string, itemId: string, participants: any[], nativeObject?: boolean, cacheRequest?: boolean, lightweight?: boolean): Observable<any> {
    let observable: Observable<any>;
    let parameters = _.compact([projectId, sectionId, subSectionId, typeId, itemId]).join('/');
    if (cacheRequest) {
      if (this.cache[parameters]) {
        observable = of(this.cache[parameters]);
      } else if (this.cachedObservable[parameters]) {
        observable = this.cachedObservable[parameters];
      } else {
        this.cachedObservable[parameters] = this.apiService
          .httpGet(`/api/mediaAnnotations/${parameters}` + (lightweight ? '?lightweight=true' : ''))
          .pipe(
            tap(res => this.cache[parameters] = res),
            share(),
            map((data) => {
              let result: any;
              if (nativeObject) {
                result = data;
              } else {
                let annotations = new Array<Annotation>();
                _.map(data, (annotation: any) => {
                  let parent = _.filter(annotation.comments, (comment) => {
                    return HelperService.isObjectEmpty(comment.parentId);
                  });
                  let replies = _.filter(annotation.comments, (comment) => {
                    return !HelperService.isObjectEmpty(comment.parentId);
                  });
                  replies.sort((commentA: any, commentB: any) => {
                    return _.get(commentA, 'meta.datetime') - _.get(commentB, 'meta.datetime');
                  });
                  annotation.comments = [...parent, ...replies];
                  let entity = this.getAnnotationViewModel(annotation, participants);
                  annotations.push(entity);
                });
                annotations.sort((annotationA: any, annotationB: any) => {
                  return _.get(annotationB.comments, '[0].meta.datetime') - _.get(annotationA.comments, '[0].meta.datetime');
                });
                result = annotations;
              }
              return result;
            }),
            finalize(() => {
              this.cachedObservable[parameters] = void 0;
              this.cache[parameters] = void 0;
            })
          )
        observable = this.cachedObservable[parameters];
      }
    } else {
      observable = this.apiService
        .httpGet(`/api/mediaAnnotations/${parameters}`)
        .pipe(
          map((data) => {
            let result: any;
            if (nativeObject) {
              result = data;
            } else {
              let annotations = new Array<Annotation>();
              _.map(data, (annotation: any) => {
                let parent = _.filter(annotation.comments, (comment) => {
                  return HelperService.isObjectEmpty(comment.parentId);
                });
                let replies = _.filter(annotation.comments, (comment) => {
                  return !HelperService.isObjectEmpty(comment.parentId);
                });
                replies.sort((commentA: any, commentB: any) => {
                  return _.get(commentA, 'meta.datetime') - _.get(commentB, 'meta.datetime');
                });
                annotation.comments = [...parent, ...replies];
                let entity = this.getAnnotationViewModel(annotation, participants);
                annotations.push(entity);
              });
              annotations.sort((annotationA: any, annotationB: any) => {
                return _.get(annotationB.comments, '[0].meta.datetime') - _.get(annotationA.comments, '[0].meta.datetime');
              });
              result = annotations;
            }
            return result;
          })
        )
    }
    return observable;
  }

  addNewAnnotation(projectId: string, sectionId: string, subSectionId: string, typeId: string, itemId: string, annotation: any, participants: any[]): Observable<any> {
    let subject = new Subject<any>();
    let payload = new AnnotationDTO();
    payload.id = annotation.id;
    payload.range = JSON.stringify(annotation.range);
    payload.resolution = JSON.stringify(annotation.resolution);
    payload.shape = annotation.shape;
    if (annotation.privateConditionKey) {
      payload.privateConditionKey = annotation.privateConditionKey;
    }
    payload.comments = new Array<AnnotationCommentDTO>();
    _.map(annotation.comments, (comment) => {
      let annotationCommentDTO = new AnnotationCommentDTO();
      annotationCommentDTO.id = comment.id;
      annotationCommentDTO.body = comment.body;
      annotationCommentDTO.files = comment.files;
      annotationCommentDTO.parentId = (comment.parentId === annotation.comments[0].id) ? void 0 : comment.parentId;
      annotationCommentDTO.meta = {
        datetime: comment.meta.datetime,
        updatetime: comment.meta.updatetime,
        username: comment.meta.user_id
      }
      annotationCommentDTO.taggedUsers = _.uniq(comment.body.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g));
      annotationCommentDTO.parentId = comment.parentId;
      payload.comments.push(annotationCommentDTO);
    });
    this.apiService
      .httpPut(`/api/mediaAnnotations/${projectId}/${sectionId}/${subSectionId}/${typeId}/${itemId}`, payload)
      .subscribe(
        (data) => {
          let annotations = this.getAnnotationViewModel(data, participants);
          subject.next(annotations);
        }, (err) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }

  deleteAnnotation(projectId: string, sectionId: string, subSectionId: string, typeId: string, itemId: string, annotationId: string) {
    let subject = new Subject<any>();
    this.apiService
      .httpDelete(`/api/mediaAnnotations/${projectId}/${sectionId}/${subSectionId}/${typeId}/${itemId}/${annotationId}`)
      .subscribe(
        (data) => {
          subject.next(data);
        }, (err) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }

  deleteAnnotationComment(projectId: string, sectionId: string, subSectionId: string, typeId: string, itemId: string, annotationId: string, commentId: string) {
    let subject = new Subject<any>();
    this.apiService
      .httpDelete(`/api/mediaAnnotations/${projectId}/${sectionId}/${subSectionId}/${typeId}/${itemId}/${annotationId}/${commentId}`)
      .subscribe(
        (data) => {
          subject.next(data);
        }, (err) => {
          subject.error(err);
        }
      );
    return subject.asObservable();
  }

  updatePrivateCondition(value: AnnotationPrivateCondition) {
    this.privateConditionChange$.next(value);
  }
}

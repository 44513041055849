
import { empty as observableEmpty, Observable, Subject } from 'rxjs';
import * as _ from "lodash";
import { ActivatedRoute, Router } from '@angular/router';
import { DateHelperService } from 'app/shared/services/date-helper.service';
import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from "./api.service";
import { Project, ProjectSection } from "app/shared/interfaces/project";
import { AuthService } from "./auth.service";
import { HelperService } from "app/shared/services/helper.service";
import { PermissionService } from 'app/shared/services/permissions.service';
import { TranslatePipe } from "../pipes/translate.pipe";
import { UserService } from "./user.service";
import { NotificationService } from './notification.service';
import { EMPTY_ID } from '../components/task/task-status.const';
import { environment } from 'environments/environment';

export var onLoadProject: EventEmitter<any> = new EventEmitter<any>();
export var onCompanyLoad: EventEmitter<any> = new EventEmitter();
export var onSectionsLoad: EventEmitter<any> = new EventEmitter();
export var onPermissionsAppliedToProject: EventEmitter<any> = new EventEmitter();
export var onLeaveFromProject: EventEmitter<any> = new EventEmitter();
export var onLoadedProposal: EventEmitter<any> = new EventEmitter<any>();
export var onNavigatedFromPayment: EventEmitter<any> = new EventEmitter<any>();
export var onCheckFinalPayment: EventEmitter<any> = new EventEmitter<any>();

export let onItemSelected: EventEmitter<any> = new EventEmitter<any>();
export let onItemClicked: EventEmitter<any> = new EventEmitter<any>();
export let onNavReordered: EventEmitter<any> = new EventEmitter<any>();

const ignoredRoles = ['PROJECT_SENIOR_STAFF', 'PROJECT_SUPERVISOR', 'PRODUCTION_SENIOR_STAFF', 'PRODUCTION_SUPERVISOR'];

@Injectable({
	providedIn: 'root'
})
export class ProjectService {
	private allColors: string[] = [
		'#4dbd89',
		'#8dc870',
		'#bdd05c',
		'#f8a036',
		'#ff7d2d',
		'#ff5e2f',
		'#ff3c00'
	];
	public colors: string[] = [];
	private allSections: any[] = [];
	public sections: any[] = [];
	public subsections: any[] = [];
	public project: any = {};
	public company: any = {};
	public subsectionsSelected: any[];
	public proposal: any = {};
	public isOnloadedProposal: boolean = false;
	public parentSubSections: any[];
	public subSectionDetails: any[];
	private psd: string = '';
	public usersLoaded = false;
	public projectOnloaded: boolean = false;
	private fileCache: Record<string, any> = {}; 

	constructor(private api: ApiService,
		private activeRoute: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		public permissionService: PermissionService,
		public userService: UserService,
		public notificationService: NotificationService,
		private translateService: TranslatePipe) {

		this.initSections();
	}

	public leaveFromProject() {
		this.projectOnloaded = false;
		setTimeout(() => {
			$(document.getElementsByTagName('body')).removeClass('project-loaded');
		}, 0);
		this.usersLoaded = false;
		this.project = {};
		this.psd = '';
		this.emitProjectOnloaded();
		onLoadProject = new EventEmitter<any>();
		onCompanyLoad = new EventEmitter();
		onSectionsLoad = new EventEmitter();
		onPermissionsAppliedToProject = new EventEmitter();
		onLeaveFromProject = new EventEmitter();
		onLoadedProposal = new EventEmitter<any>();
		onNavigatedFromPayment = new EventEmitter<any>();
		onCheckFinalPayment = new EventEmitter<any>();
	}

	public emitProjectOnloaded() {
		onLoadProject.emit(this.project);
		console.log('emit this.project', this.project)
		onCheckFinalPayment.emit(this.project);
	}

	public prepareProjectInit(projectId: any) {
		let sectionsId: any[] = [];
		let subsectionsId: any[] = [];
		this.initSections();

		this.projectOnloaded = false;
		setTimeout(() => {
			$(document.getElementsByTagName('body')).removeClass('project-loaded');
		}, 0);
		this.permissionService.getProjectPermissionsFromLocalStorage(projectId);
		this.userService.users = [];
		this.getProject(projectId).subscribe((p) => {
			this.project = p;
			this.initProjectSections();
			this.getProjectUsers(projectId).subscribe((users) => {
				this.userService.users = [...this.userService.users, ...p.participants, ...users];
			});
			this.loadSections(new Date(p.startDate), new Date(p.plannedEndDate)).then(() => {
				this.loadSectionsAndSubsections(new Date(this.project.startDate), new Date(this.project.plannedEndDate), projectId).then((v) => {
					this.authService.updateAllowedOperations(projectId, this.sections).subscribe(() => {
						this.sections.forEach(s => {
							sectionsId[s.id] = s;
							if (s.subsections && Array.isArray(s.subsections)) {
								s.subsections.forEach(ss => {
									subsectionsId[ss.id] = ss;
								});
							}
							this.permissionService.prepareSectionAndSubSections(s);
						});
						this.project.sections = this.sections;
						this.project.sectionsId = sectionsId;
						this.project.subsectionsId = subsectionsId;
						this.projectOnloaded = true;
						setTimeout(() => {
							$(document.getElementsByTagName('body')).addClass('project-loaded');
						}, 0);
						onPermissionsAppliedToProject.emit();
						this.emitProjectOnloaded();
					});
				});
			}, err => {
				onLoadProject.error(err)
			});
		}, err2 => {
			const { notificationCode } = this.activeRoute.snapshot.queryParams;
			if (err2.errorCode === 'NOT_FOUND' && notificationCode) {
				this.notificationService.open({
					title: this.translateService.transform('projectDeletedTitle'),
					notificationType: 'success',
					centerHeader: true,
					notificationInfo: this.translateService.transform('projectDeletedInfo'),
					confirmBtn: this.translateService.transform('ok')
				}).subscribe(confirm => {
					this.router.navigate(['/projects']);
				})
			} else {
				onLoadProject.error(err2);
			}
		});
	}


	getProjectById(projectId: any) {
		return this.getProject(projectId);
	}


	getSingleProject(projectId: any) {
		let subject = new Subject<any>();

		this.api.httpGet('/api/projects/' + projectId).subscribe(
			(data: any) => {
				subject.next(data);
				subject.complete();
			},
			(err: any) => {
				subject.error(err);
				subject.complete();
			}
		);

		return subject.asObservable();
	}

	navigateCompanyToProfileSuggestionAfterProjectInit(projectId: string, router: Router): Observable<any> {
		this.psd = projectId;
		return new Observable(observer => {
			onLoadProject.subscribe((project) => {
				this.navigateCompantProfileSuggestion(router);
				observer.next(undefined);
				observer.complete();
			}, err => {
				observer.error(err);
			});
			this.prepareProjectInit(this.psd.toString());
		})
	}

	navigateCompantProfileSuggestion(router: Router) {
		if (!this.project || !this.project.sections || this.psd == '')
			return;

		let section = this.project.sections.find(k => k.section == 'PRODUCTION_PARTNER_SELECTION');
		//on production side, subsection is prevented by permissions
		if (section.projectSubSections && section.projectSubSections.length > 0) {
			let subsection = section.projectSubSections.find(k => k.subSection == 'COMPANY_PROFILE_SUGGESTIONS');
			let url = `/projects/${this.psd}/${section.id}/${subsection.id}/preview`;
			router.navigate([url]);
			onNavigatedFromPayment.emit(section);
		}
	}

	private initSections(): void {
		this.allSections = [
			{
				name: this.getSectionName('PRODUCTION_PARTNER_SELECTION'),
				description: '',
				startDate: '',
				endDate: '',
				phase: 'PRODUCTION',
				section: 'PRODUCTION_PARTNER_SELECTION',
				projectId: this.project.id,
				orderNumber: 3,
			},
			{
				name: this.getSectionName('PRODUCTION_BRIEF'),
				description: '',
				startDate: '',
				endDate: '',
				phase: 'PRODUCTION',
				section: 'PRODUCTION_BRIEF',
				projectId: this.project.id,
				orderNumber: 4,
			},
			{
				name: this.getSectionName('PRODUCTION_COMPANY_PROPOSALS'),
				description: '',
				startDate: '',
				endDate: '',
				phase: 'PRODUCTION',
				section: 'PRODUCTION_COMPANY_PROPOSALS',
				projectId: this.project.id,
				orderNumber: 5,
			},
			{
				name: this.getSectionName('BRIEF'),
				description: '',
				startDate: '',
				endDate: '',
				phase: 'PRODUCTION',
				section: 'BRIEF',
				projectId: this.project.id,
				orderNumber: 1,
			},
			{
				name: this.getSectionName('PRE_PRODUCTION'),
				description: '',
				startDate: '',
				endDate: '',
				phase: 'PRODUCTION',
				section: 'PRE_PRODUCTION',
				projectId: this.project.id,
				orderNumber: 6,
			},
			{
				name: this.getSectionName('SHOOTING'),
				description: '',
				startDate: '',
				endDate: '',
				phase: 'PRODUCTION',
				section: 'SHOOTING',
				projectId: this.project.id,
				orderNumber: 7,
			},
			{
				name: this.getSectionName('POST_PRODUCTION'),
				description: '',
				startDate: '',
				endDate: '',
				phase: 'POST_PRODUCTION',
				section: 'POST_PRODUCTION',
				projectId: this.project.id,
				orderNumber: 8,
			},
			//comment out diffusion based on request
			/*
			{
				name: this.getSectionName('DIFFUSION'),
				description: '',
				startDate: '',
				endDate: '',
				phase: 'DIFFUSION',
				section: 'DIFFUSION',
				projectId: this.project.id,
				orderNumber: 9
			}
			*/
		];
	}

	private initProjectSections() {
		// if (!this.project.projectType || this.project.projectType != 'SUBSCRIPTION') {
		// 	const subscriptionSections = ['BRIEF'];
		// 	this.sections = this.allSections.filter(s => subscriptionSections.indexOf(s.section) == -1);
		// 	this.colors = [...this.allColors];
		// } else {
			const ppsSections = ['PRODUCTION_PARTNER_SELECTION', 'PRODUCTION_BRIEF', 'PRODUCTION_COMPANY_PROPOSALS'];
			this.sections = this.allSections.filter(s => ppsSections.indexOf(s.section) == -1);
			this.colors = this.allColors.slice(2);
		//}
	}

	public getFilesInKeyProjectFolder(projectId: string, parentId: string) {
		return this.api.httpGet('/api/projects/' + projectId + '/kpffiles?parentId=' + parentId);
	}

	public getProjectFiles(projectId: string, parentId?: any) {
		let query = [];
		if (parentId) {
			query.push(`parentId=${parentId}`);
		}
		return this.api.httpGet('/api/projects/' + projectId + '/files' + (query.length ? '?' + query.join('&') : ''));
	}

	public getTaskAttachments(projectId: string, parentId?: any) {
		return this.api.httpGet('/api/tasks/attachement/' + projectId + '/' + parentId);
	}

	public getProjectHierarchical(projectId: string): Observable<any> {
		return this.api.httpGet('/api/projects/' + projectId + '/files-tree');
	}

	public getZippedSectionFiles(projectId: string, sectionIds: any[] = []): Observable<any> {
		return this.api.httpGet('/api/projects/' + projectId + '/files-tree/zip?sectionIds=' + sectionIds.join(','));
	}

	public getFilesBySectionId(projectId: string, sectionId: any) {
		return this.api.httpGet('/api/projects/' + projectId + '/' + sectionId + '/files-tree').toPromise();
	}

	public getFilesTree(projectId: string, enableKeyProjectFiles: boolean = false): Observable<any> {
		return this.api.httpGet(`/api/assets/project/${projectId}/file-tree?enableKeyProjectFiles=${enableKeyProjectFiles}`);
	}

	public getKPFTree(projectId: string, parentId: string = null): Observable<any> {
		const parent = parentId ? `?parent=${parentId}` : '';
		return this.api.httpGet(`/api/assets/project/${projectId}/file-tree/kpf${parent}`);
	}

	public addUser(projectId: string, username: string, roles: string[]): Observable<any> {
		return this.setRoles(projectId, username, roles);
	}

	public setRoles(projectId: string, username: string, roles: string[]): Observable<any> {
		let map = {};
		map[username] = roles;
		return this.api.httpPost('/api/projects/' + projectId + '/users/roles', map);
	}

	public mthdReInvite(projectId: string, username: string): Observable<any> {
		return this.api.httpPost('/api/projects/' + projectId + '/users/reinvite', [username]);
	}

	getSortedRoles() {
		const subject = new Subject<any>();
		this.api.httpGet('/api/util/roles').subscribe({
			next: (data: any) => {
				let roles = [];
				const allowedRoles = data.filter(r => !ignoredRoles.includes(r.role));
				allowedRoles.sort((a, b) => {
					if (a.id < b.id)
						return -1;
					if (a.id > b.id)
						return 1;
					return 0;
				});
				roles = allowedRoles.map((r) => {
					return { name: this.translateService.transform("role" + r.role), id: r.role };
				})
				subject.next(roles);
			},
			error: (err: any) => {
				subject.error(err);
			}
		});
		return subject.asObservable();
	}

	public getRoles(multiselect: boolean = false) {
		let subject = new Subject<any>();

		this.api.httpGet('/api/util/roles').subscribe(
			(data: any) => {
				const allowedRoles = data.filter(r => !ignoredRoles.includes(r.role));
				let roles = [];

				allowedRoles.sort((a, b) => {
					if (a.id < b.id)
						return -1;
					if (a.id > b.id)
						return 1;
					return 0;
				});
				// Add label name for each role
				if (!multiselect) {
					roles = allowedRoles.map((r) => {
						return { label: this.translateService.transform("role" + r.role), code: r.role };
					});

					// Sort roles by name
					roles.sort(function (a, b) {
						if (a.label < b.label)
							return -1;
						if (a.label > b.label)
							return 1;
						return 0;
					});
				} else {
					roles = allowedRoles.map((r) => {
						return { name: this.translateService.transform("role" + r.role), id: r.role };
					}).filter(role => !role.id.startsWith("COMPANY_"))
				}

				subject.next(roles);
				subject.complete();
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}

	public getFormaDefinitions() {
		let subject = new Subject<any>();

		this.api.httpGet('/api/commons/formatDefinitions/all').subscribe(
			(data: any) => {
				let defs = [];

				// Add label name for each role
				defs = data.map((r) => {
					return { label: r.name.toLowerCase(), code: r.id };
				});

				// Sort roles by name
				defs.sort(function (a, b) {
					if (a.label < b.label)
						return -1;
					if (a.label > b.label)
						return 1;
					return 0;
				});

				subject.next(defs);
			},
			(err: any) => {
				subject.error(err);
			}
		)
		return subject.asObservable();
	}

	public loadSections(start_date, end_date, projectId?: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.loadSubsections().then(() => {
				this.getSections(projectId).subscribe((res: any) => {
					if (Array.isArray(res)) {
						let _length = this.sections.length;
						for (let i = 0; i < _length; i++) {
							let found = res.find(s => s.section === this.sections[i].section);
							if (found) {
								this.sections[i] = found;
							}
						}
					}
					this.setSectionsWidth(start_date, end_date);
					onSectionsLoad.emit(this.subsections);
					resolve(true);
				}, (err: any) => {
					reject(false);
				});
			})
		});
	}


	public loadSectionsAndSubsections(start_date, end_date, projectId?: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.getSectionsAndSubsections(projectId).subscribe((res: any) => {
				if (Array.isArray(res)) {
					let _length = this.sections.length;
					for (let i = 0; i < _length; i++) {
						let found = res.find(s => s.section === this.sections[i].section);
						if (found) {
							found.status = this.sections[i].status;
							this.sections[i] = found;
						}
					}
				}

				this.setSectionsWidth(start_date, end_date);
				onSectionsLoad.emit(this.subsections);
				resolve(true);
			}, (err: any) => {
				reject(false);
			});
		});
	}

	/**
	 * Load all default subsections
	 */
	public loadSubsections(): Promise<any> {
		return new Promise((resolve, reject) => {
			if (!this.subsections.length) {
				this.api.httpGet('/api/commons/subsections/all').subscribe((res: any) => {
					this.subsections = res; 
					this.subsections.map((sub) => {
						sub.orderNumber = sub.id;
						sub.name = sub.subSection.toLowerCase().replace(/_/g, ' ').replace(/^.|\s\S/g, l => l.toUpperCase());
						sub.name = sub.name.replace('(', ' (');
					});

					resolve(this.subsections);
				}, err => reject(err));
			} else {
				resolve(this.subsections);
			}
		});
	}

	/**
	 * Set width for all sections
	 */
	private setSectionsWidth(start_date, end_date): void {

		let totalDays = this.countDays(start_date, end_date);

		let sectionsLoaded = this.sections.filter((link: any) => {
			if (link.startDate && link.endDate)
				return true;
			return false;
		}).length;

		const totalSections = this.sections.length;
		this.sections.map((link: any) => {
			link.width = (100 / totalSections).toFixed(2);
		});
	}

	/**
	 * Load Project
	 *
	 * @param id
	 */
	public loadProject(id: string): void {
		this.clearData();
		this.getProject(id).subscribe((res) => {
			this.project = res;
			this.getCompany(this.project.company.id).subscribe((c) => {
				this.company = c;
				onCompanyLoad.emit(this.company);
			});
			this.loadSections(new Date(res.startDate), new Date(res.plannedEndDate));
		});
	}

	/**
	 * Add users
	 *
	 * @param projectId
	 * @param users
	 */
	public addUsers(projectId: string, users: any[]): Observable<any> {
		let subject = new Subject<any>();
		this.api.httpPost('/api/projects/' + projectId + '/users/add', users).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}

	/**
	 * Delete Users
	 *
	 * @param projectId
	 * @param users
	 */
	public deleteUsers(projectId: string, users: any[]): Observable<any> {
		let subject = new Subject<any>();

		this.api.httpPost('/api/projects/' + projectId + '/users/remove', users).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}

	/**
	 * Get project details
	 * @param id
	 */
	public getProject(id: string): Observable<any> {
		let subject = new Subject<any>();
		const fetchAssetList = this.authService.getAuthUser().companyId == environment.ltg.cpdCompanyId;
		this.api.httpGet('/api/projects/' + id + (fetchAssetList ? '?fetchAssetList=true' : '')).subscribe(
			(data: any) => {
				subject.next(this.processProjectResponse(data));
				subject.complete();
			},
			(err: any) => {
				subject.error(err);
				subject.complete();
			}
		);

		return subject.asObservable();
	}

	/**
	 * Get project details
	 * @param id
	 */
	public getProjectUsers(id: string): Observable<any> {
		let subject = new Subject<any>();

		this.api.httpGet('/api/users/project/' + id).subscribe(
			(data: any) => {
				this.userService.users = [...this.userService.users, ...(data || [])];
				this.usersLoaded = true;
				subject.next(this.processProjectResponse(data));
			},
			(err: any) => {
				subject.error(err);
			}
		);

		return subject.asObservable();
	}

	/**
	 * Get project participant details with activation status
	 * @param projectId
	 */
	public getProjectParticipantWithUserActivationStatus(projectId: string): Observable<any> {
		return this.api.httpGet('/api/projects/' + projectId + "/getProjectParticipantsWithActivationStatus");
	}

	/**
	 * Retrieve a company by ID
	 *
	 * @param id
	 */
	public getCompany(id: string): Observable<any> {
		return this.api.httpGet('/api/companies/' + id);
	}

	/**
	 * Retrieve complete list of companies
	 */
	public getCompanies(ltgCompanies?): Observable<any> {
		const url = ltgCompanies ? '/api/admin/loreal-timeline-companies' : '/api/companies';
		return this.api.httpGet(url);
	}

	public enableCompanyLTG(companyId: string): Observable<any> {
		const url = '/api/admin/loreal-timeline-companies';
		return this.api.httpPost(url, { companyId });
	}

	public disableCompanyLTG(companyId: string): Observable<any> {
		const url = `/api/admin/loreal-timeline-companies/${companyId}`;
		return this.api.httpDelete(url);
	}

	public getClientCompanies(): Observable<any> {
		return this.api.httpGet(`/api/companies/client`);
	}

	/**
	 * Create a project
	 *
	 * @param project
	 */
	public saveProject(project: Project, isCpd: boolean = false): Observable<any> {
		let subject = new Subject<any>();

		this.project = project;

		let projectForSaving = _.cloneDeep(this.project);
		// if (project.airDate && project.airDate.toString().substr(19, 20) != "Z") {
		// 	projectForSaving.airDate = DateHelperService.fromDateToApi(this.project.airDate, DateHelperService.END_OF_DAY);
		// 	projectForSaving.startDate = DateHelperService.fromDateToApi(this.project.startDate);
		// 	projectForSaving.plannedEndDate = DateHelperService.fromDateToApi(this.project.plannedEndDate, DateHelperService.END_OF_DAY);
		// }

		// If project has id (exists), just save it
		if (this.project.id) {
			// Generate new project data with some fields

			const _project: any = {
				name: projectForSaving.name,
				description: projectForSaving.description,
				company: projectForSaving.company,
				videoGenre: projectForSaving.videoGenre,
				videoGenreOther: projectForSaving.videoGenreOther,
				manager: projectForSaving.manager,
				budget: projectForSaving.budget,
				startDate: projectForSaving.startDate,
				plannedEndDate: projectForSaving.plannedEndDate,
				//airDate: projectForSaving.airDate,
				languages: projectForSaving.languages,
				subTitleLanguages: projectForSaving.subTitleLanguages,
				dubbingLanguages: projectForSaving.dubbingLanguages,
				validationMode: projectForSaving.validationMode,
				validators: projectForSaving.validators,
				videoLength: projectForSaving.videoLength,
				currency: projectForSaving.currency,
				tags: projectForSaving.tags,
				assetList: projectForSaving.assetList
			};

			if (projectForSaving.workspaceId)
				_project.workspaceId = projectForSaving.workspaceId;

			if (projectForSaving.campaignId)
				_project.campaignId = projectForSaving.campaignId;

			if (projectForSaving.milestoneId) {
				_project.milestoneId = projectForSaving.milestoneId;
			}
			//if(projectForSaving.prodCompanyId)
			//	_project.prodCompanyId = projectForSaving.prodCompanyId;
			delete _project.airDate;
			delete _project.budget;
			delete _project.currency;
			delete _project.videoGenre;
			delete _project.videoGenreOther;
			delete _project.videoLength;
			delete _project.subTitleLanguages;
			delete _project.tags;
			delete _project.dubbingLanguages;
			delete _project.validationMode;
			delete _project.languages;
			this.api.httpPut('/api/projects/' + this.project.id, _project).subscribe(
				(data: any) => {
					subject.next(this.processProjectResponse(data));
				},
				(err: any) => {
					subject.error(err);
				}
			);
		} else {// Create the Project
			delete projectForSaving.airDate;
			delete projectForSaving.budget;
			delete projectForSaving.currency;
			delete projectForSaving.videoGenre;
			delete projectForSaving.videoGenreOther;
			delete projectForSaving.videoLength;
			delete projectForSaving.subTitleLanguages;
			delete projectForSaving.tags;
			delete projectForSaving.dubbingLanguages;
			delete projectForSaving.validationMode;
			delete projectForSaving.languages;
			this.api.httpPost('/api/projects', projectForSaving).subscribe(
				async (data: any) => {
					this.project = data;
					try {
						await this.generateStaticSubsections();
					} catch (error) {

					}
					subject.next(this.processProjectResponse(data));
				}, (err: any) => {
					subject.error(err);
				}
			);
		}

		return subject.asObservable();
	}

	/**
	 * Generate static subsection for a new project.
	 */
	private generateStaticSubsections(): Promise<any> {
		return new Promise(async (resolve, reject) => {
			try {
				const list = await this.loadSubsections();
				let sections = {};

				list.forEach(item => {
					if (!item.dynamic) {
						if (!sections[item.section])
							sections[item.section] = [];

						sections[item.section].push(item);
					}
				});

				this.initSections();
				this.initProjectSections();
				const promises = Object.keys(sections).map((sectionCode) => {
					let section = this.sections.filter(s => s.section === sectionCode).pop();
					if (section) {
						return new Promise(async (resolve_2, reject_2) => {
							try {
								const resSection = await this.createSection(section).toPromise();
								const promises2 = sections[sectionCode].map(item => {
									return this.createSubsection(resSection.id, item, resSection.projectId).toPromise();
								});

								await Promise.all(promises2);
								resolve_2({});
							} catch (error) {
								reject_2(error);
							}
						})
					}
				});

				await Promise.all(promises);
				resolve('SUCESSFULLY');
			} catch (error) {
				reject(error);
			}
		})
	}

	/**
	 * Save projects in cache (localStorage)
	 * @param projects
	 */
	public saveProjectsInCache(projects: any[]) {
		let projectsToSave = [];
		let workspaceIdToSave: any;
		let workspacId: string;
		Array.from(projects).forEach((project: any) => {
			workspacId = project.workspaceId
			projectsToSave.push({
				id: project.id,
				name: project.name,
				entranceFeePaid: project.entranceFeePaid,
				projectType: project.projectType
			});
		})
		workspaceIdToSave = ({
			id: workspacId
		});
		if (projects.length > 1) {
			localStorage.setItem('workspace', workspacId);
		}
		localStorage.setItem('projects', JSON.stringify(projectsToSave));
	}


	/**
	 * Get project from cache (localStorage)
	 * @param id
	 */
	public getProjectFromCache(id: string) {
		let projectFound = null;
		if (localStorage.getItem('projects')) {
			let projects = localStorage.getItem('projects');
			if (projects) {
				projects = JSON.parse(projects);
				if (Array.isArray(projects)) {
					projects.forEach((project: any) => {
						if (project.id == id)
							projectFound = project;
					})
				}
			}
		}
		return projectFound;
	}


	/**
	 * Check if projects are saved on cache (localStorage)
	 */
	public isProjectsInCache() {
		return (localStorage.getItem('projects'));
	}


	/**
	 * Process process response from API
	 * @param project
	 */
	private processProjectResponse(project: any) {

		if (project) {
			project.startDate = (project.startDate)
				? DateHelperService.fromTimestampToDate(project.startDate)
				: null;
			project.plannedEndDate = (project.plannedEndDate)
				? DateHelperService.fromTimestampToDate(project.plannedEndDate)
				: null;
			project.airDate = (project.airDate)
				? DateHelperService.fromTimestampToDate(project.airDate)
				: null;
		}
		return project;
	}

	/**
	 * Retrieve all projects for this user
	 */
	public getProjects(): Observable<any> {
		return this.api.httpGet('/api/projects/users/' + this.authService.getAuthUserName());
	}

	public getArchivedProjects(): Observable<any> {
		return this.api.httpGet('/api/projects/users/' + this.authService.getAuthUserName() + '/archived');
	}

	public getArchivedProjectsByCampaignId(campaignId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/users/${this.authService.getAuthUserName()}/archived?campaignId=${campaignId}`);
	}

	/**
	 * Retrieve all projects for this user
	 */
	public getProjectsUser(user_name: any): Observable<any> {
		return this.api.httpGet('/api/projects/users/' + user_name);
	}
	/**
	 * Retrieve all projects  roles for this user
	 */
	public getProjectRoles(user_name: any): Observable<any> {
		return this.api.httpGet('/api/projects/users/' + user_name + '/roles');
	}
	/**
	 * Create an event.
	 *
	 * @param event
	 */
	createCompany(company: any): Observable<any> {
		if (company.id) {

			let id = company.id;
			delete company.id;

			for (let p in company)
				if (HelperService.isObjectEmpty(company[p]))
					delete company[p];

			return this.api.httpPut('/api/companies/' + id, company);
		} else {
			return this.api.httpPost('/api/companies', company);
		}
	}

	/**
	 * Retrieve all sections
	 */
	public getSections(projectId = null): Observable<any> {

		let subject = new Subject<any>();

		if (!projectId)
			projectId = this.project.id;

		let _sections = this.sections;
		this.api.httpGet('/api/projects/' + projectId + '/sections').subscribe(
			(data: any) => {

				if (data && Array.isArray(data)) {
					for (let i = 0; i < _sections.length; i++) {
						let found = data.find(s => s.section === _sections[i].section);
						if (found) {
							found.startDate = (found.startDate) ? DateHelperService.fromTimestampToDate(found.startDate) : null;
							found.endDate = (found.endDate) ? DateHelperService.fromTimestampToDate(found.endDate) : null;
							_sections[i] = found;
						}
					}
				}
				subject.next(_sections);
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}

	/**
	 * Retrieve all project sections
	 */
	public getProjectSections(projectId): Observable<any> {
		let subject = new Subject<any>();
		this.api.httpGet('/api/projects/' + projectId + '/sections').subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)
		return subject.asObservable();
	}

	/**
	 * Retrieve all project sections
	 */
	public getProjectSectionSubSections(projectId, sectionId): Observable<any> {
		let subject = new Subject<any>();
		this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections`).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)
		return subject.asObservable();
	}

	public getSubSectionFiles(subSectionId) {
		let subject = new Subject<any>();
		this.api.httpGet(`/api/projects/getFilesBySubsection/${subSectionId}`).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)
		return subject.asObservable();
	}


	/**
	 * Retrieve all sections
	 */
	public getSectionsAndSubsections(projectId = null): Observable<any> {

		let subject = new Subject<any>();

		if (!projectId && !this.project.id) {
			return observableEmpty();
		} else if (!projectId) {
			projectId = this.project.id;
		}
		this.api.httpGet('/api/projects/' + projectId + '/sections/subsections').subscribe(
			(data: any) => {
				if (data && Array.isArray(data)) {
					data.map((section: any) => {
						section.name = this.getSectionName(section.section);
						section.subsections = section.projectSubSections;
						if (section.subsections && Array.isArray(section.subsections)) {
							section.subsections.map((sub: any) => {
								sub.url = section.id + '/' + sub.id;
							})
						}
					})
				}

				subject.next(data);
				subject.complete();
			},
			(err: any) => {
				subject.error(err);
				subject.complete();
			}
		)

		return subject.asObservable();
	}


	/**
	 * Create a new section
	 *
	 * @param section
	 */
	public createSection(section): Observable<any> {
		return this.api.httpPost('/api/projects/' + this.project.id + '/sections', section);
	}

	getSectionByName(sectionName: string, projectId?: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId || this.project.id}/${sectionName}`);
	}

	/**
	 * Create Main Sections
	 */
	public createMainSections(): void {

		for (let i = 0; i < this.sections.length; i++) {
			this.api.httpPost('/api/projects/' + this.project.id + '/sections', this.sections[i]).subscribe((res) => {
				console.log(res);
			});
		}
	}

	/**
	 * Retreive days count between two dates
	 */
	public countDays(start: Date, end: Date) {
		return Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
	}


	/**
	 * Create subsection
	 *
	 * @param sectionId
	 * @param subsection
	 */
	public createSubsection(sectionId, subsection, projectId?: string): Observable<any> {
		return new Observable(observer => {
			if (!HelperService.isUUID(subsection.id))
				delete subsection.id;

			let project_id = projectId;

			if (this.project.id)
				project_id = this.project.id;

			this.api.httpPost('/api/projects/' + project_id + '/sections/' + sectionId + '/subsections', subsection).subscribe(
				(data: any) => {
					if (data.startDate)
						data.startDate = DateHelperService.fromTimestampToDate(data.startDate);
					if (data.endDate)
						data.endDate = DateHelperService.fromTimestampToDate(data.endDate);

					observer.next(data);
					observer.complete();
				},
				(err: any) => {
					observer.error(err);
				}
			);
		});
	}

	/**
	 * Retrieve all subsections by section id
	 *
	 * @param sectionId
	 */
	public getSubsections(sectionId, projectId = null): Observable<any> {

		let subject = new Subject<any>();
		if (!projectId && !this.project.id) {
			return observableEmpty()
		} else if (!projectId) {
			projectId = this.project.id;
		}
		this.api.httpGet('/api/projects/' + projectId + '/sections/' + sectionId + '/subsections').subscribe(
			(data: any) => {
				let subsections = [];
				data.forEach((subsection: any) => {
					if (subsection.startDate)
						subsection.startDate = DateHelperService.fromTimestampToDate(subsection.startDate);
					if (subsection.endDate)
						subsection.endDate = DateHelperService.fromTimestampToDate(subsection.endDate);
					subsections.push(subsection);
				})
				subject.next(subsections);
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}

	/**
	 * Delete a subsection
	 *
	 * @param sectionId
	 * @param subsectionId
	 */
	public deleteSubsection(sectionId, subsectionId): Observable<any> {
		return this.api.httpDelete('/api/projects/' + this.project.id + '/sections/' + sectionId + '/subsections/' + subsectionId);
	}

	/**
	 * Get subsection by name from a particular project and section
	 * @param projectId
	 * @param sectionId
	 * @param subsectionName
	 */
	public getSubsectionByName(projectId, sectionId, subsectionName: string): Observable<any> {
		let subject = new Subject<any>();
		if (!projectId && !this.project.id) {
			return observableEmpty();
		} else if (!projectId) {
			projectId = this.project.id;
		}
		this.api.httpGet('/api/projects/' + projectId + '/sections/' + sectionId + '/subsections').subscribe(
			(data: any) => {
				let subsectionFound = null;
				if (data && Array.isArray(data)) {
					data.forEach((subsection: any) => {
						if (subsection.subSection == subsectionName)
							subsectionFound = subsection;
					})
				}
				subject.next(subsectionFound);
			},
			(err: any) => {
				subject.error(err);
			}
		)
		return subject.asObservable();
	}

	/**
	 * Return list of files for a particular project
	 * @param projectId
	 */
	public getFiles(projectId: string, parentId?: string) {
		let subject = new Subject<any>();
		this.getProjectFiles(projectId, parentId).subscribe(
			(data: any) => {
				if (data && Array.isArray(data) && (data.length > 0)) {
					data.sort((x: any, y: any) => {
						return (x.timeCreated > y.timeCreated) ? -1 : 1
					});
				}
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		);
		return subject.asObservable();
	}

	/**
	 * Delete file by ID
	 *
	 * @param fileId
	 * @param projectId
	 */
	public deleteFile(fileId: string, projectId: string): Observable<any> {
		return this.api.httpDelete('/api/projects/' + projectId + '/files/' + encodeURIComponent(fileId));
	}

	public clearData(): void {
		this.project = {};
		this.company = {};
		this.subsections = [];
		this.sections = [];
		this.initSections();
	}

	/**
	 * Retrieve start and end date for a Section based on its sub-sections
	 *
	 * @param sectionId
	 * @param newStartDate
	 * @param newEndDate
	 */
	public getStartEndDate(sectionId: string, newStartDate?: Date, newEndDate?: Date): Promise<any> {
		return new Promise((resolve, reject) => {
			this.getSubsections(sectionId).subscribe((res) => {

				let sectionDates = {
					startDate: null,
					endDate: null
				};

				let dates = res.map((sub) => {
					if (sub.startDate)
						return DateHelperService.fromTimestampToDate(sub.startDate);
				}).concat(res.map((sub) => {
					if (sub.endDate)
						return DateHelperService.fromTimestampToDate(sub.endDate);
				})).filter((v) => v !== undefined);

				if (newStartDate && newEndDate) {
					dates.push(newStartDate);
					dates.push(newEndDate);
				}

				if (dates.length) {
					sectionDates.startDate = newStartDate;
					sectionDates.endDate = newEndDate;
				}

				resolve(sectionDates);

			}, (err: any) => reject(err));
		});
	}


	/**
	 * Accept invitation
	 * @param projectId
	 */
	public acceptInvitation(projectId: string): Observable<any> {

		let subject = new Subject<any>();

		this.api.httpPut(`/api/projects/${projectId}/user/accept`, null).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}


	/**
	 * Reject invitation
	 * @param projectId
	 */
	public rejectInvitation(projectId: string): Observable<any> {

		let subject = new Subject<any>();

		this.api.httpPut(`/api/projects/${projectId}/user/reject`, null).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}


	/**
	 * Return project name based on key
	 * @param name
	 */
	public getSectionName(name: string): string {

		let _name = name;

		switch (name) {
			case 'CREATIVE_PARTNER_SELECTION':
				_name = 'Creative Partner Selection';
				break;
			case 'CREATIVE_BRIEF':
				_name = 'Creative Brief';
				break;
			case 'CREATIVE_WORK':
				_name = 'Creative Work';
				break;
			case 'PRODUCTION_PARTNER_SELECTION':
				_name = this.translateService.transform('PRODUCTION PARTNER SELECTION');
				break;
			case 'PRODUCTION_BRIEF':
				_name = this.translateService.transform('PRODUCTION BRIEF');
				break;
			case 'PRODUCTION_COMPANY_PROPOSALS':
				_name = this.translateService.transform('PRODUCTION COMPANY PROPOSALS');
				break;
			case 'PRE_PRODUCTION':
				_name = 'Pre-Production';
				break;
			case 'SHOOTING':
				_name = 'Shooting';
				break;
			case 'POST_PRODUCTION':
				_name = 'Post-Production';
				break;
			case 'SELF_FIX_EDIT':
				_name = 'Self-fix Edit';
				break;
			case 'DIFFUSION':
				_name = 'Diffusion';
				break;
			case 'BRIEF':
				_name = 'Ideation';
				break;
		}

		return _name;

	}

	public getBudget(projectId: string, subsectionId: string): Observable<any> {
		let subject = new Subject<any>();
		const endpoint = `/api/projects/${projectId}/budget/${subsectionId}`;
		this.api.httpGet(endpoint).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)
		return subject.asObservable();
	}

	public saveBudget(projectId: string, subsectionId: string, projectBudget: any): Observable<any> {
		let subject = new Subject<any>();
		let budgetOption = {
			id: projectBudget.budgetOption.id,
			optionText: projectBudget.budgetOption.optionText,
			seniors: projectBudget.budgetOption.seniors,
			midweights: projectBudget.budgetOption.midWeights,
			juniors: projectBudget.budgetOption.juniors,
			workingDay: projectBudget.budgetOption.workingDay,
			total: projectBudget.budgetOption.total
		}

		let body = {
			id: projectBudget.id,
			plannedBudget: (projectBudget && projectBudget.plannedBudget) ? projectBudget.plannedBudget : 0,
			knowBudget: projectBudget.knowBudget,
			plannedEndDate: (projectBudget && projectBudget.plannedEndDate) ? projectBudget.plannedEndDate : null,
			plannedStartDate: (projectBudget && projectBudget.plannedStartDate) ? projectBudget.plannedStartDate : null,
			projectId: projectId,
			projectSubSectionId: subsectionId,
			currency: projectBudget.currency,
			option1: budgetOption

			// TODO complete here
		}

		const endpoint = `/api/projects/${projectId}/budget/${subsectionId}`;

		this.api.httpPost(endpoint, body).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		)

		return subject.asObservable();
	}

	public isUserRsvp(project: any, username: string = null): boolean {

		if (!username)
			username = this.authService.getAuthUserName();

		let isUserRsvp: boolean = false;

		if (project) {
			if (project.participants && Array.isArray(project.participants)) {
				project.participants.forEach((participant: any) => {
					if ((participant.username == username) && (participant.rsvp)
						&& (participant.rsvp.toLowerCase() == 'yes')) {
						isUserRsvp = true;
					}
				});
			}
		}

		return isUserRsvp;
	}

	public blockUser(username: string): Observable<any> {
		let projectId = this.project.id;
		return this.api.httpPut(`/api/projects/${projectId}/users/${username}/block`, {});
	}

	public unblockUser(username: string): Observable<any> {
		let projectId = this.project.id;
		return this.api.httpPut(`/api/projects/${projectId}/users/${username}/unblock`, {});
	}

	public setSubsectionValues(projectId: string, sectionId: string, subsectionId: string, values: any): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/projectSubSectionOptionValues`, values);
	}

	public getSubsectionValues(projectId: string, sectionId: string, subsectionId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/projectSubSectionOptionValues`);
	}

	public getSubsectionValue(projectId: string, sectionId: string, subsectionId: string, optionId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/projectSubSectionOptionValues/${optionId}`);
	}

	public deleteSubsectionValue(projectId: string, sectionId: string, subsectionId: string, optionId: string): Observable<any> {
		return this.api.httpDelete(`/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/projectSubSectionOptionValues/${optionId}`);
	}

	public saveProjectInSessionStorage(project: any) {
		sessionStorage.setItem('project', JSON.stringify(project));
	}

	public getProjectFromSessionStorage() {
		let project = sessionStorage.getItem('project');
		return (project) ? JSON.parse(project) : null;
	}

	private getCompanyIdFromLocal() {
		return this.authService.getAuthUser().companyId;
	}

	private getCompanyNameFromLocal() {
		this.authService.getAuthUser().company.name
	}

	public deleteOrArchiveProject(projectId: string) {
		return this.api.httpDelete(`/api/projects/${projectId}`);
	}

	public deleteProject(projectId: string, archive: boolean = true) {
		return this.api.httpDelete(`/api/projects/${projectId}?delete=${!archive}`);
	}

	public restoreArchive(projectId: string): Observable<any> {
		return this.api.httpPut(`/api/projects/${projectId}/restore`, {});
	}

	public updateOrderNumber(o, projectId: string, sectionId: string) {

		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/order`, o);
	}

	public updateSubsectionsOrder(projectId: string, sectionId: string, subsections: any[]) {
		return this.api.httpPost(`/api/projects/${projectId}/sections/${sectionId}/subsections/order/batch`, subsections);
	}

	public getCompanyProposals(companyId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${companyId}/proposals`);
	}

	public uploadProposal(proposal, projectId, isNeedProductionProcess?): Observable<any> {
		proposal.prodCompany = this.getCompanyNameFromLocal();
		if (isNeedProductionProcess != undefined)
			return this.api.httpPost(`/api/proposals/${projectId}/proposalStatus?isNeedProductionProcess=` + isNeedProductionProcess, proposal);
		else
			return this.api.httpPost(`/api/proposals/${projectId}/proposalStatus`, proposal);
	}

	public saveUploadedProposal(proposal, projectId): Observable<any> {
		proposal.prodCompany = this.getCompanyNameFromLocal();
		return this.api.httpPost(`/api/proposals/${projectId}/saveUploadedProposal`, proposal);
	}

	public getProposal(projectId): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/proposalStatus/${this.getCompanyIdFromLocal()}`)
	}

	public getProposalByUsername(projectId): Observable<any> {
		return new Observable(observer => {
			this.api.httpGet(`/api/proposals/${projectId}/proposalStatus/user/${this.authService.getAuthUserName()}`)
				.subscribe(res => {
					this.isOnloadedProposal = true;
					this.proposal = res;
					observer.next(this.proposal);
					observer.complete();
					onLoadedProposal.emit(this.proposal);
				}, err => {
					onLoadedProposal.emit({});
					this.isOnloadedProposal = false;
				});
		})
	}

	public getAllProjectProposals(projectId): Observable<any> {
		return this.api.httpGet(`/api/proposals/${projectId}/proposalStatus`);
	}

	public checkProjectsProposalStatus(projectIds: string[]): Observable<any> {
		let url = `/api/proposals/proposalStatus/checkProposalAccepted`;
		return this.api.httpPost(url, projectIds);
	}

	public getSectionIdBySectionName(projectId, sectionName): Observable<any> {
		return this.api.httpGet('/api/projects/' + projectId + '/' + sectionName);
	}

	public getSubSectionIdByName(projectId, sectionId, subSectionName): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/sections/${sectionId}/${subSectionName}`);
	}

	public getProductionOwnerForRating(projectId, companyId): Observable<any> {
		return this.api.httpGet(`/api/ratingMail/${projectId}/${companyId}`);
	}

	public ratingMail(projectId, user): Observable<any> {
		let subject = new Subject<any>();

		this.api.httpPost(`/api/ratingMail/${projectId}`, user).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			});

		return subject.asObservable();
	}

	isRatingCodeValid(code: string) {
		let subject = new Subject<any>();

		this.api.httpGet('/api/ratingMail/' + code).subscribe(
			(data: any) => {
				subject.next(data);
			},
			(err: any) => {
				subject.error(err);
			}
		);

		return subject.asObservable();
	}

	initalSetupPaymentProject(projectId): Observable<any> {
		return new Observable((observer) => {
			(async () => {
				try {
					this.getProject(projectId).subscribe(async (project) => {
						this.project = project;
						await this.generateStaticSubsections();
						observer.next(project);
						observer.complete();
					}, err => observer.error(err))
				} catch (error) {
					observer.error(error);
				}
			});
		})
	}

	public getProductionCompanyCountries(): Observable<any> {
		return this.api.httpGet(`/api/commons/production-company-countries`);
	}

	public updateFirstSeenByProjectId(projectId): Observable<any> {
		return this.api.httpPost(`/api/projects/${projectId}/isFirstSeen`, {});
	}

	async updateFirstSeenByProjectIdAfterCheck(project) {
		try {
			if (project.isFirstSeen != 'TRUE') {
				await this.updateFirstSeenByProjectId(project.id).toPromise();
			}
		} catch (error) {

		}
	}

	public checkProposalAccepted(projectId): Observable<any> {
		return this.api.httpGet(`/api/proposals/${projectId}/checkProposalAccepted`);
	}

	public saveProposalNDA(invitationProjectId: string, proposalId: string): Observable<any> {
		return this.api.httpPut(`/api/proposals/${invitationProjectId}/${proposalId}/nda/${this.authService.getAuthUserName()}`, {});
	}

	public getProposalById(projectId, proposalId) {
		return this.api.httpGet(`/api/proposals/${projectId}/${proposalId}`);
	}

	public getCommentsVotesAnnotations(projectId: any, sectionId: any, subSectionId: any, pagingState: any, page: any, size: any) {
		return this.api.httpGet(`/api/projects/${projectId}/${sectionId}/${subSectionId}${pagingState ? `pagingState=${pagingState}` : `page=${page}&size=${size}`}`)
	}

	public createOneShotForWorkspace(workspaceId: string, projectId: string) {
		return this.api.httpPost(`/api/projects/workspace/${workspaceId}/oneShotProject/${projectId}`, {});
	}

	public getAssetFiles(projectId: string, sectionId: string, subsectionId: string, assetId: string = null): Observable<any> {
		let url = `/api/projects/${projectId}/sections/${sectionId}/subsections/${subsectionId}/items/folderfiles`;
		if (assetId) {
			url += `?itemId=${assetId}`;
		}
		return this.api.httpGet(url);
	}

	public getProjectParticipants() {
		return this.api.httpGet('/api/projects/' + this.authService.getAuthUserName() + '/users');
	}

	public updateProjectStatus(args: any) {
		let query = `status=${args.status}`;
		if (args.completionDate) {
			query += `&completionDate=${args.completionDate}`;
		}
		return this.api.httpPut(`/api/projects/${args.projectId}/updateProjectStatus?${query}`, {});
	}

	public getCompanyProjects(companyId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/company/${companyId}`);
	}

	getFileInfo(projectId: string, fileId: string): Observable<any> {
		return this.api.httpGet(`/api/projects/${projectId}/storage/${fileId}/poll`);
	}

	setFileCache(fileId: string, file: any) {
		this.fileCache[fileId] = file;
	}

	getFileCache(fileId: string) {
		return this.fileCache[fileId];
	}
}

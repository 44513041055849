import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  AnnotationService,
  onAddingAnnotation, onAnnotationsLoaded,
  onCancelAddingAnnotation,
  onCommentPluginReady
} from "app/shared/services/annotation.service";
import { AuthService } from 'app/shared/services/auth.service';
import { TranslateService } from "app/shared/services/translate.service";
import { CommentAnnotationPlugin } from "./plugin/comment-annotation.plugin";
import { MtmStompService } from "app/shared/services/mtm-stomp.service";
import { wsListenerANNOTATION_CREATED } from "app/shared/services/mtm-websocket.service";
import { commentFileAdd } from "app/shared/services/comments.service";
import {AnnotationPrivateCondition} from "../../interfaces";
import {first, takeUntil} from "rxjs/operators";
import { Subject } from "rxjs";



@Component({
    selector: 'mtm-comment-annotation',
    styleUrls: ['./comment-annotation.component.scss'],
    templateUrl: './comment-annotation.component.html'
})
export class CommentAnnotation implements OnInit, OnDestroy {
    @Input() projectId: any;
	@Input() sectionId: any;
	@Input() subsectionId: any;
    @Input() item: any;
    @Input() annotations: any[];
    @Input() hideAnnotationToolbar: boolean = true;

    annotationPlugin: CommentAnnotationPlugin;
    authUser: any;
    annotationComment: string = '';
    pluginReady: any;
	wsId: string;
	newFiles: any = [];

	files: any = [];
	videoRecordFiles: any = [];
	audioRecordFiles: any = [];
    privateCondition: AnnotationPrivateCondition = null;
	ngUnsubscribe = new Subject();
	isBusy: boolean = false;

    constructor(
		private stompService: MtmStompService,
        private authService: AuthService,
        private translateService: TranslateService,
        private annotationService: AnnotationService
    ) {
    }

    ngOnInit(): void {
        this.authUser = this.authService.getAuthUser();
        this.annotationService.privateConditionChange$.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe({
            next: (value: AnnotationPrivateCondition) => {
                this.privateCondition = value;
            }
        })
        // window["com"] = this;
        this.initPlugin();
    }

    ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
		this.stompService.unsubscribeToService(this.wsId);
    }

    ngAfterViewInit(): void {
		this.wsId = this.stompService.subscribeToService();
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        onCancelAddingAnnotation.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe({
            next:  (args: any) => this.onCancelAddingAnnotationHandler(args)
        })
        onAnnotationsLoaded.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe({
            next:  (args: any) => this.applyAnnotationsDataChange(args)
        })
        this.stompService.subscribeToListener('ANNOTATION_CREATED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnAnnotationCreate(args), wsListenerANNOTATION_CREATED);
    }

	wsOnAnnotationCreate(args) {
		commentFileAdd.emit({
			projectId: this.projectId,
			sectionId: this.sectionId,
			subSectionId: this.subsectionId,
			comment: args.mediaAnnotation.comments[0],
			annotationId: args.mediaAnnotation.id,
			files: this.newFiles
		});
        this.cleanUpFiles();
	}

    addNewAnnotation() {
		if((!this.annotationComment && !this.newFiles?.length) || this.isBusy) {
			return;
		}
        if (this.annotationPlugin && this.pluginReady) {
            this.annotationPlugin.fire('saveGeneralComment');
        }
    }

    isInValidInput() {
        return !this.annotationComment;
    }

    initPlugin() {
        let config = {
            userInfo: this.authUser,
            item: this.item,
            annotationObjects: this.annotations
        }
        this.annotationPlugin = new CommentAnnotationPlugin(this, config);

        this.annotationPlugin.onReady(() => {
            this.pluginReady = true;
            onCommentPluginReady.emit({
                act: 'onImagePluginReady',
                annotationPlugin: this.annotationPlugin
            });
        })
    }

    onCancelAddingAnnotationHandler(args: any) {
        this.resetUIComponent();
    }
    resetUIComponent() {
        this.annotationComment = '';
        this.isBusy = false;
    }

    /** Gets the comment input place holder text depending on general or annot comment */
    getPlaceholderText() {
        // let placeholderText;
        // if ( this.annotationPlugin && this.annotationPlugin.controls && (this.annotationPlugin.controls.uiState.adding || this.annotationPlugin.controls.uiState.editing) ){
        // 	placeholderText = "Write an annotation comment..."
        // }
        // else {
        // 	placeholderText = "Write a general comment..."
        // }

        let placeholderText = "Write a general comment..."

        return placeholderText;
    }

	cleanUpFiles() {
		this.newFiles = [];
		this.files = [];
		this.videoRecordFiles = [];
		this.audioRecordFiles = [];
	}

	uploadFileCallback(fileObject) {
		const { files } = fileObject;
		if (!files) {
			return;
		}
		files.forEach(file => {
			if (file.method == 'audioRecord') {
				this.audioRecordFiles.push(file);
			} else if (file.method == 'videoRecord') {
				this.videoRecordFiles.push(file);
			} else {
				this.files.push(file);
			}
		});
		this.newFiles.push(...files);
	}

	onFileDeleted(file) {
		this.newFiles = this.newFiles.filter(f => f.lastModified != file.lastModified);
		this.audioRecordFiles = this.newFiles.filter(f => f.method == 'audioRecord');
		this.videoRecordFiles = this.newFiles.filter(f => f.method == 'videoRecord');
		this.files = this.newFiles.filter(f => !f.method);
	}

    handlePrivateConditionChange(value: AnnotationPrivateCondition) {
        this.annotationService.updatePrivateCondition(value);
    }

    applyAnnotationsDataChange(args: any) {
        if(args.data){
            this.annotationPlugin.annotationState.annotations = args.data;
        }
    }
}

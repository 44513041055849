import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { ProjectV2ServiceService } from './project-v2-service.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { EmitterService } from './emitter.service';
import {DriveCampaign} from "../interfaces";

export interface headerData {
  url: string;
  userName: string;
  title: string;
  isbackGround: boolean;
  isTaskProject: boolean;
  isDashboard: boolean;
  isRecap: boolean;
  isGeneral: boolean;
  hideGeneralComponent: boolean;
  urlInside: string;
  isWorkspace: boolean;
  headerTitle?: string;
}
export interface componentData {
  componentName: string;
  bannerUrl: string;
}

export const MilestoneSelected = 'milestone.selected';
@Injectable({
  providedIn: 'root'
})

export class TimelineService {
  ngUnsubscribe = new Subject();
  driveCampaignData: any;
  driveCampaignId: string;
  campaignName: string = '';
  workspaceId: string = '';
  workspaceName: string = '';
  images = {
    analytics: '../../../assets/img/project-v2/analytics.png',
    campaignDriveDashboard: '../../../assets/img/project-v2/new-project.png',
    dashboard_hr: '../../../assets/img/project-v2/workspace-img.png',
    dashboard_ysl: '../../../assets/img/project-v2/ysl-dashboard.png',
    timeline: '../../../assets/img/project-v2/timeline-header.png',
    timeline_hr: '../../../assets/img/project-v2/workspace-img.png',
    timeline_ysl: '../../../assets/img/project-v2/ysl-dashboard.png',
    ysl: '../../../assets/img/project-v2/ysl-dashboard.png',
    taskproject_hr: '../../../assets/img/project-v2/task-project-header.png',
    taskproject_ysl: '../../../assets/img/project-v2/new-project.png',
    scenario2: '../../../assets/img/project-v2/scenario2Dashboard.png',
    scenario4ysl: '../../../assets/img/project-v2/scenario4yslheader.png',
    headerImage: '../../../assets/img/project-v2/workspace-img.png',
    yslBrandLogo: '../../../assets/img/project-v2/ysl-brand-logo.png',
    hrBrandLogo: '../../../assets/img/project-v2/hr-brand-logo.png'
  }
  fullName: string;
  jobTitle: string;

  public imageEventEmitter: EventEmitter<headerData> = new EventEmitter<headerData>();
  public workspaceBannerEmitter: EventEmitter<string> = new EventEmitter<string>();
  public searchEventEmitter: EventEmitter<string> = new EventEmitter<string>();
  public workspaceDashboardEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public resetTabsEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();

  // set and get selected milestone
  public milestone = new BehaviorSubject<any>({});
  public selectedMilestone$: Observable<any> = this.milestone.asObservable();
  public selectedCampaign$: Observable<any> = this.milestone.asObservable();

  // set header image
  public componentName = new BehaviorSubject<componentData>({
    componentName: '',
    bannerUrl: ''
  });

  public selectedComponent$: Observable<componentData> = this.componentName.asObservable();

  public setworksapceId = new BehaviorSubject<string>('');
  public getWorkspaceId$: Observable<string> = this.setworksapceId.asObservable();

  // show and hide tabs of drive campaign
  public isDriveNav: EventEmitter<boolean> = new EventEmitter<boolean>();

  // set timeline for update milestone status
  public setTimeline = new BehaviorSubject<any>({});
  public getTimelineData: Observable<any> = this.setTimeline.asObservable();

  // get current workspace data from sidebar nav
  public selectedWorkspace: EventEmitter<any> = new EventEmitter<any>();

  // task description
  public taskDescription = new BehaviorSubject<any[]>([]);

  // below emmiters are for views on drive campaign and campaigns
  public isAll: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isArchived: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isFavorites: EventEmitter<boolean> = new EventEmitter<boolean>();

  // show and hide cross button on milestone timeline block
  public showCrossBtn: EventEmitter<boolean> = new EventEmitter<boolean>();

  activeCampaign$ = new Subject<DriveCampaign>();

  // dropdown event
  private dropdownState = new Subject<void>();
  dropdownState$ = this.dropdownState.asObservable();
  // Emit events when needed
  emitIsAll(value: boolean): void {
    this.isAll.emit(value);
  }

  emitIsArchived(value: boolean): void {
    this.isArchived.emit(value);
  }

  emitIsFavorites(value: boolean): void {
    this.isFavorites.emit(value);
  }

  public lastSelectedDriveCampaign: any;
  public lastSelectedMilestone: any;
  public lastSelectedCampaign: any;

  // for calendar view milestone
  public calendarViewmilestone: any;

  // Function to update the milestone value
  public updateMilestone(newMilestone: any) {
    this.milestone.next(newMilestone);
    if (!newMilestone) {
      this.router.navigate(['workspaces'])
    }
  }

  constructor(
    private apiService: ApiService,
    private projectV2Service: ProjectV2ServiceService,
    private router: Router,
    private authService: AuthService
  ) {
    try {
      let userData: any = localStorage.getItem('auth_user');
      userData = JSON.parse(userData);
      this.fullName = userData.fullName;
      this.jobTitle = userData.jobTitle
    } catch {

    }
  }

  // create random color based on name
  // start
  stringToRGB(name?: string): [number, number, number] {
    let hash = 0;
    if (name) {
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      const r = (hash & 0xFF0000) >> 16;
      const g = (hash & 0x00FF00) >> 8;
      const b = hash & 0x0000FF;
      return [r, g, b];
    }
  }

  RGBToHex(rgb: [number, number, number]): string {
    if (rgb) {
      return `#${rgb.map((c) => c.toString(16).padStart(2, '0')).join('')}`;
    }
  }

  getColorFromName(name: string): string {
    if (name) {
      const rgb = this.stringToRGB(name);
      const hexColor = this.RGBToHex(rgb);
      return hexColor;
    } else {
      return 'rgba(0,0,0,0.03)';
    }
  }
  // end

  getDriveCampaign(workspaceId: string, driveCampaignId: string): Observable<any> {
    return this.apiService.httpGet(`/api/loreal-timeline/drive-campaigns/${workspaceId}/${driveCampaignId}`);
  }

  async getTimeLine(selectedworkspaceId: string, drivCampaignId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.projectV2Service.getSingleDriveCampaign(selectedworkspaceId, drivCampaignId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(result => {
          this.driveCampaignId = result.id;
          this.workspaceId = result.workspaceId;
          this.driveCampaignData = result;
          EmitterService.get('CAMPAIGN_DATA_UPDATED').emit(this.driveCampaignData);
          resolve(result);
        }, error => {
          reject(error);
        });
    });
  }

  // get milestone projects
  getMilestoneProjects(campaignId: string, workspaceId: string, milestoneId: string,) {
    return this.apiService.httpGet(`/api/projects/users/${this.authService.getAuthUserName()}?milestoneId=${milestoneId}`);
  }

  // get milestone projects
  getDriveCampaignByMilestoneAssetList(campaignId: string,milestoneId: string,) {
    return this.apiService.httpGet(`/api/loreal-asset-list/summary-by-milestone/${campaignId}/${milestoneId}`);
  }

  // setting top nav bar image and other required detail
  getComponentDetail() {
    this.selectedComponent$.subscribe((data: componentData) => {
      if (data) {
        let header: headerData = { headerTitle: '', url: '', urlInside: '', userName: '', title: '', isWorkspace: false, isbackGround: false, isTaskProject: false, isDashboard: false, isGeneral: false, isRecap: false, hideGeneralComponent: false };
        const myBrand = this.authService.getMyBrand();
        const path = myBrand?.name == 'Yves Saint Laurent' ? 'ysl' : 'hr';
        switch (data.componentName) {
          // Update the value based on the case match
          case 'workspaceComponent':
            header.url = data.bannerUrl ? data.bannerUrl : '';
            // header.url = data.bannerUrl ? data.bannerUrl : this.images[`dashboard_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = true;
            header.headerTitle = 'Workspaces'
            break;
          case 'taskComponent':
           header.url = data.bannerUrl ? data.bannerUrl : '';
            // header.url = data.bannerUrl ? data.bannerUrl : this.images[`dashboard_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isTaskProject = true;
            header.isDashboard = true;
            break;
          case 'campaignDriveComponent':
           header.url = data.bannerUrl ? data.bannerUrl : '';
            // header.url = data.bannerUrl ? data.bannerUrl : this.images[`dashboard_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = true;
            header.headerTitle = 'Drive Campaigns'
            break;
          case 'milestoneComponent':
           header.url = data.bannerUrl ? data.bannerUrl : '';
            // header.url = data.bannerUrl ? data.bannerUrl : this.images[`dashboard_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = true;
            break;
          case 'timelineSettingComponent':
           header.url = data.bannerUrl ? data.bannerUrl : '';
            // header.url = data.bannerUrl ? data.bannerUrl : this.images[`dashboard_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = true;
            header.hideGeneralComponent = true;
            break;
          case 'generalComponent':
            header.url = data.bannerUrl ? data.bannerUrl : this.images.timeline;
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = false;
            header.isGeneral = true;
            break;
          case 'recapeComponent':
            header.url = data.bannerUrl ? data.bannerUrl : this.images.timeline;
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = false;
            header.isRecap = true;
            break;
          case 'recapeComponentInside':
            header.url = data.bannerUrl ? data.bannerUrl : this.images.timeline;
            header.urlInside = data.bannerUrl ? data.bannerUrl : this.images[`timeline_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = false;
            header.isRecap = true;
            break;
          case 'analyticsDashboardComponent':
           header.url = data.bannerUrl ? data.bannerUrl : '';
            // header.url = data.bannerUrl ? data.bannerUrl : this.images[`dashboard_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = true;
            break;
          case 'camapignDriveNavComponent':
           header.url = data.bannerUrl ? data.bannerUrl : '';
            // header.url = data.bannerUrl ? data.bannerUrl : this.images[`dashboard_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = false;
            break;
          case 'campaignsComponent':
           header.url = data.bannerUrl ? data.bannerUrl : '';
            // header.url = data.bannerUrl ? data.bannerUrl : this.images[`dashboard_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = true;
            header.headerTitle = 'Campaigns'
            break;
          case 'projectsComponent':
           header.url = data.bannerUrl ? data.bannerUrl : '';
            // header.url = data.bannerUrl ? data.bannerUrl : this.images[`dashboard_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = true;
            header.headerTitle = 'Projects'
            break;
          case 'sharedComponent':
           header.url = data.bannerUrl ? data.bannerUrl : '';
            // header.url = data.bannerUrl ? data.bannerUrl : this.images[`dashboard_${path}`];
            header.userName = this.fullName;
            header.title = this.jobTitle;
            header.isDashboard = true;
            header.headerTitle = 'Shared'
            break;
          default:
            break;
        }
        // Emit the updated value through the imageEventEmitter
        this.imageEventEmitter.emit(header);
      }
    });
  }

  //set brand logo
  brandLogo() {
    let brandimage;
    const brandName = this.authService.getMyBrand().name;
    switch (brandName) {
      case 'Yves Saint Laurent':
        return brandimage = this.images.yslBrandLogo;
      case 'Helena Rubinstein':
        return brandimage = this.images.hrBrandLogo;
      default:
        break;
    }
  }

  // GET PROJECT AND TASK
  getTaskAndProject(workspaceId: string, driveCampaignId: string, projectIds: string[],taskIds: string[]) {
    let url = `/api/loreal-timeline/drive-campaigns/${workspaceId}/${driveCampaignId}/project-task-summary`;
    let urlParams = [];
    if (projectIds) {
      urlParams.push(`projectIds=${projectIds}`);
    }
    if (taskIds) {
      urlParams.push(`taskIds=${taskIds}`);
    }
    if (urlParams.length > 0) {
      url += '?' + urlParams.join('&');
    }
    return this.apiService.httpGet(url);
  }

  notifyDropdownOpened() {
    this.dropdownState.next();
  }
}

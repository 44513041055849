<div class="comment-annotation-component" [hidden]="hideAnnotationToolbar">
    <div class="toolbar  w-100 d-flex">
        <div class="editor-container d-flex flex-fill align-items-center">
            <mtm-text-editor [elementId]="'annotCommentArea'" (keydown.enter)="$event.preventDefault()"
                [(value)]="annotationComment" class="flex-fill" [dropUp]="false" [maxHeight]="'44px'"
                (keyup)="$event.stopPropagation()" (enterKeyPress)="addNewAnnotation()"
                (deleteFileCallback)="onFileDeleted($event)" [files]="files" [videoRecordFiles]="videoRecordFiles"
                [audioRecordFiles]="audioRecordFiles" [backgroundColor]="'#FFFFFF'" [placeholder]="getPlaceholderText()"
                (uploadFileCallback)="uploadFileCallback($event)" [showBottomToolbar]="true" [isAnnotationEditor]="true"
                [userAvatar]="authUser.username" [quillActive]="true" [supportPrivateComment]="true"
                [(privateCondition)]="privateCondition" (privateConditionChange)="handlePrivateConditionChange($event)">
            </mtm-text-editor>
        </div>
        <div [ngClass]="{'disabledButton': isInValidInput() === true}"
            (click)="$event.preventDefault();addNewAnnotation()" class="toolbarOptions save-button pt-2 pb-2 pl-1 pr-1">
            <span class="tooltiptext" directiveCommentMinButtons>{{ 'saveAnnotation' | translate
                }}</span>
            <img src="../../../../../assets/img/annotations/annot_send.svg" alt="" height="24">
        </div>
    </div>
</div>
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { milestones } from 'app/shared/loreal-dummy-repository/milestone';
import { AuthService } from 'app/shared/services/auth.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { DummyDataService } from 'app/shared/services/dummy-data.service';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MilestoneStatusEditorComponent } from "../milestone-status-editor/milestone-status-editor.component";
import { CampaignMilestone } from "app/shared/interfaces";
import { environment } from 'environments/environment';
import { MilestoneStatusEditorCPDComponent } from '../milestone-status-editor-cpd/milestone-status-editor-cpd.component';

@Component({
  selector: 'mtm-timeline-blocks',
  templateUrl: './timeline-blocks.component.html',
  styleUrls: ['./timeline-blocks.component.scss', '../timeline-style.scss']
})
export class TimelineBlocksComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('pinnedtimelinediv') pinnedtimelinediv: ElementRef;
  @Output() dataEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() isDriveCampaign = false;
  @Input() timeLineData: any = [];
  @Input() editTimelineComponent: boolean = false;
  @Input() isRecap: boolean = false;
  @Input() isHallmark: boolean = false;

  timelines: any = [];
  pinnedTimelines: any = [];
  pinnedTimelineDivWidth: number = 0;
  widthPerClosedBlock: number = 25;
  widthPerMilestone: number = 85;
  marginPerBlock: number = 7;
  timelineDropdown: any = ['view All'];
  showTooltip: boolean = false;
  isSelectedMilestone: boolean = false;
  selectedMilestone: any;
  colors = {
    'CAMPAIGN': '#000000',
    'PRODUCT': '#1E43A1',
    'MAGELLAN': '#1E43A1',
    'COLLECTOR / PRODUCT': '#086289',
    'GIFT': '#923065',
    'SET': '#502B9D'
  }
  colorInfoData = [];
  dcTimelineMilestone: any = {};
  ishiddenTimeline: boolean = true;
  authUser: any;
  ngUnsubscribe = new Subject();
  authority = Authority;
  private dataSubscription: Subscription;
  workspaceId: string;
  canViewEditTimeline: boolean = false;
  isShow: boolean = false;
  timelineElementWidth: any = 0;
  fixedTimelineSize: boolean = false;
  isLeftArrowVisible: boolean = false;
  isRightArrowVisible: boolean = false;
  isCpd: boolean = false;

  constructor(private router: Router,
    private projectV2ServiceService: ProjectV2ServiceService,
    private loarealDummyDataService: DummyDataService,
    private authUserService: AuthService,
    private timelineService: TimelineService,
    private cryptoService: CryptoService,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private translatePipe: TranslatePipe
  ) {
    this.authUser = this.authUserService.getAuthUser();
    this.isCpd = this.authUser.companyId == environment.ltg.cpdCompanyId;
    router.events.subscribe(() => {
      this.resetOpacity();
    });
  }

  ngOnInit(): void {
    this.dcryptIds();
    const authUser = this.authUserService.getAuthUser();
    this.canViewEditTimeline = this.authUserService.hasPaidSubscription();
    this.handleTimeline();
    this.timelineService.showCrossBtn.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (data) => {
        this.isShow = data;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.timeLineData) {
      const updatedData = changes.timeLineData.currentValue;
      this.handleTimelineDataUpdate(updatedData);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(undefined);;
    this.ngUnsubscribe.complete();
  }

  dcryptIds() {
    this.route.queryParams.subscribe(query => {
      if (query && query.wid && query.dc) {
        this.workspaceId = this.cryptoService.decryptUsingAES256(query.wid);
        // this.driveCampaignId = this.cryptoService.decryptUsingAES256(query.dc);
      }
    });
  }
  // when data updated from timeline-setting handleTimelineDataUpdate function called
  handleTimelineDataUpdate(updatedData: any) {
    this.timeLineData = updatedData;
    this.handleTimeline();
  }

  async handleTimeline() {
    this.timelines = await this.projectV2ServiceService.handleTimelineData(this.timeLineData, this.isHallmark);
    this.dataSubscription = this.timelineService.selectedMilestone$.subscribe((result: any) => {
      if (result && result.name) {
        this.selectedMilestone = result;
        this.updateMilestone(result);
      }
    });
    if (this.timelines) {
      let timelineTotal = 0;
      this.fixedTimelineSize = false;
      this.timelineElementWidth = 0;
      this.timelines.forEach((elem) => {
        if (elem.items.length > 0) {
          timelineTotal += elem.items.length;
          const newData = {
            name: elem.name,
            color: elem.blockColor
          };

          // Check if newData already exists in colorInfoData
          const existingDataIndex = this.colorInfoData.findIndex((data) => data.name === newData.name && data.color === newData.color);

          // Add newData to colorInfoData only if it doesn't already exist
          if (existingDataIndex === -1) {
            this.colorInfoData.push(newData);
          }
        }
        // Add block name for filter
        if (elem.name !== 'CAMPAIGN' && elem.items.length > 0) {
          // if timeline block does not present then push timeline block
          if (!this.timelineDropdown.includes(elem.name)) {
            this.timelineDropdown.push(elem.name);
          }
        }
      });
      if (timelineTotal) {
        if (timelineTotal * 85 <= 1191) {
          this.timelineElementWidth = 1191 / timelineTotal;
          this.fixedTimelineSize = true;
        }
      }
    }

    setTimeout(() => {
      this.checkScrollOffset();
    }, 100);
  }

  getTooltipWidth(): number {
    // Calculate the maximum width required based on the content length
    const maxContentWidth = this.colorInfoData.reduce(
      (maxWidth, item) => Math.max(maxWidth, item.name.length),
      0
    );
    // Add some additional width to accommodate padding and other elements
    const tooltipWidth = maxContentWidth * 16 + 32; // Adjust the multiplier as needed

    // Return the calculated width
    return tooltipWidth;
  }

  handleTimelineView(event: any) {
    const block = event.target.value;
    if (block == 'View all') {
      this.timelines.forEach((elem) => {
        elem.showBlock = true;
      })
    } else {
      this.timelines.forEach((elem) => {
        if (block == elem.name) {
          elem.showBlock = true;
        } else {
          elem.showBlock = false;
        }
        // always show campaign block
        if (elem.name == 'CAMPAIGN') {
          elem.showBlock = true;
        }
      })
    }
    // recalculate blocks width
    this.handlePinBlocks(null);
  }

  //toggle timeline block
  toggleBlockView(timeline: any) {
    const blockIndex = this.timelines.indexOf(timeline);
    this.timelines[blockIndex].showBlock = !this.timelines[blockIndex].showBlock;
  }

  //scroll timeline
  timelineScrollOnWheel(event: WheelEvent) {
    //const scrollContainer = document.querySelector('.timeline-scroll') as HTMLElement;
    //scrollContainer.addEventListener('wheel', (event) => {
    //  event.preventDefault();
    //  scrollContainer.scrollLeft += event.deltaX;
    //});
    //scrollContainer.scrollLeft += event.deltaX;
    const scrollContainer = document.querySelector('.timeline-scroll') as HTMLElement;
    if (!scrollContainer) {
      return;
    }
    scrollContainer.scrollLeft += - event.deltaY;
  }

  //need throttle?
  checkScrollState(event: Event) {
    event.stopPropagation();
    this.checkScrollOffset();
  }

  checkScrollOffset() {
    const target = document.querySelector('.timeline-scroll') as HTMLElement;
    if (!target) {
      return;
    }
    this.isLeftArrowVisible = target.scrollLeft > 0;
    let scrollLeftMax = target.scrollWidth - target.clientWidth
    this.isRightArrowVisible = target.scrollLeft < scrollLeftMax;
  }

  scrollToLeft() {
    const scrollContainer = document.getElementById("scroll-container");
    if (scrollContainer) {
      scrollContainer.scrollLeft -= 10;
    }
  }

  scrollToRight() {
    const scrollContainer = document.getElementById("scroll-container");
    if (scrollContainer) {
      scrollContainer.scrollLeft += 10;
    }
  }

  editTimeline() {
    this.ishiddenTimeline = false;
    for (let item of this.timelines) {
      // toggle block if production timeline
      item["showBlock"] = false;
      item["showToggleIcons"] = false;
      for (let subitem of item.items) {
        subitem["isNegociated"] = false;
      }
    }
    this.router.navigate([
      "workspaces/campaigndrive",
      "timeline-setting"
    ], {
      queryParams: {
        wid: this.cryptoService.encryptString(this.timelineService.workspaceId),
        dc: this.cryptoService.encryptString(this.timelineService.driveCampaignId)
      }
    });
  }

  handlePinBlocks(block) {
    // toggle block pin
    if (block) {
      const index = this.timelines.indexOf(block);
      this.timelines[index].pinBlock = !this.timelines[index].pinBlock;

      if (this.timelines[index].pinBlock) {
        this.pinnedTimelines.push(block);
      } else {
        this.pinnedTimelines = this.pinnedTimelines.filter(el => el.name !== block.name);
      }
    }

    // count number for milestones
    let milestoneCount = 0;
    let closedBlockCount = 0;
    this.pinnedTimelines.forEach(elem => {
      if (elem.items && elem.items.length && elem.showBlock) {
        elem.items.forEach(subItem => {
          if (subItem.items && subItem.items.length) {
            milestoneCount += subItem.items.length;
          } else {
            milestoneCount += 1;
          }
        });
      } else {
        closedBlockCount += 1;
      }
    });

    // calculate width of pinned blocks to set dynamic left padding
    let width = (this.widthPerMilestone * milestoneCount) + ((this.pinnedTimelines.length - closedBlockCount) * this.marginPerBlock) + (closedBlockCount * this.widthPerClosedBlock);
    this.pinnedTimelineDivWidth = width;
  }

  sendData(data) {
    if (!this.canViewEditTimeline) {
      return;
    }

    this.timelineService.lastSelectedMilestone = data;
    // this.ngZone.run(() => {
    if (data && !this.editTimelineComponent && !this.isRecap) {
      setTimeout(() => {
        this.timelines.forEach(timeline => {
          timeline.items.forEach(milestone => {
            if (milestone.items) {
              milestone.items.forEach(subMilestone => {
                if (subMilestone.name === data.name && subMilestone.id === data.id) {
                  milestone.opacity = 1;
                  milestone['isCross'] = true;
                  milestone.status = data.status;
                  milestone.statusColor = this.dcTimelineMilestone.color;
                } else {
                  milestone.opacity = 0.3;
                  milestone['isCross'] = false;
                  milestone.statusColor = '#fff';
                }
              });
            } else if (milestone.name === data.name && milestone.mad === data.mad) {
              milestone.opacity = 1;
              milestone.status = data.status;
              milestone['isCross'] = true;
              milestone.statusColor = this.dcTimelineMilestone.color;
            } else {
              milestone.opacity = 0.3;
              milestone['isCross'] = false;
              milestone.statusColor = '#fff';
            }
          });
        });
      }, 100);

      this.dataEvent.emit(data);
      this.timelineService.updateMilestone(data);
    } else {
      for (let item of this.timelines) {
        for (let subItem of item.items) {
          subItem.opacity = 1;
          subItem['isCross'] = true;
        }
      }
    };
  }

  updateMilestone(data) {
    this.timelineService.lastSelectedMilestone = data;
    // Reset all milestones to their default state
    this.timelines.forEach(timeline => {
      timeline.items.forEach(milestone => {
        milestone.opacity = 0.3;
        milestone['isCross'] = false;
        milestone.statusColor = '#fff';
        if (milestone.items) {
          milestone.items.forEach(subMilestone => {
            subMilestone.opacity = 0.3;
            subMilestone['isCross'] = false;
            subMilestone.statusColor = '#fff';
          });
        }
      });
    });
    if (data && !this.editTimelineComponent && this.selectedMilestone && !this.isRecap) {
      setTimeout(() => {
        this.timelines.forEach(timeline => {
          timeline.items.forEach(milestone => {
            if (
              (milestone.items && milestone.items.some(subMilestone => subMilestone.name === data.name && subMilestone.id === data.id)) ||
              (!milestone.items && milestone.name === data.name && milestone.mad === data.mad)
            ) {
              milestone.opacity = 1;
              milestone['isCross'] = true;
              milestone.status = data.status;
              milestone.statusColor = this.dcTimelineMilestone.color;
            }
          });
        });
      }, 100);
    } else {
      // If there is no milestone selected, set all milestones' opacity to 1
      this.timelines.forEach(timeline => {
        timeline.items.forEach(milestone => {
          milestone.opacity = 1;
          milestone['isCross'] = true;
        });
      });
    }
  }

  resetOpacity() {
    const currentPath = window.location.pathname;
    const matchPath = [
      '/workspaces/campaigndrive/5806860095/dashboard/task-project',
      '/workspaces/campaigndrive/0688832012/dashboard/task-project',
      '/workspaces/campaigndrive/0143110813/dashboard/task-project'
    ];

    if (matchPath.includes(currentPath)) {
      // do nothing
    } else {
      // reset opacity
      for (let item of this.timelines) {
        for (let subItem of item.items) {
          subItem.opacity = 1;
        }
      }
    }
  }

  // setting milestone bacground color
  getMilestoneColor(status) {
    switch (status) {
      case '' || 'In Progress' || 'IN_PROGRESS':
        return '#fff';
      case 'Completed' || 'COMPLETED':
        return '#43CB9A';
      case 'Delayed' || 'DELAYED':
        return '#F67373';
      default:
        break;
    }
  }

  //set and return block names according to need
  getBlockName(blcokName) {
    if (blcokName === 'COLLECTOR / PRODUCT') {
      blcokName = this.translatePipe.transform('Production')
    }
    return this.translatePipe.transform(blcokName);
  }

  disablePointerEvents() { const element = this.elementRef.nativeElement; this.renderer.setStyle(element, 'pointer-events', 'none'); }
  enablePointerEvents() { const element = this.elementRef.nativeElement; this.renderer.removeStyle(element, 'pointer-events'); }

  backToDashboard(milestone) {
    let dcId = milestone.driveCampaignId;
    this.timelineService.showCrossBtn.next(true);
    this.router.navigate([
      "/workspaces/campaigndrive/dashboard"
    ], {
      queryParams: {
        wid: this.cryptoService.encryptString(this.workspaceId),
        dc: this.cryptoService.encryptString(dcId)
      }
    });
  }

  editMilestone(milestone: CampaignMilestone) {
    if(this.editTimelineComponent || this.isRecap) {
      return;
    }

    //TODO: update data with child milestones
    if(!! milestone.items && milestone.items.length > 0){
      return;
    }

    console.log('milestone to edit', milestone);

    const modalRef = this.modalService.open(this.isCpd ? MilestoneStatusEditorCPDComponent :  MilestoneStatusEditorComponent, { size: 'lg', modalDialogClass: 'responsive-modal' });
    modalRef.componentInstance.milestone = milestone;
    modalRef.componentInstance.campaign = this.timeLineData;
    modalRef.result.then((result) => {
      this.handleTimeline();
    }).catch((error) => {
    });
  }
}

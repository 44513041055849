import * as _ from 'lodash';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
// import { Ng2FileDropAcceptedFile } from "ng2-file-drop";
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

import { OverlayService } from 'app/shared/services/overlayService';

const supportedFileExtensions: string[] = [
	'aac',
	'avi',
	'bmp',
	'csv',
	'doc',
	'docx',
	'fla',
	'flac',
	'gif',
	'jpeg',
	'jpg',
	'mov',
	'mkv',
	'mp3',
	'mp4',
	'mpeg',
	'odp',
	'ods',
	'odt',
	'ogg',
	'pdf',
	'png',
	'ppt',
	'pptx',
	'rtf',
	'txt',
	'wav',
	'xls',
	'xlsx',
	'zip',
	'tiff',
	'psb',
	'psd'
];

import { listenerDragActiveStatus, emitActive, emitDeactive } from 'app/shared/base-functions/chat-drag-file.function';
// import { DroppedFiles } from "ng2-file-drop/dropped-files/dropped-files";
import { NotificationService } from 'app/shared/services/notification.service';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { EditingService } from 'app/shared/services/editing.service';

const PRE_DEFINED_FILTERS = {
	IMAGE_FILTER: 'image/png, image/bmp, image/jpeg, image/jpg',
	PDF_FILTER: 'application/pdf',
	VIDEO_FILTER: 'video/avi, video/mp4, video/mpeg, video/mov',
	AUDIO_FILTER: 'audio/mp3, audio/flac',
	OFFICE_WORD: "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	OFFICE_PRESENTATION: "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation",
	SUBTITLE_LANGUAGE: ".srt",
	FONT: '.ttf',
	PNG_FILTER: 'image/png',
}

const INFO_LIST_FOR_SUPPORTED_TYPES = {
	IMAGE_FILTER: 'png, bmp, jpeg, jpg',
	PDF_FILTER: 'pdf',
	VIDEO_FILTER: 'avi, mp4, mpeg, mov',
	AUDIO_FILTER: 'mp3, flac',
	OFFICE_WORD: "doc, docx",
	OFFICE_PRESENTATION: "ppt, pptx",
	FONT: 'ttf',
	PNG_FILTER: 'png',
}

@Component({
	selector: 'mtm-upload-file',
	templateUrl: './upload-file.component.html',
	styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
	@Input() preDefinedFilter: string | string[];
	@Input() maxSizeMB: number = 0;
	@Input() isRoundedCircle: boolean = false;
	@Input() middleHtmlText: string = 'Select files';
	@Input() bottomHtmlText: string = 'Or drag & drop<br> files here';
	@Input() isRedirectImageLoadError: boolean = true;
	@Input() imageURL: string;
	@Input() disabled: boolean;
	@Input() disableAccept: boolean = false;
	@Input() inputText: string;
	@Input() secondaryText: string;
	@Input() compactStyle: boolean = false;
	@Input() disableTooltip: boolean = false;
	@Input() skipUnsupportedModal: boolean = false;
	@Input() bgColor: string = '#FFF';
	@Input() borderColor: string = '#bbb';
	@Input() color: string = '#bbb';
	@Input() isProjectV2?: boolean = false;
	@Input() isProjectV2Brand?: boolean = false;
	@Input() buttonTitle: string;

	@ViewChild('fileInput', { static: true }) fileInput: ElementRef;
	uploadForm: UntypedFormGroup;
	submitted: boolean = false;
	uploading: boolean = false;
	isDragFile: boolean = false;
	@Output() files: EventEmitter<any> = new EventEmitter();
	filesArray: File[] = [];
	isImageLoadError: boolean = false;
	ngUnsubscribe = new Subject();
	isImageFilter: boolean = false;
	containerId: string = '';
	inputId: string = '';
	changeStyle: boolean = false;
	accept;

	constructor(private fb: UntypedFormBuilder,
		private translatePipe: TranslatePipe,
		private notificationService: NotificationService,
		private serviceOverlay: OverlayService,
		private editingService: EditingService) {
		// Load form group
		this.loadFormGroup();

		listenerDragActiveStatus.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe(
			(status) => {
				this.isDragFile = status;
			}
		)
		this.containerId = this.getId();
		this.inputId = this.getId();
	}

	ngOnInit() {
		this.init();
		this.isImageFilter = <any>PRE_DEFINED_FILTERS[<any>this.preDefinedFilter] === PRE_DEFINED_FILTERS.IMAGE_FILTER;
		this.editingService.fileAddedOrEdited.subscribe(val => this.changeStyle = val)
	}

	ngOnChanges() {
		this.init();
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	private init() {
		this.isImageLoadError = false;
		let result: string[] = [];
		if (this.preDefinedFilter) {
			let filters = typeof this.preDefinedFilter == 'string' ? [this.preDefinedFilter] : this.preDefinedFilter.filter(k => k && k != '');
			result = filters.map(filterKey => {
				return Object.keys(INFO_LIST_FOR_SUPPORTED_TYPES).find(key => key == filterKey) ? INFO_LIST_FOR_SUPPORTED_TYPES[filterKey] : '';
			});
			const filterArr = filters.map(filterKey => {
				return Object.keys(PRE_DEFINED_FILTERS).find(key => key == filterKey) ? PRE_DEFINED_FILTERS[filterKey] : '';
			});
			if (!this.disableAccept) {
				this.fileInput.nativeElement.accept = filterArr.toString();
			}
		} else {
			result = Object.keys(INFO_LIST_FOR_SUPPORTED_TYPES).map(key => {
				return INFO_LIST_FOR_SUPPORTED_TYPES[key];
			});
		}
		this.accept = this.prepareExtensionList(result);

		//this.isDragFile = false;
		//emitDeactive();
	}

	private prepareExtensionList(arr: string[] = []): String[] {
		return arr.map(item => item.split(',').toString().replace(/,/g, ', '))
	}

	/**
	 * Submit login form
	 */
	onSubmit() {
		this.submitted = true;
		if (this.uploadForm.valid)
			console.log(this.uploadForm.value);
	}


	/**
	 * Trigger click on input file
	 */
	upload(): boolean {
		this.fileInput.nativeElement.click();
		return false;
	}


	/**
	 * Used to send image to second cropper
	 * @param $event
	 */
	async fileChangeListener(event): Promise<void> {
		if (event.target.files.length)
			await this.filterSupportFiles(event);
	}

	private filterSupportFiles(event) {
		const files = event.target.files;
		let compatibleFiles: any[] = [];
		let foundUnSupportFiles: string[] = [];
		for (let index = 0; index < files.length; index++) {
			let ext = files[index].name.split('.').pop();
			let foundIndex = _.indexOf(supportedFileExtensions, ext);
			if (foundIndex >= 0) {
				compatibleFiles.push(files[index]);
			} else {
				let contentType = files[index].fileType || files[index].type;
				let typeExt = contentType.substr(contentType.indexOf("/") + 1);
				let foundExtIndex = _.indexOf(supportedFileExtensions, typeExt) >= 0;
				if (foundExtIndex) {
					if (typeExt === 'vnd.openxmlformats-officedocument.presentationml.presentation') {
						ext = 'pptx';
						files[index].name = files[index].name + (typeExt ? ('.' + ext) : '');
						compatibleFiles.push(files[index]);
					} else if (typeExt === 'vnd.ms-powerpoint') {
						ext = 'ppt';
						files[index].name = files[index].name + (typeExt ? ('.' + ext) : '');
						compatibleFiles.push(files[index]);
					} else if (typeExt === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
						ext = 'xlsx';
						files[index].name = files[index].name + (typeExt ? ('.' + ext) : '');
						compatibleFiles.push(files[index]);
					} else if (typeExt === 'vnd.ms-excel') {
						ext = 'xls';
						files[index].name = files[index].name + (typeExt ? ('.' + ext) : '');
						compatibleFiles.push(files[index]);
					} else if (typeExt === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
						ext = 'docx';
						files[index].name = files[index].name + (typeExt ? ('.' + ext) : '');
						compatibleFiles.push(files[index]);
					} else if (typeExt === 'msword') {
						ext = 'docx';
						files[index].name = files[index].name + (typeExt ? ('.' + ext) : '');
						compatibleFiles.push(files[index]);
					} else {
						ext = typeExt;
						foundUnSupportFiles.push(files[index]);
					}
				} else {
					foundUnSupportFiles.push(files[index]);
				}
			}
		}
		if (foundUnSupportFiles.length && !this.skipUnsupportedModal) {
			this.notificationService
				.open({
					cssClass: 'file-confirm-container d-flex flex-column',
					noHeaderBorder: true,
					icon: 'file-confirm',
					description: this.translatePipe.transform('fileConfirmUploadTitle'),
					descriptionMeta: this.translatePipe.transform('fileConfirmReadFile'),
					noteInfo: '<div class="issue-files">' +
						'<span class="issue-file-title">' + this.translatePipe.transform('fileConfirmIssueFiles') + '</span>' +
						this.getIssueFiles(foundUnSupportFiles) +
						'</div>',
					iconSize: '80',
					confirmBtn: this.translatePipe.transform('fileConfirmUploadAnyway'),
					cancelBtn: this.translatePipe.transform('fileConfirmUploadAnother'),
					closeModalHandlerResult: true
				}).pipe(
					takeUntil(this.ngUnsubscribe)
				).subscribe((confirm: any) => {
					if (confirm.reason === 'OK') {
						let files = [...compatibleFiles, ...foundUnSupportFiles];
						if (files.length) {
							this.files.emit({ files: [...compatibleFiles, ...foundUnSupportFiles], event: event });
						}
					} else if (confirm.reason === 'cancel') {
						$(this.fileInput.nativeElement).val('');
						$(this.fileInput.nativeElement).trigger('click');
					}
					this.fileInput.nativeElement.value = '';
				});
		} else {
			if (this.isImageFilter) {
				let isThereSizeExceeded = false;
				for (let i = 0; i < compatibleFiles.length; i++) {
					if (compatibleFiles[i].size / 1000000 > (this.maxSizeMB || 1)) {
						isThereSizeExceeded = true;
						break;
					}
				}
				if (isThereSizeExceeded) {
					this.fileInput.nativeElement.value = '';
					this.serviceOverlay.showError(this.translatePipe.transform('fileSizeLimitExceed', { LIMIT_SIZE: (this.maxSizeMB || 1) }));
				} else {
					this.files.emit({ files: compatibleFiles, event: event });
					setTimeout(() => {
						this.fileInput.nativeElement.value = '';
					}, 50);
				}
			} else {
				if (this.skipUnsupportedModal) {
					let files = [...compatibleFiles, ...foundUnSupportFiles];
					if (files.length) {
						this.files.emit({ files: [...compatibleFiles, ...foundUnSupportFiles], event: event });
					}
				} else {
					this.files.emit({ files: compatibleFiles, event: event });
					setTimeout(() => {
						this.fileInput.nativeElement.value = '';
					}, 50);
				}
			}
		}
	}

	getIssueFiles(files: any[]) {
		let html = '';
		for (let i = 0; i < files.length; i++) {
			html += '<div>' + files[i].name + '</div>'
		}
		return html;
	}

	/**
	 * File being dragged has been dropped and is valid
	 *
	 * @param acceptedFile
	 */
	dragFileAccepted(acceptedFile: NgxFileDropEntry) {
		this.sendToFilesUpload([acceptedFile]);
	}

	dragFilesDropped(draggedFiles: NgxFileDropEntry) {
		this.sendToFilesUpload(draggedFiles);
		this.isDragFile = false;
		emitDeactive();
	}

	private sleep = (ms: number) => {
		return new Promise(resolve => setTimeout(resolve, ms))
	};

	private async getFiles(draggedFiles: any) {

		for await (const droppedFile of draggedFiles) {

			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file(async (file: File) => {
					this.filesArray.push(file)
				});
			}
		}
	}

	private async sendToFilesUpload(draggedFiles, $event?: any) {
		await this.getFiles(draggedFiles);
		await this.sleep(200);
		this.files.emit({ files: this.filesArray, event: $event });
		this.filesArray = []
	}

	/**
	 * Load form group
	 */
	private loadFormGroup() {
		this.uploadForm = this.fb.group({});
	}

	onImageLoadError(e) {
		this.isImageLoadError = true;
	}

	ng2FileDropHoverStart(e) {
		this.isDragFile = true;
		emitActive();
	}

	ng2FileDropHoverEnd(e) {
		this.isDragFile = false
		emitDeactive();
	}

	getId() {
		return UUID.UUID();
	}

}

import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DriveCampaignColor, DriveCampaignStatus, ProjectColor, ProjectStatus } from 'app/layouts/my-projects/project.const';
import { TaskColor, TaskStatus } from 'app/shared/components/task/task-status.const';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { AuthService } from 'app/shared/services/auth.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { DataTransferBetweenComponentsService } from 'app/shared/services/data-transfer-between-components.service';
import { HelperService } from 'app/shared/services/helper.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { OverlayService } from 'app/shared/services/overlayService';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { ProjectService } from 'app/shared/services/project.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { WorkspaceService } from 'app/shared/services/workspace.service';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { Subject, takeUntil } from 'rxjs';
import { NgbDropdown, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  ProjectCompletionDateComponent
} from "../../layouts/my-projects/project-completion-date/project-completion-date.component";
import { Project } from "app/shared/interfaces";
import moment from "moment";
import { ThemeService } from 'app/shared/services/theme.service';
import { MtmBreadcrumbLink, MtmBreadcrumbService } from 'app/shared/services/mtm-breadcrumb.service';
import { EmitterService } from 'app/shared/services/emitter.service';

export enum projectTypeOptions {
  // All_PROJECTS = 'All Projects',
  STAND_ALONE_PROJECTS = 'Stand Alone Projects',
  // SHARED_PROJECTS = 'Shared Projects',
  DRIVE_CAMPAIGN = 'Drive Campaign Projects',
  CAMPAIGN = 'Campaign Projects',
  // POERCELL_CAMPAIGN = 'Powercell Campaign',
  // REPLASTY_DRIVE_CAMPAIGN = 'Replasty Drive Campaign',
}

@Component({
  selector: 'mtm-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss', '../campaign-drive/campaign-drive.component.scss']
})

export class ProjectsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('projectStatusDropdown', { read: NgbDropdown }) statusDropdowns: QueryList<NgbDropdown>;
  @ViewChild('searchPanel', { static: false }) searchPanel: TemplateRef<any>;
  @Input() workspaceId: string;
  @Input() isProjectCollapse: boolean = false;
  @Input()isCampaignProjects?: boolean = false;

  authUser: any;
  projectSearch: string;
  calendarViewMode = 'Latest';
  workspaces: any[] = [];
  selectedProjectType: string;
  canCreateProject: boolean = false;
  canEditStatus: boolean = false;
  isError: boolean = false;
  isLoaded: boolean = false;
  projectType = Object.values(projectTypeOptions);
  isStandAlone: boolean = false;
  isArchiveProjects: boolean = false;
  ngUnsubscribe = new Subject();
  taskColor = TaskColor;
  taskStatus = TaskStatus;
  projectColorList = ProjectColor;
  projectStatusList = ProjectStatus;
  projectColor = DriveCampaignColor;
  projectStatus = DriveCampaignStatus;
  projects: any[] = [];
  backupProjects: any[] = [];
  favprojects: any[] = [];
  favbackupProjects: any[] = [];
  archiveBackupProjects: any[] = [];
  archiveProjects: any[] = [];
  isAll: boolean = true;
  isFav: boolean = false;
  isArchived: boolean = false;
  selectedWorkspace: any = {
    name: 'All Workspaces',
    id: null
  };
  isAdminUser: boolean = false;
  isAccountOwner: boolean = false;
  isAllProject: boolean = true;
  isFavoritesProject: boolean = false;
  isArchivedProject: boolean = false;
  activeTab: string = 'all';
  filteredDates: any;

  constructor(
    private authService: AuthService,
    public timelineService: TimelineService,
    private workspaceService: WorkspaceService,
    private permissionService: PermissionService,
    private projectService: ProjectService,
    private router: Router,
    private analyticService: AnalyticsService,
    private notificationService: NotificationService,
    private overlayService: OverlayService,
    private translate: TranslatePipe,
    private cryptoService: CryptoService,
    private route: ActivatedRoute,
    private dataTransferService: DataTransferBetweenComponentsService,
    private breadcrumbService: BreadcrumbService,
    private modalService: NgbModal,
    private themeService: ThemeService,
    private mtmBreadcrumbService: MtmBreadcrumbService

  ) {
    this.setBreadcrumb();
    EmitterService.get('BRAND_UPDATED').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (brand) => {
        this.setBreadcrumb();
      }
    });

    //get calendar filter 
    EmitterService.get('CALENDAR_FILTER_UPDATED').pipe(
      takeUntil(this.ngUnsubscribe)
  ).subscribe({
      next: (res) => {
          this.filteredDates = res;
          this.getStandAloneProjects();
      }
  });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(query => {
      if (query.wid) {
        this.workspaceId = this.cryptoService.decryptUsingAES256(query.wid);
      }
    });
    this.isAdminUser = this.permissionService.hasAuthority(Authority.Z, null);
    this.isAccountOwner = this.permissionService.hasAuthority(Authority.S, null);
    // resetTab
    this.timelineService.resetTabsEmmiter.emit(true);

    this.authUser = this.authService.getAuthUser();

    this.timelineService.isDriveNav.emit(true);
    const brand = this.authService.getMyBrand();
    this.timelineService.componentName.next({
      componentName: 'projectsComponent',
      bannerUrl: brand.banner
    });
    this.canCreateProject = (((this.permissionService.hasAuthority(Authority.Z, this.permissionService.GLOBAL_SCOPE_PROJECT_ID) || this.permissionService.hasAuthority(Authority.Z)) || this.authService.hasPaidSubscription()) && this.permissionService.hasAuthority(Authority.P, this.permissionService.GLOBAL_SCOPE_PROJECT_ID));
    this.canEditStatus = (this.permissionService.hasAuthority(Authority.Z, this.permissionService.GLOBAL_SCOPE_PROJECT_ID) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.S, this.permissionService.GLOBAL_SCOPE_PROJECT_ID));

    // set default stand alone project
    this.selectedProjectType = projectTypeOptions.STAND_ALONE_PROJECTS;
    if (this.selectedProjectType == projectTypeOptions.STAND_ALONE_PROJECTS) {
      this.isStandAlone = !this.isStandAlone;
      console.log('this.isStandAlone', this.isStandAlone)
      this.getStandAloneProjects();
    }

    // trigger tabs
    this.getTabsInputs();

    // get brand workspaces
    this.getWorkspaces();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.mtmBreadcrumbService.setLinks([]);
    // this.timelineService.isDriveNav.emit(false);
  }
  ngAfterViewInit(): void {
    this.themeService.updateContent({
      searchPanel: this.searchPanel,
      fullWidthContent: true
    });
    // breadcrumb
    if (!this.workspaceId) {
      this.breadcrumbService.addCallbackForRouteRegex(`/workspaces/projects`, (id) => {
        return 'Projects';
      });
    } else {
      this.breadcrumbService.addCallbackForRouteRegex(`/workspaces/projects`, (id) => {
        return this.timelineService.workspaceName;
      });
    }
  }

  searchProject() {
    if (this.projectSearch) {
      this.projects = this.backupProjects.filter(campaign => {
        let isMatched = true;
        if (this.projectSearch) {
          if (!campaign.name) {
            isMatched = false;
          } else {
            isMatched = campaign.name.toLowerCase().indexOf(this.projectSearch.toLowerCase()) > -1;
          }
          if (!isMatched) {
            this.projects = this.backupProjects;
          }
        }
        return isMatched;
      })
    } else {
      this.projects = this.backupProjects;
    }
  }

  selectCalendarMode(mode) {
    switch (mode) {
      case '3_months':
        this.calendarViewMode = '3 Months';
        break;
      case '6_months':
        this.calendarViewMode = '6 Months';
        break;
      case 'week':
        this.calendarViewMode = 'Week';
        break;
      default:
        this.calendarViewMode = 'Latest';
        break;
    }
  }

  markAsFavorite(project) {
    if (this.selectedProjectType === projectTypeOptions.STAND_ALONE_PROJECTS) {
      this.analyticService.markAsFavorite(project, 'STANDALONE_PROJECT').pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe({
        next: (res: any) => {
          this.projects = this.projects.map(project => ({
            ...project,
            isFavorite: res.items.includes(project.id)
          }));
        },
        error: (err) => {
          console.error(err);
          project.isFavorite = false;
        },
        complete: () => {
        }
      });
    }
  }

  removeFavorite(project) {
    this.analyticService.removeAsFavorite(project, 'STANDALONE_PROJECT').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        // this.projects = this.projects.map(project => ({
        //   ...project,
        //   isFavorite: res.items.includes(project.id)
        // }));
        this.getStandAloneProjects();
        // this.getFavoriteProjects();
      },
      error: (err) => {
        console.error(err)
      },
      complete: () => {
      }
    });
  }

  getFavoriteProjects() {
    this.projects = [];
    this.backupProjects = [];
    this.isError = false;
    this.isLoaded = false;
    let type: string;
    if (this.selectedProjectType === projectTypeOptions.CAMPAIGN) {
      type = 'CAMPAIGN';
    } else if (this.selectedProjectType === projectTypeOptions.DRIVE_CAMPAIGN) {
      type = 'DRIVE_CAMPAIGN';
    } else {
      type = 'STANDALONE_PROJECT';
    }
    this.analyticService.getFavoriteItem(type).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        if (this.isFav) {
          // Filter the results based on the selected workspace
          if (this.workspaceId) {
            this.projects = res.filter(project => project.workspaceId === this.workspaceId);
          } else {
            this.projects = res; // If no workspace is selected, show all favorite projects
          }

          this.backupProjects = [...this.projects]; // Create a copy for backup
          this.projects.forEach(project => {
            project.isFavorite = true;
          });
          this.backupProjects.forEach(project => {
            project.isFavorite = true;
          });
          this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.projects = this.projects.map(p => {
            p.isHaveAccess = !!p.participants.filter(r => r.email === this.authUser.username).length;
            return p;
          })
          this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.isLoaded = true;
        } else if (this.isAll) {
          this.favprojects.forEach(project => {
            const foundproject = res.find(w => w.id === project.id);
            project.isFavorite = foundproject !== undefined;
          });
          this.favbackupProjects.forEach(project => {
            const foundproject = res.find(w => w.id === project.id);
            project.isFavorite = foundproject !== undefined;
          });
          // remove duplicate items
          this.projects = this.favprojects.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.backupProjects = this.favbackupProjects.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.projects = this.projects.map(p => {
            p.isHaveAccess = !!p.participants.filter(r => r.email === this.authUser.username).length;
            return p;
          })
          this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.isLoaded = true;
        } else if (this.isArchived) {
          this.archiveProjects.forEach(project => {
            const foundProject = res.find(w => w.id === project.id);
            project.isFavorite = foundProject !== undefined;
          });

          this.archiveBackupProjects.forEach(project => {
            const foundProject = res.find(w => w.id === project.id);
            project.isFavorite = foundProject !== undefined;
          });

          this.isLoaded = true;
          // remove duplicate items
          this.projects = this.archiveProjects.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id && (!this.workspaceId || t.workspaceId === this.workspaceId)
            ))
          );
          this.projects = this.projects.map(p => {
            p.isHaveAccess = !!p.participants.filter(r => r.email === this.authUser.username).length;
            return p;
          })

          this.backupProjects = this.archiveBackupProjects.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id && (!this.workspaceId || t.workspaceId === this.workspaceId)
            ))
          );

          this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        }
        this.isLoaded = true;
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      },
      complete: () => {
        // Handle completion if needed
      }
    });
  }

  getTabsInputs() {
    this.timelineService.isAll.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = true;
          this.isFav = false;
          this.isArchived = false
          if (this.selectedProjectType = projectTypeOptions.STAND_ALONE_PROJECTS) {
            this.getStandAloneProjects();
          }
          this.timelineService.emitIsAll(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });

    this.timelineService.isFavorites.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = false;
          this.isFav = true;
          this.isArchived = false
          this.getFavoriteProjects();
          this.timelineService.emitIsFavorites(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });

    this.timelineService.isArchived.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = false;
          this.isFav = false;
          this.isArchived = true
          if (this.selectedProjectType = projectTypeOptions.STAND_ALONE_PROJECTS) {
            this.getStandAloneArchiveProjects();
          }
          this.timelineService.emitIsArchived(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });
  }

  getStandAloneProjects() {
    this.projects = [];
    this.backupProjects = [];
    this.isLoaded = false;
    this.isError = false;
    // let workspaceId: string = ''; 

    // if (this.selectedWorkspace) {
    //   workspaceId = this.selectedWorkspace.id
    // }
    // const isStandAlone =true;

    const request = this.workspaceId ?
      this.analyticService.getStandaloneProjectInWorkspace(this.workspaceId,this.isStandAlone,this.filteredDates).pipe(takeUntil(this.ngUnsubscribe)) :
      this.analyticService.getProjectsUser(this.authUser.email, false, this.workspaceId, true).pipe(takeUntil(this.ngUnsubscribe))
    request.subscribe({
      next: (data) => {
        this.projects = data;
        this.backupProjects = data;
        this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.projects = this.projects.map(p => {
          p.isHaveAccess = !!p.participants.filter(r => r.email === this.authUser.username).length;
          return p;
        })
        this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.favbackupProjects = this.backupProjects;
        this.favprojects = this.projects;
        this.isLoaded = true;
        this.getFavoriteProjects();
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      },
      complete: () => {

      }
    })
  }

  shouldShowEllipsis(name: string): boolean {
    if (name && name.length) {
      return name.length > 15;
    }
  }

  changeProjectType(event) {
    this.selectedProjectType = event;
    switch (event) {
      case projectTypeOptions.STAND_ALONE_PROJECTS:
        this.isStandAlone = true;
        this.getStandAloneProjects();
        break;
      // case projectTypeOptions.All_PROJECTS:
      //   this.isStandAlone = false;
      //   this.projects = [];
      //   break;
      case projectTypeOptions.CAMPAIGN:
        this.isStandAlone = false;
        this.projects = [];
        this.getCamapignAndDriveCampaign();
        break;
      // case projectTypeOptions.POERCELL_CAMPAIGN:
      //   this.isStandAlone = false;
      //   this.projects = [];
      //   break;
      case projectTypeOptions.DRIVE_CAMPAIGN:
        this.isStandAlone = false;
        this.projects = [];
        this.getCamapignAndDriveCampaign();
        break;
      // case projectTypeOptions.REPLASTY_DRIVE_CAMPAIGN:
      //   this.isStandAlone = false;
      //   this.projects = [];
      //   break;
      // case projectTypeOptions.SHARED_PROJECTS:
      //   this.isStandAlone = false;
      //   this.projects = [];
      //   break;
      default:
        break;
    }
  }

  createProject() {
    window.location.href = `/projects/new/company`;
  }

  editProject(project) {
    let projectId = project.id;
    this.router.navigate([`/projects/edit/${projectId}/company`]);
  }

  deleteProject(project) {
    this.notificationService.open({
      title: this.translate.transform('projectDeleteTitle'),
      description: this.translate.transform('projectDeleteConfirm', { project_name: project.name }),
      confirmBtn: this.translate.transform('yes'),
      cancelBtn: this.translate.transform('cancel')
    }).subscribe((confirm: boolean) => {
      if (!confirm) {
        return;
      }

      this.projectService.deleteProject(project.id, !project.deleted).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe({
        next: (res) => {
          project.deleted = !project.deleted;
          this.projects = this.projects.filter(p => p.id != project.id);
          // Optionally update other properties in your project if needed
        },
        error: (err) => {
          this.notificationService.open({
            title: 'Warning',
            description: err.message,
            confirmBtn: 'Ok'
          });
        }
      });
    });
  }

  changeProjectStatus(project: Project, status: string) {
    const isProjectOwner = project.manager = this.authUser.username;
    const oldStatus = project.status;
    const isCompanyOwner = project.company.id === this.authUser.companyId && this.isAccountOwner;
    if (!this.isAdminUser && !isProjectOwner && !isCompanyOwner) {
      return;
    }
    const className = `status-${project.id}`;
    this.statusDropdowns.toArray().find(({ dropdownClass }) => dropdownClass === className)?.close();
    if (status === ProjectStatus.Completed) {
      const modalRef = this.modalService.open(ProjectCompletionDateComponent, { size: 'sm' });
      modalRef.componentInstance.setParams({ project });
      modalRef.result.then((data: any) => {
        if (!data) {
          return;
        }
        project.status = status;
        if (data.completionDate) {
          project.completionDate = data.completionDate;
        }
        this.saveProjectStatus(project, status, oldStatus);
      });
    } else {
      project.status = status;
      this.saveProjectStatus(project, status, oldStatus);
    }
  }

  private saveProjectStatus(project: Project, status: string, oldStatus: string) {
    const updateArgs: any = {
      projectId: project.id,
      status,
    }
    if (project.completionDate) {
      updateArgs.completionDate = moment(project.completionDate).format('DD-MM-yyyy');
    }
    this.projectService.updateProjectStatus(updateArgs)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result: any) => {
          project.status = result.status;
          project.completionDate = result.completionDate;
        },
        error: () => {
          project.status = oldStatus;
          this.overlayService.showError(this.translate.transform('overlaySaveError'), "Error");
        }
      })
  }

  getStandAloneArchiveProjects() {
    // this.isArchiveProjects = true;
    this.projects = [];
    this.backupProjects = [];
    this.isLoaded = false;
    this.isError = false;
    this.projectService.getArchivedProjects().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (data: any) => {
        if (!Array.isArray(data))
          return;

        let projects = [];
        data.forEach((project: any) => {
          projects.push(project);
          if (project.avatarUrl)
            project.avatarUrl = project.avatarUrl + '?' + (new Date).getTime();
        });

        this.projects = projects;
        this.backupProjects = projects;
        this.isLoaded = true;
        this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.projects = this.projects.map(p => {
          p.isHaveAccess = !!p.participants.filter(r => r.email === this.authUser.username).length;
          return p;
        })
        this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.archiveProjects = this.projects;
        this.archiveBackupProjects = this.backupProjects;
        // this.projects = this.projects.filter(project => project.milestoneId === this.driveCampaignId);
        this.getFavoriteProjects();
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      }
      // this.showInfoForArrayData(this.archivedProjects, 'You don’t have any archived project');
    });
  }

  archiveRestoreProject(project) {
    if (!project.deleted) {
      this.notificationService.open({
        title: this.translate.transform('project_archive'),
        description: this.translate.transform('project_confirmArchive', { name: project.name }),
        confirmBtn: this.translate.transform('yes'),
        cancelBtn: this.translate.transform('cancel')
      }).pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe((confirm: any) => {
        if (!confirm) {
          return;
        }

        this.projectService.deleteProject(project.id, true).pipe(
          takeUntil(this.ngUnsubscribe)
        ).subscribe({
          next: (res) => {
            project.deleted = true;
            // Find the index of the project in the array
            const projectIndex = this.projects.findIndex(p => p.id === project.id);
            if (projectIndex !== -1) {
              if (!this.isAll) {
                this.projects.splice(projectIndex, 1);
              } else {
                this.projects[projectIndex].status = 'ARCHIVED';
              }
            }
          },
          error: (err) => {
            this.notificationService.open({
              title: 'Warning',
              description: err.message,
              confirmBtn: 'Ok'
            });
          }
        });
      });
    } else {
      this.projectService.restoreArchive(project.id).subscribe({
        next: (result: any) => {
          if (this.isArchived) {
            this.projects = this.projects.filter(project => project.id != result.id);
          } else {
            project.status = result.status;
            project.deleted = false;
          }
        },
        error: (err: unknown) => {
          this.overlayService.showError(this.translate.transform('overlayErrorSave'), 'Error');
        }
      });
    }
  }

  getWorkspaces() {
    this.workspaces = [];
    this.analyticService.getBrands().pipe(takeUntil(this.ngUnsubscribe)).
      subscribe({
        next: (brands: any) => {
          let allBrands = brands;
          allBrands.forEach(brand => {
            // set header banner
            if (brand.workspaces) {
              this.workspaces.push(...brand.workspaces);
              this.workspaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
            }
            this.workspaces.sort((d1, d2) => d1.name.localeCompare(d2.name));
            if (this.workspaceId) {
              let workspace = this.workspaces.find(w => w.id === this.workspaceId);

              if (workspace) {
                this.selectedWorkspace = workspace;
              } else {
                console.warn('Workspace not found with id:', this.workspaceId);
              }
            }
          })
        }, error: (err) => {
          console.error('err', err)
        }
      });
  }

  changeWorkspace(workpsace) {
    if (workpsace === 'all_workspaces') {
      this.selectedWorkspace = {
        name: 'All Workspaces'
      }
      this.workspaceId = '';
      const navigationExtras: NavigationExtras = {
        queryParams: {}
      };

      this.router.navigate(['/workspaces/projects'], navigationExtras);
    } else {
      this.selectedWorkspace = workpsace;
      if (workpsace.id || this.workspaceId) {
        let wid = this.cryptoService.encryptString(workpsace.id);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            wid: wid,  // Use the encrypted workspaceId
          },
          queryParamsHandling: 'merge',  // Preserve existing queryParams
        });
        HelperService.changeBreadcrumbUrl(this.dataTransferService.breadcrumbComponent, 1, `/workspaces/projects?wid=${wid}`);
      }
      this.workspaceId = workpsace.id;
    }
    if (this.selectedProjectType == projectTypeOptions.STAND_ALONE_PROJECTS) {
      this.isStandAlone = true;
      this.getStandAloneProjects();
    } else {
      this.isStandAlone = false;
      this.getCamapignAndDriveCampaign();
    }
  }

  getCamapignAndDriveCampaign() {
    let isDriveCampaign: boolean;
    this.projects = [];
    this.backupProjects = [];
    this.isLoaded = false;
    this.isError = false;
    if (this.selectedProjectType === projectTypeOptions.CAMPAIGN) {
      isDriveCampaign = false;
    } else if (this.selectedProjectType === projectTypeOptions.DRIVE_CAMPAIGN) {
      isDriveCampaign = true;
    }
    let workspaceId: string = ''
    if (this.selectedWorkspace) {
      workspaceId = this.selectedWorkspace.id;
    }
    this.analyticService.getProjectsUser(this.authUser.email, isDriveCampaign, workspaceId, false).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (data) => {
        this.projects = data;
        this.backupProjects = data;
        this.projects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.projects = this.projects.map(p => {
          p.isHaveAccess = !!p.participants.filter(r => r.email === this.authUser.username).length;
          return p;
        })
        this.backupProjects.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.favbackupProjects = this.backupProjects;
        this.favprojects = this.projects;
        this.isLoaded = true;
        this.getFavoriteProjects();
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      },
      complete: () => {

      }
    })
  }

  redirectToDashboard(project) {
    let projectId = project.id;
    this.router.navigate([`/projects/${projectId}/dashboard`]);
  }

  gotoWorkspace(project) {
    
    this.router.navigate(['/workspaces/campaign-drive'],
      {
        queryParams: {
          wid: this.cryptoService.encryptString(project?.workspaceId),
        }
      });
  }

  gotoCampaign(project) {
    this.router.navigate(['/workspaces/campaigndrive/dashboard'],
      {
        queryParams: {
          wid: this.cryptoService.encryptString(project?.workspaceId),
          dc: this.cryptoService.encryptString(project?.campaignId)
        }
      });
  }

  allProjectItems() {
    this.isArchivedProject = false;
    this.isFavoritesProject = false;
    this.isAllProject = true;
    this.isAll=true;
    this.timelineService.emitIsAll(true);
  }

  favoriteProjectItems() {
    this.isArchivedProject = false;
    this.isFavoritesProject = true;
    this.isAllProject = false;
    this.isFav=true;
    this.getFavoriteProjects();
  }

  getArchiveProjects() {
    this.isArchivedProject = true;
    this.isFavoritesProject = false;
    this.isAllProject = false;
   this.timelineService.emitIsArchived(true);

}

setBreadcrumb() {
  const brand = JSON.parse(localStorage.getItem('brand'));
  if (brand) {
    const pageLinks: MtmBreadcrumbLink[] = [{
      label: brand.name
    }, {
      label: 'Projects',
   }]
    this.mtmBreadcrumbService.setLinks(pageLinks);
  }
}

}
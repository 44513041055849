import { ElementRef, Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Headers } from "@angular/http";
import * as moment from 'moment';
import 'moment-timezone';
import * as _ from 'lodash';
import { UntypedFormGroup } from "@angular/forms";
import { isNullOrUndefined } from 'util';
import { ChartCreationParams, ChartCreationResult, IHaveVersions, TimeInfo } from "../interfaces";
import * as d3 from "d3";
import { AuthService } from "app/shared/services/auth.service";

@Injectable({
	providedIn: 'root'
})
export class HelperService {

	static getHttpRapidAPIHeaders(uploadFile: boolean = false): Headers {
		let headers = new Headers();
		headers.append('x-rapidapi-key', '962a261d3amshe957a1acab414eep13b360jsn7f9fb9b79935');
		headers.append('x-rapidapi-host', 'ip-geo-location.p.rapidapi.com');
		return headers;
	}


	/**
	 * Create http headers object
	 * @mockapi boolean If true, then dont add token since it fails
	 * @returns {Headers}
	 */
	static getHttpHeaders(uploadFile: boolean = false, customHeaders: any = {}): Headers {
		let headers = new Headers();

		// If dont want to upload file, then add these headers
		if (!uploadFile) {
			headers.append('Accept', 'application/json');
			headers.append('Content-Type', 'application/json');
		}

		Object.entries(customHeaders).forEach(([key, value]) => {
			headers.set(key.toString(), value.toString());
		});

		// Send token
		let token = localStorage.getItem('token');
		if (!token)
			token = localStorage.getItem('token_external');

		if (token)
			headers.append('Authorization', 'Bearer ' + token);
		else {
			const registerData = JSON.parse(localStorage.getItem('register_data'));
			if (registerData && registerData.auth) {
				headers.append('Authorization', 'Bearer ' + registerData.auth.token);
			}
		}
		return headers;
	}

	static getRegistrationHeaders(noToken: boolean = false): Headers {
		let registerData = JSON.parse(localStorage.getItem('register_data'));
		if (!noToken) {
			return new Headers({
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + registerData.auth.token
			});
		} else {
			return new Headers({
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			});
		}
	}

	static getMovieSubtitleEndpointHeader() {
		let token = localStorage.getItem('token');
		if (!token)
			token = localStorage.getItem('token_external');
		return new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		});
	}


	/**
	 * Return a random number
	 * @param start
	 * @param end
	 * @returns {number}
	 */
	static genRandomNumber(start: number = 0, end: number = 100000) {
		return Math.floor(Math.random() * end) + start;
	}


	/**
	 * Return default image for no avatar
	 */
	static getAvatarNoImage(): string {
		return environment.web.baseUrl + '/assets/img/misc/avatar-no-image.png';
	}


	/**
	 * Return default image for no group image
	 */
	static getGroupNoImage(): string {
		return environment.web.baseUrl + '/assets/img/misc/group-no-image.png';
	}


	/**
	 * Return default audio image
	 */
	static getAudioImage(): string {
		return environment.web.baseUrl + '/assets/img/misc/audio.png';
	}


	/**
	 * Return default video image
	 */
	static getVideoImage(): string {
		return ''; // environment.web.baseUrl + '/assets/img/misc/video.png'
	}


	/**
	 * Return links from text
	 */
	static getLinksFromText(text: string) {
		let matchArray;
		let urlArray = [];
		var regexToken = /(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)|((mailto:)?[_.\w-]+@([\w][\w\-]+\.)+[a-zA-Z]{2,3})/g;

		// Iterate through any URLs in the text.
		while ((matchArray = regexToken.exec(text)) !== null) {
			var token = matchArray[0];
			urlArray.push(token);
		}
		return urlArray;
	}

	static isTimestamp(nameArr: any) {
		const parsed = parseFloat(nameArr);
		return !Number.isNaN(parsed) && Number.isFinite(parsed) && /^\d+\.?\d+$/.test(nameArr);
	}

	/**
	 * Get embed HTML content for the link
	 * @param link
	 */
	static getEmbedHTML(link: string) {
		return HelperService.getEmbedHTMLWithDetails(link, "310", "250");
	}

	/**
	 * Get embed HTML content for the link
	 * @param link
	 * @param options
	 * @param width
	 * @param height
	 */
	static getEmbedHTMLWithDetails(link: string, width: string, height: string) {

		// YouTube
		var videoid = link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
		if (videoid != null) {
			let vid = videoid[1];
			let html = `<iframe width="${width}" height="${height}" src="https://www.youtube.com/embed/${vid}" frameborder="0" allowfullscreen></iframe>`
			return html;
		}

		// Vimeo
		var vimeoid = link.match(/(videos|video|channels|\.com)\/([\d]+)/);
		if (vimeoid != null) {
			let vid = vimeoid[2];
			let html = `<iframe src="https://player.vimeo.com/video/${vid}" width="${width}" height="${height}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
			return html;
		}

		// Soundcloud
		var data = link.match(/https?:\/\/(?:w\.|www\.|)(?:soundcloud\.com\/)(?:(?:player\/\?url=https\%3A\/\/api.soundcloud.com\/tracks\/)|)(((\w|-)[^A-z]{7})|([A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*(?!\/sets(?:\/|$))(?:\/[A-Za-z0-9]+(?:[-_][A-Za-z0-9]+)*){1,2}))/);
		if (data != null) {
			// let id = data[1];
			// let html = `<iframe width="300" height="250" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/309689093&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"></iframe>`;
		}

		return null;

	}


	/**
	 * Return an array of string based on one string
	 * @param text
	 * @param splitBy
	 */
	static fromStringToArray(text: string, splitBy: string = ' '): string[] {
		if (text && text != '') {
			let array = text.trim().split(splitBy);
			let arrayRet = [];
			for (let i = 0; i < array.length; i++) {
				if (array[i].trim() != '')
					arrayRet.push(array[i].trim())
			}
			return arrayRet;
		}

		else
			return [];
	}


	/**
	 * Return string from array of strings
	 * @param array
	 * @param separator
	 */
	static fromArrayToString(array: string[], separator: string = ' '): string {
		if (array && array.length > 0) {
			let stringRet = '';
			array.forEach((text: string) => {
				if (stringRet != '')
					stringRet += separator;
				stringRet += text;
			})
			return stringRet;
		}
		else
			return '';
	}


	/**
	 * Fix avatar url
	 * @param avatarUrl
	 */
	static getFixedAvatarUrl(avatarUrl: string , version:any=null) {

		// If avatar url is saved in google and dont have alt=media param, then add it
		if ((avatarUrl.indexOf('googleapis') >= 0) && (avatarUrl.indexOf("alt=media") < 0)) {

			// If avatar url already have params, just add &alt=media
			if (avatarUrl.indexOf('?') >= 0)
				avatarUrl += "?alt=media&_v=" + version;
			else
				avatarUrl += "?alt=media&_v=" + version;
		}
		return avatarUrl;
	}

	/**
	 * Retrieve date formatted for API
	 * @param date
	 */
	static formatDate(date: any): string {
		return moment(date).utcOffset(0).format();
	}


	/**
	 * Returns a Date object based on datepicker selection
	 * example: { day: 20, month: 1, year: 2017 }
	 * @param date
	 */
	static getDateFromDatepicker(date: any): Date {

		let d = new Date();
		d.setMonth(date.month - 1);
		d.setFullYear(date.year);
		d.setDate(date.day);

		return d;
	}


	/**
	 * Format timezone names from momentjs
	 * @param tzName
	 * @return string
	 */
	static formatTimezoneName(tzName: string) {

		let tzNameFilterArray = new Array<string>();

		let tzNameArray = tzName.split('');
		tzNameArray.forEach(c => {
			if (c == '/')
				c = ' / ';
			else if (c == '_')
				c = ' ';
			tzNameFilterArray.push(c);
		})

		return tzNameFilterArray.join('');
	}


	/**
	 * Check if email is valid
	 * @param email
	 */
	static isEmail(email: string = null) {

		if (!email)
			return false;

		var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

		if (email.trim() != "" && (email.trim().length <= 5 || !EMAIL_REGEXP.test(email.trim()))) {
			return false;
		}
		return true;
	}


	/**
	 * Return if image exists
	 */
	static isImageExists(url) {
		var img = new Image();
		img.src = url;
		return img.height != 0;
	}


	/**
	 * Return video call url
	 * @param projectId
	 * @param name
	 * @param roomId
	 * @param invite
	 */
	static getVideoCallUrl(projectId, id, roomId, invite: boolean = false) {
		let url = `video-chat/${projectId}/${id}/${roomId}`;
		if (invite)
			url += '?invite=true';
		return url;
	}


	/**
	 * Return true if str is UUID format
	 * @param str
	 * @param version
	 */
	static isUUID(str, version = 'all') {
		const uuid = {
			3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
			4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
			5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
			all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
		};
		const pattern = uuid[version];

		return pattern && pattern.test(str);
	}

	/**
	 * Convert time (hour, min, sec) to seconds
	 * @param hour
	 * @param min
	 * @param sec
	 * @return number
	 */
	static fromTimeToSeconds(hours: string | number, minutes: string | number, seconds: string | number): number {

		hours = +hours;
		minutes = +minutes;
		seconds = +seconds;

		return (seconds + (minutes * 60) + (hours * 3600));
	}


	/**
	 * From seconds to time (hour, min, sec)
	 * @param sec
	 * @return number
	 */
	static fromSecondsToTime(durationInSeconds: string | number): any {

		let time = {
			hours: 0,
			minutes: 0,
			seconds: 0
		}

		if ("undefined" === typeof durationInSeconds)
			return time;

		else {

			// Convert string to number (if needed)
			durationInSeconds = +durationInSeconds;

			// Convert to hours, minutes and seconds
			let hours: number = Math.floor(durationInSeconds / 3600);
			var minutes: number = Math.floor((durationInSeconds - (hours * 3600)) / 60);
			var seconds = durationInSeconds - (hours * 3600) - (minutes * 60);

			time.hours = hours;
			time.minutes = minutes;
			time.seconds = seconds;

			return time;
		}
	}


	/**
	 * Return true if some text starts with another text
	 * @param str
	 * @param prefix
	 */
	static startsWith(str, prefix) {
		return str.startsWith(prefix);
	}


	/**
	 * Return true if some text ends with another text
	 * @param str
	 * @param suffix
	 */
	static endsWith(str, suffix) {
		return str.indexOf(suffix, str.length - suffix.length) !== -1;
	}

	static endsWithIgnoreCase(str, suffix) {
		return str.toLocaleLowerCase().indexOf(suffix, str.length - suffix.length) !== -1;
	}

	/**
	 * Return true if file is a video file
	 * @param file
	 */
	static isVideoFile(file) {
		if (file) {
			if (file.contentType && HelperService.startsWith(file.contentType, 'video')) {
				return true;
			}
			else if (file.type && (typeof file.type === 'string') &&
				(
					HelperService.endsWith(file.type, 'mp4')
					|| HelperService.endsWith(file.type, 'webm')
					|| HelperService.endsWith(file.type, 'mkv')
					|| HelperService.endsWith(file.type, 'flv')
					|| HelperService.endsWith(file.type, 'mov')
					|| HelperService.endsWith(file.type, 'hevc')
				)) {
				return true;
			}
			else if (file.contentType && (typeof file.contentType === 'string') &&
				(
					HelperService.endsWith(file.contentType, 'mp4')
					|| HelperService.endsWith(file.contentType, 'webm')
					|| HelperService.endsWith(file.contentType, 'mkv')
					|| HelperService.endsWith(file.contentType, 'flv')
					|| HelperService.endsWith(file.contentType, 'mov')
					|| HelperService.endsWith(file.contentType, 'hevc')
				)) {
				return true;
			}
			else if (file.publicLink &&
				(
					(
						HelperService.endsWith(file.publicLink.toLowerCase(), '.mp4')
						|| HelperService.endsWith(file.publicLink.toLowerCase(), '.webm')
						|| HelperService.endsWith(file.publicLink.toLowerCase(), '.mkv')
						|| HelperService.endsWith(file.publicLink.toLowerCase(), '.flv')
						|| HelperService.endsWith(file.publicLink.toLowerCase(), '.mov')
						|| HelperService.endsWith(file.publicLink.toLowerCase(), '.hevc')
					)
				)
			) {
				return true;
			}
			else if (file.name &&
				(
					(
						HelperService.endsWith(file.name.toLowerCase(), '.mp4')
						|| HelperService.endsWith(file.name.toLowerCase(), '.webm')
						|| HelperService.endsWith(file.name.toLowerCase(), '.mkv')
						|| HelperService.endsWith(file.name.toLowerCase(), '.flv')
						|| HelperService.endsWith(file.name.toLowerCase(), '.mov')
						|| HelperService.endsWith(file.name.toLowerCase(), '.hevc')
					)
				)) {
				return true;
			}
		}

		return false;
	}

	static isVideoFileByExtension(extension: string) {
		return HelperService.startsWith(extension, 'video');
	}


	/**
	 * Return true if file is an audio file
	 * @param file
	 */
	static isAudioFile(file) {

		if (file) {
			if (file.contentType && HelperService.startsWith(file.contentType, 'audio')) {
				return true;
			}
			else if (file.type && (typeof file.type === 'string') &&
				(
					HelperService.endsWith(file.type, 'mp3')
					|| HelperService.endsWith(file.type, 'ogg')
					|| HelperService.endsWith(file.type, 'wav')
				)) {
				return true;
			}
			else if (file.contentType && (typeof file.contentType === 'string') &&
				(
					HelperService.endsWith(file.contentType, 'mp3')
					|| HelperService.endsWith(file.contentType, 'ogg')
					|| HelperService.endsWith(file.contentType, 'wav')
				)) {
				return true;
			}
			else if (file.publicLink &&
				((HelperService.endsWith(file.publicLink.toLowerCase(), '.mp3')
					|| HelperService.endsWith(file.publicLink.toLowerCase(), '.ogg'))
					|| HelperService.endsWith(file.publicLink.toLowerCase(), '.wav'))) {
				return true;
			}
			else if (file.name &&
				((HelperService.endsWith(file.name.toLowerCase(), '.mp3')
					|| HelperService.endsWith(file.name.toLowerCase(), '.ogg'))
					|| HelperService.endsWith(file.name.toLowerCase(), '.wav'))) {
				return true;
			}
		}

		return false;
	}

	static isAudioFileByExtension(extension: string) {
		return HelperService.startsWith(extension, 'audio');
	}


	static isSupportedImageFormat(mimeType: string) {
		switch (mimeType) {
			case 'image/png':
			case 'image/jpeg':
			case 'image/gif':
			case 'image/bmp':
				return true;
			default:
				return false;
		}
	}

	/**
	 * Return true if file is an image file
	 * @param file
	 */
	static isImageFile(file) {

		// if (file) {
		// 	if (file.contentType && HelperService.startsWith(file.contentType, 'image')) {
		// 		return true;
		// 	}
		// 	else if (file.type && (typeof file.type === 'string') &&
		// 		(HelperService.endsWith(file.type.toLowerCase(), '.png')
		// 			|| HelperService.endsWith(file.type.toLowerCase(), '.jpg')
		// 			|| HelperService.endsWith(file.type.toLowerCase(), '.jpeg')
		// 			|| HelperService.endsWith(file.type.toLowerCase(), '.bmp')
		// 			|| HelperService.endsWith(file.type.toLowerCase(), '.gif'))) {
		// 		return true;
		// 	}
		// 	else if (file.contentType && (typeof file.contentType === 'string') &&
		// 		(HelperService.endsWith(file.contentType.toLowerCase(), '.png')
		// 			|| HelperService.endsWith(file.contentType.toLowerCase(), '.jpg')
		// 			|| HelperService.endsWith(file.contentType.toLowerCase(), '.jpeg')
		// 			|| HelperService.endsWith(file.contentType.toLowerCase(), '.bmp')
		// 			|| HelperService.endsWith(file.contentType.toLowerCase(), '.gif'))) {
		// 		return true;
		// 	}
		// 	else if (file.publicLink && (typeof file.publicLink === 'string') &&
		// 		(HelperService.endsWith(file.publicLink.toLowerCase(), '.png')
		// 			|| HelperService.endsWith(file.publicLink.toLowerCase(), '.jpg')
		// 			|| HelperService.endsWith(file.publicLink.toLowerCase(), '.jpeg')
		// 			|| HelperService.endsWith(file.publicLink.toLowerCase(), '.bmp')
		// 			|| HelperService.endsWith(file.publicLink.toLowerCase(), '.gif'))) {
		// 		return true;
		// 	}
		// 	else if (file.name &&
		// 		(HelperService.endsWith(file.name.toLowerCase(), '.png')
		// 			|| HelperService.endsWith(file.name.toLowerCase(), '.jpg')
		// 			|| HelperService.endsWith(file.name.toLowerCase(), '.jpeg')
		// 			|| HelperService.endsWith(file.name.toLowerCase(), '.bmp')
		// 			|| HelperService.endsWith(file.name.toLowerCase(), '.gif'))) {
		// 		return true;
		// 	}
		// }
		if (file) {
			// We use regular expressions (.test()) to check if the strings end with one of the specified file extensions.
			if (
				(file.contentType && HelperService.startsWith(file.contentType, 'image')) ||
				(file.type && typeof file.type === 'string' &&
					/\.(png|jpg|jpeg|bmp|gif)$/i.test(file.type)) ||
				(file.contentType && typeof file.contentType === 'string' &&
					/\.(png|jpg|jpeg|bmp|gif)$/i.test(file.contentType)) ||
				(file.publicLink && typeof file.publicLink === 'string' &&
					/\.(png|jpg|jpeg|bmp|gif)$/i.test(file.publicLink)) ||
				(file.name && /\.(png|jpg|jpeg|bmp|gif)$/i.test(file.name.toLowerCase()))
			) {
				return true;
			}
		}

		return false;
	}

	static isImageFileByExtension(extension: string) {
		return HelperService.startsWith(extension, 'image')
	}

	/**
	 * Return true if file is a subtitle file
	 * @param file
	 */
	static isSubtitleFile(file) {

		if (file && (file.name || file.displayName)) {
			if (HelperService.endsWith((file.name || file.displayName).toLowerCase(), '.srt')) {
				return true;
			}
		}

		return false;
	}

	static isOfficeMimeType(extension: string) {
		const mimeTypes = [
			"application/msword",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			"application/vnd.ms-powerpoint",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation",
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			'application/vnd.oasis.opendocument.presentation',
			'application/vnd.oasis.opendocument.spreadsheet',
			'application/vnd.oasis.opendocument.text'
		];

		return mimeTypes.includes(extension);
	}

	static isPowerPointMimeType(extension: string) {
		const mimeTypes = [
			"application/vnd.ms-powerpoint",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		];

		return mimeTypes.includes(extension);
	}

	static isWordMimeType(extension: string) {
		const mimeTypes = [
			"application/msword",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		];

		return mimeTypes.includes(extension);
	}

	static isExcelMimeType(extension: string) {
		const mimeTypes = [
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		];

		return mimeTypes.includes(extension);
	}

	static isOpenDocumentMimeType(extension: string) {
		const mimeTypes = [
			'application/vnd.oasis.opendocument.presentation',
			'application/vnd.oasis.opendocument.spreadsheet',
			'application/vnd.oasis.opendocument.text'
		];

		return mimeTypes.includes(extension);
	}

	static isOfficeDoc(file) {
		const embedTypes = [
			"application/msword",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			"application/vnd.ms-powerpoint",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation",
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			'application/vnd.oasis.opendocument.presentation',
			'application/vnd.oasis.opendocument.spreadsheet',
			'application/vnd.oasis.opendocument.text',
			'application/wps-office.xlsx',
			'application/wps-office.docx',
			'application/wps-office.pptx'
		];

		if (!file)
			return false;

		if (file.contentType && embedTypes.find(k => k == file.contentType))
			return true;
		else if (file.type && embedTypes.find(k => k == file.type))
			return true;
		else return false;
	}

	static isConvertibleImageFormat(file) {
		const contentTypes = [
			'image/tiff',
			'image/vnd.adobe.photoshop',
			'application/vnd.3gpp.pic-bw-small'
		]

		if (!file)
			return false;

		if (file.contentType && contentTypes.find(k => k == file.contentType))
			return true;
		else if (file.type && contentTypes.find(k => k == file.type))
			return true;
		else
			return false;
	}

	/**
	 * Return true if media file (video or audio)
	 * @param file
	 */
	static isMediaFile(file: any) {
		return (HelperService.isAudioFile(file) || HelperService.isVideoFile(file));
	}


	/**
	 * Return if filename has some extension
	 * @param filename
	 * @param extension
	 */
	static isFileExtension(filename, extension) {
		if (filename)
			return (HelperService.endsWithIgnoreCase(filename, extension))
		return false
	}


	/**
	 * Return if url is a real url link
	 * @param url
	 */
	static isUrl(url: string): boolean {
		var strRegex = "^((https|http|ftp|rtsp|mms)?://)"
			+ "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" //ftp的user@
			+ "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
			+ "|" // 允许IP和DOMAIN（域名）
			+ "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
			+ "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
			+ "[a-z]{2,6})" // first level domain- .com or .museum
			+ "(:[0-9]{1,4})?" // 端口- :80
			+ "((/?)|" // a slash isn't required if there is no file name
			+ "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
		var re = new RegExp(strRegex);
		return re.test(url);
	}


	/**
	 * Return true if valid youtube url
	 * @param link
	 */
	static isYouTubeUrl(link: string) {
		var videoid = link.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
		return (videoid != null);
	}


	/**
	 * Return true if valid vimeo url
	 * @param link
	 */
	static isVimeoUrl(link: string) {
		var vimeoid = link.match(/(videos|video|channels|\.com)\/([\d]+)/);
		return (vimeoid != null);
	}



	/**
	 * Open new popup
	 * @param url
	 */
	static openNewPopup(url) {
		const strWindowFeatures = "noopener=yes";
		window.open(url, '_blank', strWindowFeatures);
	}

	static isObjectEmpty(value: any) {
		var isEmpty = typeof value !== 'number' && (_.isUndefined(value) || _.isEmpty(value));
		return isEmpty;
	}

	static hexToRgbA(hex: any, o: any) {
		let c: any;
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('');
			if (c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c = '0x' + c.join('');
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + o + ')';
		}
		throw new Error('Bad Hex');
	}

	static getNormalizedName(name: string) {
		return _.deburr(name.toLocaleLowerCase()).replace(/['"]/g, '');
	}

	static checkFormFields(group: UntypedFormGroup) {
		Object.keys(group.controls).forEach(field => {
			const control = group.get(field);
			try {
				control.markAsTouched({ onlySelf: true });
			} catch (e) {
				console.log(`field ${field} not found`);
			}
		});
	}

	static setFormFieldsEnabled(group: UntypedFormGroup, enabled: boolean) {
		Object.keys(group.controls).forEach(field => {
			const control = group.get(field);
			try {
				if (!enabled) {
					control.disable();
				} else {
					control.enable();
				}
			} catch (e) {
				console.log(`field ${field} not found`);
			}
		});
	}

	static validateCssString(styles) {
		const orgGlobalStyle = styles;
		var style = String('{' + orgGlobalStyle + '}')
			.replace(/"/gi, "'")
			.replace(/\s+/g, ' ')
			.replace(/^{/, '{\n')
			.replace(/\}/g, '}\n')
			.replace(/\n\s*([^\{]+)\s+?\{/g, '\n"$1":{')
			.replace(/([\{;])\s*([^:"\s]+)\s*:/g, '$1"$2":')
			.replace(/":\s*([^\}\{;]+)\s*(;|(\}))/g, '":"$1",$3')
			.replace(/\}/g, '},')
			.replace(/,\s*\}/g, '}');
		style = style.substring(0, style.length - 2);
		try {
			return JSON.parse(style);
		} catch (e) {
			return void 0;
		}
	}

	static wrapStyle(jsonCss) {
		var result = '';
		for (var key in jsonCss) {
			var rules = key.split(',');
			for (var i = 0; i < rules.length; i++) {
				result += rules[i];
				result += '{';
				for (var style in jsonCss[key]) {
					result += style + ':' + jsonCss[key][style] + ';'
				}
				result += '}';
			}
		}
		return result;
	}

	static addOrRemoveStyle(action: any, id: any, styles?: any) {
		const docHead = document.head;
		if (action === 'load') {
			if (docHead) {
				let element = <any>document.getElementById(id);
				if (element == null || element == undefined) {
					element = <any>document.createElement('style');
					element.setAttribute("id", id);
					element.type = 'text/css';
					if (element.styleElement) {
						element.styleElement.cssText = styles;
					} else {
						element.appendChild(document.createTextNode(styles));
					}
					document.getElementsByTagName("head")[0].appendChild(element);
				} else {
					element.innerText = styles;
				}
			}
		} else if (action === 'remove') {
			if (docHead) {
				let styleElement = document.getElementById(id);
				if (styleElement != null && styleElement != undefined) {
					styleElement.remove();
				}
			}
		}
	};

	static deleteCookie(name: string, domain: string = '') {
		let domainStr = '';
		if (domain) {
			domainStr += `domain=${domain};`;
		}
		document.cookie = `${name}=; path=/; ${domainStr} expires=${new Date(
			0
		).toUTCString()}`
	}

	static deleteAuthCookie() {
		HelperService.deleteCookie('AUTH-TOKEN', '.mtm.video');
	}

	static getCachebusterUrl(value: string): string {
		try {
			const url = new URL(value);
			url.searchParams.set('v', (+ new Date()).toString());
			return url.toString();
		}
		catch (e) {
			return value;
		}
	}

	//this is just quick check if the image is base64 encoded
	static isBase64Image(value: string) {
		if (value && value.length > 0) {
			return value.indexOf('data:image') > -1;
		}
		return false;
	}

	//taken from https://gist.github.com/codeguy/6684588?permalink_comment_id=3332719#gistcomment-3332719
	static slugify(text: string): string {
		return text
			.toString()
			.normalize('NFD')                   // split an accented letter in the base letter and the acent
			.replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
			.toLowerCase()
			.trim()
			.replace(/\s+/g, '-')
			.replace(/[^\w\-]+/g, '')
			.replace(/\-\-+/g, '-');
	}

	static renderAgGridDate(params: any) {
		const { value } = params;
		return HelperService.formatDateBasedOnPreference(value);
	}

	static compareAgGridDate(filterLocalDateAtMidnight, cellValue) {
		const dateAsString = cellValue;
		if (dateAsString == null) {
			return -1;
		}
		const cellMoment = moment(cellValue).startOf('day');
		const filterMoment = moment(filterLocalDateAtMidnight);

		if (cellMoment.isSame(filterMoment)) {
			return 0;
		} else if (cellMoment.isBefore(filterMoment)) {
			return -1;
		}
		return 1;
	}

	static getCurrencySymbol(currency: string): string {
		const value = currency.toLowerCase();
		switch (value) {
			case 'usd':
				return '$';
			default:
				return '€';
		}
	}

	static getFileDisplayName(name: string) {
		if (!name) {
			return '';
		}
		let ext = (name.split('.').length > 1) ? name.split('.').pop() : '';
		let nameArr = name.split('_');
		let isTime = HelperService.isTimestamp(nameArr.pop().split('.')[0]);
		let savedName = '';
		if (isTime) {
			savedName = nameArr.length ? _.slice(nameArr, 0, nameArr.length - 1 >= 0 ? (nameArr.length) : 0).join('_') : name.split('.')[0];
		} else {
			let nameSplit = name.split('.');
			savedName = _.slice(nameSplit, 0, nameSplit.length - 1 > 0 ? (nameSplit.length - 1) : 1).join('.');
		}
		return savedName.replace(/[^a-zA-Z0-9-] /g, "_") + (ext && (ext !== 'undefined') ? ('.' + ext) : '');
	}

	static formatDateBasedOnPreference(value: any, defaultFormat: string = 'YYYY-MM-DD'): string {
		if (!value) {
			return '';
		}
		const date = moment(value);
		if (!date.isValid()) {
			return '';
		}

		let format: string = defaultFormat;
		let locale: string = 'en_us';

		try {
			const userSettings = JSON.parse(localStorage.getItem('auth_user_settings'));
			if (userSettings && userSettings.dateFormat) {
				format = userSettings.dateFormat;
			}
			locale = localStorage.getItem('auth_user_language');
		} catch (_) {

		}

		return date.locale(locale).format(format);
	}

	static changeBreadcrumbUrl(breadcrumbComponent: any, pathIndex: number, newUrl: string) {
		if (breadcrumbComponent?._urls?.length > pathIndex) {
			breadcrumbComponent._urls[pathIndex] = newUrl;
		}
	}

	static setBreadcrumbSegmentClickable(pathIndex: number, clickable: boolean) {
		const $links = jQuery('.main-layout-container').find('header breadcrumb .breadcrumb-item');
		if ($links.length === 0 || $links.length <= pathIndex) {
			return;
		}

		jQuery($links[pathIndex]).find('a').css('pointer-events', clickable ? 'auto' : 'none')
	}

	static getCurrentPageVersion(page: IHaveVersions): number {
		let currentVersion: number = 1;

		if (page.versions.length > 0) {
			currentVersion = page.versions[0].versionNumber;
		}

		return currentVersion;
	}

	static getOptionPreviewUrl(page: IHaveVersions, file: any): string {
		const version = HelperService.getCurrentPageVersion(page);
		return `/projects/${page.projectId}/${page.sectionId}/${page.subsectionId}/preview?directLink=true&version=${version}&fileId=${file.id}#${page.optionId}`;
	}

	static getFullCalendarLocale(language: string): string {
		switch (language) {
			case 'zh':
				return 'zh-cn';
			case 'en_us':
				return 'en';
			default:
				return language;
		}
	}

	static getDateFormatFromDays(days: number): string {
		let value = '';
		let label = '';

		if (days > 365) {
			value = (days / 365).toFixed(1).toString();
			label = 'Y';
		} else if (days > 30) {
			value = (days / 30).toFixed(1).toString();
			label = 'M';
		} else if (days > 7) {
			value = (days / 7).toFixed(1).toString();
			label = 'W';
		} else {
			value = days.toString();
			label = 'D';
		}

		value = value.replace('.0', '').replace(',', '.');

		return `${value}${label}`;
	}

	static abbreviateBudget(num, fixed): string {
		if (num === null) { return null; } // terminate early
		if (num === 0) { return '0'; } // terminate early
		fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
		var b = (num).toPrecision(2).split("e"), // get power
			k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
			c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
			d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
			e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
		return e;
	}

	static getTimeInfoFromSeconds(totalSeconds: number): TimeInfo {
		const hours = Math.floor(totalSeconds / 3600);
		const minutesRemainder = totalSeconds % 3600;
		const minutes = Math.floor(minutesRemainder / 60);
		const seconds = minutesRemainder % 60;

		return { hours, minutes, seconds };
	}

	static createCommonSvgChart(chartInfo: ChartCreationParams): ChartCreationResult {
		if ((chartInfo.isSingleData && !chartInfo.data) || (!chartInfo.isSingleData && !chartInfo.data?.length)) {
			chartInfo.chartContainer.nativeElement.replaceChildren();

			return {
				continueDrawing: false
			};
		}

		const container = d3.select(chartInfo.chartContainer.nativeElement);

		container.selectAll('svg')
			.remove();

		const svg = container.append('svg')
			.attr('width', chartInfo.chartWidth)
			.attr('height', chartInfo.chartHeight)
			.attr('preserveAspectRatio', 'xMidYMid meet')
			.style('max-width', '100%').style('max-height', '100%');

		return {
			continueDrawing: true,
			svgSelection: svg
		};
	}

	static getEmptyUUID(): string {
		return '00000000-0000-0000-0000-000000000000';
	}

	static copyToClipboard(text: string): void {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = text;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}

	static getCurrentOrigin(): string {
		return environment.isProductionSide ? `https:${environment.web.baseUrl}` : window.location.origin;
	}

  static getInitial(fullName: string): string {
    let fullNameTrimmed = fullName.trim();
    if (!fullNameTrimmed) {
      return '';
    }
    try {
      return fullNameTrimmed.trim().split(' ').filter(c => !!c.trim()).slice(0, 2).map(part => part[0]).join('').toLowerCase();
    } catch (ex) {
      return fullName.slice(0, 2).toLowerCase();
    }
  }

}

import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { NotificationService } from "./notification.service";

@Injectable({ providedIn: 'root' })
export class LorealDummyDataService {

  users: string[] = [
    'testim+scenario3.hr@mtm.video', ,
    'testim+scenario3.hr.hl@mtm.video',
    'testim+scenario4.hr@mtm.video',
    // ysl
    'testim+scenario1.ysl@mtm.video',
    'testim+scenario3.ysl@mtm.video',
    'testim+scenario3.ysl.hr.hl@mtm.video'
  ];

  constructor(private authService: AuthService,
    private notificationService: NotificationService) {
  }

  isLorealDemoUser() {
    const username = this.authService.getAuthUserName();
    return this.users.indexOf(username) > -1;
  }

  getCalendarData() {
    return [
      [
        {
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
          "startDate": 1615896000000,
          "endDate": 1615896900000,
          "id": "b9658791-612e-4221-8f9a-14cfff2c5bae",
          "description": "testigng notes.",
          "title": "huppur",
          "owner": "krishna_1170@yahoo.co.in",
          "location": null,
          "allDay": false,
          "editable": true,
          "section": "OTHER",
          "conversationId": null,
          "repeatEvent": null,
          "repeatUntil": null,
          "excludeWeekends": false,
          "occurrenceId": null,
          "occurrenceNumber": 0,
          "eventUpdateType": "THIS",
          "users": [
            "adi.winata@mtm.video",
            "adriano.dalpane@mtm.video",
            "david@sentai.eu",
            "krishna_1170@yahoo.co.in"
          ],
          "participants": [
            {
              "username": "adi.winata@mtm.video",
              "fullName": "Adi Winata",
              "email": "adi.winata@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adi.winata@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "adriano.dalpane@mtm.video",
              "fullName": "Adriano Dalpane",
              "email": "adriano.dalpane@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adriano.dalpane@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "david@sentai.eu",
              "fullName": "David Senouf",
              "email": "david@sentai.eu",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/david@sentai.eu/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "krishna_1170@yahoo.co.in",
              "fullName": "KG Krish",
              "email": "krishna.gupta@audencia.com",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/krishna_1170@yahoo.co.in/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            }
          ]
        },
        {
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
          "startDate": 1616119200000,
          "endDate": 1616159700000,
          "id": "23948830-f016-45b9-b8b0-6473673031b8",
          "description": "I am adding notes to this test event.",
          "title": "Test Event xx",
          "owner": "adi.winata@mtm.video",
          "location": null,
          "allDay": false,
          "editable": true,
          "section": "PRE_PRODUCTION",
          "conversationId": "5657c728-669e-2dfd-acab-ef8306520dbc",
          "repeatEvent": null,
          "repeatUntil": null,
          "excludeWeekends": false,
          "occurrenceId": null,
          "occurrenceNumber": 0,
          "eventUpdateType": "THIS",
          "users": [
            "adi.winata@mtm.video",
            "adriano.dalpane@mtm.video",
            "david.senouf@mtm.video",
            "krishna.gupta@mtm.video",
            "krishna_1170@yahoo.co.in",
            "nour.benammar@mtm.video",
            "punit.seelam@mtm.video"
          ],
          "participants": [
            {
              "username": "adi.winata@mtm.video",
              "fullName": "Adi Winata",
              "email": "adi.winata@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adi.winata@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "adriano.dalpane@mtm.video",
              "fullName": "Adriano Dalpane",
              "email": "adriano.dalpane@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adriano.dalpane@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "david.senouf@mtm.video",
              "fullName": "David Senouf",
              "email": "david.senouf@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/david.senouf@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "krishna_1170@yahoo.co.in",
              "fullName": "KG Krish",
              "email": "krishna.gupta@audencia.com",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/krishna_1170@yahoo.co.in/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "krishna.gupta@mtm.video",
              "fullName": "Krishna Gupta",
              "email": "krishna.gupta@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/krishna.gupta@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "nour.benammar@mtm.video",
              "fullName": "Nour Ben Ammar",
              "email": "nour.benammar@mtm.video",
              "avatarUrl": null,
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "punit.seelam@mtm.video",
              "fullName": "Punit Seelam",
              "email": "punit.seelam@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/punit.seelam@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            }
          ]
        },
        {
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
          "startDate": 1616742000000,
          "endDate": 1616793300000,
          "id": "2abf8cea-6d60-4496-b8dd-20ed9693244a",
          "description": "conv4",
          "title": "testtt",
          "owner": "krishna.gupta@mtm.video",
          "location": null,
          "allDay": false,
          "editable": true,
          "section": "CREATIVE_BRIEF",
          "conversationId": "27d926be-52c5-af47-9892-47ae1f4bce16",
          "repeatEvent": null,
          "repeatUntil": null,
          "excludeWeekends": false,
          "occurrenceId": null,
          "occurrenceNumber": 0,
          "eventUpdateType": "THIS",
          "users": [
            "adi.winata@mtm.video",
            "adriano.dalpane@mtm.video",
            "krishna.gupta@mtm.video",
            "krishna_1170@yahoo.co.in"
          ],
          "participants": [
            {
              "username": "adi.winata@mtm.video",
              "fullName": "Adi Winata",
              "email": "adi.winata@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adi.winata@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "adriano.dalpane@mtm.video",
              "fullName": "Adriano Dalpane",
              "email": "adriano.dalpane@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adriano.dalpane@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "krishna_1170@yahoo.co.in",
              "fullName": "KG Krish",
              "email": "krishna.gupta@audencia.com",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/krishna_1170@yahoo.co.in/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "krishna.gupta@mtm.video",
              "fullName": "Krishna Gupta",
              "email": "krishna.gupta@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/krishna.gupta@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            }
          ]
        },
        {
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
          "startDate": 1657069200000,
          "endDate": 1657072800000,
          "id": "cd9d3d4b-f074-40cf-97aa-7ab71a23aedf",
          "description": "",
          "title": "Fix second task",
          "owner": "krishna.gupta@mtm.video",
          "location": null,
          "allDay": false,
          "editable": true,
          "section": "OTHER",
          "conversationId": null,
          "repeatEvent": null,
          "repeatUntil": null,
          "excludeWeekends": false,
          "occurrenceId": null,
          "occurrenceNumber": 0,
          "eventUpdateType": "THIS",
          "users": [
            "adi.winata@mtm.video",
            "anton.setiawan@mtm.video",
            "krishna.gupta@mtm.video"
          ],
          "participants": [
            {
              "username": "adi.winata@mtm.video",
              "fullName": "Adi Winata",
              "email": "adi.winata@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adi.winata@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "anton.setiawan@mtm.video",
              "fullName": "Anton Setiawan",
              "email": "anton.setiawan@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/anton.setiawan@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "krishna.gupta@mtm.video",
              "fullName": "Krishnaa Guptaa",
              "email": "krishna.gupta@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/krishna.gupta@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            }
          ]
        },
        {
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
          "startDate": 1658278800000,
          "endDate": 1658282400000,
          "id": "e0b98486-89aa-465c-a75c-59836057c46f",
          "description": "",
          "title": "test new",
          "owner": "krishna.gupta@mtm.video",
          "location": null,
          "allDay": false,
          "editable": true,
          "section": "OTHER",
          "conversationId": null,
          "repeatEvent": null,
          "repeatUntil": null,
          "excludeWeekends": false,
          "occurrenceId": null,
          "occurrenceNumber": 0,
          "eventUpdateType": "THIS",
          "users": [
            "adi.winata@mtm.video",
            "krishna.gupta@mtm.video"
          ],
          "participants": [
            {
              "username": "adi.winata@mtm.video",
              "fullName": "Adi Winata",
              "email": "adi.winata@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adi.winata@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "krishna.gupta@mtm.video",
              "fullName": "Krishnaa Guptaa",
              "email": "krishna.gupta@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/krishna.gupta@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            }
          ]
        },
        {
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
          "startDate": 1674781200000,
          "endDate": 1674784800000,
          "id": "82196d6f-8ae6-4ee3-aed0-fe6625eaa720",
          "description": "",
          "title": "New event",
          "owner": "adi.winata@mtm.video",
          "location": null,
          "allDay": false,
          "editable": true,
          "section": "OTHER",
          "conversationId": "7295cf04-1cb5-2305-8af0-1922ff9c3fa6",
          "repeatEvent": null,
          "repeatUntil": null,
          "excludeWeekends": false,
          "occurrenceId": null,
          "occurrenceNumber": 0,
          "eventUpdateType": "THIS",
          "users": [
            "adi.winata@mtm.video",
            "anton.setiawan@mtm.video"
          ],
          "participants": [
            {
              "username": "adi.winata@mtm.video",
              "fullName": "Adi Winata",
              "email": "adi.winata@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adi.winata@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "anton.setiawan@mtm.video",
              "fullName": "Anton Setiawan",
              "email": "anton.setiawan@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/anton.setiawan@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            }
          ]
        },
        {
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
          "startDate": 1674867600000,
          "endDate": 1674871200000,
          "id": "726a1db8-6997-493c-b12b-cebb0c7caa3a",
          "description": "",
          "title": "Test",
          "owner": "adi.winata@mtm.video",
          "location": null,
          "allDay": false,
          "editable": true,
          "section": "OTHER",
          "conversationId": "f1a57689-776f-ff1d-2a84-5a4100fa1eeb",
          "repeatEvent": null,
          "repeatUntil": null,
          "excludeWeekends": false,
          "occurrenceId": null,
          "occurrenceNumber": 0,
          "eventUpdateType": "THIS",
          "users": [
            "adi.winata@mtm.video"
          ],
          "participants": [
            {
              "username": "adi.winata@mtm.video",
              "fullName": "Adi Winata",
              "email": "adi.winata@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adi.winata@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            }
          ]
        },
        {
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
          "startDate": 1676422800000,
          "endDate": 1676426400000,
          "id": "5399da42-2a62-4204-9123-81c8b206a9a5",
          "description": "",
          "title": "Event 2 ",
          "owner": "adi.winata@mtm.video",
          "location": null,
          "allDay": false,
          "editable": true,
          "section": "OTHER",
          "conversationId": "a53df79f-0aab-0ad4-5254-cb6c33de8a52",
          "repeatEvent": null,
          "repeatUntil": null,
          "excludeWeekends": false,
          "occurrenceId": null,
          "occurrenceNumber": 0,
          "eventUpdateType": "THIS",
          "users": [
            "adi.winata@mtm.video",
            "anton.setiawan@mtm.video"
          ],
          "participants": [
            {
              "username": "adi.winata@mtm.video",
              "fullName": "Adi Winata",
              "email": "adi.winata@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adi.winata@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "anton.setiawan@mtm.video",
              "fullName": "Anton Setiawan",
              "email": "anton.setiawan@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/anton.setiawan@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            }
          ]
        },
        {
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
          "startDate": 1683637200000,
          "endDate": 1683645300000,
          "id": "203798fb-126c-4fc3-9541-aadd06d9e73b",
          "description": "description",
          "title": "Push Event to google Calendar",
          "owner": "nour.benammar@mtm.video",
          "location": null,
          "allDay": false,
          "editable": true,
          "section": "OTHER",
          "conversationId": "db21bad4-8def-6df0-65d0-7ae9a5acd0f2",
          "repeatEvent": null,
          "repeatUntil": null,
          "excludeWeekends": false,
          "occurrenceId": null,
          "occurrenceNumber": 0,
          "eventUpdateType": "THIS",
          "users": [
            "adi.winata@mtm.video",
            "nour.benammar@mtm.video"
          ],
          "participants": [
            {
              "username": "adi.winata@mtm.video",
              "fullName": "Adi Winata",
              "email": "adi.winata@mtm.video",
              "avatarUrl": "https://storage.googleapis.com/mtmvideo-dev-v2/adi.winata@mtm.video/avatar",
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            },
            {
              "username": "nour.benammar@mtm.video",
              "fullName": "Nour Ben Ammar",
              "email": "nour.benammar@mtm.video",
              "avatarUrl": null,
              "roles": [

              ],
              "rsvp": "YES",
              "blocked": false
            }
          ]
        }
      ],
      [
        {
          "phase": "PRODUCTION",
          "section": "PRODUCTION_BRIEF",
          "projectSectionKey": {
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "id": "04251dea-0607-4b66-a56d-ef69146c41cf"
          },
          "name": "Production Brief",
          "description": null,
          "orderNumber": 4,
          "startDate": null,
          "endDate": null,
          "id": "04251dea-0607-4b66-a56d-ef69146c41cf",
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647"
        },
        {
          "phase": "POST_PRODUCTION",
          "section": "POST_PRODUCTION",
          "projectSectionKey": {
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "id": "3782504c-e005-4024-b7c8-35dc269dfee3"
          },
          "name": "Post-Production",
          "description": null,
          "orderNumber": 8,
          "startDate": 1637836312000,
          "endDate": 1637836312000,
          "id": "3782504c-e005-4024-b7c8-35dc269dfee3",
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647"
        },
        {
          "phase": "PRODUCTION",
          "section": "PRODUCTION_PARTNER_SELECTION",
          "projectSectionKey": {
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "id": "551cd834-8881-480f-8cf0-b2433c6f3a29"
          },
          "name": "Production Partner Selection",
          "description": null,
          "orderNumber": 3,
          "startDate": null,
          "endDate": null,
          "id": "551cd834-8881-480f-8cf0-b2433c6f3a29",
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647"
        },
        {
          "phase": "CREATIVE",
          "section": "CREATIVE_WORK",
          "projectSectionKey": {
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "id": "62ca40ec-53e2-4f44-8c37-ff8c3b1db324"
          },
          "name": "Creative Work",
          "description": null,
          "orderNumber": 2,
          "startDate": null,
          "endDate": null,
          "id": "62ca40ec-53e2-4f44-8c37-ff8c3b1db324",
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647"
        },
        {
          "phase": "DIFFUSION",
          "section": "DIFFUSION",
          "projectSectionKey": {
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "id": "7dea2f77-150a-4f18-bb7c-9ec1db42661c"
          },
          "name": "Diffusion",
          "description": null,
          "orderNumber": 9,
          "startDate": null,
          "endDate": null,
          "id": "7dea2f77-150a-4f18-bb7c-9ec1db42661c",
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647"
        },
        {
          "phase": "CREATIVE",
          "section": "CREATIVE_BRIEF",
          "projectSectionKey": {
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "id": "91a361c8-c077-443f-a7c4-6c75282327a9"
          },
          "name": "Creative Brief",
          "description": null,
          "orderNumber": 1,
          "startDate": null,
          "endDate": null,
          "id": "91a361c8-c077-443f-a7c4-6c75282327a9",
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647"
        },
        {
          "phase": "PRODUCTION",
          "section": "PRODUCTION_COMPANY_PROPOSALS",
          "projectSectionKey": {
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "id": "9349690e-fc7c-4a32-9aae-a478f05deaad"
          },
          "name": "Production Company Proposals",
          "description": null,
          "orderNumber": 5,
          "startDate": 1669852800000,
          "endDate": 1733097599000,
          "id": "9349690e-fc7c-4a32-9aae-a478f05deaad",
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647"
        },
        {
          "phase": "CREATIVE",
          "section": "CREATIVE_PARTNER_SELECTION",
          "projectSectionKey": {
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "id": "a814508e-61c1-4865-a643-9a0a4714e17b"
          },
          "name": "Creative Partner Selection",
          "description": null,
          "orderNumber": 0,
          "startDate": null,
          "endDate": null,
          "id": "a814508e-61c1-4865-a643-9a0a4714e17b",
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647"
        },
        {
          "phase": "PRODUCTION",
          "section": "PRE_PRODUCTION",
          "projectSectionKey": {
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "id": "bded5b86-9c11-473d-b3c2-39bc11e96986"
          },
          "name": "Pre-Production",
          "description": null,
          "orderNumber": 6,
          "startDate": 1677715200000,
          "endDate": 1679615999000,
          "id": "bded5b86-9c11-473d-b3c2-39bc11e96986",
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647"
        },
        {
          "phase": "PRODUCTION",
          "section": "SHOOTING",
          "projectSectionKey": {
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "id": "c705e083-be1b-4659-970b-6c2aa98cde20"
          },
          "name": "Shooting",
          "description": "Shooting",
          "orderNumber": 8,
          "startDate": null,
          "endDate": null,
          "id": "c705e083-be1b-4659-970b-6c2aa98cde20",
          "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647"
        }
      ],
      [
        {
          "projectSubSectionKey": {
            "projectSectionId": "04251dea-0607-4b66-a56d-ef69146c41cf",
            "id": "95d16bde-3125-4eb0-b4bd-71e915750c67"
          },
          "name": "Upload Brief",
          "phase": "PRODUCTION",
          "subSection": "UPLOAD_BRIEF",
          "section": "PRODUCTION_BRIEF",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 100,
          "mediaType": null,
          "id": "95d16bde-3125-4eb0-b4bd-71e915750c67",
          "projectSectionId": "04251dea-0607-4b66-a56d-ef69146c41cf"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "04251dea-0607-4b66-a56d-ef69146c41cf",
            "id": "a97ad7d1-0c78-41e7-83d2-7618de1e2b00"
          },
          "name": "Q&a",
          "phase": "PRODUCTION",
          "subSection": "Q&A",
          "section": "PRODUCTION_BRIEF",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 102,
          "mediaType": null,
          "id": "a97ad7d1-0c78-41e7-83d2-7618de1e2b00",
          "projectSectionId": "04251dea-0607-4b66-a56d-ef69146c41cf"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3",
            "id": "067def88-ceb9-4db2-9d55-624f1df8a095"
          },
          "name": "Subtitles",
          "phase": "POST_PRODUCTION",
          "subSection": "SUBTITLES",
          "section": "POST_PRODUCTION",
          "description": null,
          "startDate": 1617062400000,
          "endDate": 1619654400000,
          "orderNumber": 107,
          "mediaType": null,
          "id": "067def88-ceb9-4db2-9d55-624f1df8a095",
          "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3",
            "id": "140ee5dc-6712-4bdc-bfcd-ed1e2fe85433"
          },
          "name": "Voice Over Recording",
          "phase": "POST_PRODUCTION",
          "subSection": "VOICE_OVER_RECORDING",
          "section": "POST_PRODUCTION",
          "description": null,
          "startDate": 1606176000000,
          "endDate": 1606694400000,
          "orderNumber": 107,
          "mediaType": null,
          "id": "140ee5dc-6712-4bdc-bfcd-ed1e2fe85433",
          "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3",
            "id": "4f909cf2-8672-44fa-906c-7c00506747f3"
          },
          "name": "Music  Selection",
          "phase": "POST_PRODUCTION",
          "subSection": "MUSIC SELECTION",
          "section": "POST_PRODUCTION",
          "description": null,
          "startDate": 1605657600000,
          "endDate": 1606694400000,
          "orderNumber": 104,
          "mediaType": null,
          "id": "4f909cf2-8672-44fa-906c-7c00506747f3",
          "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3",
            "id": "4fe87629-7447-47d0-92fd-67fece1f1b4c"
          },
          "name": "Editing",
          "phase": "POST_PRODUCTION",
          "subSection": "EDITING",
          "section": "POST_PRODUCTION",
          "description": null,
          "startDate": 1601596800000,
          "endDate": 1603238400000,
          "orderNumber": 101,
          "mediaType": null,
          "id": "4fe87629-7447-47d0-92fd-67fece1f1b4c",
          "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3",
            "id": "619a4af1-1ac7-4972-a4de-80db0a58b714"
          },
          "name": "Voice Over Casting",
          "phase": "POST_PRODUCTION",
          "subSection": "VOICE_OVER_CASTING",
          "section": "POST_PRODUCTION",
          "description": null,
          "startDate": 1605571200000,
          "endDate": 1606694400000,
          "orderNumber": 106,
          "mediaType": null,
          "id": "619a4af1-1ac7-4972-a4de-80db0a58b714",
          "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3",
            "id": "88d44852-3bdb-4b65-afec-88431a94294f"
          },
          "name": "Color Grading",
          "phase": "POST_PRODUCTION",
          "subSection": "COLOR_GRADING",
          "section": "POST_PRODUCTION",
          "description": null,
          "startDate": 1605571200000,
          "endDate": 1606262400000,
          "orderNumber": 102,
          "mediaType": null,
          "id": "88d44852-3bdb-4b65-afec-88431a94294f",
          "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3",
            "id": "8ff93ab1-18c0-420c-86bc-6c2f85dc24a6"
          },
          "name": "Digital Effects",
          "phase": "POST_PRODUCTION",
          "subSection": "DIGITAL_EFFECTS",
          "section": "POST_PRODUCTION",
          "description": null,
          "startDate": 1605052800000,
          "endDate": 1606694400000,
          "orderNumber": 103,
          "mediaType": null,
          "id": "8ff93ab1-18c0-420c-86bc-6c2f85dc24a6",
          "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3",
            "id": "b1e82177-21ab-4c4b-9381-a99a1b8df795"
          },
          "name": "Sound Design",
          "phase": "POST_PRODUCTION",
          "subSection": "SOUND_DESIGN",
          "section": "POST_PRODUCTION",
          "description": null,
          "startDate": 1605571200000,
          "endDate": 1606694400000,
          "orderNumber": 103,
          "mediaType": null,
          "id": "b1e82177-21ab-4c4b-9381-a99a1b8df795",
          "projectSectionId": "3782504c-e005-4024-b7c8-35dc269dfee3"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "551cd834-8881-480f-8cf0-b2433c6f3a29",
            "id": "4eeaf893-95a1-4705-b709-c5114b3ac47b"
          },
          "name": "Company Profile Suggestions",
          "phase": "PRODUCTION",
          "subSection": "COMPANY_PROFILE_SUGGESTIONS",
          "section": "PRODUCTION_PARTNER_SELECTION",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 13,
          "mediaType": null,
          "id": "4eeaf893-95a1-4705-b709-c5114b3ac47b",
          "projectSectionId": "551cd834-8881-480f-8cf0-b2433c6f3a29"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "551cd834-8881-480f-8cf0-b2433c6f3a29",
            "id": "85d0f06d-a8fb-4bae-82f2-93a9040fea06"
          },
          "name": "Content",
          "phase": "PRODUCTION",
          "subSection": "CONTENT",
          "section": "PRODUCTION_PARTNER_SELECTION",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 11,
          "mediaType": null,
          "id": "85d0f06d-a8fb-4bae-82f2-93a9040fea06",
          "projectSectionId": "551cd834-8881-480f-8cf0-b2433c6f3a29"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "551cd834-8881-480f-8cf0-b2433c6f3a29",
            "id": "c7dad23a-719e-4f48-ad89-348bf174c300"
          },
          "name": "Schedule & Pricing",
          "phase": "PRODUCTION",
          "subSection": "SCHEDULE_&_PRICING",
          "section": "PRODUCTION_PARTNER_SELECTION",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 12,
          "mediaType": null,
          "id": "c7dad23a-719e-4f48-ad89-348bf174c300",
          "projectSectionId": "551cd834-8881-480f-8cf0-b2433c6f3a29"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "62ca40ec-53e2-4f44-8c37-ff8c3b1db324",
            "id": "97d44171-d327-485a-a93a-e762a2d2a127"
          },
          "name": "Presentation 2",
          "phase": "CREATIVE",
          "subSection": "PRESENTATION_2",
          "section": "CREATIVE_WORK",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 9,
          "mediaType": null,
          "id": "97d44171-d327-485a-a93a-e762a2d2a127",
          "projectSectionId": "62ca40ec-53e2-4f44-8c37-ff8c3b1db324"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "62ca40ec-53e2-4f44-8c37-ff8c3b1db324",
            "id": "c97ff5b7-66ba-446b-804e-5787f6ad9ab2"
          },
          "name": "Presentation 1",
          "phase": "CREATIVE",
          "subSection": "PRESENTATION_1",
          "section": "CREATIVE_WORK",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 8,
          "mediaType": null,
          "id": "c97ff5b7-66ba-446b-804e-5787f6ad9ab2",
          "projectSectionId": "62ca40ec-53e2-4f44-8c37-ff8c3b1db324"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "62ca40ec-53e2-4f44-8c37-ff8c3b1db324",
            "id": "f83e6adb-5f59-4842-aaaf-fe64378131e3"
          },
          "name": "Presentation 3",
          "phase": "CREATIVE",
          "subSection": "PRESENTATION_3",
          "section": "CREATIVE_WORK",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 10,
          "mediaType": null,
          "id": "f83e6adb-5f59-4842-aaaf-fe64378131e3",
          "projectSectionId": "62ca40ec-53e2-4f44-8c37-ff8c3b1db324"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "7dea2f77-150a-4f18-bb7c-9ec1db42661c",
            "id": "90f84628-820e-4101-869e-f4cf0e15d41f"
          },
          "name": "Project Deliverables",
          "phase": "DIFFUSION",
          "subSection": "PROJECT_DELIVERABLES",
          "section": "DIFFUSION",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 44,
          "mediaType": null,
          "id": "90f84628-820e-4101-869e-f4cf0e15d41f",
          "projectSectionId": "7dea2f77-150a-4f18-bb7c-9ec1db42661c"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "7dea2f77-150a-4f18-bb7c-9ec1db42661c",
            "id": "9149a6a5-983d-44cb-bb7c-86bdde90fd0d"
          },
          "name": "Format Request",
          "phase": "DIFFUSION",
          "subSection": "FORMAT_REQUEST",
          "section": "DIFFUSION",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 42,
          "mediaType": null,
          "id": "9149a6a5-983d-44cb-bb7c-86bdde90fd0d",
          "projectSectionId": "7dea2f77-150a-4f18-bb7c-9ec1db42661c"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "7dea2f77-150a-4f18-bb7c-9ec1db42661c",
            "id": "e6c46f16-fad4-4901-88bb-c3506bcd8064"
          },
          "name": "Exports",
          "phase": "DIFFUSION",
          "subSection": "EXPORTS",
          "section": "DIFFUSION",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 43,
          "mediaType": null,
          "id": "e6c46f16-fad4-4901-88bb-c3506bcd8064",
          "projectSectionId": "7dea2f77-150a-4f18-bb7c-9ec1db42661c"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "91a361c8-c077-443f-a7c4-6c75282327a9",
            "id": "29d0b797-a749-46ee-8aa7-19cff0eefd6b"
          },
          "name": "Q&a Session",
          "phase": "CREATIVE",
          "subSection": "Q&A_SESSION",
          "section": "CREATIVE_BRIEF",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 7,
          "mediaType": null,
          "id": "29d0b797-a749-46ee-8aa7-19cff0eefd6b",
          "projectSectionId": "91a361c8-c077-443f-a7c4-6c75282327a9"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "91a361c8-c077-443f-a7c4-6c75282327a9",
            "id": "865725eb-0afd-4a30-aac1-4f03666751c7"
          },
          "name": "Creative Brief (presentation)",
          "phase": "CREATIVE",
          "subSection": "CREATIVE_BRIEF(PRESENTATION)",
          "section": "CREATIVE_BRIEF",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 6,
          "mediaType": null,
          "id": "865725eb-0afd-4a30-aac1-4f03666751c7",
          "projectSectionId": "91a361c8-c077-443f-a7c4-6c75282327a9"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "91a361c8-c077-443f-a7c4-6c75282327a9",
            "id": "a0a48783-d0e7-486b-a203-5f30ab3e3e6b"
          },
          "name": "Creative Brief (upload)",
          "phase": "CREATIVE",
          "subSection": "CREATIVE_BRIEF(UPLOAD)",
          "section": "CREATIVE_BRIEF",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 5,
          "mediaType": null,
          "id": "a0a48783-d0e7-486b-a203-5f30ab3e3e6b",
          "projectSectionId": "91a361c8-c077-443f-a7c4-6c75282327a9"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "9349690e-fc7c-4a32-9aae-a478f05deaad",
            "id": "1d61aab6-b3de-49c2-b74f-a5bf2c6acdb9"
          },
          "name": "Proposals & Discussion",
          "phase": "PRODUCTION",
          "subSection": "PROPOSALS_&_DISCUSSION",
          "section": "PRODUCTION_COMPANY_PROPOSALS",
          "description": null,
          "startDate": 1669852800000,
          "endDate": 1733011200000,
          "orderNumber": 16,
          "mediaType": null,
          "id": "1d61aab6-b3de-49c2-b74f-a5bf2c6acdb9",
          "projectSectionId": "9349690e-fc7c-4a32-9aae-a478f05deaad"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "a814508e-61c1-4865-a643-9a0a4714e17b",
            "id": "5ed7359b-d50a-4621-a818-7d9500159141"
          },
          "name": "Creative Profile",
          "phase": "CREATIVE",
          "subSection": "CREATIVE_PROFILE",
          "section": "CREATIVE_PARTNER_SELECTION",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 2,
          "mediaType": null,
          "id": "5ed7359b-d50a-4621-a818-7d9500159141",
          "projectSectionId": "a814508e-61c1-4865-a643-9a0a4714e17b"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "a814508e-61c1-4865-a643-9a0a4714e17b",
            "id": "a8c264de-62b5-4aed-9e9c-de8b60d77823"
          },
          "name": "Creative Profile Suggestions",
          "phase": "CREATIVE",
          "subSection": "CREATIVE_PROFILE_SUGGESTIONS",
          "section": "CREATIVE_PARTNER_SELECTION",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 4,
          "mediaType": null,
          "id": "a8c264de-62b5-4aed-9e9c-de8b60d77823",
          "projectSectionId": "a814508e-61c1-4865-a643-9a0a4714e17b"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "a814508e-61c1-4865-a643-9a0a4714e17b",
            "id": "d1a10886-7bf0-4c61-b23b-2e74f4945068"
          },
          "name": "Schedule & Pricing",
          "phase": "CREATIVE",
          "subSection": "SCHEDULE_&_PRICING",
          "section": "CREATIVE_PARTNER_SELECTION",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 3,
          "mediaType": null,
          "id": "d1a10886-7bf0-4c61-b23b-2e74f4945068",
          "projectSectionId": "a814508e-61c1-4865-a643-9a0a4714e17b"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "a814508e-61c1-4865-a643-9a0a4714e17b",
            "id": "d434553b-3b30-4b05-bfce-64dab98841c7"
          },
          "name": "Content Type",
          "phase": "CREATIVE",
          "subSection": "CONTENT_TYPE",
          "section": "CREATIVE_PARTNER_SELECTION",
          "description": null,
          "startDate": null,
          "endDate": null,
          "orderNumber": 1,
          "mediaType": null,
          "id": "d434553b-3b30-4b05-bfce-64dab98841c7",
          "projectSectionId": "a814508e-61c1-4865-a643-9a0a4714e17b"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "1c7d472e-e51e-4f48-beb5-c7b7fc1a1e6b"
          },
          "name": "Stylism / Costume Design",
          "phase": "PRODUCTION",
          "subSection": "STYLISM_/_COSTUME_DESIGN",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1605571200000,
          "endDate": 1606694400000,
          "orderNumber": 106,
          "mediaType": null,
          "id": "1c7d472e-e51e-4f48-beb5-c7b7fc1a1e6b",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "27fd0353-ae79-4cc6-85d2-f59308dfb6e6"
          },
          "name": "Set Design",
          "phase": "PRODUCTION",
          "subSection": "SET_DESIGN",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1677715200000,
          "endDate": 1679529600000,
          "orderNumber": 111,
          "mediaType": null,
          "id": "27fd0353-ae79-4cc6-85d2-f59308dfb6e6",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "2b37e288-5bf3-4e9e-8e1d-a7acaba4b2c1"
          },
          "name": "Brief Animatic 3d",
          "phase": "PRODUCTION",
          "subSection": "ANIMATIC_3D",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1601424000000,
          "endDate": 1651536000000,
          "orderNumber": 102,
          "mediaType": null,
          "id": "2b37e288-5bf3-4e9e-8e1d-a7acaba4b2c1",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "36745019-4643-4dfb-8113-29de1425a762"
          },
          "name": "Location",
          "phase": "PRODUCTION",
          "subSection": "LOCATION",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1604275200000,
          "endDate": 1606348800000,
          "orderNumber": 107,
          "mediaType": null,
          "id": "36745019-4643-4dfb-8113-29de1425a762",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "83919c6e-66a8-4871-b2c9-3b383eefee06"
          },
          "name": "Brief Animatic 2d",
          "phase": "PRODUCTION",
          "subSection": "Brief ANIMATIC_2D",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1601510400000,
          "endDate": 1603152000000,
          "orderNumber": 101,
          "mediaType": null,
          "id": "83919c6e-66a8-4871-b2c9-3b383eefee06",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "8edef6a7-d624-4539-8fad-041c119cc298"
          },
          "name": "Hair Dresser",
          "phase": "PRODUCTION",
          "subSection": "HAIR_DRESSER",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1674172800000,
          "endDate": 1674172800000,
          "orderNumber": 109,
          "mediaType": null,
          "id": "8edef6a7-d624-4539-8fad-041c119cc298",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "b1481bc7-cfdc-4120-86b4-ffeba9fcc916"
          },
          "name": "Storyboard",
          "phase": "PRODUCTION",
          "subSection": "STORYBOARD",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1615334400000,
          "endDate": 1620518400000,
          "orderNumber": 105,
          "mediaType": null,
          "id": "b1481bc7-cfdc-4120-86b4-ffeba9fcc916",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "b2aba530-67ed-4e60-bcbb-a7c389c75195"
          },
          "name": "Music Brief",
          "phase": "PRODUCTION",
          "subSection": "MUSIC_SELECTION",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1674172800000,
          "endDate": 1674259200000,
          "orderNumber": 110,
          "mediaType": null,
          "id": "b2aba530-67ed-4e60-bcbb-a7c389c75195",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "be7ad184-a3db-4789-aef1-3bd5c05b2c50"
          },
          "name": "Shooting Board",
          "phase": "PRODUCTION",
          "subSection": "SHOOTING_BOARD",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1601510400000,
          "endDate": 1643500800000,
          "orderNumber": 104,
          "mediaType": null,
          "id": "be7ad184-a3db-4789-aef1-3bd5c05b2c50",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "deb47478-b755-4a60-9dca-db43395fce9d"
          },
          "name": "Make-up",
          "phase": "PRODUCTION",
          "subSection": "MAKE-UP",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1605830400000,
          "endDate": 1606435200000,
          "orderNumber": 103,
          "mediaType": null,
          "id": "deb47478-b755-4a60-9dca-db43395fce9d",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "id": "fa0a4d64-fabc-4a45-8929-bfda8df2a8d0"
          },
          "name": "Casting",
          "phase": "PRODUCTION",
          "subSection": "CASTING",
          "section": "PRE_PRODUCTION",
          "description": null,
          "startDate": 1605571200000,
          "endDate": 1606348800000,
          "orderNumber": 106,
          "mediaType": null,
          "id": "fa0a4d64-fabc-4a45-8929-bfda8df2a8d0",
          "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "c705e083-be1b-4659-970b-6c2aa98cde20",
            "id": "03e0b79b-820d-450d-9ffc-af0e98deb485"
          },
          "name": "Test test",
          "phase": "PRODUCTION",
          "subSection": "SCENES",
          "section": "SHOOTING",
          "description": "ok",
          "startDate": null,
          "endDate": null,
          "orderNumber": 91,
          "mediaType": null,
          "id": "03e0b79b-820d-450d-9ffc-af0e98deb485",
          "projectSectionId": "c705e083-be1b-4659-970b-6c2aa98cde20"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "c705e083-be1b-4659-970b-6c2aa98cde20",
            "id": "3252da9c-f974-4c5c-b803-9dfdc64b2832"
          },
          "name": "VP Scene 1",
          "phase": "PRODUCTION",
          "subSection": "SCENES",
          "section": "SHOOTING",
          "description": "aaa",
          "startDate": null,
          "endDate": null,
          "orderNumber": 3,
          "mediaType": null,
          "id": "3252da9c-f974-4c5c-b803-9dfdc64b2832",
          "projectSectionId": "c705e083-be1b-4659-970b-6c2aa98cde20"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "c705e083-be1b-4659-970b-6c2aa98cde20",
            "id": "68e2c0c3-d2d2-4ff6-85bc-7b5d3d424878"
          },
          "name": "Test scene 5",
          "phase": "PRODUCTION",
          "subSection": "SCENES",
          "section": "SHOOTING",
          "description": "n.A",
          "startDate": null,
          "endDate": null,
          "orderNumber": 86,
          "mediaType": null,
          "id": "68e2c0c3-d2d2-4ff6-85bc-7b5d3d424878",
          "projectSectionId": "c705e083-be1b-4659-970b-6c2aa98cde20"
        },
        {
          "projectSubSectionKey": {
            "projectSectionId": "c705e083-be1b-4659-970b-6c2aa98cde20",
            "id": "b79184fa-791b-4b8d-8d09-9849992e0d42"
          },
          "name": "Scene 4",
          "phase": "PRODUCTION",
          "subSection": "SCENES",
          "section": "SHOOTING",
          "description": "N.A",
          "startDate": null,
          "endDate": null,
          "orderNumber": 84,
          "mediaType": null,
          "id": "b79184fa-791b-4b8d-8d09-9849992e0d42",
          "projectSectionId": "c705e083-be1b-4659-970b-6c2aa98cde20"
        }
      ],
      {
        "pagingState": 1,
        "hasNext": false,
        "data": [
          {
            "campaignId": "00000000-0000-0000-0000-000000000000",
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "projectSectionId": "00000000-0000-0000-0000-000000000000",
            "projectSubSectionId": "00000000-0000-0000-0000-000000000000",
            "id": "1c70cb3a-fe33-4229-8451-1d57fb4427a6",
            "topicId": "00000000-0000-0000-0000-000000000000",
            "optionId": "00000000-0000-0000-0000-000000000000",
            "title": "Handle for transcription",
            "description": null,
            "assetsVersions": null,
            "priority": "highest",
            "status": "DELAYED",
            "creationDate": 1686824501008,
            "startDate": 1686787200000,
            "dueDate": 1687478400000,
            "creator": "adi.winata@mtm.video",
            "assignee": "adi.winata@mtm.video",
            "collaborators": [
              "adi.winata@mtm.video"
            ],
            "projectSectionName": "",
            "projectSubSectionName": ""
          },
          {
            "campaignId": "00000000-0000-0000-0000-000000000000",
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "projectSectionId": "00000000-0000-0000-0000-000000000000",
            "projectSubSectionId": "00000000-0000-0000-0000-000000000000",
            "id": "293f31b1-31dd-4ddb-9594-bc84306c08cb",
            "topicId": "00000000-0000-0000-0000-000000000000",
            "optionId": "00000000-0000-0000-0000-000000000000",
            "title": "Handle for transcription 2",
            "description": "test",
            "assetsVersions": null,
            "priority": "highest",
            "status": "TO_COME",
            "creationDate": 1686861311617,
            "startDate": 1686009600000,
            "dueDate": 1687996800000,
            "creator": "david.senouf@mtm.video",
            "assignee": "david.senouf@hotmail.com",
            "collaborators": [
              "adi.winata@mtm.video",
              "ardiansyah@mtm.video",
              "david.senouf@hotmail.com"
            ],
            "projectSectionName": "",
            "projectSubSectionName": ""
          },
          {
            "campaignId": "00000000-0000-0000-0000-000000000000",
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "projectSectionId": "00000000-0000-0000-0000-000000000000",
            "projectSubSectionId": "00000000-0000-0000-0000-000000000000",
            "id": "907dbd58-2bc1-42c1-8e4e-101905ce65ff",
            "topicId": "00000000-0000-0000-0000-000000000000",
            "optionId": "00000000-0000-0000-0000-000000000000",
            "title": "Dummy task",
            "description": null,
            "assetsVersions": null,
            "priority": "highest",
            "status": "DELAYED",
            "creationDate": 1686824809333,
            "startDate": 1686787200000,
            "dueDate": 1686960000000,
            "creator": "adi.winata@mtm.video",
            "assignee": "adi.winata@mtm.video",
            "collaborators": [
              "adi.winata@mtm.video",
              "admin@mtm.video",
              "anton.setiawan@mtm.video",
              "ardiansyah@mtm.video",
              "camille.marie@mtm.video"
            ],
            "projectSectionName": "",
            "projectSubSectionName": ""
          },
          {
            "campaignId": "00000000-0000-0000-0000-000000000000",
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "projectSubSectionId": "83919c6e-66a8-4871-b2c9-3b383eefee06",
            "id": "b417b2c0-f74c-4425-a059-fa1ca4dc79de",
            "topicId": "00000000-0000-0000-0000-000000000000",
            "optionId": "00000000-0000-0000-0000-000000000000",
            "title": "Create dummy data for test",
            "description": null,
            "assetsVersions": [
              {
                "assetId": "b1166a9c-1594-452d-9294-482091ee49ab",
                "versionNumber": 1,
                "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
                "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
                "projectSubSectionId": "83919c6e-66a8-4871-b2c9-3b383eefee06",
                "topicId": "4746afaa-3d42-4731-a2b4-a1e0642441b9",
                "optionId": null
              },
              {
                "assetId": "b1166a9c-1594-452d-9294-482091ee49ab",
                "versionNumber": 2,
                "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
                "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
                "projectSubSectionId": "83919c6e-66a8-4871-b2c9-3b383eefee06",
                "topicId": "4746afaa-3d42-4731-a2b4-a1e0642441b9",
                "optionId": null
              },
              {
                "assetId": "b1166a9c-1594-452d-9294-482091ee49ab",
                "versionNumber": 3,
                "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
                "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
                "projectSubSectionId": "83919c6e-66a8-4871-b2c9-3b383eefee06",
                "topicId": "4746afaa-3d42-4731-a2b4-a1e0642441b9",
                "optionId": null
              },
              {
                "assetId": "b1166a9c-1594-452d-9294-482091ee49ab",
                "versionNumber": 4,
                "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
                "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
                "projectSubSectionId": "83919c6e-66a8-4871-b2c9-3b383eefee06",
                "topicId": "4746afaa-3d42-4731-a2b4-a1e0642441b9",
                "optionId": null
              },
              {
                "assetId": "b1166a9c-1594-452d-9294-482091ee49ab",
                "versionNumber": 5,
                "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
                "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
                "projectSubSectionId": "83919c6e-66a8-4871-b2c9-3b383eefee06",
                "topicId": "4746afaa-3d42-4731-a2b4-a1e0642441b9",
                "optionId": null
              }
            ],
            "priority": "highest",
            "status": "TO_COME",
            "creationDate": 1678786143167,
            "startDate": 1686182400000,
            "dueDate": 1688083200000,
            "creator": "adi.winata@mtm.video",
            "assignee": "nour.benammar@mtm.video",
            "collaborators": [
              "nour.benammar@mtm.video"
            ],
            "projectSectionName": "Pre-Production",
            "projectSubSectionName": "Brief Animatic 2d"
          },
          {
            "campaignId": "00000000-0000-0000-0000-000000000000",
            "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
            "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
            "projectSubSectionId": "fa0a4d64-fabc-4a45-8929-bfda8df2a8d0",
            "id": "9a795582-b8bc-4137-b121-844f1a408378",
            "topicId": "00000000-0000-0000-0000-000000000000",
            "optionId": "00000000-0000-0000-0000-000000000000",
            "title": "Gather more pictures for different roles",
            "description": "Test",
            "assetsVersions": [
              {
                "assetId": "d2972259-26f5-440e-9862-11285ab07e53",
                "versionNumber": 1,
                "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
                "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
                "projectSubSectionId": "fa0a4d64-fabc-4a45-8929-bfda8df2a8d0",
                "topicId": null,
                "optionId": null
              },
              {
                "assetId": "d2972259-26f5-440e-9862-11285ab07e53",
                "versionNumber": 1,
                "projectId": "4a893946-f0b7-4a7f-84c5-6c2a86e38647",
                "projectSectionId": "bded5b86-9c11-473d-b3c2-39bc11e96986",
                "projectSubSectionId": "fa0a4d64-fabc-4a45-8929-bfda8df2a8d0",
                "topicId": "df9525ec-eec6-4f9a-b6e6-a8d48a015212",
                "optionId": "d2972259-26f5-440e-9862-11285ab07e53"
              }
            ],
            "priority": "highest",
            "status": "DELAYED",
            "creationDate": 1678528075430,
            "startDate": 1678320000000,
            "dueDate": 1686182400000,
            "creator": "david.senouf@mtm.video",
            "assignee": "prince.diop@mtm.video",
            "collaborators": [
              "david.senouf@mtm.video",
              "prince.diop@mtm.video"
            ],
            "projectSectionName": "Pre-Production",
            "projectSubSectionName": "Casting"
          }
        ]
      }
    ]
  }

  getNotifications(projectId: string) {
    const username = this.authService.getAuthUserName();
    return new Observable<any>(subscriber => {
      const result = {
        hasNext: true,
        pagingState: "0x18616e746f6e2e736574696177616e406d746d2e766964656f0a000800000188d2c36fabf07fffffe6f07fffffe6",
        data: [
          {
            "username": username,
            "time": + new Date(),
            "seen": false,
            "projectId": projectId,
            "category": "CASTING",
            "code": "FILE_UPLOADED",
            "dummy": true,
            "date": + new Date(),
            "customBorderClass": "blue-border",
            "object": {
              "notifiedByAvatarUrl": "/assets/img/project-v2/ysl/ysl-user-1.svg",
              "fileContentType": "video/mp4",
              "sectionName": "Casting",
              "notifiedBy": "Margot Duppont",
              "fileType": "video",
              "uploadImageUrl": "/assets/img/project-v2/dashboard/video-1.png"
            },
            "notificationDisplay": {
              "label": 'have uploaded <a href="#" script="text-decoration:underline;">experienceVideo.mp4</a> into <a href="#" script="text-decoration:underline;">Casting</a> subsection'
            }
          },
          {
            "username": username,
            "time": + new Date(),
            "seen": false,
            "projectId": projectId,
            "category": "CASTING",
            "code": "FILE_UPLOADED",
            "dummy": true,
            "date": + new Date(),
            "object": {
              "notifiedByAvatarUrl": "/assets/img/project-v2/dashboard/mtm-bot.svg",
              "fileContentType": "video/mp4",
              "sectionName": "Casting",
              "notifiedBy": "MTM Bot",
              "fileType": "video"
            },
            "notificationDisplay": {
              "label": 'Your archive is ready to download, follow this <a href="#">link</a>'
            }
          },
          {
            "username": username,
            "time": + new Date(),
            "seen": false,
            "projectId": projectId,
            "category": "CASTING",
            "code": "FILE_UPLOADED",
            "customBorderClass": "crimson-border",
            "dummy": true,
            "date": + new Date(),
            "object": {
              "notifiedByAvatarUrl": "/assets/img/project-v2/ysl/ysl-user-1.svg",
              "fileContentType": "video/mp4",
              "sectionName": "Casting",
              "notifiedBy": "MTM Bot",
              "fileType": "video",
            },
            "notificationDisplay": {
              "label": 'have uploaded <a href="#" script="text-decoration:underline;">experienceVideo.mp4</a> into <a href="#" script="text-decoration:underline;">Casting</a> subsection'
            }
          },
          {
            "username": username,
            "time": + new Date(),
            "seen": false,
            "projectId": projectId,
            "category": "CASTING",
            "code": "FILE_UPLOADED",
            "dummy": true,
            "date": + new Date(),
            "object": {
              "notifiedByAvatarUrl": "/assets/img/project-v2/ysl/ysl-user-2.svg",
              "fileContentType": "video/mp4",
              "sectionName": "Casting",
              "notifiedBy": "Linda McDonalds",
              "fileType": "video",
              "uploadImageUrl": "/assets/img/project-v2/dashboard/video-2.png"
            },
            "notificationDisplay": {
              "label": 'have uploaded <a href="#" script="text-decoration:underline;">experienceVideo.mp4</a> into <a href="#" script="text-decoration:underline;">Casting</a> subsection'
            }
          },
        ]
      };
      subscriber.next(result);
      subscriber.complete();
    });
  }

  getTasks(): Observable<any> {
    const result = [{
      sectionName: 'Post Production',
      title: 'Hero Film',
      description: 'Final storyboard for the Egerie film Validation',
      assignee: {
        avatarUrl: '/assets/img/project-v2/ysl/ysl-user-1.svg'
      },
      collaborators: [
        {
          avatarUrl: '/assets/img/project-v2/dashboard/user-1.png'
        },
        {
          avatarUrl: '/assets/img/project-v2/dashboard/user-2.png'
        },
        {
          avatarUrl: '/assets/img/project-v2/dashboard/user-3.png'
        },
        {
          avatarUrl: '/assets/img/project-v2/dashboard/user-4.png'
        }
      ],
      dueDate: + new Date(),
      status: 'In Progress',
      borderClass: 'purple-border',
      taskStatus: [
        'In Progress',
        'Completed',
        'Delayed'
      ]
    }, {

      sectionName: 'Post Production',
      title: 'Hero Film',
      description: 'Music Selection for the Egerie film Validation',
      assignee: {
        avatarUrl: '/assets/img/project-v2/ysl/ysl-user-1.svg'
      },
      collaborators: [

      ],
      dueDate: + new Date(),
      status: 'In Progress',
      borderClass: 'crimson-border',
      taskStatus: [
        'In Progress',
        'Completed',
        'Delayed'
      ]
    }, {
      sectionName: 'Post Productions',
      title: 'Hero Film',
      description: 'Final edit of an Egerie film Validation',
      assignee: {
        avatarUrl: '/assets/img/project-v2/ysl/ysl-user-1.svg'
      },
      collaborators: [
        {
          avatarUrl: '/assets/img/project-v2/dashboard/user-1.png'
        },
        {
          avatarUrl: '/assets/img/project-v2/dashboard/user-2.png'
        }
      ],
      dueDate: + new Date(),
      status: 'In Progress',
      borderClass: 'blue-border',
      taskStatus: [
        'In Progress',
        'Completed',
        'Delayed'
      ]
    }, {
      sectionName: 'Post Productions',
      title: 'Hero Film',
      description: 'Hero Shoot is delayed because of the egerie',
      assignee: {
        avatarUrl: '/assets/img/project-v2/ysl/ysl-user-1.svg'
      },
      collaborators: [
        {
          avatarUrl: '/assets/img/project-v2/dashboard/user-2.png'
        }
      ],
      dueDate: + new Date(),
      status: 'Delayed',
      taskStatus: [
        'In Progress',
        'Completed',
        'Delayed'
      ]
    }];
    return new Observable<any>(subscriber => {
      subscriber.next(result);
      subscriber.complete();
    });
  }

}

<nav (mouseleave)="minimizeSidebar()" class="left-sidebar h-100 position-absolute">

  <div class="h-100 inner-container">

    <div class="d-flex flex-column h-100">
      <div class="d-flex align-items-center">
        <a throttleClick (throttledClick)="goToInitialPage()"
          class="d-flex align-items-center justify-content-center logo-container">
          <img alt="MTM" class="logo" height="31" src="/assets/svg/sidebar/logo.svg" width="35" />
        </a>
        <a throttleClick (throttledClick)="goToInitialPage()"
          class="d-flex align-items-center justify-content-center">
          <img alt="MTM" class="logo" height="26" src="/assets/svg/sidebar/mtm.svg" width="64" />
        </a>
      </div>

      <!-- active workspace menu -->
      <ng-container *ngIf="isActiveWorkspaceMenuVisible">
        <div class="separator"></div>
        <ul class="w-100 my-1 active-workspace-menu">
          <li class="menu-item">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center justify-content-center menu-icon workspace-avatar">
                <mtm-item-icon [containerWidth]="'35px'" [generatedTextColor]="true" [height]="'35px'"
                  [imageUrl]="activeWorkspace.avatarUrl" [name]="activeWorkspace.name" [width]="'35px'"
                  [cacheBuster]="cacheBusterString"
                >
                </mtm-item-icon>
              </div>
              <span (click)="toggleMenuState('workspace')" [title]="activeWorkspace.name"
                class="menu-text workspace-name">
                {{ activeWorkspace.name }}
              </span>
              <span (click)="toggleMenuState('workspace')" class="toggle-container">
                <img [alt]="menuStates.workspace.toggleAlt" [src]="menuStates.workspace.toggleIcon" height="9"
                  width="9" />
              </span>
            </div>
            <div class="expandable" [class.active]="menuStates.workspace.isExpanded">
              <ul class="mt-2">
                <li class="menu-item" *ngIf="canEditWorkspace">
                  <a [routerLink]="['/workspace/edit', activeWorkspace.id]" class="d-flex align-items-center"
                    routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarWorkspaceSettings' | translate" height="14" ngSrc="/assets/svg/sidebar/settings.svg"
                        width="14" />
                    </span>
                    <span [title]="'leftBarWorkspaceSettings' | translate" class="menu-text">{{ 'leftBarWorkspaceSettings' | translate
                      }}</span>
                  </a>
                </li>
                <li class="menu-item">
                  <a [routerLink]="['/account', activeWorkspace.id,'notifications-settings']"
                    class="d-flex align-items-center" routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarNotificationSettings' | translate" height="13"
                        ngSrc="/assets/svg/sidebar/notification.svg" width="11" />
                    </span>
                    <span [title]="'leftBarNotificationSettings' | translate" class="menu-text">
                      {{ 'leftBarNotificationSettings' | translate }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </ng-container>

      <!-- active campaign menu -->
      <ng-container *ngIf="isActiveCampaignMenuVisible && isActiveCampaignMenuHidde ">
        <div class="separator"></div>
        <ul class="w-100 my-1 active-campaign-menu">
          <li class="menu-item">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center justify-content-center menu-icon campaign-avatar">
                <mtm-item-icon [containerWidth]="'35px'" [generatedTextColor]="true" [height]="'35px'"
                  [imageUrl]="activeCampaign.logo" [name]="activeCampaign.name" [width]="'35px'"
                  [cacheBuster]="cacheBusterString"
                >
                </mtm-item-icon>
              </div>
              <span (click)="toggleMenuState('campaign')" [title]="activeCampaign.name" class="menu-text campaign-name">
                {{ activeCampaign.name }}
              </span>
              <span (click)="toggleMenuState('campaign')" class="toggle-container">
                <img [alt]="menuStates.campaign.toggleAlt" [src]="menuStates.campaign.toggleIcon" height="9"
                  width="9" />
              </span>
            </div>
            <div class="expandable" [class.active]="menuStates.campaign.isExpanded">
              <ul class="mt-2">
                <li class="menu-item" *ngIf="canEditCampaign">
                  <a class="d-flex align-items-center"
                    [routerLink]="['/workspaces/campaigndrive', activeCampaign.workspaceId, 'general']"
                    [queryParams]="driveCampaignSettingsQueryParams" (click)="goToCampaign($event)" routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarCampaignSettings' | translate" height="14" ngSrc="/assets/svg/sidebar/settings.svg"
                        width="14" />
                    </span>
                    <span [title]="'leftBarCampaignSettings' | translate" class="menu-text">{{ 'leftBarCampaignSettings' | translate
                      }}</span>
                  </a>
                </li>
                <li class="menu-item" *ngIf="activeCampaign.workspaceId">
                  <a class="d-flex align-items-center" (click)=" backToWorkspace()">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarBackToWorkspace' | translate" height="14"
                        ngSrc="/assets/svg/sidebar/workspaces.svg" width="14" />
                    </span>
                    <span [title]="'leftBarBackToWorkspace'| translate" class="menu-text">
                      {{ 'leftBarBackToWorkspace' | translate }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </ng-container>

      <!-- active project menu -->
      <ng-container *ngIf="isActiveProjectMenuVisible">
        <div class="separator"></div>
        <ul class="w-100 my-1 active-project-menu">
          <li class="menu-item">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center justify-content-center menu-icon workspace-avatar">
                <mtm-item-icon [containerWidth]="'35px'" [generatedTextColor]="true" [height]="'35px'"
                  [imageUrl]="project.avatarUrl" [name]="project.name" [width]="'35px'"
                  [cacheBuster]="projectCacheBuster">
                </mtm-item-icon>
              </div>
              <span (click)="toggleMenuState('project')" [title]="project.name" class="menu-text project-name">
                {{ project.name }}
              </span>
              <span (click)="toggleMenuState('project')" class="toggle-container">
                <img [alt]="menuStates.project.toggleAlt" [src]="menuStates.project.toggleIcon" height="9" width="9" />
              </span>
            </div>
            <div class="expandable" [class.active]="menuStates.project.isExpanded">
              <ul class="mt-2">
                <li class="menu-item">
                  <a [routerLink]="['/projects', project.id, 'notification-setting']" class="d-flex align-items-center"
                    routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarNotificationSettings' | translate" height="13"
                        ngSrc="/assets/svg/sidebar/notification.svg" width="11" />
                    </span>
                    <span [title]="'leftBarNotificationSettings' | translate" class="menu-text">
                      {{ 'leftBarNotificationSettings' | translate }}
                    </span>
                  </a>
                </li>
                <li class="menu-item" hasUserAccess [authority]="authority.U" [projectId]="project.id">
                  <a [routerLink]="['/projects/edit', project.id, 'company']" class="d-flex align-items-center"
                    routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarProjectSettings' | translate" height="14"
                        ngSrc="/assets/svg/sidebar/settings.svg" width="14" />
                    </span>
                    <span [title]="'leftBarProjectSettings' | translate" class="menu-text">{{ 'leftBarProjectSettings' |
                      translate }}</span>
                  </a>
                </li>
                <li class="menu-item" *ngIf="project.campaignId && hasLtgPermission">
                  <a class="d-flex align-items-center" (click)="backToCampaign()">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarBackToCampaign' | translate" height="14"
                        ngSrc="/assets/svg/sidebar/workspaces.svg" width="14" />
                    </span>
                    <span [title]="'leftBarBackToCampaign' | translate" class="menu-text">
                      {{ 'leftBarBackToCampaign' | translate }}
                    </span>
                  </a>
                </li>
                <li class="menu-item" *ngIf="project.workspaceId && !project.campaignId">
                  <a class="d-flex align-items-center" (click)=" backToWorkspace()">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="(hasLtgPermission ? 'leftBarBackToWorkspace': 'leftBarBackToWorkspaces') | translate"
                        height="14" ngSrc="/assets/svg/sidebar/workspaces.svg" width="14" />
                    </span>
                    <span [title]="(hasLtgPermission ? 'leftBarBackToWorkspace': 'leftBarBackToWorkspaces') | translate"
                      class="menu-text">
                      {{ (hasLtgPermission ? 'leftBarBackToWorkspace': 'leftBarBackToWorkspaces') | translate }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <ng-container>
          <div class="separator"></div>
          <ul class="w-100 project-content-menu">
            <li class="mt-2 menu-item">
              <a [routerLink]="['/projects', project.id, 'dashboard']" class="d-flex align-items-center"
                routerLinkActive="active">
                <span class="d-flex align-items-center justify-content-center menu-icon">
                  <img [alt]="'leftBarDashboard' | translate" height="18" ngSrc="/assets/svg/sidebar/dashboard.svg"
                    width="18" />
                </span>
                <span class="menu-text" [title]="'leftBarDashboard' | translate">
                  {{ 'leftBarDashboard' | translate }}
                </span>
              </a>
            </li>
            <li class="menu-item">
              <!-- TODO:- When new design ready active routing till that coming soon functionality visible -->
              <!-- <a [routerLink]="['/projects', project.id, 'calendar']" class="d-flex align-items-center" -->
              <a  class="d-flex align-items-center"
                routerLinkActive="active">
                <span class="d-flex align-items-center justify-content-center menu-icon">
                  <img [alt]="'leftBarCalendar' | translate" height="16" ngSrc="/assets/svg/sidebar/calendar.svg"
                    width="18" />
                </span>
                <span class="menu-text" [title]="'leftBarCalendar' | translate">
                  {{ 'leftBarCalendar' | translate }}
                </span>
              </a>
              <div class="hover-tooltip">
                <img src="../../../assets/svg/comming-soon-clock.svg" alt=""> Coming soon..
              </div>
            </li>
            <li class="menu-item">
              <a class="d-flex align-items-center" [routerLink]="'/kanban/board'"
                [queryParams]="{projectId: project.id}" (click)="goToTasks($event)" routerLinkActive="active">
                <span class="d-flex align-items-center justify-content-center menu-icon">
                  <img [alt]="'tasks' | translate" height="18" ngSrc="/assets/svg/sidebar/tasks.svg" width="18" />
                </span>
                <span class="menu-text" [title]="'tasks' | translate">
                  {{ 'tasks' | translate }}
                </span>
              </a>
            </li>
            <li class="menu-item">
              <a [routerLink]="['/projects', project.id, 'teamfiles']" class="d-flex align-items-center"
                routerLinkActive="active">
                <span class="d-flex align-items-center justify-content-center menu-icon">
                  <img [alt]="'leftBarFiles' | translate" height="12" ngSrc="/assets/svg/sidebar/files.svg"
                    width="16" />
                </span>
                <span class="menu-text" [title]="'leftBarFiles' | translate">
                  {{ 'leftBarFiles' | translate }}
                </span>
              </a>
            </li>
            <li class="menu-item">
              <a [routerLink]="['/projects', project.id, 'review-links']" class="d-flex align-items-center"
                routerLinkActive="active">
                <span class="d-flex align-items-center justify-content-center menu-icon">
                  <img [alt]="'reviewLinks' | translate" height="18" ngSrc="/assets/svg/sidebar/review-link.svg"
                    width="18" />
                </span>
                <span class="menu-text" [title]="'reviewLinks' | translate">
                  {{ 'reviewLinks' | translate }}
                </span>
              </a>
            </li>
            <li class="menu-item" *ngIf="hasSubtitlesAccess">
              <a [routerLink]="subtitlesUrlPath" class="d-flex align-items-center" routerLinkActive="active">
                <span class="d-flex align-items-center justify-content-center menu-icon">
                  <img [alt]="'Subtitles' | translate" height="16" ngSrc="/assets/svg/sidebar/subtitles.svg"
                    width="18" />
                </span>
                <span class="menu-text" [title]="'Subtitles' | translate">
                  {{ 'Subtitles' | translate }}
                </span>
              </a>
            </li>
            <li class="menu-item" *ngIf="hasAIReframingAccess">
              <a [routerLink]="['/projects', project.id, 'ai-reframing']" class="d-flex align-items-center"
                routerLinkActive="active">
                <span class="d-flex align-items-center justify-content-center menu-icon">
                  <img [alt]="'AI Reframing' | translate" height="18" ngSrc="/assets/svg/sidebar/ai-reframing.svg"
                    width="18" />
                </span>
                <span [title]="'AI Reframing' | translate" class="menu-text">{{ 'AI Reframing' | translate }}</span>
              </a>
            </li>
          </ul>
        </ng-container>
      </ng-container>

      <!-- LTG main menu -->
      <ng-container *ngIf="isLtgMainMenuVisible">
        <div class="separator"></div>

        <ul class="w-100 workspaces-menu">
          <li class="mt-2 menu-item">
            <a [routerLink]="'/workspaces'"
              class="d-flex align-items-center" [class.active]="activeMenuItem === 'workspaces'">
              <span class="d-flex align-items-center justify-content-center menu-icon">
                <img [alt]="'workspaces' | translate" height="18" ngSrc="/assets/svg/sidebar/workspaces.svg"
                  width="18" />
              </span>
              <span class="menu-text">
                {{ 'workspaces' | translate }}
              </span>
            </a>
          </li>
          <li class="menu-item">
            <a (click)="navigateTo('/workspaces/campaign-drive')" class="d-flex align-items-center"
              [class.active]="activeMenuItem === 'drive-campaign'">
              <span class="d-flex align-items-center justify-content-center menu-icon">
                <img [alt]="'loreal_campaigns' | translate" height="16" ngSrc="/assets/svg/sidebar/drive-campaign.svg"
                  width="16" />
              </span>
              <span class="menu-text">{{ (isCpd ? 'loreal_campaigns' : 'loreal_driveCampaigns') | translate }}</span>
            </a>
          </li>
          <li class="menu-item">
            <a [routerLink]="'/workspaces/projects'" class="d-flex align-items-center" routerLinkActive="active">
              <span class="d-flex align-items-center justify-content-center menu-icon">
                <img [alt]="'projects' | translate" height="12" ngSrc="/assets/svg/sidebar/projects.svg" width="16" />
              </span>
              <span class="menu-text">{{ 'projects' | translate }}</span>
            </a>
          </li>
          <li class="menu-item">
            <a class="d-flex align-items-center" (click)="goToReportingDashboard()"
              [class.active]="activeMenuItem == 'reporting'">
              <span class="d-flex align-items-center justify-content-center menu-icon">
                <img [alt]="'leftBarReporting' | translate" height="19" ngSrc="/assets/svg/sidebar/reporting.svg"
                  width="19" />
              </span>
              <span class="menu-text">{{ 'leftBarReporting' | translate }}</span>
            </a>
            <div class="hover-tooltip">
              <img src="../../../assets/svg/comming-soon-clock.svg" alt=""> Coming soon..
          </div>
          </li>
          <li class="menu-item">
            <!-- [routerLink]="'/workspaces/gamification/dashboard'" -->
            <a class="d-flex align-items-center" 
              routerLinkActive="active" (click)="goToGamification($event)">
              <span class="d-flex align-items-center justify-content-center menu-icon">
                <img [alt]="'leftBarGamification' | translate" height="16" ngSrc="/assets/svg/sidebar/self-trainer.svg"
                  width="19" />
              </span>
              <span class="menu-text">{{ 'leftBarGamification' | translate }}</span>
            </a>
            <div class="hover-tooltip">
              <img src="../../../assets/svg/comming-soon-clock.svg" alt=""> Coming soon..
          </div>
          </li>
        </ul>
      </ng-container>


      <!-- Non LTG Workspaces menu -->
      <ng-container *ngIf="isCompanyWorkspacesMenuVisible">
        <div class="separator"></div>

        <ul class="w-100 company-workspaces-menu">
          <li class="mt-2 menu-item">
            <a class="d-flex align-items-center" (click)="goToInitialPage()">
              <span class="d-flex align-items-center justify-content-center menu-icon">
                <img [alt]="'workspaces' | translate" height="18" ngSrc="/assets/svg/sidebar/workspaces.svg"
                  width="18" />
              </span>
              <span class="menu-text">
                {{ 'workspaces' | translate }}
              </span>
            </a>
          </li>
          <li class="menu-item" *ngIf="hasMarketplaceAccess">
            <a class="d-flex align-items-center" (click)="setMarketplaceWorkspace()">
              <span class="d-flex align-items-center justify-content-center menu-icon">
                <mtm-item-icon [name]="authUser.company.name" [imageUrl]="marketplaceAvatar" [width]="'14px'"
                  [height]="'14px'" [generatedTextColor]="true" />
              </span>
              <span class="menu-text">
                MTM Marketplace
              </span>
            </a>
          </li>
        </ul>
      </ng-container>

      <!-- bottom account menu -->
      <!-- TODO: automate child account menu generations -->
      <div class="mt-auto separator"></div>
      <ul class="w-100 account-menu">
        <li class="mt-3 menu-item">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center justify-content-center menu-icon account-avatar">
              <mtm-user-profile-picture [forceLoadProfile]="true" [height]="36" [user]="authUser"
                [username]="authUser?.username" [width]="36" style="width: 55px;">
              </mtm-user-profile-picture>
            </div>
            <span (click)="toggleMenuState('account')" class="menu-text account-name">
              {{ authUser?.fullName }}
            </span>
            <span (click)="toggleMenuState('account')" class="toggle-container">
              <img [alt]="menuStates.account.toggleAlt" [src]="menuStates.account.toggleIcon" height="9" width="9" />
            </span>
          </div>
          <div class="expandable" [class.active]="menuStates.account.isExpanded ">
            <ul class="mt-2 account-submenu">
              <ng-container *ngIf="! isReviewLinkUser">
                <li class="menu-item">
                  <a [routerLink]="['/account', 'notification-settings']" class="d-flex align-items-center"
                    routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarNotificationSettings' | translate" height="13"
                        ngSrc="/assets/svg/sidebar/notification.svg" width="11" />
                    </span>
                    <span [title]="'leftBarNotificationSettings' | translate" class="menu-text">
                      {{ 'leftBarNotificationSettings' | translate }}
                    </span>
                  </a>
                </li>
                <li [authority]="authority.E" class="menu-item" hasUserAccess *ngIf="hasMarketplaceAccess">
                  <a [routerLink]="'/account/company'" class="d-flex align-items-center" routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'companyProfile' | translate" height="15" ngSrc="/assets/svg/sidebar/portfolio.svg"
                        width="18" />
                    </span>
                    <span [title]="'companyProfile' | translate" class="menu-text">
                      {{ 'companyProfile' | translate }}
                    </span>
                  </a>
                </li>
                <li [authority]="authority.S" class="menu-item" hasUserAccess>
                  <a [routerLink]="'/account/company-detail'" class="d-flex align-items-center"
                    fragment="company-details" routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarAccountCompanyDetails' | translate" height="15"
                        ngSrc="/assets/svg/sidebar/company.svg" width="18" />
                    </span>
                    <span [title]="'leftBarAccountCompanyDetails' | translate" class="menu-text">
                      {{ 'leftBarAccountCompanyDetails' | translate }}
                    </span>
                  </a>
                </li>
                <li [authority]="authority.S" class="menu-item" hasUserAccess>
                  <a [routerLink]="'/account/company-security-policy'" class="d-flex align-items-center"
                    routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarAccountCompanySecurityPolicy' | translate" height="15"
                        ngSrc="/assets/svg/sidebar/company-security-policy.svg" width="18" />
                    </span>
                    <span [title]="'leftBarAccountCompanySecurityPolicy' | translate" class="menu-text">
                      {{ 'leftBarAccountCompanySecurityPolicy' | translate }}
                    </span>
                  </a>
                </li>
                <li [authority]="authority.S" class="menu-item" hasUserAccess>
                  <a [routerLink]="'/account/manage/users-and-teams'" class="d-flex align-items-center"
                    routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarAccountManageUser' | translate" height="14"
                        ngSrc="/assets/svg/sidebar/members.svg" width="19" />
                    </span>
                    <span [title]="'leftBarAccountManageUser' | translate" class="menu-text">
                      {{ 'leftBarAccountManageUser' | translate }}</span>
                  </a>
                </li>
                <li [authority]="Authority.S" class="menu-item" hasUserAccess>
                  <a [routerLink]="'/account/brands/brandsworkspaces'" class="d-flex align-items-center"
                    routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarBrands' | translate" height="16"
                        ngSrc="/assets/svg/sidebar/brands-workspaces.svg" width="16" />
                    </span>
                    <span [title]="'leftBarBrandsWorkspaces' | translate" class="menu-text">
                      {{ 'leftBarBrandsWorkspaces' | translate }}
                    </span>
                  </a>
                </li>
                <li [authority]="authority.S" class="menu-item" hasUserAccess>
                  <a [routerLink]="'/account/manage/billing/overview'" class="d-flex align-items-center"
                    routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarAccountManageBilling' | translate" height="18"
                        ngSrc="/assets/svg/sidebar/billing.svg" width="15" />
                    </span>
                    <span [title]="'leftBarAccountManageBilling' | translate" class="menu-text">{{
                      'leftBarAccountManageBilling' | translate }}</span>
                  </a>
                </li>
                <li class="menu-item">
                  <a [routerLink]="'/account/settings'" class="d-flex align-items-center" routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarAccountSettings' | translate" height="14"
                        ngSrc="/assets/svg/sidebar/settings.svg" width="14" />
                    </span>
                    <span [title]="'leftBarAccountSettings' | translate" class="menu-text">{{ 'leftBarAccountSettings' |
                      translate }}</span>
                  </a>
                </li>
                <li class="menu-item">
                  <a [routerLink]="'/account/preferences'" class="d-flex align-items-center" routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarAccountPreferences' | translate" height="14"
                        ngSrc="/assets/svg/sidebar/preferences.svg" width="14" />
                    </span>
                    <span [title]="'leftBarAccountPreferences' | translate" class="menu-text">{{
                      'leftBarAccountPreferences' | translate }}</span>
                  </a>
                </li>
                <li [authority]="authority.Z" class="menu-item" hasUserAccess>
                  <a [routerLink]="'/admin'" class="d-flex align-items-center" routerLinkActive="active">
                    <span class="d-flex align-items-center justify-content-center menu-icon">
                      <img [alt]="'leftBarAdmin' | translate" height="14" ngSrc="/assets/svg/sidebar/admin.svg"
                        width="19" />
                    </span>
                    <span [title]="'leftBarAdmin' | translate" class="menu-text">{{ 'leftBarAdmin' | translate }}</span>
                  </a>
                </li>
              </ng-container>
              <li class="menu-item">
                <a throttleClick (throttledClick)="logout()" class="d-flex align-items-center">
                  <span class="d-flex align-items-center justify-content-center menu-icon">
                    <img [alt]="'leftBarLogout' | translate" height="18" ngSrc="/assets/svg/sidebar/logout.svg"
                      width="18" />
                  </span>
                  <span class="menu-text">{{ 'leftBarLogout' | translate }}</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

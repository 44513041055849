import { AfterViewInit, Component, inject, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "app/shared/services/auth.service";
import {
  ActiveWorkspaceUpdatedEvent, DriveCampaign,
  Project, ProjectImageUpdatedEvent,
  ProjectWorkspaceUpdatedEvent,
  User, WorkspaceAvatarUpdatedEvent, WorkspaceDeleted,
  WorkspaceDTO, WorkspaceUpdatedEvent
} from "app/shared/interfaces";
import { Authority, PermissionService } from "app/shared/services/permissions.service";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import { BroadcastService } from "app/shared/services/broadcast.service";
import { EmitterService } from "app/shared/services/emitter.service";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { environment } from "../../../environments/environment";
import { onLeaveFromProject, onLoadProject, ProjectService } from "app/shared/services/project.service";
import { forkJoin, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { KanbanProjectService } from "app/kanban/project/state/project/project.service";
import { CompanyService } from "app/shared/services/company.service";
import { WorkspaceService } from "app/shared/services/workspace.service";
import { UserTourService } from "app/shared/services/user-tour.service";
import { CryptoService } from "app/shared/services/crypto.service";
import { TimelineService } from "app/shared/services/timeline.service";
import { ProjectV2ServiceService } from "app/shared/services/project-v2-service.service";

type SidebarMenuTypes = 'workspace' | 'campaign' | 'project' | 'account';

@Component({
  selector: 'mtm-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  private authService = inject(AuthService);
  private router = inject(Router);
  private broadcastService = inject(BroadcastService);
  private kanbanService = inject(KanbanProjectService);
  private translatePipe = inject(TranslatePipe);
  private route = inject(ActivatedRoute);
  private permissionService = inject(PermissionService);
  private projectService = inject(ProjectService);
  private companyService = inject(CompanyService);
  private workspaceService = inject(WorkspaceService);
  private userTourService = inject(UserTourService);
  private cryptoService = inject(CryptoService);
  private timelineService = inject(TimelineService);
  private projectV2Service = inject(ProjectV2ServiceService);
  protected readonly Authority = Authority;
  authUser: User;
  authority = Authority;
  isProductionCompany = false;
  project: Project | null = null;
  activeWorkspace: WorkspaceDTO | null = null;
  activeCampaign: DriveCampaign | null = null;
  inMarketplaceWorkspace = false;
  canEditWorkspace: boolean = false;
  canEditCampaign: boolean = false;
  isCpd: boolean = false;
  isReviewLinkUser = false;
  hasLtgPermission = false;
  publicReviewLink = false;
  hasSubtitlesAccess = false;
  subtitlesUrlPath: string[] = [];
  cacheBuster = Date.now();
  projectCacheBuster = Date.now().toString();
  workspaces: WorkspaceExtraDTO[] = [];
  otherWorkspaces: any[] = [];
  marketplaceAvatar: string = '';
  hasMarketplaceAccess = false;
  hasAIReframingAccess = false;
  activeMenuItem: string = '';
  currentWorkspace: WorkspaceDTO | null = null;
  isActiveCampaignMenuHidde: boolean = true;

  menuStates = {
    workspace: {
      isExpanded: false,
      toggleAlt: 'expand',
      toggleIcon: '/assets/svg/sidebar/expand-arrow.svg'
    },
    campaign: {
      isExpanded: false,
      toggleAlt: 'expand',
      toggleIcon: '/assets/svg/sidebar/expand-arrow.svg'
    },
    project: {
      isExpanded: false,
      toggleAlt: 'expand',
      toggleIcon: '/assets/svg/sidebar/expand-arrow.svg'
    },
    account: {
      isExpanded: false,
      toggleAlt: 'expand',
      toggleIcon: '/assets/svg/sidebar/expand-arrow.svg'
    }
  };

  private ngUnsubscribe = new Subject<boolean>();

  get logoRouterLink(): string {
    return this.hasLtgPermission ? '/workspaces' : '/projects';
  }

  get isOtherWorkspacesMenuVisible(): boolean {
    return !this.hasLtgPermission && this.otherWorkspaces.length > 0 && !this.project;
  }

  get isCompanyWorkspacesMenuVisible(): boolean {
    return !this.hasLtgPermission && !this.project;
  }

  get isActiveWorkspaceMenuVisible(): boolean {
    return !!this.activeWorkspace && !this.isReviewLinkUser;
  }

  get isActiveCampaignMenuVisible(): boolean {
    return !!this.activeCampaign && !this.isReviewLinkUser && !this.project;
  }

  get isActiveProjectMenuVisible() {
    return this.project && !this.isReviewLinkUser;
  }

  get isLtgMainMenuVisible() {
    return this.hasLtgPermission && !this.project && !this.isReviewLinkUser;
  }

  get driveCampaignSettingsQueryParams() {
    return {
      wid: this.cryptoService.encryptString(this.activeCampaign.workspaceId),
      dcId: this.cryptoService.encryptString(this.activeCampaign.id)
    }
  }

  get cacheBusterString(): string {
    return this.cacheBuster.toString();
  }

  ngOnInit() {
    this.currentWorkspace = this.workspaceService.getActiveWorkspace();
    this.authUser = this.authService.getAuthUser()!;
    this.isProductionCompany = this.authUser.company?.companyType === 'PRODUCER';
    this.isCpd = this.authUser.companyId == environment.ltg.cpdCompanyId;
    this.hasLtgPermission = this.authService.checkLtgPermission();
    this.publicReviewLink = this.route.snapshot.data.publicReviewLink;
    this.isReviewLinkUser = this.authUser.globalRole == 'COMPANY_OCCASIONAL';
    this.hasAIReframingAccess = this.authUser.email == environment.aiReframingUserAccount.username;
    this.checkSubtitlesAccess();
    this.handleUserEvents();
    this.handleWorkspacesEvents();
    this.handleCampaignEvents();
    this.handleProjectEvents();
    this.detectRouteChange();
    this.trackNavigation(this.router.url);

    if (!this.hasLtgPermission) {
      this.initWorkspaces();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {

  }

  navigateTo(path: string) {
    this.router.navigateByUrl('/temporaryRedirect', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(path);
    });
  }

  private handleUserEvents() {
    EmitterService.get('authUser.update').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((user: any) => {
      this.authUser = this.authService.getAuthUser();
      if (this.authUser) {
        this.isReviewLinkUser = this.authUser.globalRole == 'COMPANY_OCCASIONAL';
      }
    });

    EmitterService.get('authUser.updateCompany').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.authUser = this.authService.getAuthUser();
    });
  }

  private handleWorkspacesEvents() {
    EmitterService.get(ActiveWorkspaceUpdatedEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (updates) => {
        this.activeWorkspace = updates.workspace;
        this.inMarketplaceWorkspace = updates.inMarketplace;
        this.checkWorkspaceAccess();
      }
    });

    EmitterService.get(ProjectWorkspaceUpdatedEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {
        if (this.projectService.project) {
          this.project = this.projectService.project;
        }
        this.cacheBuster = Date.now();
        if (!this.hasLtgPermission) {
          this.initWorkspaces();
        }
      }
    });

    EmitterService.get(WorkspaceAvatarUpdatedEvent).
      pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.cacheBuster = Date.now();
      });

    EmitterService.get(WorkspaceDeleted).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.cacheBuster = Date.now();
      if (!this.hasLtgPermission) {
        this.initWorkspaces();
      }
    });
  }

  private handleCampaignEvents() {
    this.timelineService.activeCampaign$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(campaign => {
      this.activeCampaign = campaign;
      this.canEditCampaign = this.permissionService.hasAuthority(Authority.Z, null)
        || (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.P, null));
      this.adjustActiveWorkspace();
    });
  }

  private handleProjectEvents() {
    onLoadProject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(project => {
      if (this.projectService.projectOnloaded) {
        if (!!project.id) {
          this.project = project;
          this.adjustActiveWorkspace();
        } else {
          //TODO: fix leave from project
          this.projectService.projectOnloaded = false;
        }
      } else {
        this.project = null;
      }
    });

    EmitterService.get(ProjectImageUpdatedEvent).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.projectCacheBuster = Date.now().toString();
    });
  }

  private detectRouteChange() {
    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.trackNavigation(event.urlAfterRedirects);
        }
      });
  }

  private trackNavigation(url: string) {
    let isInsideProject: boolean = false;
    const urlObj = new URL(url, window.location.origin);


    if (urlObj.pathname === '/workspaces') {
      this.activeMenuItem = 'workspaces';
    } else if ((url.startsWith('/workspaces/campaign-drive') && !url.includes('?wid='))) {
      this.activeMenuItem = 'drive-campaign';
    } else if (url.startsWith('/workspaces/analytics')) {
      this.activeMenuItem = 'reporting';
    } else {
      this.activeMenuItem = '';
    }

    if (this.hasLtgPermission && (url.startsWith('/projects') || url.startsWith('/kanban'))) {
      isInsideProject = true;
    }

    if (!isInsideProject && this.activeMenuItem != 'drive-campaign') {
      this.timelineService.activeCampaign$.next(null);
    }

    if ((url.startsWith('/workspaces/campaign-drive') && url.includes('?wid='))) {
      if (this.currentWorkspace)
        this.activeWorkspace = this.currentWorkspace;
      this.isActiveCampaignMenuHidde = false;
    } else {
      this.isActiveCampaignMenuHidde = true;
    }

    if (urlObj.pathname == "/workspaces/projects" && urlObj.search == "") {
      this.activeWorkspace = null;
    }
  }

  logout() {
    this.authService.logout();
    // Emit event and redirect to Login page
    EmitterService.get('user.logout').emit();
    this.broadcastService.send({ action: 'user.logout' });
    this.router.navigate(['/entry']);
  }

  toggleMenuState(menuType: SidebarMenuTypes) {
    if (this.menuStates[menuType].isExpanded) {
      this.setMenuState(menuType, false);
    } else {
      this.setMenuState('workspace', menuType === 'workspace');
      this.setMenuState('campaign', menuType === 'campaign');
      this.setMenuState('project', menuType === 'project');
      this.setMenuState('account', menuType === 'account');
    }
  }

  private setMenuState(menuType: SidebarMenuTypes, isExpanded: boolean) {
    this.menuStates[menuType].isExpanded = isExpanded;
    this.adjustMenuState(menuType);
  }

  private adjustMenuState(menuType: SidebarMenuTypes) {
    this.menuStates[menuType].toggleAlt = this.menuStates[menuType].isExpanded ? 'collapse' : 'expand';
    this.menuStates[menuType].toggleIcon = this.menuStates[menuType].isExpanded ? '/assets/svg/sidebar/collapse-arrow.svg'
      : '/assets/svg/sidebar/expand-arrow.svg';
  }

  minimizeSidebar() {
    this.setMenuState('workspace', false);
    this.setMenuState('campaign', false);
    this.setMenuState('project', false);
    this.setMenuState('account', false);
  }

  goToTasks(event: MouseEvent) {
    event.preventDefault();
    const projectId = this.project?.id;
    let queryParams: any = { projectId };
    if (this.project.campaignId) {
      queryParams.campaignId = this.project.campaignId
    }
    this.kanbanService.setProjectId.next(projectId)
    this.router.navigate(['/kanban/board'], { queryParams });
  }

  goToCampaign(event: MouseEvent) {
    event.preventDefault();
    this.projectV2Service.updateSelectedDriveCampaign(this.activeCampaign.workspaceId, this.activeCampaign.id);
  }

  checkSubtitlesAccess() {
    const sections = this.projectService.project?.sections;

    if (!sections) {
      this.subtitlesUrlPath = [];
      this.hasSubtitlesAccess = false;
      return;
    }

    const postProd = sections.find(section => section.section === 'POST_PRODUCTION');

    if (!postProd || !postProd.subsections) {
      this.subtitlesUrlPath = [];
      this.hasSubtitlesAccess = false;
      return;
    }

    const subSection = postProd.subsections.find(sub => sub.subSection === 'SUBTITLES');

    if (!subSection) {
      this.subtitlesUrlPath = [];
      this.hasSubtitlesAccess = false;
      return;
    }

    if (!this.permissionService.isReadAuthority(this.projectService.project.id, subSection.id)) {
      this.subtitlesUrlPath = [];
      this.hasSubtitlesAccess = false;
      return;
    }

    this.subtitlesUrlPath = ['/projects', this.projectService.project.id, postProd.id, subSection.id, 'grid'];
    this.hasSubtitlesAccess = true;
  }

  private initWorkspaces() {
    const sources$ = [this.workspaceService.getWorkspaces(this.authUser.company.id),
    this.projectService.getProjects()];

    if (this.isProductionCompany) {
      sources$.push(this.companyService.getCompanyById(this.authUser.companyId));
    }

    let availableWorkspaces: WorkspaceExtraDTO[] = [];

    forkJoin([...sources$]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any[]) => {
        this.workspaceService.setCachedProjects(result[1]);
        this.populateCompanyWorkspaces({
          workspaceResult: result[0],
          projectResult: result[1],
          availableWorkspaces
        });

        this.populateOtherWorkspaces({
          workspaceResult: result[0],
          projectResult: result[1],
          availableWorkspaces
        });

        if (this.isProductionCompany && result.length == 3) {
          if (result[2].avatarUrl) {
            this.marketplaceAvatar = result[2].avatarUrl;
          }
          this.hasMarketplaceAccess = result[2].marketplaceAccess;
        }
      })
  }

  private populateCompanyWorkspaces(params: CompanyWorkspacesParams) {
    const { workspaceResult, availableWorkspaces } = params;
    const projectRoles = this.authService.getCachedProjectRoles();
    const allowedRoles = ['ADMIN', 'COMPANY_PRINCIPAL'];
    const ownerRole = !this.isProductionCompany ? 'COMPANY_PROJECT_OWNER' : 'COMPANY_PRODUCTION_OWNER';
    workspaceResult.forEach(workspace => {
      if (allowedRoles.includes(this.authUser.globalRole)) {
        availableWorkspaces.push({
          ...workspace,
          canEdit: true,
          company: { name: this.authUser?.company.name },
          cacheBuster: this.cacheBuster
        });
        return;
      }

      if (workspace.usernames.indexOf(this.authUser.username) > -1) {
        let canEdit = this.authUser.globalRole === ownerRole;
        availableWorkspaces.push({
          ...workspace,
          canEdit,
          company: { name: this.authUser?.company.name },
          cacheBuster: this.cacheBuster
        });
        return;
      }

      let hasProjectAccess = false;
      if (workspace.projectIds?.length > 0) {
        for (let i = 0; i < workspace.projectIds.length; i++) {
          if (projectRoles[workspace.projectIds[i]]) {
            hasProjectAccess = true;
            break;
          }
        }
      }

      if (hasProjectAccess) {
        availableWorkspaces.push({
          ...workspace,
          canEdit: false,
          company: { name: this.authUser?.company.name },
          cacheBuster: this.cacheBuster
        });
      }
    });
  }

  private populateOtherWorkspaces(params: CompanyWorkspacesParams) {
    const { workspaceResult, projectResult, availableWorkspaces } = params;
    const idSet = new Set<string>();
    const currentCompanyId = this.authUser.companyId;
    let otherWorkspaces = [];
    const otherCompanies: any = {};
    let projects = projectResult;
    if (this.isProductionCompany) {
      projects = projects.filter(p => p.projectType == 'SUBSCRIPTION' || p.projectType == null);
    }
    projects.filter(p => p.company && p.company.id != currentCompanyId).forEach(p => {
      idSet.add(p.workspaceId);
      const companyId = p.company.id;

      if (!otherCompanies[companyId]) {
        otherCompanies[companyId] = {
          name: p.company.name
        };
      }
    });

    //no other company workspaces
    if (idSet.size === 0) {
      this.setWorkspaceList(availableWorkspaces);
      return;
    }

    Array.from(idSet).forEach(id => {
      const existing = this.workspaces.find(w => w.id == id);
      if (existing)
        idSet.delete(id);
    });

    this.workspaceService.getWorkspacesByIds(currentCompanyId, Array.from(idSet))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((workspaces: WorkspaceDTO[]) => {
        if (workspaces.length == 0) {
          this.setWorkspaceList(availableWorkspaces);
          return;
        }

        otherWorkspaces.push(...workspaces);
        otherWorkspaces.forEach(workspace => {
          workspace.canEdit = false;
          const company = otherCompanies[workspace.companyId];
          workspace.company = {
            name: company ? company.name : ''
          };
        });
        otherWorkspaces = otherWorkspaces.sort((a, b) => {
          let result = a.company.name.localeCompare(b.company.name);
          if (result != 0)
            return result;
          return a.name.localeCompare(b.name);
        });
        this.otherWorkspaces = otherWorkspaces;
        availableWorkspaces.unshift(...otherWorkspaces);
        this.setWorkspaceList(availableWorkspaces);
      });
  }

  private setWorkspaceList(availableWorkspaces: WorkspaceExtraDTO[]) {
    availableWorkspaces.sort((a, b) => a.name.localeCompare(b.name));
    this.workspaces = availableWorkspaces;
    this.workspaceService.setCachedWorkspaces(availableWorkspaces);
  }

  private adjustActiveWorkspace() {
    if (!this.project?.workspaceId && !this.activeCampaign?.workspaceId) {
      return;
    }

    if (this.activeWorkspace && (this.project?.workspaceId === this.activeWorkspace?.id || this.activeCampaign.workspaceId === this.activeWorkspace?.id)) {
      return;
    }

    let workspaceId = this.project ? this.project.workspaceId : this.activeCampaign.workspaceId;

    //if non ltg
    if (this.workspaces?.length > 0) {
      const recordedWorkspace = this.workspaces.find(w => w.id == workspaceId);
      if (recordedWorkspace) {
        this.activeWorkspace = recordedWorkspace;
        this.checkWorkspaceAccess();
        const currentWorkspace = this.workspaceService.getActiveWorkspace();
        if (!currentWorkspace || (currentWorkspace.id != this.project?.workspaceId || currentWorkspace.companyId != this.project.company.id))
          this.workspaceService.setActiveWorkspace(this.activeWorkspace);
        this.inMarketplaceWorkspace = false;
        return;
      }
    }

    this.workspaceService.getWorkspacesByIds(this.authUser.companyId, [workspaceId])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: workspaces => {
          if (workspaces.length > 0) {
            this.workspaceService.setActiveWorkspace(workspaces[0]);
          }
        },
        error: error => {

        }
      })
  }

  setWorkspace(workspace: WorkspaceExtraDTO) {
    this.project = null;
    this.workspaceService.setActiveWorkspace(workspace);
    this.workspaceService.changeStatus(true);
    this.router.navigate(['/projects']);
  }

  setMarketplaceWorkspace() {
    this.workspaceService.setInMarketplace(true);
    this.router.navigate(['/projects']);
  }

  goToInitialPage() {
    this.project = null;
    this.workspaceService.unsetActiveWorkspace();
    if (this.hasLtgPermission) {
      this.router.navigate(['/workspaces']);
    } else {
      this.router.navigate(['/projects']);
    }
  }

  goToReportingDashboard() {
    // let workspaceId = localStorage.getItem('workspaceV2Id');
    // this.router.navigate(['/workspaces/analytics'], {
    //   queryParams: {
    //     wid: workspaceId
    //   }
    // });
  }

  goToGamification(event: MouseEvent) {
    // event.preventDefault();
    // this.userTourService.isTourStart.emit(true)
    // this.router.navigate(['/workspaces/gamification/dashboard']);
  }

  backToCampaign() {
    let workspaceId = this.activeWorkspace.id;
    let campaignId = this.project?.campaignId;
    this.router.navigate(['/workspaces/campaigndrive/dashboard'],
      {
        queryParams: {
          wid: this.cryptoService.encryptString(workspaceId),
          dc: this.cryptoService.encryptString(campaignId)
        }
      });
  }

  private checkWorkspaceAccess() {
    if (!this.activeWorkspace) {
      return;
    }

    if (this.permissionService.hasAuthority(Authority.Z, null)) {
      this.canEditWorkspace = true;
      return;
    }

    const ownerRole = this.authUser.company.companyType == 'ADVERTISER' ? 'COMPANY_PROJECT_OWNER' : 'COMPANY_PRODUCTION_OWNER';
    if ((this.permissionService.hasAuthority(Authority.S, null) && this.activeWorkspace.companyId === this.authUser.companyId) ||
      (this.activeWorkspace.usernames.indexOf(this.authUser.username) > -1 && this.authUser.globalRole == ownerRole)) {
      this.canEditWorkspace = true;
      return;
    }

    this.canEditWorkspace = false;
  }

  backToWorkspace() {
    if (!this.hasLtgPermission) {
      this.router.navigate(['/projects']);
    }

    let workspaceId = this.activeWorkspace.id;
    this.router.navigate(['/workspaces/campaign-drive'],
      {
        queryParams: {
          wid: this.cryptoService.encryptString(workspaceId),
        }
      });
  }
}
type CompanyWorkspacesParams = {
  workspaceResult: WorkspaceDTO[];
  projectResult: Project[];
  availableWorkspaces: WorkspaceExtraDTO[];
}

interface WorkspaceExtraDTO extends WorkspaceDTO {
  canEdit: boolean;
  company: { name: string };
  cacheBuster: number;
}

import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DragulaService } from "ng2-dragula";
import { forkJoin, Observable, of, Subject, Subscription } from "rxjs";
import { ApiService } from "../../services/api.service";
import { concatMap, takeUntil, toArray } from "rxjs/operators";
import { NotificationService } from "app/shared/services/notification.service";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";
import { SubsectionService } from "app/shared/services/subsection.service";
import { GridOption, OptionVersion } from "app/shared/interfaces";
import { OverlayService } from "app/shared/services/overlayService";

@Component({
	selector: 'mtm-version-list',
	templateUrl: './subsection-version-list.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./subsection-version-list.component.scss']
})
export class SubsectionVersionListComponent implements OnInit, AfterViewInit, OnDestroy {
	private dragulaKey: string = 'versions-modal';
	private dropSubscription: Subscription = null;
	private deleteList: number[] = [];
	private _versions: OptionVersion[] = [];

	@Input() projectId: string;
	@Input() sectionId: string;
	@Input() subsectionId: string;
	@Input() itemId: string;
	@Input() option?: GridOption;
	@Input() isPreviewOnly: boolean = false;


	get versions(): OptionVersion[] {
		return this._versions;
	}

	@Input()
	set versions(values: OptionVersion[]) {
		this._versions = values;
		this._versions.forEach((v: any) => {
			v.items = v.files;
		});
	}

	isLoading: boolean = false;
	private ngUnsubscribe = new Subject();

	constructor(private dragulaService: DragulaService,
		private apiService: ApiService,
		private subsectionService: SubsectionService,
		private notificationService: NotificationService,
		private overlayService: OverlayService,
		private translatePipe: TranslatePipe,
		private activeModal: NgbActiveModal) {
		this.initDragula();
	}

	ngOnInit(): void {
		console.log('option', this.option, ', versions', this.versions);
	}

	private initDragula() {

		this.dragulaService.createGroup(this.dragulaKey, {
			moves: (el: any, container: any, handle: any) => {
				return $(handle).closest('.drag-button').length > 0;

			}
		});

		this.dropSubscription = this.dragulaService.drop(this.dragulaKey).subscribe((dropValue) => {

		});
	}

	ngAfterViewInit(): void {

	}

	ngOnDestroy(): void {
		this.dragulaService.destroy(this.dragulaKey);
		if (this.dropSubscription) {
			this.dropSubscription.unsubscribe();
		}
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	deleteVersion(version: OptionVersion) {
		const oldIndex = this._versions.findIndex(v => v.versionNumber == version.versionNumber);
		if (oldIndex <= -1) {
			return;
		}
		if (this._versions.length <= 1) {
			this.notificationService.open({
				title: this.translatePipe.transform('versioning_edit_deleteVersionError'),
				description: this.translatePipe.transform('versioning_edit_oneVersionRequired'),
				confirmBtn: this.translatePipe.transform('accept')
			});
			return;
		}

		this.deleteList.push(version.versionNumber);
		this._versions.splice(oldIndex, 1);
	}

	cancel() {
		this.activeModal.dismiss();
	}

	save() {
		this.isLoading = true;
		const orderedIds = this._versions.map(version => version.versionNumber);
		const $obsList: Array<Observable<any>> = [];

		if (this.isPreviewOnly) {
			this.activeModal.close(orderedIds);
			return;
		}

		if (this.deleteList.length > 0) {
			console.log('has obs to delete');
			$obsList.push(this.subsectionService.deleteVersions(this.projectId, this.sectionId, this.subsectionId,
				this.itemId, this.deleteList));
		}

		if (orderedIds.length > 0) {
			console.log('need to reorder');
			$obsList.push(this.subsectionService.reorderVersions(this.projectId, this.sectionId, this.subsectionId,
				this.itemId, orderedIds));
		}

		if ($obsList.length > 0) {
			of(...$obsList)
				.pipe(concatMap((obs: Observable<any>) => obs), toArray())
				.subscribe({
					next: (result: any) => {
						this.switchVersion(orderedIds);
					},
					error: () => {
						console.log('Failed to reorder');
						this.isLoading = false;
					}
				});

		} else {
			this.activeModal.close([]);
		}
	}

	private switchVersion(orderedIds: number[]) {
		const query$: Array<Observable<any>> = [];
		query$.push(this.subsectionService.getOption(this.projectId, this.sectionId, this.subsectionId, this.itemId));
		const newActiveVersion = orderedIds[0];

		if (!this.option.versionCache[newActiveVersion]) {
			query$.push(this.subsectionService.getVersion({
				projectId: this.projectId,
				sectionId: this.sectionId,
				subsectionId: this.subsectionId,
				itemId: this.itemId,
				versionNumber: newActiveVersion
			}));
		} else {
			this.option.files = this.option.versionCache[newActiveVersion].files;
			this.option.items = this.option.versionCache[newActiveVersion].files;
			this.option.currentVersion = newActiveVersion;
		}


		forkJoin(query$)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: (result: any[]) => {
					const newOption = this.subsectionService.convertOptionToViewModel(result[0]);
					if (newOption.simpleVersions) {
						newOption.simpleVersions.sort((v1, v2) => v1.order - v2.order);
					}
					this.option.simpleVersions = newOption.simpleVersions;

					if (result.length > 1) {
						const version = result[1];

						this.option.versionCache[newActiveVersion] = version;
						this.option.files = version.files;
						this.option.items = version.files;
						this.option.currentVersion = newActiveVersion;
					}
					this.isLoading = false;
					this.activeModal.close(orderedIds);
				},
				error: (err) => {
					console.error(err);
					this.isLoading = false;
				}
			});
	}
}

import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UUID } from 'angular2-uuid';
import { DriveCampaignColor, DriveCampaignStatus } from 'app/layouts/my-projects/project.const';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { AnalyticsService } from 'app/shared/services/analytics.service';
import { AuthService } from 'app/shared/services/auth.service';
import { CryptoService } from 'app/shared/services/crypto.service';
import { EmitterService } from 'app/shared/services/emitter.service';
import { HelperService } from 'app/shared/services/helper.service';
import { MtmBreadcrumbLink, MtmBreadcrumbService } from 'app/shared/services/mtm-breadcrumb.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { Authority, PermissionService } from 'app/shared/services/permissions.service';
import { ProjectV2ServiceService } from 'app/shared/services/project-v2-service.service';
import { ProjectService, onLoadProject } from 'app/shared/services/project.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { TimelineService } from 'app/shared/services/timeline.service';
import { environment } from 'environments/environment';
import _ from 'lodash';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WorkspaceService } from "app/shared/services/workspace.service";
import { WorkspaceDTO } from "app/shared/interfaces";
import { OverlayService } from 'app/shared/services/overlayService';
import { ProjectsComponent } from '../projects/projects.component';

@Component({
  selector: 'mtm-campaign-drive',
  templateUrl: './campaign-drive.component.html',
  styleUrls: ['./campaign-drive.component.scss', '../nav-tabs.scss']
})
export class CampaignDriveComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(NgbDropdown) settingsDropdown: QueryList<NgbDropdown>;
  @ViewChild(NgbDropdown, { static: false }) dropdownProjectStatus: NgbDropdown;
  @ViewChild('searchPanel', { static: false }) searchPanel: TemplateRef<any>;
  @ViewChild(ProjectsComponent) projects!: ProjectsComponent;

  defaultimage = '../../../assets/img/project-v2/hr/skin-care-3.png';
  worskspaceSearch: string;
  ngUnsubscribe = new Subject();
  isLoaded: boolean = false;
  inWorkspace: boolean = false;
  backupDriveCampaigns = [];
  DriveCampaigns = [];
  favbackupDriveCampaigns = [];
  favDriveCampaigns = [];
  archiveDriveCampaigns = [];
  archiveBackupDriveCampaigns = [];
  timelines: any = [];
  authUser: any;
  isHrUser: boolean = false;
  isYslUser: boolean = false;
  userType: string;
  workspaceId: string;
  url: any;
  projectColor = DriveCampaignColor;
  projectStatus = DriveCampaignStatus;
  initialName: any;
  nameColor: any;
  usersList: any = {};
  usersLoaded: boolean = false;
  blobPicture: any;
  username: any;
  driveStatus: any[] = [{
    value: 'IN_PROGRESS',
    label: 'In Progress'
  }, {
    value: 'COMPLETED',
    label: 'Completed'
  }, {
    value: 'DELAYED',
    label: 'Delayed'
  }];
  categoryTags: string[] = [];
  brandId: string;
  canCreateDriveCampaign: boolean = false;
  canEditStatus: boolean = false;
  canCreateProject: boolean = false;
  workspaceName: string;
  banner: any;
  isError: boolean = false;
  cacheBuster: string = new Date().getTime().toString();
  isActive: boolean = true;
  searchDriveCampaign: string;
  selectedItem: string;
  workspaces: any[] = [];
  selectedWorkspace: any = {
    name: 'All Workspaces',
    id: null
  };

  selectedDateFilter: any = {
    name: 'Last updated',
    id: null
  };

  calendarViewMode = 'Last updated';
  isAll: boolean = true;
  isFav: boolean = false;
  isFavorites: boolean = false;
  isArchived: boolean = false;
  hasNext: boolean = false;
  isAllProject: boolean = true;
  isFavoritesProject: boolean = false;
  isArchivedProject: boolean = false;
  page: number = 1;
  isInit: boolean = false;
  pagingState: string;
  isCpd: boolean = false;
  headerLabel = 'loreal_campaigns';
  isCampaignCollapse: boolean = true;
  isProjectCollapse: boolean = true;
  isProjectLoaded: boolean = false
  activeTab: string = 'all';
  showGoBackToWorkspace: boolean = false;
  calendarViewDates: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectV2Service: ProjectV2ServiceService,
    private translatePipe: TranslatePipe,
    private analyticService: AnalyticsService,
    private authService: AuthService,
    private cryptoService: CryptoService,
    private breadcrumbService: BreadcrumbService,
    private notificationService: NotificationService,
    public timelineService: TimelineService,
    private permissionService: PermissionService,
    public projectService: ProjectService,
    private mtmBreadcrumbService: MtmBreadcrumbService,
    private overlayService: OverlayService,
    private workspaceService: WorkspaceService,
    private themeService: ThemeService,
  ) {
    this.mtmBreadcrumbService.setLinks([]);
    this.setBreadcrumb();
    this.timelineService.lastSelectedDriveCampaign = null;
    this.timelineService.isDriveNav.emit(true);
    this.router.events.subscribe((event: any) => {
      if (event.url) {
        this.url = event.url
      }
    });
    this.nameColor = this.stringToHslColor(UUID.UUID() + " " + UUID.UUID());

    // Subscribe to change avatar user
    EmitterService.get('authUser.changeAvatar').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((data) => {
      if (data && data.externalUser) {
        this.username = data.user.email;
        setTimeout(() => {
          this.reloadProfilePicture();
        }, 350);
      } else if (data && data.email === this.username) {
        setTimeout(() => {
          this.reloadProfilePicture();
        }, 350);
      }
    })
    EmitterService.get('BRAND_UPDATED').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (brand) => {
        this.setBreadcrumb();
      }
    });
  }

  ngOnInit(): void {
    // resetTab
    // this.timelineService.resetTabsEmmiter.emit(true);
    this.insideWorkspace();
    this.timelineService.activeCampaign$.next(null);

    this.authUser = this.authService.getAuthUser();
    this.isCpd = this.authUser.companyId == environment.ltg.cpdCompanyId;
    this.headerLabel = this.isCpd ? 'loreal_campaigns' : 'loreal_driveCampaigns';
    this.workspaceName = this.timelineService.workspaceName;
    // for dashboard image
    
    this.brandId = localStorage.getItem('brandId')
    this.nameColor = this.stringToHslColor(this.username);
    this.route.queryParams.subscribe(query => {
      this.showGoBackToWorkspace = !!query.wid;
      if (query.wid) {
        this.workspaceId = this.cryptoService.decryptUsingAES256(query.wid);
        this.timelineService.workspaceId = this.workspaceId;
        this.getWorkspaceData();
        if (this.workspaceId) {
          this.workspaceName = this.timelineService.workspaceName;
          const brand = JSON.parse(localStorage.getItem('brand'));
          const pageLinks: MtmBreadcrumbLink[] = [{
            label: brand.name
          }, {
            label: this.selectedWorkspace.name,
            url: ['/workspaces', 'campaign-drive'],
            queryParams: {
              wid: this.cryptoService.encryptString(this.workspaceId)
            }
          }, {
            label: 'Campaigns'
          }]
          this.mtmBreadcrumbService.setLinks(pageLinks);
        }
      } else {
        this.timelineService.workspaceId = '';
      }
    });
    this.getWorkspaceDriveCampaign();
    this.setProfilePicture(this.authUser)
    this.getSideNavWorkspace();

    // get workspace
    this.getWorkspaces();

    this.isInit = true;
    // trigger tabs
    // this.getTabsInputs();

    this.canCreateDriveCampaign = ((this.permissionService.hasAuthority(Authority.Z, this.permissionService.GLOBAL_SCOPE_PROJECT_ID) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription()) && this.permissionService.hasAuthority(Authority.P, this.permissionService.GLOBAL_SCOPE_PROJECT_ID));
    this.canEditStatus = (this.permissionService.hasAuthority(Authority.Z, this.permissionService.GLOBAL_SCOPE_PROJECT_ID) || this.permissionService.hasAuthority(Authority.Z)) || (this.authService.hasPaidSubscription() && this.permissionService.hasAuthority(Authority.S, this.permissionService.GLOBAL_SCOPE_PROJECT_ID));
    this.canCreateProject = (((this.permissionService.hasAuthority(Authority.Z, this.permissionService.GLOBAL_SCOPE_PROJECT_ID) || this.permissionService.hasAuthority(Authority.Z)) || this.authService.hasPaidSubscription()) && this.permissionService.hasAuthority(Authority.P, this.permissionService.GLOBAL_SCOPE_PROJECT_ID));

  }

  ngAfterViewInit(): void {
    this.setBreadcrumb();
    this.themeService.updateContent({
      searchPanel: this.searchPanel,
      fullWidthContent: true
    });
    // breadcrumb
    if (!this.workspaceId) {
      this.breadcrumbService.addCallbackForRouteRegex(`/workspaces/campaign-drive`, (id) => {
        return 'Drive Campaign';
      });
    } else {
      this.breadcrumbService.addCallbackForRouteRegex(`/workspaces/campaign-drive`, (id) => {
        return this.timelineService.workspaceName;
      });
    }
  }

  setBreadcrumb() {
    const brand = JSON.parse(localStorage.getItem('brand'));
    const pageLinks: MtmBreadcrumbLink[] = [{
      label: brand.name
    },{
      label:this.isCpd ? 'Campaigns & Projects' : 'Drive Campaigns & Projects'
    },]
    this.mtmBreadcrumbService.setLinks(pageLinks);
   
  }

  getSideNavWorkspace() {
    this.timelineService.selectedWorkspace.subscribe((wk: any) => {
      if (wk && wk.id) {
        this.timelineService.componentName.next({
          componentName: 'campaignDriveComponent',
          bannerUrl: wk.coverUrl
        });
        this.workspaceId = wk.id;
        this.timelineService.workspaceId = this.workspaceId;
        this.getWorkspaceDriveCampaign();
      }
    })
  }

  ngOnDestroy(): void {
    this.mtmBreadcrumbService.setLinks([]);
    this.timelineService.workspaceName=this.workspaceName;
    this.themeService.updateContent({
      searchPanel: null,
      fullWidthContent: false
    })
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  prepareBreadcrumbs(): void {
    if (this.url) {
      const route = this.url.split('?')[0];
      const searchParams = new URLSearchParams(this.url.split('?')[1]);
      const queryParams = {};
      for (const [key, value] of searchParams.entries()) {
        queryParams[key] = value;
      }
      this.breadcrumbService.hideRoute(route);
      const newRoute = `${route}?${Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&')}`;
      const newRouteName = this.timelineService.workspaceName;
      this.breadcrumbService.addFriendlyNameForRoute(newRoute, newRouteName);
    }
  }

  getTimelines() {
    this.projectV2Service.getTimelines().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(result => {
      this.timelines = result;
    });
  }

  getWorkspaceDriveCampaign() {
    this.isLoaded = false;
    this.isError = false;
    this.DriveCampaigns = [];
    this.hasNext  = false;
    if (!this.workspaceId) {
      this.workspaceId = ''
    }
    let archived: boolean = false;
    let limit = 15;
    if (this.isAll) {
      archived = false
    } else if (this.isArchived) {
      archived = true
    }

    if (this.calendarViewDates) {
      this.calendarViewDates = this.calendarViewDates;
    } else {
      this.calendarViewDates = null;
    }

    this.projectV2Service.getDriveCampaign(this.workspaceId, archived,this.calendarViewDates,this.pagingState).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (result: any) => {
        this.hasNext = result.hasNext;
				if (result.pagingState){
					this.pagingState = result.pagingState+1;
        }
        this.isLoaded = true;
        if (this.workspaceId) {
          this.DriveCampaigns = result.data;
          this.backupDriveCampaigns = result.data;
        } else {
          // this.page = result.pagingState + 1;
          this.DriveCampaigns = result.data;
          this.backupDriveCampaigns = result.data;
        }
        // Get category or tags
        this.DriveCampaigns.forEach(drives => {
          drives['isFavorite'] = false;
          if (drives.logo) {
            drives.logo = drives.logo + `?v=${this.cacheBuster}`
          }
          if (drives.name) {
            this.categoryTags.push(drives.name);
          }
        });
        // Sort data based on alphabetical order
        this.DriveCampaigns.sort((d1, d2) => d1.name.localeCompare(d2.name));
        this.backupDriveCampaigns.sort((p1, p2) => p1.name.localeCompare(p2.name));
        this.favDriveCampaigns = this.DriveCampaigns;
        this.favbackupDriveCampaigns = this.backupDriveCampaigns;
        if (archived) {
          this.archiveBackupDriveCampaigns = this.backupDriveCampaigns;
          this.archiveDriveCampaigns = this.DriveCampaigns;
        }
        this.isInit = false;
        this.getFavoriteDriveCampaigns();
      },
      error: (error) => { // Corrected syntax for the error handling block
        console.error('error', error)
        this.isError = true;
        this.isLoaded = false;
      }
    })
  }

  searchDriveCampaigns() {
    this.projects.projectSearch = this.searchDriveCampaign;
    this.projects.searchProject();

    if (this.searchDriveCampaign) {
      this.DriveCampaigns = this.backupDriveCampaigns.filter(item => {
        let isMatched = true;
        if (this.searchDriveCampaign) {
          if (!item.name) {
            isMatched = false;
          } else {
            isMatched = item.name.toLowerCase().indexOf(this.searchDriveCampaign.toLowerCase()) > -1;
          }
          if (!isMatched) {
            this.DriveCampaigns = this.backupDriveCampaigns;
          }
        }
        return isMatched;
      })
    } else {
      this.DriveCampaigns = this.backupDriveCampaigns;
    }
  }

  createCampaignDrive() {
    // reset driveCoordinatorMilestone
    this.projectV2Service.driveCoordinatorMilestone = undefined;
    this.projectV2Service.timelineId = null;
    this.projectV2Service.updateFormData({}, {})
    this.router.navigate([`workspaces/campaigndrive/${this.workspaceId}/general`]);
    this.timelineService.isDriveNav.emit(false);
  }

  redirectToDashboard(drive) {
    this.timelineService.componentName.next({
      componentName: 'milestoneComponent',
      bannerUrl: drive.banner
    })
    if (!this.authService.hasPaidSubscription()) {
      return;
    }
    this.timelineService.lastSelectedDriveCampaign = drive;
    this.projectV2Service.driveCoordinatorMilestone = undefined;
    const path = `workspaces/campaigndrive/dashboard`;
    this.router.navigate([path], {
      queryParams: {
        wid: this.cryptoService.encryptString(drive.workspaceId),
        dc: this.cryptoService.encryptString(drive.id),
      }
    });
    //reset stored milestone
    this.timelineService.updateMilestone({});

  }

  gotoWorkspace(project) {
    this.router.navigateByUrl('/temporaryRedirect', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/workspaces/campaign-drive'],
        {
          queryParams: {
            wid: this.cryptoService.encryptString(project?.workspaceId),
          }
        });
    });
  }

  editCampaignDrive(drive) {
    this.projectV2Service.updateSelectedDriveCampaign(drive.workspaceId, drive.id);
  }

  // set profile picture as a name of company if image is not available
  private setProfilePicture(currentUser) {
    let fullName = currentUser.fullName ? _.get(currentUser, 'fullName', '') : _.get(currentUser, 'firstName', '') + ' ' + _.get(currentUser, 'lastName', '');
    let nameArr = fullName.toLowerCase().split(' ');
    if (nameArr.length > 0 && !HelperService.isObjectEmpty(nameArr[0][0])) {
      this.initialName = nameArr[0][0];
      if (nameArr.length > 1 && !HelperService.isObjectEmpty(nameArr[1][0])) {
        this.initialName += nameArr[1][0];
      } else {
        this.initialName += nameArr[0][1];
      }
      this.nameColor = this.stringToHslColor(this.initialName);
      this.blobPicture = _.get(currentUser, 'blobPicture') ? void 0 : currentUser.blobPicture;
    }
  }

  reloadProfilePicture() {
    this.usersLoaded = false;
    this.usersList[this.username] = void 0;
  }

  stringToHslColor(initial: any = []) {
    let hash = 0;
    if (!initial) {
      return;
    }
    for (let i = 0; i < initial.length; i++) {
      hash = initial.charCodeAt(i) + ((hash << 5) - hash);
    }
    let h = hash % 360;
    return 'hsl(' + h + ', 75%, 62%)';
  }

  changeDriveCampaignStatus(item, status) {
    this.dropdownProjectStatus.close();
    item.status = status;
    this.projectV2Service.updateDriveCampaign(item)
      .subscribe(res => {
      });
  }

  deleteDriveCampaign(item) {
    this.notificationService.open({
      title: this.translatePipe.transform('confirmation'),
      notificationType: 'danger',
      centerHeader: true,
      question: this.translatePipe.transform('deleteDriveCampaignConfirm'),
      question2: this.translatePipe.transform('deleteDriveCampaignConfirmQuestion'),
      confirmBtn: this.translatePipe.transform('confirm'),
      cancelBtn: this.translatePipe.transform('cancel')
    }).subscribe(confirm => {
      if (confirm) {
        this.projectV2Service.deleteDriveCampaign(item.workspaceId, item.id)
          .subscribe({
            next: res => {
              this.DriveCampaigns = this.DriveCampaigns.filter(d => d.id != item.id);
            },
            error: err => {
              if (err?.errorCode === 'INVALID_DELETE_CAMPAIGN_OPERATION') {
                this.overlayService.showError(this.translatePipe.transform('deleteDriveCampaign_error_hasProject'));
              } else {
                this.overlayService.showError(this.translatePipe.transform('deleteDriveCampaign_error'));
              }
            }
          });
      }
    });
  }

  redirectToCampaign() {
    this.router.navigate(['workspaces/campaign'], {
      queryParams: {
        wid: this.cryptoService.encryptString(this.workspaceId),
      }
    });
  }
  search() {

  }

  changeWorkspace(workpsace) {
    if (workpsace === 'all_workspaces') {
      this.selectedWorkspace = {
        name: 'All Workspaces'
      }
      this.workspaceId = '';
      const navigationExtras: NavigationExtras = {
        queryParams: {}
      };

      const brand = this.authService.getMyBrand();
      this.timelineService.componentName.next({
        componentName: 'campaignDriveComponent',
        bannerUrl: brand.banner
      });

      this.router.navigate(['/workspaces/campaign-drive'], navigationExtras);
    } else {
      this.workspaceName = workpsace.name;
      this.selectedWorkspace = workpsace;
      if (workpsace.id || this.workspaceId) {
        let wid = this.cryptoService.encryptString(workpsace.id);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            wid: wid,  // Use the encrypted workspaceId
          },
          queryParamsHandling: 'merge',  // Preserve existing queryParams
        });
      }
      this.workspaceId = workpsace.id;
      this.timelineService.workspaceId = this.workspaceId;
      this.timelineService.componentName.next({
        componentName: 'campaignDriveComponent',
        bannerUrl: this.selectedWorkspace.coverUrl
      });
    }
    if (this.selectedWorkspace?.id) {
      const brand = JSON.parse(localStorage.getItem('brand'));
      const pageLinks: MtmBreadcrumbLink[] = [
        {
          label: brand.name
        }, {
          label: 'Workspaces',
          url: ['/workspaces']
        }, {
          label: this.selectedWorkspace.name,
          url: ['/workspaces', 'campaign-drive'],
          queryParams: {
            wid: this.cryptoService.encryptString(this.workspaceId)
          }
        }
      ]
      this.mtmBreadcrumbService.setLinks(pageLinks);
    } else {
      const brand = JSON.parse(localStorage.getItem('brand'));
      const pageLinks: MtmBreadcrumbLink[] = [
        {
          label: brand.name
        }, {
          label: 'Workspaces',
          url: ['/workspaces']
        }
      ]
      this.mtmBreadcrumbService.setLinks(pageLinks);
    }
    this.getWorkspaceDriveCampaign();
  }

  changeDate(event) {
    this.selectedDateFilter = event;
  }

  editBanner() {
  }

  removeBanner() {
  }

  getBrands() {
    this.isError = false;
    this.isLoaded = false;
    this.analyticService.getBrands().pipe(takeUntil(this.ngUnsubscribe)).
      subscribe({
        next: (brands: any) => {
          let allBrands = brands;
          allBrands.forEach(brand => {
            // set header banner
            if (brand.workspaces) {
              this.workspaces.push(...brand.workspaces);
              this.workspaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
            }
          });
          this.isLoaded = true;
        },
        error: (err) => {
          this.isError = true;
          console.error('An error occurred:', err);
        },
        complete: () => {
          console.info('complete')
        }
      })
  }

  getWorkspaces() {
    this.workspaces = [];
    this.analyticService.getBrands().pipe(takeUntil(this.ngUnsubscribe)).
      subscribe({
        next: (brands: any) => {
          let allBrands = brands;
          allBrands.forEach(brand => {
            // set header banner
            if (brand.workspaces) {
              this.workspaces.push(...brand.workspaces);
              this.workspaces.sort((w1, w2) => w1.name.localeCompare(w2.name));
            }
            this.workspaces.sort((d1, d2) => d1.name.localeCompare(d2.name));
            if (this.workspaceId) {
              let workspace = this.workspaces.find(w => w.id === this.workspaceId);

              if (workspace) {
                this.selectedWorkspace = workspace;
              } else {
                console.warn('Workspace not found with id:', this.workspaceId);
              }
            }
            if (this.workspaceId) {
              const selectedWorkspace = this.workspaces.find(w => w.id == this.workspaceId);
              this.timelineService.workspaceName = selectedWorkspace.name;
              this.changeWorkspace(selectedWorkspace);
            } else {
              const brand = this.authService.getMyBrand();
              this.timelineService.componentName.next({
                componentName: 'campaignDriveComponent',
                bannerUrl: brand.banner
              });
            }
          })
        }, error: (err) => {
          console.error('err', err)
        }
      });
  }

  shouldShowEllipsis(name: string): boolean {
    if (name && name.length) {
      return name.length > 15;
    }
  }

  markAsFavorite(driveCampaign) {
    this.analyticService.markAsFavorite(driveCampaign, 'DRIVE_CAMPAIGN').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        this.DriveCampaigns = this.DriveCampaigns.map(dc => ({
          ...dc,
          isFavorite: res.items.includes(dc.id)
        }));
      },
      error: (err) => {
        driveCampaign.isFavorite = false;
        console.error(err)
      },
      complete: () => {

      }
    });
  }

  removeFavorite(driveCampaign) {
    this.analyticService.removeAsFavorite(driveCampaign, 'DRIVE_CAMPAIGN').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        // this.DriveCampaigns = this.DriveCampaigns.map(dc => ({
        //   ...dc,
        //   isFavorite: res.items.includes(dc.id)
        // }));
        this.getWorkspaceDriveCampaign();
      },
      error: (err) => {
        console.error(err)
      },
      complete: () => {
      }
    });
  }

  selectCalendarMode(mode) {
    this.calendarViewDates = this.projectV2Service.calculateDateRange(mode);
    EmitterService.get('CALENDAR_FILTER_UPDATED').emit(this.calendarViewDates)

    this.getWorkspaceDriveCampaign();

    switch (mode) {
      case '3_months':
        this.calendarViewMode = '3 Months';
        break;
      case '6_months':
        this.calendarViewMode = '6 Months';
        break;
      case 'week':
        this.calendarViewMode = 'Week';
        break;
      default:
        this.calendarViewMode = 'Last updated';
        break;
    }
  }

  getFavoriteDriveCampaigns() {
    this.isError = false;
    this.isLoaded = false;
    this.DriveCampaigns = [];
    this.backupDriveCampaigns = [];
    this.analyticService.getFavoriteItem('DRIVE_CAMPAIGN').pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (res: any) => {
        if (this.isFav) {
          if (this.workspaceId) {
            this.DriveCampaigns = res.filter(campaign => campaign.workspaceId === this.workspaceId);
          } else {
            this.DriveCampaigns = res; // If no workspace is selected, show all favorites
          }

          this.backupDriveCampaigns = [...this.DriveCampaigns];
          this.DriveCampaigns.forEach(project => {
            project.isFavorite = true;
            project.logo = project.logo ? project.logo + `?v=${this.cacheBuster}` : null;
          });
          this.backupDriveCampaigns.forEach(project => {
            project.isFavorite = true;
            project.logo = project.logo ? project.logo + `?v=${this.cacheBuster}` : null;
          });

          // Sort the results alphabetically by name
          this.DriveCampaigns.sort((a, b) => a.name.localeCompare(b.name));
          this.backupDriveCampaigns.sort((a, b) => a.name.localeCompare(b.name));

          this.isLoaded = true;
        } else if (this.isAll) {
          this.favDriveCampaigns.forEach(driveCamapign => {
            const founddriveCamapign = res.find(w => w.id === driveCamapign.id);
            driveCamapign.isFavorite = founddriveCamapign !== undefined;
          });
          this.favbackupDriveCampaigns.forEach(driveCamapign => {
            const founddriveCamapign = res.find(w => w.id === driveCamapign.id);
            driveCamapign.isFavorite = founddriveCamapign !== undefined;
          });
          this.isLoaded = true;
          // remove duplicate items
          this.DriveCampaigns = this.favDriveCampaigns.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.backupDriveCampaigns = this.favbackupDriveCampaigns.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.DriveCampaigns.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.backupDriveCampaigns.sort((p1, p2) => p1.name.localeCompare(p2.name));
        } else if (this.isArchived) {
          this.archiveDriveCampaigns.forEach(driveCamapign => {
            const founddriveCamapign = res.find(w => w.id === driveCamapign.id);
            driveCamapign.isFavorite = founddriveCamapign !== undefined;
          });
          this.archiveBackupDriveCampaigns.forEach(driveCamapign => {
            const founddriveCamapign = res.find(w => w.id === driveCamapign.id);
            driveCamapign.isFavorite = founddriveCamapign !== undefined;
          });
          this.isLoaded = true;
          // remove duplicate items
          this.DriveCampaigns = this.archiveDriveCampaigns.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.backupDriveCampaigns = this.archiveBackupDriveCampaigns.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.id === value.id
            )));
          this.DriveCampaigns.sort((p1, p2) => p1.name.localeCompare(p2.name));
          this.backupDriveCampaigns.sort((p1, p2) => p1.name.localeCompare(p2.name));
        }
        this.isLoaded = true;
      },
      error: (err) => {
        console.error(err);
        this.isError = true;
      },
      complete: () => {
        // Handle completion if needed
      }
    });
  }

  getTabsInputs() {
    this.timelineService.isAll.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = true;
          this.isFav = false;
          this.isArchived = false;
          this.timelineService.emitIsAll(false);
          this.getWorkspaceDriveCampaign();
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });

    this.timelineService.isFavorites.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = false;
          this.isFav = true;
          this.isArchived = false;
          this.getFavoriteDriveCampaigns();
          this.timelineService.emitIsFavorites(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });

    this.timelineService.isArchived.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data: boolean) => {
        if (data) {
          this.isAll = false;
          this.isFav = false;
          this.isArchived = true;
          this.getWorkspaceDriveCampaign();
          this.timelineService.emitIsArchived(false);
        }
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        // Handle completion if needed
      }
    });
  }

  archiveDriveCampaign(item) {
    item.archived = !item.archived;
    this.projectV2Service.updateDriveCampaign(item).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (data) => {
        this.DriveCampaigns = this.DriveCampaigns.filter(drive => drive.id != item.id);
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  loadMore(check: boolean) {
    if (!check || !this.hasNext) {
      return;
    }
    this.getWorkspaceDriveCampaign();
  }

  /**
   * toggle tabs
   **/
  allItems() {
    this.isArchived = false;
    this.isFavorites = false;
    this.isFav = false;
    this.isAll = true;
    this.getWorkspaceDriveCampaign();

  }

  favoriteItems() {
    this.isArchived = false;
    this.isFavorites = true;
    this.isFav = true;
    this.isAll = false;
    this.getFavoriteDriveCampaigns();
  }

  getArchiveCampaign() {
    this.isArchived = true;
    this.isFavorites = false;
    this.isFav = false;
    this.isAll = false;
    this.getWorkspaceDriveCampaign();
  }

  allProjectItems() {
    this.isArchivedProject = false;
    this.isFavoritesProject = false;
    this.isAllProject = true;
    this.timelineService.emitIsAll(true);
  }

  favoriteProjectItems() {
    this.isArchivedProject = false;
    this.isFavoritesProject = true;
    this.isAllProject = false;
    this.timelineService.emitIsFavorites(true);
  }

  getArchiveProjects() {
    this.isArchivedProject = true;
    this.isFavoritesProject = false;
    this.isAllProject = false;
    this.timelineService.emitIsArchived(true);
  }
  /**
   * End
   */

  /**
   * Collapse campaigns
   */
  CollapseCampaign() {
    this.isCampaignCollapse = !this.isCampaignCollapse;
  }
  /**
   * End
   */

  /**
  * Collapse projects
  */
  CollapseProjects() {
    this.isProjectCollapse = !this.isProjectCollapse;
  }
  /**
   * End
   */

  /**
   * Create project
   */
  createProject() {
    window.location.href = this.workspaceId ? `/projects/new/company?workspaceId=${this.workspaceId}` : `/projects/new/company`;
  }

  private getWorkspaceData() {
    const companyId = this.authUser.companyId;
    let workspace = this.workspaceService.getActiveWorkspace();
    if (workspace) {
      return;
    }

    this.workspaceService.getWorkspacesByIds(companyId, [this.workspaceId])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (wsList: WorkspaceDTO[]) => {
          if (wsList.length > 0) {
            this.workspaceService.setActiveWorkspace(wsList[0]);
          }
        }
      })
  }
  insideWorkspace() {

    const currentUrl = this.router.url;
    let check_route;
    const pathStartsWith = currentUrl.startsWith('/workspaces/campaign-drive');

    this.route.queryParams.subscribe(params => {
      const hasWidParameter = 'wid' in params;

      let hasMatchingUrl = pathStartsWith && hasWidParameter;

      check_route = hasMatchingUrl;
    });

    this.inWorkspace = check_route;
  }

  handleItemsScroll() {
    if (!this.settingsDropdown) {
      return;
    }

    this.settingsDropdown.forEach((dropdown: NgbDropdown) => {
      if (dropdown.isOpen()) {
        dropdown.close();
      }
    });
  }

  backToWorkspace() {
    this.router.navigate(['/workspaces'])
  }
}
